import React, { Fragment, useState } from 'react';
import {
    Button,
    ClickAwayListener,
    DialogActions,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Create, DeleteOutlined, HelpOutline } from '@material-ui/icons';
import clsx from 'clsx';

export default function UnitRow(props) {
    const classes = useStyles();

    //######## STATE ########
    const [open, setOpen] = useState(props.open);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuType, setMenuType] = React.useState('');

    //######## FUNCTIONS ########
    const handleChangeValueClick = event => {
        setAnchorEl(event.currentTarget);
        setMenuType(event.currentTarget.id);
    };

    const handleChangeValueClose = () => {
        setAnchorEl(null);
        setMenuType('');
    };

    const handleUnitTypeDropDownChange = event => {
        props.onUnitTypeChange(props.type, event);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const addVariable = e => {
        e.stopPropagation();
        if (!open) {
            setOpen(true);
        }

        props.addSubValue(props.type);
    };

    const getTotalValue = () => {
        let noValue = '--';
        let totalValue = -1;
        console.log(props.subValues);
        
        props.subValues.forEach((v, i) => {
            if (v > 0) {
                if (i === 0) {
                    totalValue = parseFloat(v);
                } else {
                    totalValue *= parseFloat(v);
                }
            }
        });

        if (totalValue !== -1) {
            return Math.round((totalValue + Number.EPSILON) * 10000) / 10000;
        } else {
            return noValue;
        }
    };

    const getInputUnit = () => {
        let { unitType, units, type } = props;
        let unit = units.find(u => u.id === unitType);
        return unit ? unit.name : '';
    };

    const getDisplayUnit = () => {
        let { displayUnitType, units, type } = props;
        let unit = units.find(u => u.id === displayUnitType);
        return unit ? unit.name : '';
    };

    const getOutputUnit = () => {
        let { conversionUnitType, units, type } = props;
        let unit = units.find(u => u.id === conversionUnitType);
        return unit ? (type === 'speed' ? unit.name + '/min' : unit.name) : '';
    };

    //######## RENDER ########
    const getExplainerText = () => {
        if (getDisplayUnit() === getInputUnit()) {
            return (
                <div>
                    Input and display units are both <span className={classes.bold}>{getInputUnit()}</span>
                </div>
            );
        }

        return (
            <div>
                There is {getTotalValue()} <span className={classes.bold}>{getDisplayUnit()}</span> in a{' '}
                <span className={classes.bold}>{getInputUnit()}</span>
            </div>
        );
    };

    const renderVariablesPopover = () => {
        return (
            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleChangeValueClose}
            >
                {props.subValues.map((v, i) => {
                    return (
                        <div key={'sub-value-' + i} className={classes.subValueRow}>
                            <div className={classes.subValueRowDiv}>
                                <TextField
                                    variant={'standard'}
                                    className={classes.notesTextField}
                                    color={'primary'}
                                    autoFocus={i === 0}
                                    type="number"
                                    value={v.value}
                                    // placeholder="Variable Value"
                                    onChange={props.onValueChange.bind(this, props.type, i)}
                                    error={v.value <= 0 && v.value !== ''}
                                    helperText={v.value <= 0 && v.value !== '' ? 'Positive number only' : ''}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    variant={'standard'}
                                    className={classes.notesTextField}
                                    color={'primary'}
                                    value={v.notes}
                                    placeholder="Notes"
                                    onChange={props.onNotesChange.bind(this, props.type, i)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                            {i !== 0 && (
                                <DeleteOutlined
                                    className={classes.deleteVariableIcon}
                                    onClick={props.onSubValueDelete.bind(this, props.type, i)}
                                    htmlColor={'#0A71CE'}
                                />
                            )}
                        </div>
                    );
                })}
                <DialogActions>
                    {props.resetToLineDefaults && (
                        <Button variant={'text'} onClick={props.resetToLineDefaults.bind(this, props.type)}>
                            Reset
                        </Button>
                    )}
                    <Button variant={'contained'} color={'primary'} onClick={addVariable}>
                        Add Variable
                    </Button>
                </DialogActions>
            </Popover>
        );
    };

    const renderOutputConversionPopover = () => {
        return (
            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleChangeValueClose}
            >
                <div className={classes.subValueRow}>
                    <div className={classes.outputConversionInputDiv}>
                        <TextField
                            variant={'standard'}
                            color={'primary'}
                            autoFocus
                            type="number"
                            value={props.conversionValue}
                            // placeholder="Conversion Value"
                            onChange={props.onConversionValueChange.bind(this, props.type)}
                            error={props.conversionValue < 0}
                            helperText={props.conversionValue < 0 ? 'Positive number only' : ''}
                            InputProps={{ inputProps: { min: 0 } }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </div>
                </div>
                {props.resetToLineDefaults && (
                    <DialogActions>
                        <Button variant={'text'} onClick={props.resetToLineOutputDefault.bind(this, props.type)}>
                            Reset
                        </Button>
                    </DialogActions>
                )}
            </Popover>
        );
    };

    const renderDisplayUnitDropdown = () => {
        if (props.name.toLowerCase() === 'sensor count') {
            return <div> </div>;
        }

        return (
            <Fragment>
                <InputLabel
                    className={clsx([classes.label, classes.labelMargin])}
                    shrink={true}
                    id="unit-type-select-label"
                >
                    Display unit
                </InputLabel>
                <TextField
                    select
                    className={classes.selectTextField}
                    value={props.displayUnitType}
                    error={props.unitTypeError}
                    onChange={handleUnitTypeDropDownChange}
                    disabled={!props.enabled}
                    InputProps={{ disableUnderline: true }}
                >
                    {props.units.map(u => {
                        return (
                            <MenuItem key={'unit-' + u.id} value={u.id} id="display-unit-type">
                                {u.name}
                            </MenuItem>
                        );
                    })}
                </TextField>
                <FormHelperText
                    className={clsx({ [classes.helperDisabled]: !props.enabled })}
                    error={props.unitTypeError}
                    disabled={!props.enabled}
                >
                    {props.enabled ? (props.unitTypeError ? 'Unit required' : ' ') : 'Default'}
                </FormHelperText>
            </Fragment>
        );
    };

    const renderOutputDiv = () => {
        if (props.name.toLowerCase() === 'sensor count') {
            return (
                <div className={classes.outputDiv}>
                    {' '}
                    <FormControl className={classes.formControl}>
                        <InputLabel className={clsx([classes.label, classes.labelMargin])} shrink={true}>
                            Display & Output unit
                        </InputLabel>
                        <TextField
                            select
                            className={classes.selectTextField}
                            value={props.displayUnitType}
                            error={props.unitTypeError}
                            onChange={handleUnitTypeDropDownChange}
                            disabled={!props.enabled}
                            InputProps={{ disableUnderline: true }}
                        >
                            {props.units.map(u => {
                                return (
                                    <MenuItem key={'unit-' + u.id} value={u.id}>
                                        {u.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <FormHelperText
                            className={clsx({ [classes.helperDisabled]: !props.enabled })}
                            error={props.unitTypeError}
                            disabled={!props.enabled}
                        >
                            {props.enabled ? (props.unitTypeError ? 'Unit required' : ' ') : 'Default'}
                        </FormHelperText>
                    </FormControl>
                </div>
            );
        }

        let unit = props.units.find(u => u.id === props.conversionUnitType);
        return (
            <div className={classes.outputDiv}>
                <div className={classes.unitValueDiv}>
                    <div className={classes.labelDiv}>
                        <Typography variant="caption" className={classes.label}>
                            Output conversion
                        </Typography>
                    </div>
                    <div className={classes.unitValueReadOnlyDiv}>
                        {/* <Typography className={clsx({ [classes.textDisabled]: !props.enabled })} variant="body1">
                            {props.conversionValue}
                        </Typography>
                        <Button
                            classes={{
                                root: classes.addVariableButton,
                                disabled: classes.addVariableButtonDisabled
                            }}
                            disabled={!props.enabled}
                            variant={'contained'}
                            id={'output'}
                            onClick={handleChangeValueClick}
                        >
                            <Create htmlColor={'#fff'} />
                        </Button> */}

                        <TextField
                            variant={'standard'}
                            color={'primary'}
                            autoFocus
                            type="number"
                            value={props.conversionValue}
                            disabled={!props.enabled}
                            // placeholder="Conversion Value"
                            onChange={props.onConversionValueChange.bind(this, props.type)}
                            error={props.conversionValue < 0}
                            helperText={props.conversionValue < 0 ? 'Positive number only' : ''}
                            InputProps={{ inputProps: { min: 0 } }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </div>
                    <FormHelperText
                        className={clsx({ [classes.helperDisabled]: !props.enabled })}
                        error={props.valueError}
                        disabled={!props.enabled}
                    >
                        {props.enabled ? (props.valueError ? 'Value required' : ' ') : 'Default'}
                    </FormHelperText>
                </div>
                <FormControl className={classes.formControl}>
                    <InputLabel
                        className={clsx([classes.label, classes.labelMargin])}
                        shrink={true}
                        id="unit-type-select-label"
                    >
                        Output unit
                    </InputLabel>
                    <TextField
                        className={classes.inputBase}
                        value={unit ? (props.type === 'speed' ? unit.name + '/min' : unit.name) : ''}
                        error={props.unitTypeError}
                        disabled
                        InputProps={{ disableUnderline: true }}
                    />
                    <FormHelperText className={classes.helperDisabled}>Sensor</FormHelperText>
                </FormControl>
            </div>
        );
    };

    return (
        <div className={clsx([classes.wrapper, { [classes.last]: props.name.toLowerCase() === 'finished goods' }])}>
            {menuType === 'input' && renderVariablesPopover()}
            {menuType === 'output' && renderOutputConversionPopover()}
            <div className={classes.titleDiv}>
                <div className={classes.titleInnerDiv}>
                    {props.icon}
                    <Typography variant={'subtitle2'} className={classes.name}>
                        {props.name}
                    </Typography>
                </div>
            </div>
            <div className={classes.fieldsWrapper}>
                <div className={classes.inputOutputWrapper}>
                    <div className={classes.inputDiv}>
                        <div className={classes.unitValueDiv}>
                            <div className={classes.labelDiv}>
                                <Typography variant="caption" className={classes.label}>
                                    Input value
                                </Typography>
                                {/* <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true
                                        }}
                                        classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}
                                        onClose={handleTooltipClose}
                                        open={tooltipOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        arrow
                                        title={`Input value is calculated by multiplying the variables added below.`}
                                    >
                                        <IconButton className={classes.labelIconButton} onClick={handleTooltipOpen}>
                                            <HelpOutline style={{ fontSize: 12 }} />
                                        </IconButton>
                                    </Tooltip>
                                </ClickAwayListener> */}
                            </div>
                            <div className={classes.unitValueReadOnlyDiv}>
                                {/* <Typography
                                    className={clsx({ [classes.textDisabled]: !props.enabled })}
                                    variant="body1"
                                >
                                    {getTotalValue()}
                                </Typography>
                                <Button
                                    classes={{
                                        root: classes.addVariableButton,
                                        disabled: classes.addVariableButtonDisabled
                                    }}
                                    disabled={!props.enabled}
                                    variant={'contained'}
                                    id={'input'}
                                    onClick={handleChangeValueClick}
                                >
                                    <Create htmlColor={'#fff'} />
                                </Button> */}
                                <TextField
                                    variant={'standard'}
                                    color={'primary'}
                                    type="number"
                                    disabled={!props.enabled}
                                    value={props.subValues[0] ? props.subValues[0] : ""}
                                    // placeholder="Variable Value"
                                    onChange={(e) => props.onValueChange(props.type, e)}
                                    error={props.subValues[0] && props.subValues[0] <= 0}
                                    helperText={(props.subValues[0] && props.subValues[0] <= 0) ? 'Positive number only' : ""}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                            <FormHelperText
                                className={clsx({ [classes.helperDisabled]: !props.enabled })}
                                error={props.valueError}
                                disabled={!props.enabled}
                            >
                                {props.enabled ? (props.valueError ? 'Value required' : ' ') : 'Default'}
                            </FormHelperText>
                        </div>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                className={clsx([classes.label, classes.labelMargin])}
                                shrink={true}
                                id="unit-type-select-label"
                            >
                                Input unit
                            </InputLabel>
                            <TextField
                                select
                                className={classes.selectTextField}
                                value={props.unitType}
                                error={props.unitTypeError}
                                onChange={handleUnitTypeDropDownChange}
                                disabled={!props.enabled}
                                InputProps={{ disableUnderline: true }}
                            >
                                {props.units.map(u => {
                                    return (
                                        <MenuItem key={'unit-' + u.id} value={u.id} id={'input-unit-type'}>
                                            {u.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            <FormHelperText
                                className={clsx({ [classes.helperDisabled]: !props.enabled })}
                                error={props.unitTypeError}
                                disabled={!props.enabled}
                            >
                                {props.enabled ? (props.unitTypeError ? 'Unit required' : ' ') : 'Default'}
                            </FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>{renderDisplayUnitDropdown()}</FormControl>
                    </div>
                    {renderOutputDiv()}
                </div>
                <div className={classes.explainerDiv}>
                    <div className={classes.inputExplainerDiv}>
                        <Typography variant={'caption'}>{getExplainerText()}</Typography>
                    </div>
                    {props.name.toLowerCase() !== 'sensor count' && (
                        <div className={classes.outputExplainerDiv}>
                            <Typography variant={'caption'}>
                                There is {props.conversionValue} <span className={classes.bold}>{getOutputUnit()}</span>{' '}
                                in a <span className={classes.bold}>{getDisplayUnit()}</span>
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        height: '160px',
        marginBottom: '10px'
    },
    last: {
        marginBottom: 0
    },
    titleDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '0 15px 40px',
        background: '#FAFAFA',
        width: '165px',
        borderRight: '2px solid #FFF'
    },
    titleInnerDiv: {
        display: 'flex'
    },
    fieldsWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    inputOutputWrapper: {
        flex: 1,
        display: 'flex'
    },
    inputDiv: {
        flex: 3,
        background: 'rgba(0, 164, 105, 0.05)',
        borderRight: '2px solid #FFF',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '30px'
    },
    outputDiv: {
        flex: 2,
        background: 'rgba(28, 11, 219, 0.05)',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '40px'
    },
    explainerDiv: {
        background: '#FAFAFA',
        borderTop: '2px solid #FFF',
        height: '44px',
        display: 'flex'
    },
    inputExplainerDiv: {
        flex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'italic',
        color: '#666'
    },
    outputExplainerDiv: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'italic',
        color: '#666'
    },
    bold: {
        fontWeight: 700
    },
    formControl: {
        flex: 1,
        margin: theme.spacing(1),
        minWidth: 120,
        marginRight: '40px'
    },
    name: {
        color: '#666',
        marginLeft: '10px'
    },
    innerRow: {
        boxShadow: 'inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08)',
        background: '#f2f2f2'
    },
    unitValueDiv: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 120,
        marginRight: '40px'
    },
    labelDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '0.75rem',
        marginBottom: theme.spacing(1)
    },
    label: {
        fontWeight: 700,
        color: '#999',
        position: 'relative'
    },
    labelMargin: {
        marginTop: '4px'
    },
    labelIconButton: {
        padding: '6px'
    },
    unitValueReadOnlyDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        borderRadius: '2px',
        padding: '4px 8px',
        marginTop: '-8px'
    },
    selectTextField: {
        backgroundColor: '#fff',
        borderRadius: '2px',
        height: '40px',
        padding: '0 10px',
        margin: '4px 0 0',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '200px',
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fff'
        }
    },
    inputBase: {
        height: '40px',
        padding: '0 10px',
        margin: '4px 0 0',
        display: 'flex',
        justifyContent: 'center',
        color: '#000 !important'
    },
    subValueRow: {
        width: '100%',
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #bbb'
    },
    subValueRowDiv: {
        display: 'flex',
        flexDirection: 'column'
    },
    outputConversionInputDiv: {
        margin: '0 24px'
    },
    resetToDefaultRow: {
        width: '100%',
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        justifyContent: 'flex-end'
    },
    notesTextField: {
        marginLeft: theme.spacing(3),
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        }
    },
    tooltipPlacementBottom: {
        margin: 0
    },
    deleteVariableIcon: {
        marginRight: '8px'
    },
    textDisabled: {
        color: '#999'
    },
    helperDisabled: {
        fontStyle: 'italic',
        color: '#bbb'
    },
    addVariableButton: {
        minWidth: 'initial',
        padding: '4px',
        backgroundColor: '#000'
    },
    addVariableButtonDisabled: {
        backgroundColor: '#bbb !important'
    }
}));
