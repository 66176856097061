import React, { Component } from 'react';
import SideNav from '../components/SideNav';
import { FontIcon } from 'material-ui';

import {
    Button,
    Tab,
    Tabs,
    Fab,
    Paper,
    CircularProgress,
    Typography,
    Divider,
    Snackbar
} from '@material-ui/core';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material'
import moment from 'moment-timezone';
import Modal from '../components/Modal';
import { Tooltip as TippyTooltip } from 'react-tippy';
import AssociateRunToBatchRunModal from '../modals/AssociateRunToBatchRunModal';
import EditGiveawayModal from '../modals/EditGiveawayModal';
import AddEvent from '../modals/AddEvent';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import EditEvent from '../components/EditEvent';
import EditRun from '../modals/EditRun';
import RunTimeline from '../components/RunTimeline';
import ReactPaginate from 'react-paginate';
import SpeedChangeChart from '../components/SpeedChangeChart';
import AddClosedRun from '../modals/AddClosedRun';
import FilterDowntimeCategories from '../modals/FilterDowntimeCategories';
import MoveRunData from '../modals/MoveRunData';
import TrendReportContainer from '../components/reports/trend/TrendReportContainer';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import DailyReportRunTable from '../components/reports/daily/DailyReportRunTable';
import Footer from '../components/Footer';
import HeaderTopNav from '../components/HeaderTopNav';
import Animate from 'react-smooth/lib';
import { AddOutlined } from '@material-ui/icons';
import { minimumRole } from '../components/PermissionsHelper';
import DailyReportTitleAndOptions from '../components/reports/daily/DailyReportTitleAndOptions';
import ReportEfficiencyChart from '../components/reports/ReportEfficiencyChart';
import ReportParetoChart from '../components/reports/ReportParetoChart';
import RunReportTitleCard from '../components/reports/run/RunReportTitleCard';
import { Link } from 'react-router-dom';
import LostTimeDrillDownModal from '../modals/LostTimeDrillDownModal';
import _ from 'lodash';
import { TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import TablePagination from '@mui/material/TablePagination';
import {auth0UserManagementAPIInstance} from "../components/Auth0API"
import ReportsCard from '../components/ReportsCard';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import Filter from '../components/Filter';
import FilterDropdown from '../components/FilterDropdown';
import ReportEfficiencyChartNew from '../components/reports/ReportEfficiencyChartNew';
import ReportPlannedUnplannedChart from '../components/reports/ReportPlannedUnplannedChart';
import ReportUtilizationChart from '../components/reports/ReportUtilizationChart';

import EfficiencyChartIcon from '../components/icons/EfficiencyChartIcon.svg';
import PlannedUnplannedChartIcon from '../components/icons/PlannedUnplannedChartIcon.svg';
import UtilizationChartIcon from '../components/icons/UtilizationChartIcon.svg';
import RunDetailsMetricsIcon from '../components/icons/RunDetailsMetricsIcon.svg';

import TotalSensorCountIcon from '../components/icons/TotalSensorCountIcon.svg';
import TotalReworkIcon from '../components/icons/TotalReworkIcon.svg';
import TotalWasteIcon from '../components/icons/TotalWasteIcon.svg';
import TotalDowntimeIcon from '../components/icons/TotalDowntimeIcon.svg';
import LabourIcon from '../components/icons/LabourIcon.svg';
import FinishedGoodsIcon from '../components/icons/FinishedGoodsIcon.svg';

import RunTimelineDowntimeIcon from '../components/icons/RunTimelineDowntimeIcon.svg';
import RunTimelineSpeedIcon from '../components/icons/RunTimelineSpeedIcon.svg';
import TableEditIcon from '../components/icons/TableEditIcon.svg';
import TableDeleteIcon from '../components/icons/TableDeleteIcon.svg';

import ParetoChartIcon from '../components/icons/ParetoChartIcon.svg';
import WasteChartIcon from '../components/icons/WasteChartIcon.svg';
import ReworkChartIcon from '../components/icons/ReworkChartIcon.svg';
import NewReportParetoChart from '../components/reports/NewReportParetoChart';
import NewLostTimeDrillDown from '../components/reports/NewLostTimeDrillDown';
import NewLostTimeDrillDownCategory from '../components/reports/NewLostTimeDrillDownCategory';
import colorPalette from '../Palette';
import { formatToHHhMMmSSs, formatToHhMmSs, numberWithCommas } from '../newUtils/timeFormatter';
import WasteParetoChart from '../components/reports/WastePareto';
import ReworkParetoChart from '../components/reports/ReworkPareto';
import OldDailyReportTitleAndOptions from '../components/reports/daily/OldDailyReportTitleAndOptions';
import OldDailyReportRunTable from '../components/reports/daily/OldDailyReportRunTable';
import OldRunTimeline from '../components/OldRunTimeline';
import OldSpeedChangeChart from '../components/OldSpeedChangeChart';
import OldRunReportTitleCard from '../components/reports/run/OldRunReportTitleCard';

function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
}

class Reports extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            user: null,
            lines: null,
            report: null,
            report_weekly: null,
            loading_report: false,
            loading_run: false,
            run: null,
            kill_state: false,
            popped: false,
            run_id: this.getRunIdFromUrl(),
            run_description: this.getRunDescription(),
            error: false,
            selected_line: 0,
            selected_lines: this.getSelectedLinesFromUrl(),
            disableEndDate: this.isEndDateDisabled(),
            date_from: this.generateFromDate(),
            report_date_from: this.generateFromDate(),
            date_to: this.generateToDate(),
            report_date_to: this.generateToDate(),
            view_type: props.showTrend ? 3 : 1, //this.getReportType(),
            show_modal: false,
            edit_batch_modal_data: null,
            updatePage: false,
            nextPallet: '',
            nextBatch: '',
            caseWeight: '',
            show_associate_batch: false,
            run_start_date: '',
            editing_run: false,
            selected_product: this.getSelectedProduct(),
            selected_products: this.getSelectedProductsFromUrl(),
            products: ProductStore.get(),
            categories: [],
            selected_categories: [],
            tags: [],
            selected_tags: [],
            line_products: null,
            selected_product_display: 'All Products',
            inDetails: false,
            weekly_report_view_type: 1,
            daily_report_view_type: 1,
            inactive: false,
            screenWidth: window.innerWidth,
            backTo: this.getBackTo(),
            pallets: null,
            report_selected_line: '',
            report_selected_product: '',
            run_ids: null,
            nextRun: null,
            nextRunId: null,
            loadedId: null,
            lastRun: '',
            batchRunRun: null,
            log: null,
            logError: null,
            event: null,
            reasons: null,
            pageCount: 0,
            offset: 0,
            daily_tab_index: 0,
            summary: true,
            downtime: null,
            output: null,
            waste: null,
            rework: null,
            log_type: '',
            downtimeSort: 0,
            sort: 0,
            categories: null,
            runLogSort: 0,
            selectAllProducts: true,
            show_select_products: false,
            show_select_categories: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false,
            number_of_pareto_bars: 10,
            edit_run_title: 'Edit Run',
            selected_products_display: [],
            selected_lines_display: [],
            move_split_tab_index: 0,
            split_tab: 'move',
            show_trend: props.showTrend, //false,
            filtered_run_id: 0,
            print_status: 'disabled',
            factory_name: '',
            factory_error: null,
            pareto_bars: 10,
            average_speed: 0,
            collapse: true,
            selected_dates: this.getSelectedDatesFromUrl(),
            report_selected_dates: this.getSelectedDatesFromUrl(),
            multi_date: this.getUrlVars()['dates'],
            report_multi_date: this.getUrlVars()['dates'],
            active_lost_time_reason: null,
            show_lost_time_reason_dialog: null,
            return_to_reason: null,
            drill_down_reason: null,
            drill_down_reason_id: -1,
            drill_down_category: null,
            snackbar: false,
            units: null,
            line_units: null,
            timeZone: 'America/Toronto',
            run_line_id: null,
            run_line_fetch_data: null,
            shiftIds: [],
            shift_start_time: null,
            shift_end_time: null,
            activeShifts: [],
            currentPage: 0,
            uses_headcount: false,
            shifts: 0,
            shifts_error: null,
            uncategorized_products: true,
            untagged_products: true,
            order: 'asc',
            orderBy: 'category',
            factory_users: [],
            reasonCategories: [],
            paretoValue: "Reason",
            uses_latest_report: true,
            perPages: window.localStorage.getItem('perPages') ? window.localStorage.getItem('perPages') : 10,
        };
    }

    componentWillMount() {
        this.setState({
            products: []
        })
    }

    /* -------- LIFECYCLE METHODS -------- */

    

    componentDidMount() {
        this.getUnits();
        this.getFeatures();
        this.getCategories();
        this.getReasonCategories();
        this.getTags();
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
        })
        
        this.getOrgUsers(org);
        User.subscribe(this.handleUserChange);
        LineStore.subscribeLines(this.handleLineChange);
        ProductStore.subscribeFull(this.handleProductChange);
        if (!this.props.showTrend) {
            this.getFirstReport();
        }
        this.getFactorySettings();
        document.title = 'LiveTracking | Reports';
        if (window.ga) {
            window.ga('set', 'page', '/reports');
            window.ga('send', 'pageview');
        }
        window.localStorage.setItem('selectedShifts', '[]');
        window.addEventListener('resize', this._onResize);

        window.onpopstate = event => {
            this.setState(event.state);

            if (event.state.run_id == null) {
                this.getReport(this.state.view_type, false, true);
            } else {
                this.selectRun({ run_id: event.state.run_id, description: event.state.run_description, popped: true });
            }
        };
    }

    componentWillUnmount() {
        User.unsubscribe(this.handleUserChange);
        LineStore.unsubscribe(this.handleLineChange);
        ProductStore.unsubscribe(this.handleProductChange);
        window.removeEventListener('resize', this._onResize);
        window.onpopstate = null;
    }

    /* -------- FUNCTIONS -------- */

    getUserEmail = (id, is_system_generated) => {
        let user = "Not found";
        if (this.state.factory_users.length > 0) {
            let userFound = this.state.factory_users.find((i) => i.user_id === id)
            user = userFound ? userFound.email : is_system_generated ? "System" : "Not found"
        }
        return user
    }

    getLastEditUser = (id) => {
        if (this.state.factory_users && this.state.factory_users.length > 0) {
            const userData =  this.state.factory_users.filter((user) => {
                return user.user_id === id
            })
            if (userData[0] && userData[0].user_metadata && (userData[0].user_metadata.first_name || userData[0].user_metadata.last_name)) {
                return `${userData[0].user_metadata.first_name} ${userData[0].user_metadata.last_name}`
            }
            else if (userData[0] && userData[0].name) {
                return userData[0].name
            }
            else if (userData[0] && userData[0].email) {
                return userData[0].email
            }
        }

        return "System User"
    }

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                if (factory_users) {
                    const copy_factory_users = [...factory_users]
                    let selected = copy_factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1]))
                    selected.forEach((item, i) => {
                        selected[i] = item.user_id
                    })
                    this.setState({
                        all_users: res.data,
                        org_users,
                        factory_users: factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1])),
                        selected_users: selected
                    })
                }
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    onCollapseClickChange = collapse => {
        this.setState({ collapse });
    };

    handleMultiDatePickerClose = () => {
        this.setState({
            show_select_dates: false
        });
    };

    getRunDescription() {
        let desc = null;
        if (decodeURI(this.getUrlVars()['run_description'])) {
            desc = decodeURI(this.getUrlVars()['run_description']);
        }

        if (desc == 'null' || desc == 'undefined') {
            return null;
        }
        return desc;
    }

    generateFromDate() {
        let d = null;
        if (this.getUrlVars()['from']) {
            d = new Date();
            let date = this.getUrlVars()['from'];
            d = new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2));
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
        }

        return d;
    }

    updateFromDate() {
        let d = new Date();
        d.setDate(d.getDate() - 1);

        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        return d;
    }

    generateWeeklyFromDate() {
        let d = new Date();
        d.setDate(d.getDate() - 31);

        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        return d;
    }

    getReportType() {
        let r = 1;
        if (this.getUrlVars()['type']) {
            r = this.getUrlVars()['type'];
        }

        return r;
    }

    getBackTo() {
        let r = 'reports';
        if (this.getUrlVars()['return']) {
            r = this.getUrlVars()['return'];
        }

        return r;
    }

    getSelectedProduct() {
        let product = 0;
        if (this.getUrlVars()['product']) {
            product = this.getUrlVars()['product'];
        }

        return parseInt(product);
    }

    getSelectedProducts() {
        let selectedProducts = [];

        if (this.state.selected_products[0]) {
            selectedProducts = this.state.selected_products;
        } else {
            let allProducts = ProductStore.get();

            if (allProducts.length > 0) {
                allProducts.forEach(product => {
                    selectedProducts.push(product.product_id);
                });
            }
        }

        return selectedProducts;
    }

    getSelectedLines() {
        let selectedLines = [];

        if (this.state.selected_lines[0]) {
            selectedLines = this.state.selected_lines;
        } else {
            let allLines = LineStore.getLines();

            if (allLines[0]) {
                allLines.forEach(line => {
                    selectedLines.push(line.id);
                });
            }
        }

        return selectedLines;
    }

    getUrlVars() {
        let vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    getRunIdFromUrl() {
        let runId = null;
        let parts = window.location.href.match(/[?&]+([^=&]+)=([^&]*)/gi);
        if (parts) {
            parts.forEach(param => {
                if (param.includes('run')) {
                    runId = param.substr(5);
                }
            });
        }

        return runId;
    }

    getSelectedProductsFromUrl() {
        let vars = [];
        let parts = window.location.href.match(/[?&]+([^=&]+)=([^&]*)/gi);
        if (parts) {
            parts.forEach(param => {
                if (param.includes('product')) {
                    vars = param.substr(9).split(',').map(Number);
                }
            });
        }
        return vars;
    }

    getSelectedLinesFromUrl() {
        let vars = [];
        let parts = window.location.href.match(/[?&]+([^=&]+)=([^&]*)/gi);
        if (parts) {
            parts.forEach(param => {
                if (param.includes('line')) {
                    vars = param.substr(6).split(',').map(Number);
                }
            });
        }
        return vars;
    }

    getSelectedDatesFromUrl() {
        let vars = [];
        let parts = window.location.href.match(/[?&]+([^=&]+)=([^&]*)/gi);
        if (parts) {
            parts.forEach(param => {
                if (param.includes('dates')) {
                    vars = param
                        .substr(7)
                        .split(',')
                        .map(date => {
                            let d = new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2));
                            d.setHours(0);
                            d.setMinutes(0);
                            d.setSeconds(0);
                            return d;
                        });
                }
            });
        }
        return vars;
    }

    generateToDate() {
        let d = null;

        if (this.getUrlVars()['to']) {
            let date = this.getUrlVars()['to'];
            d = new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2));
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
        }
        return d;
    }

    isEndDateDisabled = () => {
        let disabled = true;
        if (this.getUrlVars()['to'] !== this.getUrlVars()['from']) {
            disabled = false;
        }

        return disabled;
    };

    enableToDate = () => {
        let d = new Date();
        d.setDate(d.getDate());

        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        return d;
    };

    disableFutureDates = date => {
        if (date >= new Date()) {
            return true;
        } else {
            return false;
        }
    };

    handleUserChange = () => {
        this.setState({
            user: User.get()
        });
    };

    handleLineChange = () => {
        // set initial line
        this.setState({
            lines: LineStore.getLines(),
            selected_lines: this.getSelectedLines()
        });
    };

    handleProductChange = () => {
        this.setState({
            products: ProductStore.get(),
            selected_products: this.getSelectedProducts()
        });
    };

    getMultiDayText = doc => {
        let { report_selected_dates } = this.state;

        let lines = 1;

        if (this.state.report_multi_date) {
            if (this.state.report_selected_dates.length === 1) {
                doc.text(`${Util.formatDateMMMDDYYYY(this.state.report_selected_dates[0])}`, 8, 42);
            } else {
                let dates = [...report_selected_dates];
                dates.sort((a, b) => a.getTime() - b.getTime());

                let yearMonthDays = [];

                for (let i = 0; i < dates.length; i++) {
                    let d = dates[i];
                    let year = d.getFullYear();
                    let month = d.getMonth();
                    let day = d.getDate();

                    if (!yearMonthDays.some(e => e.year === year)) {
                        let monthObj = { month, days: [day] };
                        yearMonthDays.push({ year: year, months: [monthObj] });
                    } else {
                        let yearObj = yearMonthDays.find(e => e.year === year);
                        let yearIndex = yearMonthDays.findIndex(e => e.year === year);

                        if (yearObj.months.some(m => m.month === month)) {
                            let monthIndex = yearObj.months.findIndex(m => m.month === month);
                            yearMonthDays[yearIndex].months[monthIndex].days.push(day);
                        } else {
                            let monthObj = { month, days: [day] };
                            yearMonthDays[yearIndex].months.push(monthObj);
                        }
                    }
                }

                let range = '';

                if (yearMonthDays.length > 0) {
                    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                    for (let i = 0; i < yearMonthDays.length; i++) {
                        if (i > 0) {
                            range += ' - ';
                        }
                        range += yearMonthDays[i].year + ':';
                        for (let j = 0; j < yearMonthDays[i].months.length; j++) {
                            if (j > 0) {
                                range += ' -';
                            }
                            range += ' ' + months[yearMonthDays[i].months[j].month] + ' ';
                            for (let k = 0; k < yearMonthDays[i].months[j].days.length; k++) {
                                range += yearMonthDays[i].months[j].days[k];
                                if (k !== yearMonthDays[i].months[j].days.length - 1) {
                                    range += ', ';
                                }
                            }
                        }
                    }
                }

                doc.text(`${range}`, 8, 42);
            }
        } else {
            doc.text(
                `${Util.formatDateMMMDDYYYY(this.state.report_date_from)} - ${Util.formatDateMMMDDYYYY(
                    this.state.report_date_to
                )}`,
                8,
                42
            );
        }
    };

    printScreen = () => {
        this.setState({
            print_status: 'printing'
        });

        let line_name = this.state.selected_lines_display[0]
            ? this.state.lines.find(line => line.id === this.state.selected_lines_display[0]).name
            : 'All Lines';

        if (this.state.selected_lines_display.length > 1) {
            line_name = 'Multi-line';
        }

        if (this.state.selected_lines_display.length === this.state.lines.length) {
            line_name = 'All Lines';
        }

        let product_description = this.state.selected_products_display[0]
            ? this.state.products.find(product => product.product_id === this.state.selected_products_display[0])
                .display_name
            : 'All Products';

        if (this.state.selected_products_display.length > 1) {
            product_description = 'Multi-product';
        }

        if (this.state.selected_products_display.length === this.state.products.length) {
            product_description = 'All Products';
        }

        let doc = new jsPDF({ format: 'letter' }); // 8.5" x 11" paper size

        html2canvas(document.getElementById('charts'), { logging: false })
            .then(canvas => {
                doc.addImage(document.getElementById('logo'), 'JPEG', 8, 4, 40, 8);
                doc.setDrawColor(41, 103, 193);
                doc.setLineWidth(0.5);
                doc.line(0, 16, 215.9, 16);

                doc.setFontSize(16);
                doc.text(`${this.state.factory_name} - Daily Run Report`, 8, 24);
                doc.text(`${line_name}`, 8, 30);
                doc.text(`${product_description}`, 8, 36);
                {
                    this.getMultiDayText(doc);
                }

                let img = canvas.toDataURL('image/png');
                doc.addImage(img, 'JPEG', 0, 52, 215, 65);

                doc.setFontSize(10);
                doc.text(`Run Log`, 8, 126);

                doc.autoTable({
                    startY: 130,
                    margin: { top: 20, right: 8, left: 8, bottom: 12 },
                    rowPageBreak: 'avoid',
                    didParseCell: data => {
                        if (data.section === 'head') {
                            switch (data.column.index) {
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                    data.cell.styles.fillColor = '#428BCB';
                                    break;
                                default:
                                    data.cell.styles.fillColor = '#3EC623';
                            }
                        }
                    },
                    headStyles: {
                        valign: 'middle',
                        halign: 'center',
                        fontSize: 8
                    },
                    columnStyles: {
                        date: { minCellWidth: 20, valign: 'middle', halign: 'center', fontSize: 8 },
                        line: { minCellWidth: 15, valign: 'middle', halign: 'center', fontSize: 8 },
                        sku: { minCellWidth: 15, valign: 'middle', halign: 'center', fontSize: 8 },
                        product: { minCellWidth: 24, valign: 'middle', halign: 'center', fontSize: 8 },
                        start_time: { minCellWidth: 15, valign: 'middle', halign: 'center', fontSize: 8 },
                        end_time: { minCellWidth: 15, valign: 'middle', halign: 'center', fontSize: 8 },
                        oee: { minCellWidth: 15, valign: 'middle', halign: 'center', fontSize: 8 },
                        avg_speed: { minCellWidth: 25, valign: 'middle', halign: 'center', fontSize: 8 },
                        target_speed: { minCellWidth: 25, valign: 'middle', halign: 'center', fontSize: 8 },
                        finished_goods: { minCellWidth: 25, valign: 'middle', halign: 'center', fontSize: 8 }
                    },
                    columns: [
                        { header: 'DATE', dataKey: 'date' },
                        { header: 'LINE', dataKey: 'line' },
                        { header: 'SKU', dataKey: 'sku' },
                        { header: 'PRODUCT', dataKey: 'product' },
                        { header: 'START TIME', dataKey: 'start_time' },
                        { header: 'END TIME', dataKey: 'end_time' },
                        { header: 'OEE', dataKey: 'oee' },
                        { header: 'AVERAGE SPEED (PACKS/MIN)', dataKey: 'avg_speed' },
                        { header: 'TARGET SPEED (PACKS/MIN)', dataKey: 'target_speed' },
                        { header: 'FINISHED GOODS (CASES)', dataKey: 'finished_goods' }
                    ],
                    body: this.getRunLogPDFBody()
                });

                let pageCount = doc.internal.getNumberOfPages();

                let footerDates = `${Util.formatDateMMMDDYYYY(
                    this.state.report_date_from
                )} - ${Util.formatDateMMMDDYYYY(this.state.report_date_to)}`;
                if (this.state.report_multi_date) {
                    if (this.state.report_selected_dates.length === 1) {
                        footerDates = Util.formatDateMMMDDYYYY(this.state.report_selected_dates[0]);
                    } else {
                        footerDates = 'Multi-day';
                    }
                }

                for (let i = 0; i < pageCount; i++) {
                    doc.setPage(i);
                    if (i !== 0) {
                        doc.addImage(document.getElementById('logo'), 'JPEG', 8, 4, 40, 8);
                        doc.setDrawColor(41, 103, 193);
                        doc.setLineWidth(0.5);
                        doc.line(0, 16, 215.9, 16);
                    }
                    doc.setDrawColor(41, 103, 193);
                    doc.setLineWidth(0.5);
                    doc.line(0, 268, 215.9, 268);

                    doc.setFontSize(6);
                    doc.text(
                        doc.internal.getCurrentPageInfo().pageNumber + ' of ' + pageCount,
                        107.5,
                        272,
                        null,
                        null,
                        'center'
                    );
                    doc.text(
                        `${this.state.factory_name} - Daily Run Report - ${line_name} - ${product_description} - ${footerDates}`,
                        207.9,
                        272,
                        null,
                        null,
                        'right'
                    );
                }

                doc.save(`Daily Run Report - ${line_name} - ${product_description} - ${footerDates}.pdf`);
                this.setState({
                    print_status: 'enabled'
                });
            })
            .catch(err => console.log(err));
    };

    getRunLogPDFBody = () => {
        let log = this.state.report.table_info_list;
        let tableBody = [];

        log.map(run => {
            tableBody.push({
                date: this.formatDayDate(run.run_start_time),
                line: run.line_name,
                sku: run.product_name,
                product: run.product_description,
                start_time: Util.formatHourMinute(run.run_start_time),
                end_time: run.run_end_time ? Util.formatHourMinute(run.run_end_time) : 'In Progress',
                oee: parseFloat(run.run_efficiency_percent).toFixed(1) + '%',
                avg_speed: run.average_speed_pieces_per_min,
                target_speed: run.target_speed_pieces_per_min,
                finished_goods: run.run_finished_goods
            });
        });

        return tableBody;
    };

    openSelectLines = () => {
        this.setState({
            show_select_lines: true,
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_dates: false,
            show_select_shifts: false,
        });
    };

    hideSelectLines = selected_lines => {
        let updatedSelection = Array.from(selected_lines);
        let newArray = [];
        updatedSelection.forEach(line => {
            if (line.id) {
                newArray.push(line.id);
            }
            else {
                newArray.push(line);
            }
        })
        this.setState({
            selected_lines: newArray,
            show_select_lines: false,
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_dates: false,
            show_select_shifts: false,
            inactive: false
        });
    };

    cancelSelectLines = () => {
        this.setState({
            show_select_lines: false,
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_dates: false,
            show_select_shifts: false
        });
    };

    openSelectDates = () => {
        this.setState({
            show_select_dates: true,
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_shifts: false
        });
    };

    openSelectProducts = () => {
        this.setState({
            show_select_products: true,
            show_select_categories: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false
        });
    };

    openSelectCategories = () => {
        this.setState({
            show_select_categories: true,
            show_select_products: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false,
        })
    }
    openSelectShifts = () => {
        this.setState({
            show_select_shifts: true,
            show_select_products: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_categories: false,
        })
    }
    openSelectTags = () => {
        this.setState({
            show_select_tags: true,
            show_select_categories: false,
            show_select_products: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false
        });
    };

    hideSelectProducts = selected_products => {
        let updatedSelection = Array.from(selected_products);
        let newArray = [];
        updatedSelection.forEach(product => {
            if (product.id) {
                newArray.push(product.id);
            }
            else {
                newArray.push(product);
            }
        })
        this.setState({
            selected_products: newArray,
            show_select_products: false,
            show_select_categories: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false,
            inactive: false
        });
    };

    hideSelectCategories = (selected_categories) => {
        let uncategorized_products = false;
        let updatedSelection = Array.from(selected_categories);
        if (updatedSelection.some((item) => item.id === -1)) {
            updatedSelection = updatedSelection.filter((item) => item.id !== -1);
            uncategorized_products = true;
        }
        let newArray = [];
        updatedSelection.forEach(category => {
            if (category.id) {
                newArray.push(category.id);
            }
            else {
                newArray.push(category);
            }
        })
        this.setState({
            uncategorized_products: uncategorized_products,
            selected_categories: newArray,
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false,
            inactive: false
        })
    }

    hideSelectTags = (selected_tags) => {
        let untagged_products = false
        let updatedSelection = Array.from(selected_tags);
        if (updatedSelection.some((item) => item.id === -1)) {
            updatedSelection = updatedSelection.filter((item) => item.id !== -1);
            untagged_products = true;
        }
        let newArray = [];
        updatedSelection.forEach(tag => {
            if (tag.id) {
                newArray.push(tag.id);
            }
            else {
                newArray.push(tag);
            }
        })
        this.setState({
            selected_tags: newArray,
            show_select_tags: false,
            show_select_categories: false,
            show_select_products: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false,
            inactive: false,
            untagged_products: untagged_products,
        });
    };

    cancelSelectProducts = () => {
        this.setState({
            show_select_products: false,
            show_select_categories: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false
        });
    };

    cancelSelectCategories = () => {
        this.setState({
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false
        })
    }

    cancelSelectShifts = () => {
        this.setState({
            show_select_categories: false,
            show_select_products: false,
            show_select_tags: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false,
        })
    }

    cancelSelectTags = () => {
        this.setState({
            show_select_tags: false,
            show_select_categories: false,
            show_select_products: false,
            show_select_lines: false,
            show_select_dates: false,
            show_select_shifts: false
        });
    };

    updateUrl = () => {
        let { selected_lines, selected_products } = this.state;
        let lines = '&line=';
        let products = '&product=';
        let dates = '';

        if (!this.state.lines || this.state.lines.length === selected_lines.length || selected_lines.length === 0) {
            lines = '';
        } else {
            for (let i = 0; i < selected_lines.length; i++) {
                let value = selected_lines[i];
                lines += `${value}`;
                if (i !== selected_lines.length - 1) {
                    lines += ',';
                }
            }
        }

        if (
            !this.state.products ||
            this.state.products.length === selected_products.length ||
            selected_products.length === 0
        ) {
            products = '';
        } else {
            for (let i = 0; i < selected_products.length; i++) {
                let value = selected_products[i];
                products += `${value}`;
                if (i !== selected_products.length - 1) {
                    products += ',';
                }
            }
        }

        if (this.state.report_multi_date) {
            dates = 'dates=';
            this.state.report_selected_dates.map((d, i) => {
                dates += Util.formatYearMonthDateURL(d);
                if (i !== this.state.report_selected_dates.length - 1) {
                    dates += ',';
                }
            });
        } else {
            dates =
                'from=' +
                Util.formatYearMonthDateURL(this.state.date_from) +
                '&to=' +
                Util.formatYearMonthDateURL(this.state.date_to);
        }

        let stateObj = null; //this.state
        let shifts = '';
        let url = new URL(window.location.href);
        let shiftsIds = url.searchParams.getAll('shift_id');
        if (shiftsIds.length) {
            for (let shift of shiftsIds) {
                shifts += `&shift_id=${shift}`;
            }
        }
        let href = '/reports/?' + dates + lines + products + shifts;

        if (this.state.popped === true) {
            history.replaceState(stateObj, 'LiveTracking', href);
        } else {
            history.pushState(stateObj, 'LiveTracking', href);
        }
    };

    handleDateFromChange = date => {
        if (this.state.disableEndDate) {
            this.setState({
                date_to: date
            });
        }
        this.setState({
            date_from: date,
            inactive: false
        });
    };

    handleDateToChange = date => {
        this.setState({
            date_to: date,
            inactive: false
        });
    };

    shiftCheckBoxHandled = data => {
        // this.setState({
        //     activeShifts: data.temp
        // });
    };

    handleEndDateCheckBox = (event, isInputChecked) => {
        if (isInputChecked) {
            this.setState({
                date_to: this.state.date_from
            });
        } else {
            this.setState({
                date_to: this.enableToDate()
            });
        }
        this.setState({
            disableEndDate: isInputChecked,
            inactive: false
        });
    };

    canUpdateOptions = () => {
        return (
            (this.state.date_from == null && this.state.date_to == null && this.state.selected_dates[0] === null) ||
            this.state.selected_line == null ||
            this.state.inactive
        );
    };

    onTabChange = (event, index) => {
        let summary = false;
        let offset = 0;
        switch (index) {
            case 0:
                summary = true;
                break;
            case 1:
                this.getLog('downtime');
                break;
            case 2:
                this.getLog('output');
                break;
            case 3:
                this.getLog('waste');
                break;
            case 4:
                this.getLog('rework');
                break;
            case 5:
                this.getLog('finished_goods');
                break;
        }
        this.setState({ offset, summary, daily_tab_index: index });
    };

    compareDowntimes = (a, b) => {
        let aDuration = (a.end_time ? new Date(a.end_time) : new Date()).getTime() - new Date(a.start_time).getTime();
        let bDuration = (b.end_time ? new Date(b.end_time) : new Date()).getTime() - new Date(b.start_time).getTime();

        switch (this.state.downtimeSort) {
            case 0:
                if (new Date(a.start_time).getTime() < new Date(b.start_time).getTime()) {
                    return 1;
                } else if (new Date(a.start_time).getTime() > new Date(b.start_time).getTime()) {
                    return -1;
                } else {
                    return 0;
                }
            case 1:
                if (new Date(a.start_time).getTime() < new Date(b.start_time).getTime()) {
                    return -1;
                } else if (new Date(a.start_time).getTime() > new Date(b.start_time).getTime()) {
                    return 1;
                } else {
                    return 0;
                }
            case 2:
                if (
                    (a.end_time ? new Date(a.end_time) : new Date()).getTime() <
                    (b.end_time ? new Date(b.end_time) : new Date()).getTime()
                ) {
                    return 1;
                } else if (
                    (a.end_time ? new Date(a.end_time) : new Date()).getTime() >
                    (b.end_time ? new Date(b.end_time) : new Date()).getTime()
                ) {
                    return -1;
                } else {
                    return 0;
                }
            case 3:
                if (
                    (a.end_time ? new Date(a.end_time) : new Date()).getTime() <
                    (b.end_time ? new Date(b.end_time) : new Date()).getTime()
                ) {
                    return -1;
                } else if (
                    (a.end_time ? new Date(a.end_time) : new Date()).getTime() >
                    (b.end_time ? new Date(b.end_time) : new Date()).getTime()
                ) {
                    return 1;
                } else {
                    return 0;
                }
            case 4:
                if (aDuration < bDuration) {
                    return 1;
                } else if (aDuration > bDuration) {
                    return -1;
                } else {
                    return 0;
                }
            case 5:
                if (aDuration > bDuration) {
                    return 1;
                } else if (aDuration < bDuration) {
                    return -1;
                } else {
                    return 0;
                }
        }
    };

    compareEvents = (a, b) => {
        switch (this.state.sort) {
            case 0:
                if (new Date(a.start_time).getTime() < new Date(b.start_time).getTime()) {
                    return 1;
                } else if (new Date(a.start_time).getTime() > new Date(b.start_time).getTime()) {
                    return -1;
                } else {
                    return 0;
                }
            case 1:
                if (new Date(a.start_time).getTime() < new Date(b.start_time).getTime()) {
                    return -1;
                } else if (new Date(a.start_time).getTime() > new Date(b.start_time).getTime()) {
                    return 1;
                } else {
                    return 0;
                }
            case 2:
                if (a.value < b.value) {
                    return 1;
                } else if (a.value > b.value) {
                    return -1;
                } else {
                    return 0;
                }
            case 3:
                if (a.value < b.value) {
                    return -1;
                } else if (a.value > b.value) {
                    return 1;
                } else {
                    return 0;
                }
        }
    };

    sortDowntime = sortOn => {
        let sort = 0;
        switch (sortOn) {
            case 'start_time':
                if (this.state.downtimeSort === 0) {
                    sort = 1;
                } else {
                    sort = 0;
                }
                break;
            case 'end_time':
                if (this.state.downtimeSort === 2) {
                    sort = 3;
                } else {
                    sort = 2;
                }
                break;
            case 'duration':
                if (this.state.downtimeSort === 4) {
                    sort = 5;
                } else {
                    sort = 4;
                }
                break;
        }

        this.setState({
            downtimeSort: sort
        });
    };

    filterDowntime = () => {
        if (this.state.categories && this.state.categories.length > 0 && this.state.categories[0][0]) {
            let catMap = new Map();
            this.state.downtime.map(downtime => {
                catMap.set(downtime.reason_name, true);
            });
            let comparisonCategories = Array.from(catMap).sort(this.compareCategories);
            let areTheSame = true
            comparisonCategories.forEach((item, i) => {
                if (!this.state.categories[i] || item[0] !== this.state.categories[i][0]) {
                    areTheSame = false
                    return
                }
            })

            if (areTheSame) {
                this.setState({
                    show_modal: 'filter_downtime'
                });
            }
            else {
                this.setState({
                    categories: comparisonCategories,
                    show_modal: 'filter_downtime'
                });
            }
        } else {
            let catMap = new Map();

            this.state.downtime.map(downtime => {
                catMap.set(downtime.reason_name, true);
            });

            let categories = Array.from(catMap).sort(this.compareCategories);

            this.setState({
                categories,
                show_modal: 'filter_downtime'
            });
        }
    };

    filterDowntimeEvents = categories => {
        this.setState({
            categories
        });
    };

    compareCategories = (a, b) => {
        if (a[0] === '') {
            return 1;
        } else if (b[0] === '') {
            return -1;
        } else {
            return a[0] < b[0] ? -1 : 1;
        }
    };

    sortEvents = sortOn => {
        let sort = 0;
        switch (sortOn) {
            case 'time':
                if (this.state.sort === 0) {
                    sort = 1;
                } else {
                    sort = 0;
                }
                break;
            case 'value':
                if (this.state.sort === 2) {
                    sort = 3;
                } else {
                    sort = 2;
                }
                break;
        }

        this.setState({
            sort
        });
    };

    getNumOfFilteredItems = () => {
        let number_of_filtered_items = 0;

        let { categories } = this.state;

        if (categories !== null && categories.length > 0 && categories[0][0]) {
            categories.forEach(cat => {
                if (cat[1]) {
                    number_of_filtered_items++;
                }
            });
        }
        else if (categories !== null) {
            const arrayOfCategories = []
            this.state.downtime.forEach((item, i) => {
                if (!arrayOfCategories.includes(item.reason_name)) {
                    arrayOfCategories.push(item.reason_name)
                }
            })
            number_of_filtered_items = arrayOfCategories.length
        } else {
            return '';
        }

        return number_of_filtered_items;
    };

    setCurrentPage = () => {
        this.setState({ currentPage: 0 });
    };

    handlePageClick = (event, page) => {
        let selected = page
        let offset = Math.ceil(selected * 20);
        this.setState({ offset: offset, currentPage: selected });
    };

    changeViewType = type => {
        if (type === 3) {
            this.setState(
                {
                    show_trend: true,
                    // disableEndDate: false,
                    // date_to: this.enableToDate(),
                    // report_date_to: this.enableToDate(),
                    // date_from: this.generateWeeklyFromDate(),
                    // report_date_from: this.generateWeeklyFromDate(),
                    view_type: type
                    // report: null,
                    // report_weekly: null,
                    // inactive: false
                },
                () => {
                    // setTimeout(this.getReport(this.state.view_type), 150);
                }
            );
        } else {
            this.setState(
                {
                    show_trend: false,
                    disableEndDate: true,
                    date_from: this.updateFromDate(),
                    report_date_from: this.updateFromDate(),
                    date_to: this.updateFromDate(),
                    report_date_to: this.updateFromDate(),
                    view_type: type,
                    report: null,
                    report_weekly: null,
                    inactive: false
                },
                () => {
                    setTimeout(() => this.getReport(this.state.view_type), 150);
                }
            );
        }
    };

    addEvent = () => {
        this.setState({
            show_modal: 'add_event'
        });
    };

    editEvent = event => {
        this.setState({
            show_modal: 'edit_event',
            event: event
        });
    };

    showDeleteEventConfirmation = event => {
        this.setState({
            show_modal: 'delete_event',
            event: event
        });
    };

    disableFromFutureDates = date => {
        if (this.state.disableEndDate) {
            return date >= new Date();
        }

        return date >= new Date() || date > this.state.date_to;
    };

    formatDayDate = d => {
        let date = new Date(d);
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return days[date.getDay()] + ' ' + months[date.getMonth()] + ' ' + date.getDate();
    };

    formatDateTime = d => {
        let date = new Date(d);

        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let hour = ('0' + date.getHours()).substr(-2, 2);
        let minute = ('0' + date.getMinutes()).substr(-2, 2);
        let second = ('0' + date.getSeconds()).substr(-2, 2);
        let suffix = ' AM';

        if (hour > 12) {
            hour = hour - 12;
            suffix = ' PM';
        } else if (hour == 12) {
            suffix = ' PM';
        } else if (hour == 0) {
            hour = 12;
        }

        let pretty_time = hour + ':' + minute + ':' + second + ' ' + suffix;

        return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    };

    hideEditBatchModal = () => {
        this.setState({
            show_modal: false,
            edit_batch_modal_data: null,
            editing_run: false
        });
        this.selectRun({ run_id: this.state.run_id, description: this.state.run_description });
        if (this.state.updatePage) {
            this.refreshReport();
            this.setState({
                updatePage: false
            });
        }
    };

    updateEventLog = event => {
        if (event) {
            this.setState({
                show_modal: false,
                offset: 0,
                summary: false
            });

            this.selectRun({ run_id: this.state.run_id, description: this.state.run_description });

            if (typeof event === 'string' || event instanceof String) {
                this.getLog(event.toLowerCase());
            }
        }
    };

    hideModalFromDelete = () => {
        this.setState({
            inDetails: false,
            show_modal: false,
            run_id: null,
            run: null,
            updatePage: true
        });
        this.hideEditBatchModal();
    };

    changeModalTitle = title => {
        this.setState({
            edit_run_title: title
        });
    };

    modified = () => {
        this.setState({
            updatePage: true
        });
    };

    onMoveSplitTabChange = (tabIndex, split_tab) => {
        this.setState({
            move_split_tab_index: tabIndex,
            split_tab
        });
    };

    getDeletePath = e => {
        switch (e.type) {
            case 'Output':
                return 'delete_output_event';
            case 'Downtime':
                return 'delete_downtime_event';
            case 'Waste':
                return 'delete_waste';
            case 'Rework':
                return 'delete_rework';
            case 'Finished Goods':
                return 'delete_finished_goods';
            case 'finished_goods':
                return 'delete_finished_goods';
        }
    };
//
    onBackToLineClick = () => {
        let show_lost_time_reason_dialog = null;

        if (this.state.backTo === 'drill-down') {
            show_lost_time_reason_dialog = this.state.return_to_reason;
        }
        
        this.setState(
            {
                lastRun: '',
                // backTo: this.getBackTo(),
                daily_tab_index: 0,
                daily_report_view_type: 1,
                log: null,
                logError: null,
                waste: null,
                rework: null,
                downtime: null,
                output: null,
                show_lost_time_reason_dialog,
                active_lost_time_reason: show_lost_time_reason_dialog
            },
            this.updateUrl
        );
        this.selectRun({ run_id: null, description: null });
    };

    showAddRunModal = () => {
        this.setState({
            show_modal: 'add_closed_run'
        });
    };

    showEditRunModal = () => {
        this.setState({
            show_modal: 'edit_run',
            edit_run_title: 'Edit Run',
            editing_run: true
        });
    };

    showMoveRunDataModal = () => {
        this.setState({
            show_modal: 'move_run_data'
        });
    };

    handleTableClick = (item, event) => {
        if (event.ctrlKey || event.metaKey) {
            let run_id = item.run_id;
            // let description = item.description;

            let url = '/reports?run=' + run_id;

            window.open(url, '_blank');
        } else {
            this.setState({
                show_lost_time_reason_dialog: null,
                active_lost_time_reason: null,
                drill_down_reason: null,
                drill_down_reason_id: null,
                drill_down_category: null,
                drill_down_category_id: null  
            });
            this.selectRun(item);
        }
    };

    handleDrillDownTableClick = (item, event) => {
        if (event.ctrlKey || event.metaKey) {
            let run_id = item.run_id;

            let url = '/reports?run=' + run_id;

            window.open(url, '_blank');
        } else {
            this.setState({

                backTo: 'drill-down',
                return_to_reason: this.state.show_lost_time_reason_dialog,
                show_lost_time_reason_dialog: null,
                active_lost_time_reason: null,
                // drill_down_reason: null,
                // drill_down_reason_id: null,
                // drill_down_category: null,
                // drill_down_category_id: null  
                
            });

            this.selectRun(item);
        }
    };

    getShortReason = reason => {
        if (reason) {
            let r = reason.substr(0, 12);

            if (reason.length > 12) {
                r += '...';
            }

            return r;
        } else {
            return '';
        }
    };

    handleNumberOfBarsChange = (report, event) => {
        let maxBars = report.lost_time_list.length - 1;
        let barCount = event.target.value;
        if (barCount > maxBars) {
            barCount = maxBars;
        }

        if (barCount < 0) {
            barCount = Math.abs(barCount);
        } else if (barCount === 0) {
            barCount = 1;
        }

        this.setState({
            number_of_pareto_bars: barCount
        });
    };

    _onResize = () => {
        this.setState({
            screenWidth: window.innerWidth
        });
    };

    

    onFactoryChange = () => {
        let state = {
            user: null,
            lines: null,
            report: null,
            report_weekly: null,
            loading_report: false,
            loading_run: false,
            run: null,
            kill_state: false,
            popped: false,
            run_id: null,
            run_description: null,
            error: false,
            selected_line: 0,
            selected_lines: [],
            disableEndDate: true,
            date_from: this.generateFromDate(),
            report_date_from: this.generateFromDate(),
            date_to: this.generateToDate(),
            report_date_to: this.generateToDate(),
            view_type: 1,
            show_modal: false,
            edit_batch_modal_data: null,
            updatePage: false,
            nextPallet: '',
            nextBatch: '',
            caseWeight: '',
            show_associate_batch: false,
            run_start_date: '',
            editing_run: false,
            selected_product: 0,
            selected_products: [],
            products: ProductStore.get(),
            line_products: null,
            selected_product_display: 'All Products',
            inDetails: false,
            weekly_report_view_type: 1,
            daily_report_view_type: 1,
            inactive: false,
            screenWidth: window.innerWidth,
            backTo: this.getBackTo(),
            pallets: null,
            report_selected_line: '',
            report_selected_product: '',
            run_ids: null,
            nextRun: null,
            nextRunId: null,
            loadedId: null,
            lastRun: '',
            batchRunRun: null,
            log: null,
            logError: null,
            event: null,
            reasons: null,
            pageCount: 0,
            offset: 0,
            daily_tab_index: 0,
            summary: true,
            downtime: null,
            output: null,
            waste: null,
            rework: null,
            log_type: '',
            downtimeSort: 0,
            sort: 0,
            categories: null,
            runLogSort: 0,
            selectAllProducts: true,
            show_select_products: false,
            show_select_lines: false,
            number_of_pareto_bars: 10,
            edit_run_title: 'Edit Run',
            selected_products_display: [],
            selected_lines_display: [],
            move_split_tab_index: 0,
            split_tab: 'move',
            show_trend: false,
            filtered_run_id: 0,
            print_status: 'disabled',
            factory_name: '',
            factory_error: null,
            pareto_bars: 10,
            average_speed: 0,
            collapse: true,
            uses_headcount: false,
            shifts: 0,
            shifts_error: null,
            uncategorized_products: true,
            untagged_products: true,
        };
        this.getFactorySettings();
        ProductStore.clearFull();
        LineStore.subscribeLines(this.handleLineChange);
        ProductStore.subscribeFull(this.handleProductChange);

        this.setState(state, async () => {
            history.pushState(state, 'LiveTracking', '/reports');
            LineStore.clear();
            this.getFeatures();
            this.getCategories();
            this.getLatestReport();
            this.getSelectedTags();
            await this.getFactorySettings();
            LineStore.fetchLines();
        });
        // this.setState({
        //     lines: LineStore.getLines(),
        //     selected_lines: this.getSelectedLines()
        // });
    };

    setReportMultipleDates = selectedDates => {
        let parsedSelectedDates = []
        selectedDates.forEach(date => {
            date.setHours(0, 0, 0, 0);
            let newDate = new Date().setHours(0, 0, 0, 0);
            if (date <= newDate) {
                parsedSelectedDates.push(date)
            }
        });
        
        
        this.setState({
            multi_date: true,
            selected_dates: parsedSelectedDates,
            show_select_dates: false,
            inactive: false
        });
    };

    setReportRangeDates = (from, to) => {
        this.setState({
            multi_date: false,
            date_from: from,
            date_to: to,
            show_select_dates: false,
            inactive: false
        });
    };

    handledCheck = ({ id }) => {
        if (!id) {
            this.setState({ activeShifts: [] });
        } else {
            let activeShifts = _.xor(this.state.activeShifts, typeof id === 'object' ? id : [String(id)]);
            this.setState({ activeShifts });
        }
    };

    onLostTimeBarClick = (props) => {
        if (props !== null) {
            if (this.state.run) {
                let reasonName = props.reason;

                if (reasonName === this.state.active_lost_time_reason) {
                    reasonName = null;
                }

                this.setState({
                    active_lost_time_reason: reasonName
                });
            } else {
                // let reasonName = props.reason;
                // this.setState({
                //     show_lost_time_reason_dialog: reasonName,
                //     drill_down_reason: reasonName,
                //     drill_down_reason_id: props.reason_id,
                //     drill_down_category: props.category
                // });

                if (this.state.paretoValue === "Reason") {
                    let reasonName = props.reason;
                    let reasonId = props.reason_id;
                    let category = props.category;
                    
                    if (reasonName === this.state.show_lost_time_reason_dialog) {
                        reasonName = null;
                        reasonId = null;
                        category = null;
                        this.setState({
                            active_lost_time_reason: null
                        })
                    }
                    this.setState({
                        show_lost_time_reason_dialog: reasonName,
                        drill_down_reason: reasonName,
                        drill_down_reason_id: reasonId,
                        drill_down_category: category
                    });
                }
                else {
                    let reasonName = props.reason;
                    let reasonId = props.reason_id;
                    if (reasonName === this.state.show_lost_time_reason_dialog) {
                        reasonName = null;
                        reasonId = null;
                    }
                    this.setState({
                        show_lost_time_reason_dialog: reasonName,
                        drill_down_category: reasonName,
                        drill_down_category_id: reasonId
                    });
                }
            }
        }
    };

    onLostTimeBarClickOld = props => {
        if (props !== null) {
            if (this.state.run) {
                let reasonName = props.activePayload[0].payload.reason;
                if (reasonName === this.state.active_lost_time_reason) {
                    reasonName = null;
                }

                this.setState({
                    active_lost_time_reason: reasonName
                });
            } else {
                let reasonName = props.activePayload[0].payload.reason;
                this.setState({
                    show_lost_time_reason_dialog: reasonName,
                    drill_down_reason: reasonName,
                    drill_down_reason_id: props.activePayload[0].payload.reason_id,
                    drill_down_category: props.activePayload[0].payload.category
                });
            }
        }
    };

    getDatesLabel = () => {
        let label = '';

        if (this.state.multi_date) {
            if (this.state.selected_dates.length === 1) {
                label = this.formatDateTime(this.state.selected_dates[0]);
            } else {
                label = 'Multiple Dates';
            }
        } else if (this.state.date_from && this.state.date_to) {
            label = this.formatDateTime(this.state.date_from) + ' - ' + this.formatDateTime(this.state.date_to);
        }

        return label;
    };

    getUnitName = unit_id => {
        let unit = '';
        this.state.units.forEach(u => {
            if (u.id === unit_id) {
                unit = u.name;
            }
        });

        return unit;
    };

    /* -------- API CALLS -------- */
    getFeatures = () => {
        let params = {
            path: 'get_features_check',
            success: resp => this.setState({ uses_headcount: resp.uses_headcount })
        };

        API.c(params, 2);
    };
    getFirstReport = () => {
        this.setState({
            print_status: 'disabled'
        });
        if (this.state.run_id == null) {
            if (this.state.date_from == null && !this.state.multi_date) {
                this.getLatestReport();
            } else {
                this.getLatestReport();
                // this.getReport(this.state.view_type, false, false, 0);
            }
        } else {
            if (this.state.run == null) {
                this.selectRun({ run_id: this.state.run_id, description: this.state.run_description });
            }
        }
    };

    refreshReport = () => {
        if (this.state.run_id == null) {
            this.getReport(this.state.view_type, false, true);
        } else {
            this.selectRun({
                run_id: this.state.run_id,
                description: this.state.run_description,
                popped: true,
                shiftIds: this.state.shiftIds
            });
            this.showSpeedChangeChart();
        }
    };

    goToNewRun = data => {
        this.setState(
            {
                run_id: data.id
            },
            () => this.refreshReport()
        );
    };

    goToDateRunApply = data => {
        this.setState(
            {
                run_line_id: data.runLineId,
                shiftIds: data.shiftIndexes
            },
            () => this.refreshReport()
        );
    };

    goToDateRun = data => {
        this.setState({
            run_line_id: data.runLineId,
            shiftIds: data.shiftIndexes
        });
    };

    onRunCurrentlyRunning = () => {
        this.setState({
            snackbar: true
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbar: false
        });
    };

    getLatestReport = () => {
        this.setState({
            loading_report: true,
            error: false,
            inactive: true,
            report_date_from: this.state.date_from,
            report_date_to: this.state.date_to
        });

        let params = {
            path: 'last_production_day',
            data: {},
            success: this.loadLastProductionReportSuccess,
            error: this.loadReportError
        };

        API.c(params);
    };

    loadLastProductionReportSuccess = data => {
        if (window.location.pathname.split('/')[1] === 'reports') {
            let run_ids = [];
            data.table_info_list.map(run => {
                run_ids.push(run.run_id);
            });
            this.setState({
                loading_report: false,
                run_ids: run_ids,
                selected_products_display: Array.from(this.state.selected_products),
                selected_lines_display: Array.from(this.state.selected_lines),
                multi_date: true,
                report_multi_date: true,
                report_selected_dates: [new Date(data.date)],
                selected_dates: [new Date(data.date)]
            });

            this.setState(
                {
                    report: data,
                    date_from: new Date(data.date),
                    report_date_from: new Date(data.date),
                    date_to: new Date(data.date),
                    report_date_to: new Date(data.date),
                    print_status: 'enabled'
                },
                this.updateUrl
            );
        }
    };

    getReport = (
        report_path = this.state.view_type,
        killState = false,
        popped = false,
        selected_line = this.state.selected_line
    ) => {
        //
        if (this.state.backTo !== 'drill-down') {
            this.setState({
                show_lost_time_reason_dialog: null,
                drill_down_reason: null,
                drill_down_reason_id: null,
                drill_down_category: null,
                active_lost_time_reason: null,
                drill_down_category_id: null
            })
        }

        let getShiftsFromUrl = () => {
            let url = new URL(window.location.href);
            return url.searchParams.getAll('shift_id');
        };
        let product_id = null;
        if (this.state.selected_products && this.state.products) {
            if (
                this.state.selected_products.length > 0 &&
                this.state.selected_products.length !== this.state.products.length
            ) {
                product_id = this.state.selected_products;
            }
        }

        let category_id = null
        if (this.state.selected_categories && this.state.categories) {
            if (
                this.state.selected_categories.length > 0 &&
                this.state.selected_categories.length !== this.state.categories.length
            ) {
                category_id = this.state.selected_categories;
            }
            else if (this.state.selected_categories.length > 0 && this.state.selected_categories.length === this.state.categories.length) {
                category_id = this.state.selected_categories
            }
        }

        let tag_id = null
        if (this.state.selected_tags && this.state.tags) {
            if (
                this.state.selected_tags.length > 0 &&
                this.state.selected_tags.length !== this.state.tags.length
            ) {
                tag_id = this.state.selected_tags;
            }
            else if (this.state.selected_tags.length > 0 && this.state.selected_tags.length === this.state.tags.length) {
                tag_id = this.state.selected_tags
            }
        }

        let line_id = null;
        if (this.state.selected_lines && this.state.lines) {
            if (this.state.selected_lines.length > 0 && this.state.selected_lines.length !== this.state.lines.length) {
                line_id = this.state.selected_lines;
            }
        }
        const runList = 'runList';

        const shiftsDoxList = JSON.parse(localStorage.getItem(`shiftIds${runList}`));
        const checkedList = JSON.parse(localStorage.getItem(`checkedItems${runList}`));
        let shifts_selected = [];

        // if (shiftsDoxList && checkedList) {
        //     let filterShifts = checkedList.map((item, index) => {
        //         if (item) {
        //             shifts_selected.push(shiftsDoxList[index]);
        //         }
        //     });
        // }
            ///////////////////////////////////////
        // if (!window.location.href.includes('run') && shifts_selected.length === 0) {
        //     shifts_selected = getShiftsFromUrl();
        // }

        if (this.state.selectedShifts && this.state.selectedShifts.length > 0) {
            shifts_selected = this.state.selectedShifts.map(shift => shift.id);
        }

        let shifts = this.state.shifts !== 0;

        if (this.state.multi_date) {
            this.setState({
                loading_report: true,
                error: false,
                kill_state: killState,
                popped: popped,
                inactive: true,

                report_selected_line: selected_line,
                report_selected_product: this.state.selected_product_display,
                report_selected_dates: [...this.state.selected_dates],
                report_date_from: null,
                report_date_to: null,
                report_multi_date: true
            });

            let dates = [];
            if (this.state.selected_dates !== null && this.state.selected_dates[0] !== null) {
                this.state.selected_dates.forEach(date => {
                    let d = new Date(date.getTime());
                    let shifts = this.state.shifts !== 0;
                    if (shifts) {
                        if (this.state.shifts === 1) {
                            d.setHours(6, 30, 0, 0);
                        } else {
                            d.setHours(18, 30, 0, 0);
                        }
                    }
                    dates.push(d.toISOString());
                });
            }
            const shiftsDoxList = JSON.parse(localStorage.getItem(`shiftIds${runList}`));
            const checkedList = JSON.parse(localStorage.getItem(`checkedItems${runList}`));

            let shifts_selected = [];

            if (this.state.selectedShifts && this.state.selectedShifts.length > 0) {
                shifts_selected = this.state.selectedShifts.map(shift => shift.id);
            }
    
            let shifts = this.state.shifts !== 0;

            let data =
                shifts_selected.length <= 0
                    ? {
                        dates,
                        product_tag_id_list: tag_id,
                        line_id_list: line_id,
                        product_id_list: product_id,
                        product_category_id_list: category_id,
                        untagged_products: this.state.untagged_products !== undefined ? this.state.untagged_products : true,
                        uncategorized_products: this.state.uncategorized_products !== undefined ? this.state.uncategorized_products : true,
                    }
                    : {
                        dates,
                        shift_id: shifts_selected,
                        product_tag_id_list: tag_id,
                        line_id_list: line_id,
                        product_id_list: product_id,
                        product_category_id_list: category_id,
                        untagged_products: this.state.untagged_products !== undefined ? this.state.untagged_products : true,
                        uncategorized_products: this.state.uncategorized_products !== undefined ? this.state.uncategorized_products : true,
                    };

            if (shifts) {
                data['shift_duration_seconds'] = 43200;
                data['use_provided_times'] = true;
            }

            if (!data.line_id_list && !data.product_id_list && !data.product_category_id_list) {

            }

            let params = {
                path: 'get_multi_day_report',
                data: data,
                success: this.loadReportSuccess,
                error: this.loadReportError
            };

            API.c(params, 2);
        } else {
            this.setState({
                loading_report: true,
                error: false,
                kill_state: killState,
                popped: popped,
                inactive: true,
                report_date_from: this.state.date_from,
                report_date_to: this.state.date_to,
                report_selected_line: selected_line,
                report_selected_product: this.state.selected_product_display,
                report_selected_dates: null,
                report_multi_date: false
            });

            if (report_path === 1) {
                report_path = 'report';
            } else {
                report_path = 'report_weekly';
            }

            const d = moment(this.state.date_to).tz(this.state.timeZone);
            const t = moment(this.state.date_to).toISOString();

            // start_date=2021-04-17T18:00:00.000Z&end_date=2021-04-29T18:00:00.000Z&shift_id=2

            let endDate;
            if (this.state.date_to !== undefined) {
                endDate = new Date(this.state.date_to.getTime());
                if (shifts) {
                    if (this.state.shifts === 2) {
                        endDate.setDate(endDate.getDate() + 1);
                        endDate.setHours(6, 30, 0, 0);
                    } else {
                        endDate.setHours(18, 30, 0, 0);
                    }
                }
                endDate = endDate.toISOString();
            } else {
                endDate = null;
            }

            let startDate;

            if (this.state.date_from !== undefined) {
                startDate = new Date(this.state.date_from.getTime());
                if (shifts) {
                    if (this.state.shifts === 1) {
                        startDate.setHours(6, 30, 0, 0);
                    } else {
                        startDate.setHours(18, 30, 0, 0);
                    }
                }
                startDate = startDate.toISOString();
            } else {
                startDate = null;
            }

            const shiftsDoxList = JSON.parse(localStorage.getItem(`shiftIds${runList}`));
            const checkedList = JSON.parse(localStorage.getItem(`checkedItems${runList}`));

            let shifts_selected = [];

            if (this.state.selectedShifts && this.state.selectedShifts.length > 0) {
                shifts_selected = this.state.selectedShifts.map(shift => shift.id);
            }
    
            // let shifts = this.state.shifts !== 0;

            // if (shiftsDoxList && checkedList && shifts_selected.length <= 0) {
            //     let filterShifts = checkedList.map((item, index) => {
            //         if (item) {
            //             shifts_selected.push(shiftsDoxList[index]);
            //         }
            //     });
            // }

            // if (!window.location.href.includes('run')) {
            //     shifts_selected = getShiftsFromUrl();
            // }

            let data =
                shifts_selected.length <= 0
                    ? {
                        line_id: line_id,
                        start_date: startDate,
                        end_date: endDate,
                        product_category_id: category_id,
                        product_tag_id: tag_id,
                        product_id: product_id,
                        uncategorized_products: this.state.uncategorized_products !== undefined ? this.state.uncategorized_products : true,
                        untagged_products: this.state.untagged_products !== undefined ? this.state.untagged_products : true,
                    }
                    : {
                        line_id: line_id,
                        start_date: startDate,
                        end_date: endDate,
                        product_id: product_id,
                        product_category_id: category_id,
                        product_tag_id: tag_id,
                        uncategorized_products: this.state.uncategorized_products !== undefined ? this.state.uncategorized_products : true,
                        untagged_products: this.state.untagged_products !== undefined ? this.state.untagged_products : true,
                        shift_id: shifts_selected,
                    };

            if (shifts) {
                data['shift_duration_seconds'] = 43200;
                data['use_provided_times'] = true;
            }

            let params = {
                path: report_path,
                data: data,
                success: this.loadReportSuccess,
                error: this.loadReportError
            };

            API.c(params);
        }
    };

    loadReportSuccess = data => {
        if (window.location.pathname.split('/')[1] === 'reports') {
            if (this.state.kill_state == true) {
                this.setState({
                    run_id: null,
                    run_description: null,
                    run: null
                });
            }

            if (this.state.backTo === 'drill-down') {
                let show_lost_time_reason_dialog = this.state.return_to_reason;
                this.setState(
                    {
                        backTo: this.getBackTo(),
                        show_lost_time_reason_dialog,
                        active_lost_time_reason: show_lost_time_reason_dialog
                    },
                );
            }

            if (this.state.view_type === 1) {
                let run_ids = [];
                data.table_info_list.map(run => {
                    run_ids.push(run.run_id);
                });
                let maxBars = data.lost_time_list.length - 1;
                let barCount = 10;
                if (barCount > maxBars) {
                    barCount = maxBars;
                }
                this.setState(
                    {
                        loading_report: false,
                        report: data,
                        run_ids: run_ids,
                        number_of_pareto_bars: barCount,
                        selected_products_display: Array.from(this.state.selected_products),
                        selected_lines_display: Array.from(this.state.selected_lines),
                        print_status: 'enabled'
                    },
                    this.updateUrl
                );
            } else {
                this.setState(
                    {
                        loading_report: false,
                        report_weekly: data
                    },
                    this.updateUrl
                );
            }
        }
    };

    loadReportError = error => {
        let err = 'There was an error loading this report; please try again.';

        if (error && error.error) {
            if (error.error === 'No runs in range') {
                err = 'No runs in range selected';
            } else {
                err = error.error;
            }
        }
        this.setState({
            loading_report: false,
            error: err
        });
    };

    selectRun = item => {
        if (window.location.pathname.split('/')[1] === 'reports') {
            let run_id = item.run_id;
            let description = item.description;
            let batch_date = item.run_start_time;
            let event;
            switch (this.state.daily_tab_index) {
                case 2:
                    event = 'output';
                    break;
                case 3:
                    event = 'waste';
                    break;
                case 4:
                    event = 'rework';
                    break;
                case 5:
                    event = 'finished_goods';
                    break;
                default:
                    event = 'downtime';
                    break;
            }
            let popped = false;
            if (item.popped) {
                popped = item.popped;
            }
            this.setState({
                run_id: run_id,
                run_description: description,
                popped: popped,
                run_start_date: batch_date,
                batchRunRun: item,
                log_type: event,
                line_products: null
            });
            if (run_id != null) {
                if (this.state.run_ids != null) {
                    let nextRunId = null;
                    this.state.run_ids.forEach((id, index) => {
                        if (id === run_id) {
                            nextRunId = this.state.run_ids[index + 1];
                        }
                    });
                    this.setState({
                        nextRunId: nextRunId
                    });
                }

                if (run_id !== this.state.filtered_run_id) {
                    this.setState({
                        // categories: null
                    });
                }
                this.setState({
                    loading_run: true,
                    inDetails: true,
                    log: null
                });
                let params = {
                    path: 'run_data',
                    path_variables: {
                        run_id: run_id
                    },
                    // data: { line_id: 16 },
                    success: this.loadRunSuccess,
                    error: this.loadRunError
                };

                let logParams = {
                    path: 'get_log',
                    path_variables: {
                        RUN_ID: run_id
                    },
                    data: { [event]: true },
                    success: log => {
                        this.loadLogSuccess(log, event);
                    },
                    error: this.loadLogError
                };

                if (!_.isEmpty(item.shiftIds)) {
                    let namesShift = [];
                    item.shiftIds.map((shiftId, index) => {
                        namesShift.push(`shift_id=${shiftId}`);
                    });
                    params = {
                        path: 'run_data',
                        path_variables: {
                            run_id: `${run_id}?${namesShift.join('&')}` //`${run_id}?${namesShift.join('&')}`
                        },
                        success: this.loadRunSuccess,
                        error: this.loadRunError
                    };
                    logParams = {
                        path: 'get_log_shift',
                        path_variables: {
                            RUN_ID: `${run_id}`,
                            TOGGLE: true,
                            EVENT: event,
                            SHIFT_ID: `${namesShift.join('&')}`
                        },
                        data: {},
                        success: log => {
                            this.loadLogSuccess(log, event);
                        },
                        error: this.loadLogError
                    };
                }

                API.c(params, 2);
                API.c(logParams);

                let stateObj = {
                    date_from: this.state.date_from,
                    date_to: this.state.date_to,
                    run_id: run_id,
                    run: this.state.run,
                    selected_lines: this.state.selected_lines,
                    selected_products: this.state.selected_products,
                    report_type: this.state.view_type,
                    inDetails: false
                };

                let href = '/reports/?run=' + run_id;

                if (popped === true) {
                    history.replaceState(stateObj, 'LiveTracking', href);
                } else {
                    history.pushState(stateObj, 'LiveTracking', href);
                }
            } else {
                this.setState({
                    run: null,
                    inDetails: false
                });

                if (this.state.date_from == null) {
                    this.getLatestReport();
                } else {
                    this.getReport();
                }
            }
        }
    };

    loadRunSuccess = info => {
        if (info.shift_start_time) {
            this.setState({
                shift_start_time: info.shift_start_time,
                shift_end_time: info.shift_end_time
            });
        } else {
            this.setState({
                shift_start_time: null,
                shift_end_time: null
            });
        }
        let maxBars = info.lost_time_list.length - 1;
        let barCount = 10;
        if (barCount > maxBars) {
            barCount = maxBars;
        }

        this.setState({
            loading_run: false,
            error: null,
            run: info,
            run_line_id: info.line_id,
            filtered_run_id: this.state.run_id,
            number_of_pareto_bars: barCount
        });

        this.getReasons(info.line_id);
        this.getLineProducts(info.line_id);
    };

    loadRunError = error => {
        let err = 'There was an error loading this run; please refresh the page to try again.';

        if (error && error.error) {
            err = error.error;
        }
        // else {
        //     err = "No Batch Run Associated To This Run";
        //     this.setState({
        //         show_modal: 'associate_run'
        //     })
        // }
        this.setState({
            loading_run: false,
            error: err
        });
    };

    reasonFetchSuccess = data => {
        this.setState({
            reasons: data
        });
    };

    reasonFetchError = error => {
        this.setState({
            submit_status: error
        });
    };

    getReasons = id => {
        let reasonParams = {
            path: 'get_line_reasons',
            path_variables: { LINE_ID: id },
            success: this.reasonFetchSuccess,
            error: this.reasonFetchError
        };

        API.c(reasonParams, 2);
    };

    getLineProducts = id => {
        let params = {
            path: 'line_products',
            path_variables: { ID: id },
            success: this.lineProductsFetchSuccess,
            error: this.lineProductsFetchError
        };

        API.c(params, 2);
    };

    lineProductsFetchSuccess = data => {
        this.setState({
            line_products: data
        });
    };

    lineProductsFetchError = error => {
        console.log(error);
    };

    getLog = t => {
        let data = {};
        data[t] = true;
        if (this.state.shiftIds.length > 0) {
            data['shift_id'] = this.state.shiftIds[0];
        }
        this.setState({
            log_type: t
        });

        let logParams = {
            path: 'get_log',
            path_variables: {
                RUN_ID: this.state.run_id
            },
            data: data,
            success: log => {
                this.loadLogSuccess(log, t);
            },
            error: this.loadLogError
        };

        API.c(logParams, 2);
    };

    loadLogSuccess = (log, type) => {
        switch (type) {
            case 'downtime':
                this.setState({
                    downtime: log
                });
                break;
            case 'output':
                this.setState({
                    output: log
                });
                break;
            case 'rework':
                this.setState({
                    rework: log
                });
                break;
            case 'waste':
                this.setState({
                    waste: log
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods: log
                });
                break;
        }

        this.setState({
            log_type: ''
        });
    };

    loadLogError = error => {
        this.setState({
            logError: error,
            log_type: ''
        });
    };

    getNextRun = () => {
        let params = {
            path: 'run_data',
            path_variables: {
                run_id: this.state.nextRunId
            },
            success: this.loadNextRunSuccess,
            error: this.loadNextRunError
        };

        API.c(params);
    };

    loadNextRunSuccess = data => {
        let nextRunId = null;
        this.state.run_ids.forEach((id, index) => {
            if (id === this.state.nextRunId) {
                nextRunId = this.state.run_ids[index + 1];
            }
        });
        this.setState({
            nextRun: data,
            loadedId: this.state.nextRunId, //Will use this to keep track of the the run id after it gets loaded to the screen (for refreshReport)
            nextRunId: nextRunId
        });
    };

    loadNextRunError = error => {
        this.setState({
            nextRun: null,
            lastRun: undefined
        });
    };

    getFactorySettings = () => {
        this.setState({
            getting_report: true
        });

        let params = {
            path: 'get_factory_settings',
            success: this.onGetFactorySettingsSuccess,
            error: this.onGetFactorySettingsError
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = data => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
        this.setState({
            pareto_bars: data.default_daily_pareto_bars,
            factory_name: data.name,
            factory_settings: data,
            timeZone: data.timezone ? data.timezone : this.state.timeZone,
            usesShifts: data.uses_shifts ? data.uses_shifts : false,
            uses_latest_report: data.uses_latest_report,
            // uses_latest_report: false,
        });
    };

    onGetFactorySettingsError = error => {
        this.setState({
            factory_error: error
        });
    };

    getUnits = () => {
        let params = {
            path: 'get_units',
            success: this.onGetUnitsSuccess,
            error: this.onGetUnitsError
        };

        API.c(params, 2);
    };

    getCategories = () => {
        let params = {
            path: 'all_categories',
            success: (data) => { this.onGetCategoriesSuccess(data); this.onGetSelectedCategoriesSuccess(data) },
            error: this.onGetCategoriesError
        };

        API.c(params, 2);
    };

    getReasonCategories = () => {
        let params = {
            path: 'all_reason_categories',
            success: this.onGetReasonCategoriesSuccess,
            error: () => {}
        };

        API.c(params);
    }

    getTags = () => {
        let params = {
            path: 'all_tags',
            success: (data) => { this.onGetTagsSuccess(data); this.onGetSelectedTagsSuccess(data) },
            error: this.onGetTagsError
        };

        API.c(params, 2);
    };

    onGetReasonCategoriesSuccess = (data) => {
        let categories = [];
        data.forEach(d => {
            categories.push(d);
        });
        this.setState({
            reasonCategories: categories,
        });
    }

    onGetUnitsSuccess = data => {
        this.setState({ units: data });
    };

    onGetUnitsError = error => {
        console.error('ERROR GETTING UNITS', error);
    };

    onGetCategoriesSuccess = data => {
        this.setState({ categories: data });
    };

    onGetSelectedCategoriesSuccess = data => {
        this.setState({ selected_categories: Array.from(data, (item) => item.id) });
    };

    onGetCategoriesError = error => {
        console.error('ERROR GETTING CATEGORIES', error);
    };

    onGetTagsSuccess = data => {
        this.setState({ tags: data });
    };

    onGetSelectedTagsSuccess = data => {
        this.setState({ selected_tags: Array.from(data, (item) => item.id) });
    };

    onGetTagsError = error => {
        console.error('ERROR GETTING TAGS', error);
    };

    onGetProductOnLineUnitsSuccess = data => {
        this.setState({ line_units: data });
    };


    handleSelectShiftsChange = (event) => {
        this.setState({
            shifts: event.target.value,
            inactive: false
        });
    };

    saveSelectedShifts = (shifts) => {
        const idArray = shifts.map(shift => shift.id);
        window.localStorage.setItem('selectedShifts', JSON.stringify(idArray));
        this.setState({
            selectedShifts: shifts,
            inactive: false
        });
    }

    /* -------- RENDERS -------- */

    showLines = () => {
        if (this.state.lines == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.selected_lines && this.state.lines[0])
            if (this.state.selected_lines.length === this.state.lines.length) {
                count = 'All';
            } else {
                count = this.state.selected_lines.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', width: '200px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className="reports__options-item dropdown-button"
                    variant="text"
                    onClick={this.openSelectLines}
                    endIcon={<FontIcon className="zmdi zmdi-caret-down" style={{ color: 'rgb(224, 224, 224)' }} />}
                >
                    {'Line Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    showProducts = () => {
        if (this.state.products == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.selected_products && this.state.products[0])
            if (this.state.selected_products.length === this.state.products.length) {
                count = 'All';
            } else {
                count = this.state.selected_products.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', width: '200px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className="reports__options-item dropdown-button"
                    variant="text"
                    // labelStyle={{textTransform: "capitalize", paddingLeft: "0px"}}
                    onClick={this.openSelectProducts}
                    endIcon={<FontIcon className="zmdi zmdi-caret-down" style={{ color: 'rgb(224, 224, 224)' }} />}
                >
                    {'Product Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    tableRowColumnStyle() {
        return {
            fontFamily: 'Roboto',
            fontSize: '12px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: '#000000',
            border: 'none'
        };
    }

    tableRowTotalColumnStyle() {
        return {
            fontFamily: 'Roboto',
            fontSize: '12px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: '#000000',
            border: 'none',
            fontWeight: 'bold'
        };
    }

    tableRowStyle(index) {
        let bgColor = '#ffffff';
        if (index % 2 === 0) bgColor = '#f2f2f2';

        return {
            border: 'none',
            backgroundColor: bgColor
        };
    }

    tableRowTotalStyle() {
        let bgColor = '#afafaf';

        return {
            border: '1px solid #afafaf',
            backgroundColor: 'none'
        };
    }

    tableHeaderStyle(bg = '#FFFFFF') {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#000000',
            borderBottom: '1px solid #999999',
            backgroundColor: bg
        };
    }

    getProductTargetWeight = () => {
        let product = null;
        let products = this.state.line_products;
        if (products) {
            products.forEach(prod => {
                let product_display = this.state.run.product_name + ' - ' + this.state.run.product_desc;
                if (prod.product_name + ' - ' + prod.product_desc === product_display) {
                    product = prod;
                }
            });

            if (product) {
                return parseFloat(product.product_target_weight).toFixed(2) + ' KG';
            } else {
                return 'Loading...';
            }
        } else {
            return 'Loading...';
        }
    };

    getMultiDayText = () => {
        let { report_selected_dates } = this.state;

        let dates = [...report_selected_dates];
        dates.sort((a, b) => a.getTime() - b.getTime());

        let yearMonthDays = [];

        for (let i = 0; i < dates.length; i++) {
            let d = dates[i];
            let year = d.getFullYear();
            let month = d.getMonth();
            let day = d.getDate();

            if (!yearMonthDays.some(e => e.year === year)) {
                let monthObj = { month, days: [day] };
                yearMonthDays.push({ year: year, months: [monthObj] });
            } else {
                let yearObj = yearMonthDays.find(e => e.year === year);
                let yearIndex = yearMonthDays.findIndex(e => e.year === year);

                if (yearObj.months.some(m => m.month === month)) {
                    let monthIndex = yearObj.months.findIndex(m => m.month === month);
                    yearMonthDays[yearIndex].months[monthIndex].days.push(day);
                } else {
                    let monthObj = { month, days: [day] };
                    yearMonthDays[yearIndex].months.push(monthObj);
                }
            }
        }

        if (yearMonthDays.length > 0) {
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            return yearMonthDays.map((o, i) => {
                let append = i === 0 ? '' : '\n';
                return (
                    <div key={'year-' + i} className="reports__title--daily-overview--column-values">
                        <span>{append + o.year + ' '}</span>
                        {o.months.map((mO, i) => {
                            return i === 0 ? (
                                <span key={'month-' + i}>
                                    {months[mO.month]}
                                    {mO.days.map((d, i) => {
                                        return <span key={'day-' + i}>{i === 0 ? ' ' + d : ', ' + d}</span>;
                                    })}
                                </span>
                            ) : (
                                <div key={'month--' + i} style={{ marginLeft: '34px' }}>
                                    {months[mO.month]}
                                    {mO.days.map((d, i) => {
                                        return <span key={'day--' + i}>{i === 0 ? ' ' + d : ', ' + d}</span>;
                                    })}
                                </div>
                            );
                        })}
                    </div>
                );
            });
        } else {
            return '';
        }
    };

    showReportTitle = () => {
        if (
            !this.state.lines ||
            this.state.lines.constructor !== Array ||
            this.state.selected_line == null ||
            Util.isEmpty(this.state.products)
        ) {
            return (
                <div className="reports__title-daily">
                    <Typography variant={'body2'} className={'reports__title-daily__typography'}>
                        Loading...
                    </Typography>
                </div>
            );
        }

        if (this.state.loading_report === true) {
            return (
                <div className="reports__title-daily">
                    <Typography variant={'body2'} className={'reports__title-daily__typography'}>
                        Loading...
                    </Typography>
                </div>
            );
        }

        if (this.state.loading_report === false && this.state.error) {
            return (
                <div className="reports__title-daily">
                    <Typography variant={'body2'} className={'reports__title-daily__typography'}>
                        Select a Line and Date Range
                    </Typography>
                </div>
            );
        }

        let line_name = this.state.selected_lines_display[0]
            ? this.state.lines.find(line => line.id === this.state.selected_lines_display[0])
                ? this.state.lines.find(line => line.id === this.state.selected_lines_display[0]).name
                : ''
            : 'All Lines';

        if (this.state.selected_lines_display.length > 1) {
            line_name = 'Multi-line';
        }

        if (this.state.selected_lines_display.length === this.state.lines.length) {
            line_name = 'All Lines';
        }

        let product_description = this.state.selected_products_display[0]
            ? this.state.products.find(product => product.product_id === this.state.selected_products_display[0])
                ? this.state.products.find(product => product.product_id === this.state.selected_products_display[0])
                    .display_name
                : ''
            : 'All Products';

        if (this.state.selected_products_display.length > 1) {
            product_description = 'Multi-product';
        }

        if (this.state.selected_products_display.length === this.state.products.length) {
            product_description = 'All Products';
        }

        let date_range = this.state.report_multi_date
            ? this.getMultiDayText()
            : this.formatDateTime(this.state.report_date_from) + ' - ' + this.formatDateTime(this.state.report_date_to);

        return null;
        return (
            <div className="reports__title-daily">
                <Typography variant={'body2'} className={'reports__title-daily__typography'}>
                    {line_name}
                </Typography>
                <Divider orientation={'vertical'} className={'reports__title-daily__divider'} />
                <Typography variant={'body2'} className={'reports__title-daily__typography'}>
                    {product_description}
                </Typography>
                <Divider orientation={'vertical'} className={'reports__title-daily__divider'} />
                <Typography variant={'body2'} component={'div'} className={'reports__title-daily__typography'}>
                    {date_range}
                </Typography>
            </div>
        );
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    showDailyReportTabs = () => {
        return (
            <div>
                <Paper style={{ boxShadow: "none" }} square>
                    <Tabs
                        className="tabs"
                        value={this.state.daily_tab_index}
                        onChange={this.onTabChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Summary" style={{ padding: `20px`, fontSize: `14px`, borderBottom: `1px solid ${colorPalette.neutral[40]}`, textTransform: "none" }} />
                        <Tab label="Downtime" style={{ padding: `20px`, fontSize: `14px`, borderBottom: `1px solid ${colorPalette.neutral[40]}`, textTransform: "none" }} />
                        <Tab label="Sensor Count" style={{ padding: `20px`, fontSize: `14px`, borderBottom: `1px solid ${colorPalette.neutral[40]}`, textTransform: "none" }} />
                        <Tab label="Waste" style={{ padding: `20px`, fontSize: `14px`, borderBottom: `1px solid ${colorPalette.neutral[40]}`, textTransform: "none" }} />
                        <Tab label="Rework" style={{ padding: `20px`, fontSize: `14px`, borderBottom: `1px solid ${colorPalette.neutral[40]}`, textTransform: "none" }} />
                        <Tab label="Finished Goods" style={{ padding: `20px`, fontSize: `14px`, borderBottom: `1px solid ${colorPalette.neutral[40]}`, textTransform: "none" }} />
                    </Tabs>
                </Paper>
                <div>
                    {this.state.daily_tab_index === 0 && (
                        <>
                            {!this.state.uses_latest_report ? (
                                <div style={{ marginTop: '16px' }}>
                                    <div className="reports__floated">
                                        {this.showEfficiency()}
                                        {this.showLostTime()}
                                    </div>
                                    {this.showRunTimeline()}
                                    {this.showSpeedChangeChart()}
                                </div>
                            ) : (
                                <div style={{ marginTop: '16px' }}>
                                    <div id="charts" className="reports__floated" style={{ padding: "0 0px", gap: "24px", width: "auto", marginBottom: "24px" }}>
                                        {this.showEfficiency()}
                                        {this.showMetrics()}
                                        {this.showPlannedUnplanned()}
                                    </div>
                                    {this.showLostTime()}
                                    <div style={{ marginTop: "24px" }}>
                                        {this.showRunTimeline()}
                                    </div>
                                    <div style={{ marginTop: "24px" }}>
                                        {this.showSpeedChangeChart()}
                                    </div>
                                    <div style={{ display: "flex", padding: "24px 0px 0px 0px", gap: "24px", width: "auto" }}>
                                        {this.showWastePareto()}
                                        {this.showReworkPareto()}
                                    </div>  
                                </div>
                            )}
                        </>
                    )}
                    {this.state.daily_tab_index === 1 && (
                        <div className="daily-report-tab">{this.renderEvent('Downtime')}</div>
                    )}
                    {this.state.daily_tab_index === 2 && (
                        <div className="daily-report-tab">{this.renderEvent('Output')}</div>
                    )}
                    {this.state.daily_tab_index === 3 && (
                        <div className="daily-report-tab">{this.renderEvent('Waste')}</div>
                    )}
                    {this.state.daily_tab_index === 4 && (
                        <div className="daily-report-tab">{this.renderEvent('Rework')}</div>
                    )}
                    {this.state.daily_tab_index === 5 && (
                        <div className="daily-report-tab">{this.renderEvent('FinishedGoods')}</div>
                    )}
                </div>
            </div>
        );
    };

    getDowntimeSortIcon = sortOn => {
        let className = 'zmdi zmdi-sort-amount-desc';

        if (sortOn === 'start_time') {
            switch (this.state.downtimeSort) {
                case 0:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 1:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }

        if (sortOn === 'end_time') {
            switch (this.state.downtimeSort) {
                case 2:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 3:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }

        if (sortOn === 'duration') {
            switch (this.state.downtimeSort) {
                case 4:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 5:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }

        return className;
    };

    getSortIcon = sortOn => {
        let className = 'zmdi zmdi-sort-amount-desc';

        if (sortOn === 'time') {
            switch (this.state.sort) {
                case 0:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 1:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }
        if (sortOn === 'value') {
            switch (this.state.sort) {
                case 2:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 3:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }

        return className;
    };

    createSortHandler = (property) => (event) => {
        this.handleRequestSort(event, property);
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    renderEvent = e => {
        let events;
        let total = 0;
        let multiplier = 1;

        switch (e) {
            case 'Output':
                events = this.state.output;
                if (events) {
                    multiplier = this.state.run.product_multiplier ? this.state.run.product_multiplier : 1;
                    events.sort(this.compareEvents);
                    events.forEach(event => (total += event.value));
                    total = total * multiplier;
                }
                break;
            case 'Rework':
                events = this.state.rework;
                if (events) {
                    let product = null;
                    let products = ProductStore.getFull();
                    products.forEach(prod => {
                        let product_display = this.state.run.product_name + ' - ' + this.state.run.product_desc;
                        if (prod.name + ' - ' + prod.desc === product_display) {
                            product = prod;
                        }
                    });
                    multiplier = this.state.run.product_multiplier ? this.state.run.product_multiplier : 1;
                    multiplier =
                        product && product.target_weight !== 0 ? multiplier * product.target_weight : multiplier;
                    events.sort(this.compareEvents);
                    events.forEach(event => (total += event.value));
                    total = total * multiplier;
                }
                break;
            case 'FinishedGoods':
                events = this.state.finished_goods;
                if (events) {
                    let product = null;
                    let products = ProductStore.getFull();
                    if (products.length > 0) {
                        products.forEach(prod => {
                            let product_display = this.state.run.product_name + ' - ' + this.state.run.product_desc;
                            if (prod.name + ' - ' + prod.desc === product_display) {
                                product = prod;
                            }
                        });
                    }
                    // multiplier = this.state.run.product_multiplier ? this.state.run.product_multiplier : 1;
                    // multiplier =
                    //     product && product.target_weight !== 0 ? multiplier * product.target_weight : multiplier;
                    events.sort(this.compareEvents);
                    events.forEach(event => (total += event.value));
                    // total = total * multiplier;
                    // total = total;
                }
                break;
            case 'Waste':
                events = this.state.waste;
                if (events) {
                    let product = null;
                    let products = ProductStore.getFull();
                    if (products.length > 0) {
                        products.forEach(prod => {
                            let product_display = this.state.run.product_name + ' - ' + this.state.run.product_desc;
                            if (prod.name + ' - ' + prod.desc === product_display) {
                                product = prod;
                            }
                        });
                    }
                    multiplier = this.state.run.product_multiplier ? this.state.run.product_multiplier : 1;
                    multiplier =
                        product && product.target_weight !== 0 ? multiplier * product.target_weight : multiplier;
                    events.sort(this.compareEvents);
                    events.forEach(event => (total += event.value));
                    total = total * multiplier;
                }
                break;
            case 'Downtime':

                events = this.state.downtime;
                if (events) {
                    events.forEach(
                        event =>
                        (total +=
                            ((event.end_time ? new Date(event.end_time) : new Date()).getTime() -
                                new Date(event.start_time).getTime()) /
                            1000)
                    );
                }
                if (this.state.categories && this.state.categories.length > 0 && this.state.categories[0][0]) {
                    let filteredEvents = [];
                    let filters = [];
                    let catMap = new Map();
                    this.state.downtime.map(downtime => {
                        catMap.set(downtime.reason_name, true);
                    });
                    let comparisonCategories = Array.from(catMap).sort(this.compareCategories);
                    let areTheSame = true
                    comparisonCategories.forEach((item, i) => {
                        if (!this.state.categories[i] || item[0] !== this.state.categories[i][0]) {
                            areTheSame = false
                            return
                        }
                    })
                    if (areTheSame) {
                        this.state.categories.forEach(category => {
                            if (category && category[1]) {
                                filters.push(category[0]);
                            }
                        });
                    }
                    else {
                        comparisonCategories.forEach(category => {
                            if (category && category[1]) {
                                filters.push(category[0]);
                            }
                        });
                        this.setState({
                            categories: comparisonCategories
                        })
                    }
                    events.forEach(event => {
                        if (filters.includes(event.reason_name)) {
                            filteredEvents.push(event);
                        }
                    });
                    events = filteredEvents;
                }
                if (events) {
                    events.sort(this.compareDowntimes);
                }
                break;
            default:
                events = null;
        }
        if (events === undefined) {
            events = []
        }
        if (events === null || Util.isEmpty(ProductStore.getFull())) {
            return (
                <div className="card__flex-box">
                    <CircularProgress />
                </div>
            );
        } else {
            let pageCount = Math.ceil(events.length / 20);
            let paginatedEvents = [];
            for (let i = this.state.offset; i < this.state.offset + 20; i++) {
                if (i < events.length) {
                    if (events[i].type === "Finished Goods") {
                        const newEvent = events[i]
                        newEvent.type = "finished_goods"
                        paginatedEvents.push(newEvent);
                    }
                    else {
                        paginatedEvents.push(events[i]);
                    }
                }
            }
            if (e === 'Downtime') {
                return (
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: `36px`, marginBottom: `36px` }}>
                            <Typography variant="subtitle2" style={{ fontSize: `20px`, textTransform: "none", fontWeight: "600", color: colorPalette.neutral[100] }}>
                                Total {e}: <span style={{ fontWeight: "500" }}>{formatToHhMmSs(total)}</span>
                            </Typography>
                            {!this.state.summary && minimumRole('factory_admin') ? (
                                <Button style={{ height: `36px`, width: `128px`, fontSize: `14px`, textTransform: "none", boxShadow: "none" }}  variant="contained" onClick={this.addEvent.bind(this)} color="primary">
                                    <span style={{ fontSize: `20px`, fontWeight: "400", marginRight: `8px` }}>+</span> Add New
                                </Button>
                            ) : null}
                        </div>
                        <Table sx={{ border: "1px solid #E2E4E9", borderRadius: `4px`, borderBottom: "none", borderCollapse: "unset !important" }}>
                            <TableHead sx={{ background: "#F2F2F2" }}>
                                <TableRow>
                                    <TableCell
                                        align={'left'}
                                        className={'reports_event_table_head_cell'}
                                        onClick={this.filterDowntime}
                                    >
                                        <span style={{ marginRight: `4px`, fontSize: `14px` }}>Reason</span>
                                        {
                                            <TippyTooltip
                                                html={
                                                    <div
                                                        style={{
                                                            fontFamily: 'Roboto',
                                                            fontSize: `10px`,
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        Filter
                                                    </div>
                                                }
                                                position="right"
                                                animation="fade"
                                                theme="dark"
                                            >
                                                <FontIcon
                                                    style={{
                                                        fontSize: `14px`,
                                                        paddingTop: `8px`,
                                                        paddingBottom: `8px`,
                                                        marginRight: `4px`
                                                    }}
                                                    className="zmdi zmdi-filter-list"
                                                />
                                            </TippyTooltip>
                                        }
                                        <span style={{ fontSize: `10px` }}>{this.getNumOfFilteredItems()}</span>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={'reports_event_table_head_cell'}
                                        sortDirection={this.state.orderBy === "start_time" ? this.state.order : false}
                                        onClick={this.sortDowntime.bind(this, 'start_time')}
                                        sx={{fontSize: `14px`}}
                                    >
                                        <TableSortLabel
                                            active={this.state.orderBy === 'start_time'}
                                            direction={this.state.orderBy === 'start_time' ? this.state.order : 'asc'}
                                            onClick={this.createSortHandler('start_time')}
                                            sx={{fontSize: `14px`}}
                                        >
                                            Start Time{' '}
                                            {this.state.orderBy === 'start_time' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={'reports_event_table_head_cell'}
                                        sortDirection={this.state.orderBy === "end_time" ? this.state.order : false}
                                        onClick={this.sortDowntime.bind(this, 'end_time')}
                                        sx={{fontSize: `14px`}}
                                    >
                                        <TableSortLabel
                                            active={this.state.orderBy === 'end_time'}
                                            direction={this.state.orderBy === 'end_time' ? this.state.order : 'asc'}
                                            onClick={this.createSortHandler('end_time')}
                                            sx={{fontSize: `14px`}}
                                        >
                                            End Time{' '}
                                            {this.state.orderBy === 'end_time' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={'reports_event_table_head_cell'}
                                        sortDirection={this.state.orderBy === "duration" ? this.state.order : false}
                                        onClick={this.sortDowntime.bind(this, 'duration')}
                                        sx={{fontSize: `14px`}}
                                    >
                                        <TableSortLabel
                                            active={this.state.orderBy === 'duration'}
                                            direction={this.state.orderBy === 'duration' ? this.state.order : 'asc'}
                                            onClick={this.createSortHandler('duration')}
                                            sx={{fontSize: `14px`}}
                                        >
                                            Duration{' '}
                                            {this.state.orderBy === 'duration' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_head_cell'}>
                                        Notes
                                    </TableCell>
                                    <TableCell align={'right'} className={'reports_event_table_head_cell'} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedEvents.map((event, index) => {
                                    if (event.type === e) {
                                        return (
                                            <TableRow key={'event-' + event.id}>
                                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                    {event.reason_name}
                                                </TableCell>
                                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                    {moment(event.start_time).tz(this.state.timeZone).format('LTS')}
                                                </TableCell>
                                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                    {event.end_time
                                                        ? moment(event.end_time).tz(this.state.timeZone).format('LTS')
                                                        : 'In Progress'}
                                                </TableCell>
                                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                    {formatToHhMmSs((( event.end_time ? new Date(event.end_time) : new Date()).getTime() - new Date(event.start_time).getTime()) /1000 ) } 
                                                    {/* {Util.formatHourMinuteSecondsFromSeconds((( event.end_time ? new Date(event.end_time) : new Date()).getTime() - new Date(event.start_time).getTime()) /1000 )} */}
                                                </TableCell>
                                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                    {event.notes}
                                                </TableCell>
                                                <TableCell align={'right'} className={'reports_event_table_body_cell'}>
                                                    {minimumRole('factory_admin') && (
                                                        <div style={{ display: "flex", alignItems: "center", gap: `14px`, justifyContent: "end" }}>
                                                            <Button
                                                                variant="text"
                                                                color=""
                                                                style={{ display: "flex", alignItems: "center", textTransform: "none" }}
                                                                onClick={this.editEvent.bind(this, event)}
                                                                style={{fontSize: `14px`}}
                                                            >
                                                                <img src={TableEditIcon} style={{ marginRight: `6px`, width: `18px` }} /> Edit
                                                            </Button>
                                                            <div style={{ width: "1px", height: "16px", background: colorPalette.neutral[40] }} />
                                                            <Button
                                                                variant="text"
                                                                color="secondary"
                                                                style={{ display: "flex", alignItems: "center", textTransform: "none" }}
                                                                onClick={this.showDeleteEventConfirmation.bind(
                                                                    this,
                                                                    event
                                                                )}
                                                                style={{fontSize: `14px`}}
                                                            >
                                                                <img src={TableDeleteIcon} style={{ marginRight: `6px`, width: `18px` }} /> Delete
                                                            </Button>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                        <div className="flex-box__row justify-end">
                            {/* <ReactPaginate
                                forcePage={this.state.currentPage}
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                previousLinkClassName={'page-previous'}
                                nextLinkClassName={'page-next'}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page'}
                                pageLinkClassName={'page-link'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            /> */}
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={events.length}
                                rowsPerPage={20}
                                page={this.state.currentPage}
                                onPageChange={this.handlePageClick}
                            />
                        </div>
                    </div>
                );
            }
            return (
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: `36px`, marginBottom: `36px` }}>
                        <Typography variant="subtitle2" style={{ fontSize: `20px`, textTransform: "none", fontWeight: "600", color: colorPalette.neutral[100] }}>
                            Total {e === 'Output' ? 'Sensor Count' : e === 'FinishedGoods' ? 'Finished Goods' : e}: <span style={{ fontWeight: "500" }}>
                                {total.toFixed(2)} {e === 'Output' ? this.state.run.unit_info.output.display_unit_name : 
                                                    e === 'FinishedGoods' ? this.state.run.unit_info.finished_goods.display_unit_name : 
                                                    e === 'Waste' ? this.state.run.unit_info.waste.display_unit_name :
                                                    this.state.run.unit_info.rework.display_unit_name}
                            </span>
                        </Typography>
                        {!this.state.summary && minimumRole('factory_admin') ? (
                            <Button style={{ height: `36px`, width: `128px`, fontSize: `14px`, textTransform: "none", boxShadow: "none" }}  variant="contained" onClick={this.addEvent.bind(this)} color="primary">
                                <span style={{ fontSize: `20px`, fontWeight: "400", marginRight: `8px` }}>+</span> Add New
                            </Button>
                        ) : null}
                    </div>
                    <Table sx={{ border: "1px solid #E2E4E9", borderRadius: `4px`, borderBottom: "none", borderCollapse: "unset !important" }}>
                        <TableHead sx={{ background: "#F2F2F2" }}>
                            <TableRow>
                                <TableCell
                                    align={'left'}
                                    className={'reports_event_table_head_cell'}
                                    onClick={this.sortEvents.bind(this, 'time')}
                                    sortDirection={this.state.orderBy === "time" ? this.state.order : false}
                                    sx={{fontSize: `14px`}}
                                >
                                    <TableSortLabel
                                        active={this.state.orderBy === 'time'}
                                        direction={this.state.orderBy === 'time' ? this.state.order : 'asc'}
                                        onClick={this.createSortHandler('time')}
                                        sx={{fontSize: `14px`}}
                                    >
                                        Time{' '}
                                        {this.state.orderBy === 'time' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    className={'reports_event_table_head_cell'}
                                    onClick={this.sortEvents.bind(this, 'value')}
                                    sortDirection={this.state.orderBy === "value" ? this.state.order : false}
                                    sx={{fontSize: `14px`}}
                                >
                                    <TableSortLabel
                                        active={this.state.orderBy === 'value'}
                                        direction={this.state.orderBy === 'value' ? this.state.order : 'asc'}
                                        onClick={this.createSortHandler('value')}
                                        sx={{fontSize: `14px`}}
                                    >
                                        Value{' '}
                                        {this.state.orderBy === 'value' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_head_cell'}>
                                    Unit
                                </TableCell>
                                {e === 'Waste' || e === 'Rework' ? (
                                    <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_head_cell'}>
                                        Reason
                                    </TableCell>
                                ) : null}
                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_head_cell'}>
                                    Notes
                                </TableCell>
                                <TableCell align={'right'} className={'reports_event_table_head_cell'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedEvents.map((event, index) => {
                                if (event.type === e || event.type === "finished_goods") {
                                    return (
                                        <TableRow key={'event-' + event.id}>
                                            <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                {moment(event.start_time).tz(this.state.timeZone).format('LTS')}
                                            </TableCell>
                                            <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                {(parseFloat(event.value) * multiplier).toFixed(2)}
                                            </TableCell>
                                            <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                {event.display_unit ? event.display_unit : ''}
                                            </TableCell>
                                            {e === 'Waste' || e === 'Rework' ? (
                                                <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                    {event.reason_name ? event.reason_name : ''}
                                                </TableCell>
                                            ) : null}
                                            <TableCell align={'left'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                {event.notes}
                                            </TableCell>
                                            <TableCell align={'right'} sx={{fontSize: `14px`}} className={'reports_event_table_body_cell'}>
                                                {minimumRole('factory_admin') && (
                                                    <div style={{ display: "flex", alignItems: "center", gap: `14px`, justifyContent: "end" }}>
                                                        <Button
                                                            variant="text"
                                                            color=""
                                                            style={{ display: "flex", alignItems: "center", textTransform: "none" }}
                                                            onClick={this.editEvent.bind(this, event)}
                                                            style={{fontSize: `14px`}}
                                                        >
                                                            <img src={TableEditIcon} style={{ marginRight: `6px`, width: `18px` }} /> Edit
                                                        </Button>
                                                        <div style={{ width: "1px", height: "16px", background: colorPalette.neutral[40] }} />
                                                        <Button
                                                            variant="text"
                                                            color="secondary"
                                                            style={{ display: "flex", alignItems: "center", textTransform: "none" }}
                                                            onClick={this.showDeleteEventConfirmation.bind(this, event)}
                                                            style={{fontSize: `14px`}}
                                                        >
                                                            <img src={TableDeleteIcon} style={{ marginRight: `6px`, width: `18px` }} /> Delete
                                                        </Button>
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                    <div className="flex-box__row justify-end">
                        {/* <ReactPaginate
                            forcePage={this.state.currentPage}
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            previousLinkClassName={'page-previous'}
                            nextLinkClassName={'page-next'}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page'}
                            pageLinkClassName={'page-link'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        /> */}
                        <TablePagination
                            rowsPerPageOptions={[20]}
                            component="div"
                            count={events.length}
                            rowsPerPage={20}
                            page={this.state.currentPage}
                            onPageChange={this.handlePageClick}
                        />
                    </div>
                </div>
            );
        }
    };

    getViewOptionClass = id => {
        let selected = '';

        if (this.state.view_type === id) {
            selected += '--selected';
        }

        return `line-view-options__item${selected}`;
    };

    renderPrint = () => {
        if (this.state.print_status === 'printing') {
            return (
                <div className="reports__options-print">
                    <CircularProgress size={25} thickness={2} />
                </div>
            );
        }

        return (
            <div
                className="reports__options-print"
                onClick={this.state.print_status === 'disabled' ? null : this.printScreen.bind(this)}
            >
                <FontIcon
                    style={
                        this.state.print_status === 'disabled'
                            ? { opacity: 0.2, cursor: 'not-allowed' }
                            : { opacity: 1 }
                    }
                    className="zmdi zmdi-collection-pdf"
                />
            </div>
        );
    };

    showReport = () => {
        if (this.state.loading_report === false && this.state.error) {
            return (
                <div className="reports__data">
                    <div className="reports__title">{this.state.error}</div>
                </div>
            );
        }

        if (!this.state.report && !this.state.run && this.state.view_type === 1) {
            return (
                <div className="reports__data">
                    <div className="card__flex-box">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        if (this.state.loading_report === true || this.state.loading_run === true) {
            return (
                <div className="reports__data">
                    <div className="card__flex-box">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        return (
            <div className="reports__data">
                {this.showReportTitle()}
                {this.showReportByType()}
            </div>
        );
    };

    showEfficiency = () => {
        let { report, run } = this.state;

        if (run != null) {
            report = run;
        }
        if (!this.state.uses_latest_report) {
            return <ReportEfficiencyChart report={report} />;
        }
        return (
            <ReportsCard icon={EfficiencyChartIcon} height={"370px"} tooltip={"Efficiency or Overall Equipment Effectiveness (OEE) measures how efficiently a production line is operating by evaluating availability, performance, and quality against your production targets."} title="Efficiency">
                <ReportEfficiencyChartNew report={report} />
            </ReportsCard>
        );
    };

    showMetrics = () => {
        let { report, run } = this.state;

        if (run != null) {
            report = run;
        }

        let totalSensorCount = 0
        let finishedGoods = 0
        let totalDowntime = 0
        let labour = 0
        let totalWaste = 0
        let totalRework = 0

        if (this.state.run) {
            finishedGoods = this.state.run.run_finished_goods
            labour = this.state.run.headcount
            totalSensorCount = this.state.run.run_output
            totalWaste = this.state.run.run_waste
            totalRework = this.state.run.run_rework
            let totalDowntimeSeconds = 0
            this.state.run.lost_time_list.forEach(downtime => {
                totalDowntimeSeconds += downtime.lost_time_seconds
            })
            totalDowntime = formatToHhMmSs(totalDowntimeSeconds)
        }

        if (!this.state.uses_latest_report) {
            return <ReportEfficiencyChart report={report} />;
        }
        return (
            <ReportsCard icon={RunDetailsMetricsIcon} height={"370px"} mainBoxStyles={{ justifyContent: "start", alignItems: "start", padding: `24px` }} tooltip={"A summary of the key captured metrics that build this run’s production performance and paretos."} title="Metrics">
                <div style={{ width: "100%", display: "grid", rowGap: `29px` }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: `0px`, alignItems: "center", width: "100%" }}>
                        <div>
                            <p style={{ display: "flex", alignItems: "center", marginBottom: `8px`, fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100] }}><img src={TotalSensorCountIcon} style={{ marginRight: `8px`, width: `24px` }} /> Total Sensor Count</p>
                            <p style={{ fontSize: `14px`, fontWeight: "400", marginLeft: `34px`, color: colorPalette.neutral[80] }}>{numberWithCommas(totalSensorCount.toFixed(0))} {this.state.run.unit_info.output.display_unit_name}</p>
                        </div>
                        <div>
                            <p style={{ display: "flex", alignItems: "center", marginBottom: `8px`, fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100] }}><img src={FinishedGoodsIcon} style={{ marginRight: `8px`, width: `24px` }} /> Finished Goods</p>
                            <p style={{ fontSize: `14px`, fontWeight: "400", marginLeft: `34px`, color: colorPalette.neutral[80] }}>{numberWithCommas(finishedGoods.toFixed(0))} {this.state.run.unit_info.finished_goods.display_unit_name}</p>
                        </div>
                    </div>
                    <hr style={{ marginLeft: `-60px`, border: "none", height: "1px", marginRight: `-35px`, marginTop: "0px", marginBottom: "0px", backgroundColor: "#E2E5EB" }} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: `0px`, alignItems: "center", width: "100%" }}>
                        <div>
                            <p style={{ display: "flex", alignItems: "center", marginBottom: `8px`, fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100] }}><img src={TotalDowntimeIcon} style={{ marginRight: `8px`, width: `24px` }} /> Total Lost Time</p>
                            <p style={{ fontSize: `14px`, fontWeight: "400", marginLeft: `34px`, color: colorPalette.neutral[80] }}>{totalDowntime}</p>
                        </div>
                        <div>
                            <p style={{ display: "flex", alignItems: "center", marginBottom: `8px`, fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100] }}><img src={LabourIcon} style={{ marginRight: `8px`, width: `24px` }} /> Labour</p>
                            <p style={{ fontSize: `14px`, fontWeight: "400", marginLeft: `34px`, color: colorPalette.neutral[80] }}>{numberWithCommas(labour ? labour.toFixed(0) : 0)}</p>
                        </div>
                    </div>
                    <hr style={{ marginLeft: `-60px`, border: "none", height: "1px", marginRight: `-35px`, marginTop: "0px", marginBottom: "0px", backgroundColor: "#E2E5EB" }} />
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: `0px`, alignItems: "center", width: "100%" }}>
                        <div>
                            <p style={{ display: "flex", alignItems: "center", marginBottom: `8px`, fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100] }}><img src={TotalWasteIcon} style={{ marginRight: `8px`, width: `24px` }} /> Total Waste</p>
                            <p style={{ fontSize: `14px`, fontWeight: "400", marginLeft: `34px`, color: colorPalette.neutral[80] }}>{numberWithCommas(totalWaste.toFixed(0))} {this.state.run.unit_info.waste.display_unit_name}</p>
                        </div>
                        <div>
                            <p style={{ display: "flex", alignItems: "center", marginBottom: `8px`, fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100] }}><img src={TotalReworkIcon} style={{ marginRight: `8px`, width: `24px` }} /> Total Rework</p>
                            <p style={{ fontSize: `14px`, fontWeight: "400", marginLeft: `34px`, color: colorPalette.neutral[80] }}>{numberWithCommas(totalRework.toFixed(0))} {this.state.run.unit_info.rework.display_unit_name}</p>
                        </div>
                    </div>
                </div>
            </ReportsCard>
        );
    };

    showPlannedUnplanned = () => {
        let { report, run } = this.state;

        if (run != null) {
            report = run;
        }

        return (
            <ReportsCard icon={PlannedUnplannedChartIcon} height={"370px"} tooltip={"Planned vs. Unplanned Downtime compares scheduled downtime (like maintenance or breaks) to unexpected stoppages, helping identify areas for operational improvement."} title="Planned vs. Unplanned">
                <ReportPlannedUnplannedChart report={report} />
            </ReportsCard>
        );
    };

    showUtilization = () => {
        let { report, run } = this.state;

        if (run != null) {
            report = run;
        }

        return (
            <ReportsCard icon={UtilizationChartIcon} height={"370px"} tooltip={"The Utilization metric measures the proportion of available production time that is effectively used, assessing how much time is spent running products compared to its maximum potential."} title="Utilization">
                {report.is_utilization_configured ? (
                    <ReportUtilizationChart report={report} />
                ) : (
                    <div style={{ width: "60%" }}>
                        <p style={{ fontSize: "18px", textAlign: "center", fontWeight: "600", color: colorPalette.neutral[100], marginBottom: "12px" }}>No data to display</p>
                        <p style={{ fontSize: "16px", textAlign: "center", fontWeight: "500", color: colorPalette.neutral[80] }}>Head over to the Utilization Settings page to set up utilization for your factory.</p>
                    </div>
                )}
            </ReportsCard>
        );
    }

    showDailyRunTabs = () => {
        if (this.state.run == null) {
            return;
        }

        let class_name = this.state.collapse ? 'reports--collapse' : 'reports';
        // let class_name = 'reports--collapse';

        // if (this.state.downtime === null) {
        //     return (
        //         <div className={class_name + ' tabs-card '}>
        //             <div className="card__flex-box">
        //                 <CircularProgress />
        //             </div>
        //         </div>
        //     );
        // }

        if (!this.state.uses_latest_report) {
            return (
                <div className={class_name + ' tabs-card'}>
                    {this.showDailyReportTabs()}
                </div>
            )
        }

        return (
            <div style={{ padding: "0px 32px 32px 32px", background: "#fff" }}>
                {/* <div className="floating-action-button__wrapper">
                    {!this.state.summary && minimumRole('factory_admin') && (
                        <Fab color="secondary" onClick={this.addEvent.bind(this)}>
                            <AddOutlined />
                        </Fab>
                    )}
                </div> */}
                {this.showDailyReportTabs()}
            </div>
        );
    };

    renderModal = () => {
        if (this.state.show_modal === false) {
            return <div />;
        } else {
            switch (this.state.show_modal) {
                case 'add_event':
                case 'edit_event':
                case 'delete_event':
                    return (
                        <Modal
                            title={this.getModalTitle()}
                            hideModal={this.hideEditBatchModal}
                            class_name={'__add_event'}
                        >
                            {this.getModal()}
                        </Modal>
                    );
                case 'add_closed_run':
                    return (
                        <Modal
                            title={this.getModalTitle()}
                            hideModal={this.hideEditBatchModal}
                            class_name={'__add_run'}
                        >
                            {this.getModal()}
                        </Modal>
                    );
                case 'edit_run':
                    let class_name_ext = '__edit_run';

                    if (this.state.edit_run_title === 'Confirm Run Edits') {
                        class_name_ext = '__confirm-run-change';
                    }

                    return (
                        <Modal
                            title={this.getModalTitle()}
                            hideModal={this.hideEditBatchModal}
                            class_name={class_name_ext}
                        >
                            {this.getModal()}
                        </Modal>
                    );
                case 'filter_downtime':
                    return (
                        <Modal
                            title={this.getModalTitle()}
                            hideModal={this.hideEditBatchModal}
                            class_name={'__add-user'}
                        >
                            {this.getModal()}
                        </Modal>
                    );
                case 'move_run_data':
                    return (
                        <Modal
                            title={this.getModalTitle()}
                            hideModal={this.hideEditBatchModal}
                            class_name={'__move-run'}
                        >
                            {this.getModal()}
                        </Modal>
                    );
                default:
                    return (
                        <Modal title={this.getModalTitle()} hideModal={this.hideEditBatchModal}>
                            {this.getModal()}
                        </Modal>
                    );
            }
        }
    };

    getModal = () => {
        let product;
        let products;
        let multiplier = 1;
        let target_weight = 1;

        switch (this.state.show_modal) {
            case 'associate_run':
                if (this.state.editing_run) {
                    return (
                        <AssociateRunToBatchRunModal
                            hideModal={this.hideEditBatchModal}
                            modified={this.modified}
                            hideModalFromDelete={this.hideModalFromDelete}
                            run_id={this.state.run_id}
                            lines={this.state.lines}
                            batchRunRun={this.state.batchRunRun}
                            selected_line={this.state.run.line_id}
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            run={this.state.run}
                            editing_run={this.state.editing_run}
                            batch_run_id={this.state.run.batch_run_id}
                            products={this.state.products}
                        />
                    );
                } else {
                    return (
                        <AssociateRunToBatchRunModal
                            hideModal={this.hideEditBatchModal}
                            modified={this.modified}
                            hideModalFromDelete={this.hideModalFromDelete}
                            run_id={this.state.run_id}
                            selected_line={this.state.selected_line}
                            date_from={this.state.date_from}
                            date_to={this.state.date_to}
                            editing_run={this.state.editing_run}
                            run={this.state.run}
                            run_start_date={this.state.run_start_date}
                        />
                    );
                }

            case 'edit_run':
                return (
                    <EditRun
                        hideModal={this.hideEditBatchModal}
                        modified={this.modified}
                        hideModalFromDelete={this.hideModalFromDelete}
                        run_id={this.state.run_id}
                        selected_line={this.state.selected_line}
                        date_from={this.state.date_from}
                        date_to={this.state.date_to}
                        editing_run={this.state.editing_run}
                        run={this.state.run}
                        run_start_date={this.state.run_start_date}
                        lines={this.state.lines}
                        products={this.state.products}
                        line_products={this.state.line_products}
                        changeModalTitle={this.changeModalTitle}
                        usesHeadcount={this.state.uses_headcount}
                    />
                );
            case 'edit_giveaway':
                return (
                    <EditGiveawayModal
                        hideModal={this.hideEditBatchModal}
                        modified={this.modified}
                        run_id={this.state.run_id}
                        giveaway={this.state.edit_batch_modal_data}
                        location_id={this.state.edit_batch_modal_data.sample_location_id}
                    />
                );
            case 'add_event':
                product = null;
                products = ProductStore.getFull();
                if (!Util.isEmpty(products)) {
                    products.forEach(prod => {
                        let product_display = this.state.run.product_name + ' - ' + this.state.run.product_desc;
                        if (prod.name + ' - ' + prod.desc === product_display) {
                            product = prod;
                        }
                    });
                    if (product) {
                        multiplier = this.state.run.product_multiplier ? this.state.run.product_multiplier : 1;
                        target_weight = (product && product.target_weight) !== 0 ? product.target_weight : 1;
                    }
                }
                return (
                    <AddEvent
                        hideModal={this.updateEventLog}
                        modified={this.modified}
                        run_id={this.state.run_id}
                        run={this.state.run}
                        line_units={this.state.run.unit_info}
                        reasons={this.state.reasons}
                        multiplier={multiplier}
                        target_weight={target_weight}
                        reasonCategories={this.state.reasonCategories}
                    />
                );
            case 'edit_event':
                product = null;
                products = ProductStore.getFull();
                if (!Util.isEmpty(products)) {
                    products.forEach(prod => {
                        let product_display = this.state.run.product_name + ' - ' + this.state.run.product_desc;
                        if (prod.name + ' - ' + prod.desc === product_display) {
                            product = prod;
                        }
                    });
                    multiplier = 1;
                    target_weight = (product && product.target_weight) !== 0 ? product.target_weight : 1;
                }
                const newEvent = this.state.event;
                if (newEvent.type === "Finished Goods") {
                    newEvent.type = "finished_goods"
                }
                return (
                    <EditEvent
                        hideModal={this.updateEventLog}
                        modified={this.modified}
                        run_id={this.state.run_id}
                        run={this.state.run}
                        line_units={this.state.run.unit_info}
                        event={newEvent}
                        reasons={this.state.reasons}
                        finished_goods={this.state.finished_goods}
                        multiplier={multiplier}
                        target_weight={target_weight}
                        timeZone={this.state.timeZone}
                        reasonCategories={this.state.reasonCategories}
                    />
                );
            case 'delete_event':
                return (
                    <DeleteConfirmationModal
                        hideModal={this.hideEditBatchModal}
                        modified={this.modified}
                        item_deleted_text={`this ${this.state.event.type === "finished_goods" ? "Finished Goods" : this.state.event.type} event`}
                        delete_path={this.getDeletePath(this.state.event)}
                        id={this.state.event.id}
                    />
                );
            case 'add_closed_run':
                return (
                    <AddClosedRun
                        hideModal={this.hideEditBatchModal}
                        modified={this.modified}
                        lines={this.state.lines}
                        products={this.state.products}
                    />
                );
            case 'filter_downtime':
                return (
                    <FilterDowntimeCategories
                        hideModal={this.hideEditBatchModal}
                        categories={this.state.categories}
                        onFilterPressed={this.filterDowntimeEvents}
                    />
                );
            case 'move_run_data':
                return (
                    <MoveRunData
                        hideModal={this.hideEditBatchModal}
                        modified={this.modified}
                        run={this.state.run}
                        run_id={this.state.run_id}
                        products={this.state.products}
                        line_products={this.state.line_products}
                        onTabChange={this.onMoveSplitTabChange}
                    />
                );
            default:
                return false;
        }
    };

    getModalTitle = () => {
        let sm = this.state.show_modal;

        if (sm === 'edit_batch') {
            return `Batch ${this.state.edit_batch_modal_data.batch_number} Details`;
        } else if (sm === 'add_weight') {
            return 'Add Weight';
        } else if (sm === 'remove_weight') {
            return 'Remove Weight';
        } else if (sm === 'move_weight') {
            return 'Move Weight';
        } else if (sm === 'edit_pallet') {
            return `Pallet ${this.state.edit_batch_modal_data.pallet_number} Details`;
        } else if (sm === 'add_pallet') {
            return `Add Pallet Details`;
        } else if (sm === 'add_batch') {
            return `Add Batch ${this.state.nextBatch}`;
        } else if (sm === 'edit_transfer') {
            return 'Edit Transfer';
        } else if (sm === 'associate_run' && this.state.editing_run) {
            return 'Edit Run';
        } else if (sm === 'associate_run') {
            return 'Associate Run to a Batch Run';
        } else if (sm === 'edit_giveaway') {
            return 'Edit Giveaway Details';
        } else if (sm === 'add_event') {
            return 'Add Event';
        } else if (sm === 'edit_event') {
            return 'Edit Event';
        } else if (sm === 'delete_event') {
            return 'Delete Event';
        } else if (sm === 'edit_run') {
            return this.state.edit_run_title;
        } else if (sm === 'add_closed_run') {
            return 'Add Closed Run';
        } else if (sm === 'filter_downtime') {
            return 'Filter Downtime Categories';
        } else if ('move_run_data') {
            switch (this.state.move_split_tab_index) {
                case 0:
                    return '';
                case 1:
                    if (this.state.split_tab === 'move') {
                        return 'Move Run Data';
                    } else {
                        return 'Split Run Data';
                    }
                case 2:
                    return 'Confirmation';
            }
            return 'Move Run Data';
        } else {
            return '';
        }
    };

    showNextRunButton = () => {
        if (this.state.run == null) {
            return <div />;
        }

        if (this.state.run_ids === null || this.state.lastRun === undefined) {
            return <div />;
        }

        //TODO remove the div return and uncomment the Button
        return <div />;

        // return <div>
        //     <FlatButton
        //         label={(!this.state.nextRun && this.state.nextRunId) ? "Loading..." : "Next Run"}
        //         primary={true}
        //         disabled={!this.state.nextRun}
        //         onClick={() => {
        //             this.setState({
        //                 run: this.state.nextRun,
        //                 nextRun: null,
        //                 run_id: this.state.loadedId,
        //                 lastRun: this.state.nextRunId
        //             })
        //             if(this.state.nextRunId) {
        //                 this.getNextRun()
        //             }
        //         }}
        //     />
        // </div>
    };

    showReportByType = () => {
        if (this.state.view_type === 1 && this.state.run === null) {
            if (!this.state.uses_latest_report) {
                return (
                    <div className="reports__container-daily">
                        <div id="charts" className="reports__floated">
                            {this.showEfficiency()}
                            {this.showLostTime()}
                        </div>
                        <OldDailyReportRunTable
                            report={this.state.report}
                            run={this.state.run}
                            lines={this.state.lines}
                            products={this.state.products}
                            handleTableClick={this.handleTableClick}
                            showAddRunModal={this.showAddRunModal}
                            timeZone={this.state.timeZone}
                            activeShifts={this.state.activeShifts}
                            usesShifts={this.state.usesShifts}
                            usesHeadcount={this.state.uses_headcount}
                        />
                    </div>
                );
            }
            return (
                <div className="reports__container-daily">
                    <div id="charts" className="reports__floated" style={{ padding: "0 30px", gap: "24px", width: "auto" }}>
                        {this.showEfficiency()}
                        {this.showPlannedUnplanned()}
                        {this.showUtilization()}
                    </div>
                    <div style={{ padding: "24px 30px 0px 30px", gap: "34px", width: "auto" }}>
                        {this.showLostTime()}
                    </div>
                    <DailyReportRunTable
                        report={this.state.report}
                        run={this.state.run}
                        lines={this.state.lines}
                        products={this.state.products}
                        handleTableClick={this.handleTableClick}
                        showAddRunModal={this.showAddRunModal}
                        timeZone={this.state.timeZone}
                        activeShifts={this.state.activeShifts}
                        usesShifts={this.state.usesShifts}
                        usesHeadcount={this.state.uses_headcount}
                    />
                    <div style={{ display: "flex", padding: "24px 30px 0px 30px", gap: "24px", width: "auto" }}>
                        {this.showWastePareto()}
                        {this.showReworkPareto()}
                    </div>  
                </div>
            );
        }
    };

    backButton = () => {
        if (this.state.backTo === 'liveview') {
            return (
                <Button variant="text" color="primary" component={Link} to={'/liveview'}>
                    Back to Liveview
                </Button>
            );
        }

        return (
            <Button variant="text" color="primary" onClick={this.onBackToLineClick.bind(this)}>
                Back to Reports
            </Button>
        );
    };

    showBackToLineButton = () => {
        if (this.state.run != null) {
            return (
                <div className="back_to_line">
                    {this.backButton()}
                    {this.showNextRunButton()}
                </div>
            );
        }
    };

    showRunTimeline = () => {
        if (this.state.run !== null) {
            if (!this.state.uses_latest_report) {
                return (
                    <OldRunTimeline
                        run={this.state.run}
                        log={this.state.downtime}
                        active_lost_time_reason={this.state.active_lost_time_reason}
                        editEvent={this.editEvent}
                        timeZone={this.state.timeZone}
                        shift_start_time={this.state.shift_start_time}
                        shift_end_time={this.state.shift_end_time}
                    />
                )
            }
            return (
                <ReportsCard title={"Run Timeline: Downtime"} icon={RunTimelineDowntimeIcon} size="large" height="250px" tooltip="This module displays the real-time state of the line during this run, indicating whether it was in uptime or downtime. Click on each segment to see more details about each downtime. ">
                    <RunTimeline
                        run={this.state.run}
                        log={this.state.downtime}
                        active_lost_time_reason={this.state.active_lost_time_reason}
                        editEvent={this.editEvent}
                        timeZone={this.state.timeZone}
                        shift_start_time={this.state.shift_start_time}
                        shift_end_time={this.state.shift_end_time}
                    />
                </ReportsCard>
            );
        }
    };

    showSpeedChangeChart = () => {
        if (this.state.run !== null && !Util.isEmpty(this.state.products)) {
            let productDisplay = this.state.run.product_name + ' - ' + this.state.run.product_desc;
            let productId = -1;
            this.state.products.forEach(product => {
                if (product.display_name === productDisplay) {
                    productId = product.product_id;
                }
            });

            if (!this.state.uses_latest_report) {
                return (
                    <OldSpeedChangeChart
                        run_id={this.state.run_id}
                        run={this.state.run}
                        product_id={productId}
                        line_id={this.state.run.line_id}
                        shiftIds={this.state.shiftIds}
                        timeZone={this.state.timeZone}
                        shift_start_time={this.state.shift_start_time}
                        shift_end_time={this.state.shift_end_time}
                    />
                )
            }

            return (
                <ReportsCard title={"Run Timeline: Speed"} icon={RunTimelineSpeedIcon} size="large" height="690px" tooltip="This module displays the real-time speed changes captured on the line during this run.">
                    <SpeedChangeChart
                        run_id={this.state.run_id}
                        run={this.state.run}
                        product_id={productId}
                        line_id={this.state.run.line_id}
                        shiftIds={this.state.shiftIds}
                        timeZone={this.state.timeZone}
                        shift_start_time={this.state.shift_start_time}
                        shift_end_time={this.state.shift_end_time}
                    />
                </ReportsCard>
            );
        }
    };

    changeParetoType = (e) => {
        this.setState({ 
            show_lost_time_reason_dialog: null, 
            active_lost_time_reason: null,
            paretoValue: e.target.value, 
            drill_down_reason: null,
            drill_down_reason_id: null,
            drill_down_category: null,
            drill_down_category_id: null  
        })
    }

    chartParetoPerPage = (perPages) => {
        localStorage.setItem("perPages", perPages);
        this.setState({
            show_lost_time_reason_dialog: null, 
            active_lost_time_reason: null,
            perPages: perPages
        })
    }

    showLostTime = () => {
        let { report } = this.state;
        let isLineB = false;

        if (this.state.run != null && this.state.view_type === 1) {
            report = this.state.run;
            let line_name = this.state.run.line_name;
            if (line_name === 'Line B') {
                isLineB = true;
            }
        }

        if (this.state.view_type === 2) {
            report = this.state.report_weekly;
        }

        if (!this.state.uses_latest_report) {
            return (
                <ReportParetoChart
                    report={report}
                    onLostTimeBarClick={this.onLostTimeBarClickOld}
                    active_lost_time_reason={this.state.active_lost_time_reason}
                    getShortReason={this.getShortReason}
                />
            );
        }

        return (
            <ReportsCard size="large" height={"613px"}  radio={true} radioValue={this.state.paretoValue} radioOne={"Reason"} radioTwo={"Category"} perPageEnabled perPageCallback={this.chartParetoPerPage} buttonCallback={this.changeParetoType} mainBoxStyles={{ alignItems: "baseline", height: "85%", flexDirection: "column" }} icon={ParetoChartIcon} buttonText={"More Details"} tooltip={"A Pareto analysis of lost time identifies, compiles, and orders the most frequent or impactful causes of production performance loss to prioritize problem-solving efforts. "} title={`Pareto of Lost Time By ${this.state.paretoValue}`}>
                <NewReportParetoChart
                    report={report}
                    onLostTimeBarClick={this.onLostTimeBarClick}
                    reset_active_lost_time_reason={() => this.setState({ active_lost_time_reason: null })}
                    active_lost_time_reason={this.state.active_lost_time_reason}
                    getShortReason={this.getShortReason}
                    type={this.state.paretoValue}
                    lostTimeOpen={this.state.show_lost_time_reason_dialog === null ? false : true}
                    lostTimeReasonName={this.state.drill_down_reason}
                    lostTimeReasonId={this.state.drill_down_reason_id}
                    lostTimeCategory={this.state.drill_down_category}
                    lostTimeCategoryId={this.state.drill_down_category_id}
                    perPage={this.state.perPages}
                />
                <NewLostTimeDrillDown
                    reason={this.state.drill_down_reason}
                    reason_id={this.state.drill_down_reason_id}
                    downtime_category={this.state.drill_down_category}
                    runs={this.state.run_ids}
                    onRowClick={this.handleDrillDownTableClick}
                    hideModal={() => this.setState({ show_lost_time_reason_dialog: null, active_lost_time_reason: null })}
                    open={this.state.show_lost_time_reason_dialog === null ? false : this.state.paretoValue === "Reason" ? true : false}
                />
                <NewLostTimeDrillDownCategory
                    reason={this.state.drill_down_reason}
                    downtime_category={this.state.drill_down_category}
                    downtime_category_id={this.state.drill_down_category_id}
                    report={this.state.report}
                    runs={this.state.run_ids}
                    onRowClick={this.handleDrillDownTableClick}
                    hideModal={() => this.setState({ show_lost_time_reason_dialog: null, active_lost_time_reason: null })}
                    open={this.state.show_lost_time_reason_dialog === null ? false : this.state.paretoValue === "Category" ? true : false}
                />
            </ReportsCard>
        )
    };

    showWastePareto = () => {
        let { report, run } = this.state;

        if (run != null) {
            report = run;
        }
        if (this.state.factory_settings && this.state.factory_settings.uses_waste) {
            return (
                <ReportsCard size={this.state.factory_settings.uses_rework ? "medium" : "large"} height={"345px"} icon={WasteChartIcon} tooltip={"The Waste Pareto ranks the leading causes of material waste in production to target reductions and improve yield. "} title="Waste Pareto">
                    <WasteParetoChart report={report} />
                </ReportsCard>
            )
        }
        else {
            return null
        }
    }

    showReworkPareto = () => {
        let { report, run } = this.state;

        if (run != null) {
            report = run;
        }

        if (this.state.factory_settings && this.state.factory_settings.uses_rework) {
            return (
                <ReportsCard size={this.state.factory_settings.uses_waste ? "medium" : "large"} height={"345px"} icon={ReworkChartIcon} tooltip={"A Pareto analysis of rework reasons highlights the main causes that generate reprocessing, helping to focus on minimizing errors and inefficiencies."} title="Rework Pareto">
                    <ReworkParetoChart report={report} />
                </ReportsCard>
            )
        }
        else {
            return null
        }
    }

    showLostTimeDrillDownModal = () => {
        return (
            this.state.show_lost_time_reason_dialog && (
                <LostTimeDrillDownModal
                    reason={this.state.drill_down_reason}
                    reason_id={this.state.drill_down_reason_id}
                    downtime_category={this.state.drill_down_category}
                    runs={this.state.run_ids}
                    onRowClick={this.handleDrillDownTableClick}
                    hideModal={() => this.setState({ show_lost_time_reason_dialog: null })}
                />
            )
        );
    };

    showTrend = () => {
        if (this.state.show_trend) {
            return <TrendReportContainer />;
        }
    };

    
    

    render() {
        if (!User.loggedIn()) {
            return <div />;
        }
        

        // let class_name = this.state.collapse ? 'reports--collapse' : 'reports';
        let class_name = 'reports--collapse';
        let header_class_name = this.state.collapse ? "header header--collapse" : "header"
        
        return (
            <div className="container">
                <HeaderTopNav className={header_class_name} factories onFactoryChange={this.onFactoryChange} />
                <SideNav
                    onShowScoreboardClicked={() => { }}
                    onCollapseClick={this.onCollapseClickChange}
                    title="Reports"
                />
                {/* <div style={{ marginLeft: "200px", marginTop: "100px" }}>
                    <ReportsCard size={"medium"} title={"Long title test"} icon={<InfoTwoToneIcon />} tooltip={"tooltip"} buttonText={"Button"} buttonCallback={() => console.log("presseds")} >
                        <p>TEST CARD</p>
                    </ReportsCard>

                    <Filter title="Line" type="text" selectedArray={testData} isOpen={false} />

                    <FilterDropdown title="Select Lines" items={testData} selectedItems={[]} />
                </div> */}
                <Snackbar
                    open={this.state.snackbar}
                    onClose={this.handleSnackbarClose}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    message="There is a run currently running on line"
                />
                <Animate
                    from={{
                        width: 'calc(100% - 216px)',
                        margin: '20px 20px 20px 176px'
                    }}
                    to={{
                        width: 'calc(100% - 96px)',
                        margin: '20px 20px 20px 56px'
                    }}
                    easing="ease-in-out"
                    duration={450}
                >
                    {transform => (
                        <div className="main_content_wrapper" style={{ marginTop: "57px", marginLeft: "97px", background: "white" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: "1280px", height: "100%", margin: "auto"  }}>
                                <div>
                                    <div>
                                        {this.state.inDetails ? (
                                            <>

                                                {!this.state.uses_latest_report ? (
                                                    <OldRunReportTitleCard
                                                        run={this.state.run}
                                                        runId={this.state.run_id}
                                                        lines={this.state.lines}
                                                        inDetails={this.state.inDetails}
                                                        collapse={this.state.collapse}
                                                        selected_line={this.state.selected_line}
                                                        selected_lines={this.state.selected_lines}
                                                        loading_report={this.state.loading_report}
                                                        products={this.state.products}
                                                        line_products={this.state.line_products}
                                                        error={this.state.error}
                                                        onBackToLineClick={this.onBackToLineClick}
                                                        showEditRunModal={this.showEditRunModal}
                                                        showMoveRunDataModal={this.showMoveRunDataModal}
                                                        getProductTargetWeight={this.getProductTargetWeight}
                                                        refreshReport={this.refreshReport}
                                                        goToNewRun={this.goToNewRun}
                                                        goToDateRunApply={this.goToDateRunApply}
                                                        goToDateRun={this.goToDateRun}
                                                        onRunCurrentlyRunning={this.onRunCurrentlyRunning}
                                                        setCurrentPage={this.setCurrentPage}
                                                        handlePageClick={this.handlePageClick}
                                                        getUserEmail={this.getUserEmail}
                                                        getLastEditUser={this.getLastEditUser}
                                                        backTo={this.state.backTo}
                                                        timeZone={this.state.timeZone}
                                                        runLineId={this.state.run_line_id}
                                                        usesShifts={this.state.usesShifts}
                                                        usesHeadcount={this.state.uses_headcount}
                                                    />
                                                ) : (
                                                    <RunReportTitleCard
                                                        run={this.state.run}
                                                        runId={this.state.run_id}
                                                        lines={this.state.lines}
                                                        inDetails={this.state.inDetails}
                                                        collapse={this.state.collapse}
                                                        selected_line={this.state.selected_line}
                                                        selected_lines={this.state.selected_lines}
                                                        loading_report={this.state.loading_report}
                                                        products={this.state.products}
                                                        line_products={this.state.line_products}
                                                        error={this.state.error}
                                                        onBackToLineClick={this.onBackToLineClick}
                                                        showEditRunModal={this.showEditRunModal}
                                                        showMoveRunDataModal={this.showMoveRunDataModal}
                                                        getProductTargetWeight={this.getProductTargetWeight}
                                                        refreshReport={this.refreshReport}
                                                        goToNewRun={this.goToNewRun}
                                                        goToDateRunApply={this.goToDateRunApply}
                                                        goToDateRun={this.goToDateRun}
                                                        onRunCurrentlyRunning={this.onRunCurrentlyRunning}
                                                        setCurrentPage={this.setCurrentPage}
                                                        handlePageClick={this.handlePageClick}
                                                        getUserEmail={this.getUserEmail}
                                                        getLastEditUser={this.getLastEditUser}
                                                        backTo={this.state.backTo}
                                                        timeZone={this.state.timeZone}
                                                        runLineId={this.state.run_line_id}
                                                        usesShifts={this.state.usesShifts}
                                                        usesHeadcount={this.state.uses_headcount}
                                                    />
                                                )}

                                            </>
                                        ) : (
                                            <>
                                                {!this.state.uses_latest_report ? (
                                                    <OldDailyReportTitleAndOptions
                                                        inDetails={this.state.inDetails}
                                                        collapse={this.state.collapse}
                                                        lines={this.state.lines}
                                                        selected_lines={this.state.selected_lines}
                                                        openSelectLines={this.openSelectLines}
                                                        products={this.state.products}
                                                        selected_products={this.state.selected_products}
                                                        openSelectProducts={this.openSelectProducts}
                                                        categories={this.state.categories}
                                                        selected_categories={this.state.selected_categories}
                                                        uncategorized_products={this.state.uncategorized_products}
                                                        openSelectCategories={this.openSelectCategories}
                                                        show_select_categories={this.state.show_select_categories}
                                                        hideSelectCategories={this.hideSelectCategories}
                                                        cancelSelectCategories={this.cancelSelectCategories}
                                                        tags={this.state.tags}
                                                        selected_tags={this.state.selected_tags}
                                                        openSelectTags={this.openSelectTags}
                                                        show_select_tags={this.state.show_select_tags}
                                                        hideSelectTags={this.hideSelectTags}
                                                        cancelSelectTags={this.cancelSelectTags}
                                                        show_select_lines={this.state.show_select_lines}
                                                        hideSelectLines={this.hideSelectLines}
                                                        cancelSelectLines={this.cancelSelectLines}
                                                        show_select_products={this.state.show_select_products}
                                                        hideSelectProducts={this.hideSelectProducts}
                                                        cancelSelectProducts={this.cancelSelectProducts}
                                                        canUpdateOptions={this.canUpdateOptions}
                                                        getReport={this.getReport}
                                                        print_status={this.state.print_status}
                                                        printScreen={this.printScreen}
                                                        disableFromFutureDates={this.disableFromFutureDates}
                                                        date_from={this.state.date_from}
                                                        date_to={this.state.date_to}
                                                        disableEndDate={this.state.disableEndDate}
                                                        handleDateFromChange={this.handleDateFromChange}
                                                        handleDateToChange={this.handleDateToChange}
                                                        handleEndDateCheckBox={this.handleEndDateCheckBox}
                                                        getDatesLabel={this.getDatesLabel}
                                                        openSelectDates={this.openSelectDates}
                                                        show_select_dates={this.state.show_select_dates}
                                                        multi_date={this.state.multi_date}
                                                        report_selected_dates={this.state.report_selected_dates}
                                                        report_date_from={this.state.report_date_from}
                                                        report_date_to={this.state.report_date_to}
                                                        handleClose={this.handleMultiDatePickerClose}
                                                        setReportMultipleDates={this.setReportMultipleDates}
                                                        setReportRangeDates={this.setReportRangeDates}
                                                        shifts={this.state.shifts}
                                                        handleSelectShiftsChange={this.handleSelectShiftsChange}
                                                        timeZone={this.state.timeZone}
                                                        handleCheck={this.handledCheck}
                                                        activeShifts={this.state.activeShifts}
                                                        usesShifts={this.state.usesShifts}
                                                        untagged_products={this.state.untagged_products}
                                                    />
                                                ) : (
                                                    <DailyReportTitleAndOptions
                                                        inDetails={this.state.inDetails}
                                                        collapse={this.state.collapse}
                                                        lines={this.state.lines}
                                                        selected_lines={this.state.selected_lines}
                                                        openSelectLines={this.openSelectLines}
                                                        products={this.state.products}
                                                        selected_products={this.state.selected_products}
                                                        openSelectProducts={this.openSelectProducts}
                                                        categories={this.state.categories}
                                                        selected_categories={this.state.selected_categories}
                                                        uncategorized_products={this.state.uncategorized_products}
                                                        openSelectCategories={this.openSelectCategories}
                                                        show_select_categories={this.state.show_select_categories}
                                                        hideSelectCategories={this.hideSelectCategories}
                                                        cancelSelectCategories={this.cancelSelectCategories}
                                                        show_select_shifts={this.state.show_select_shifts}
                                                        open_select_shifts={this.openSelectShifts}
                                                        cancelSelectShifts={this.cancelSelectShifts}
                                                        tags={this.state.tags}
                                                        selected_tags={this.state.selected_tags}
                                                        openSelectTags={this.openSelectTags}
                                                        show_select_tags={this.state.show_select_tags}
                                                        hideSelectTags={this.hideSelectTags}
                                                        cancelSelectTags={this.cancelSelectTags}
                                                        show_select_lines={this.state.show_select_lines}
                                                        hideSelectLines={this.hideSelectLines}
                                                        cancelSelectLines={this.cancelSelectLines}
                                                        show_select_products={this.state.show_select_products}
                                                        hideSelectProducts={this.hideSelectProducts}
                                                        cancelSelectProducts={this.cancelSelectProducts}
                                                        canUpdateOptions={this.canUpdateOptions}
                                                        getReport={this.getReport}
                                                        print_status={this.state.print_status}
                                                        printScreen={this.printScreen}
                                                        disableFromFutureDates={this.disableFromFutureDates}
                                                        date_from={this.state.date_from}
                                                        date_to={this.state.date_to}
                                                        disableEndDate={this.state.disableEndDate}
                                                        handleDateFromChange={this.handleDateFromChange}
                                                        handleDateToChange={this.handleDateToChange}
                                                        handleEndDateCheckBox={this.handleEndDateCheckBox}
                                                        getDatesLabel={this.getDatesLabel}
                                                        openSelectDates={this.openSelectDates}
                                                        show_select_dates={this.state.show_select_dates}
                                                        multi_date={this.state.multi_date}
                                                        report_selected_dates={this.state.report_selected_dates}
                                                        selected_dates={this.state.selected_dates}
                                                        report_date_from={this.state.report_date_from}
                                                        report_date_to={this.state.report_date_to}
                                                        handleClose={this.handleMultiDatePickerClose}
                                                        setReportMultipleDates={this.setReportMultipleDates}
                                                        setReportRangeDates={this.setReportRangeDates}
                                                        shifts={this.state.shifts}
                                                        handleSelectShiftsChange={this.handleSelectShiftsChange}
                                                        saveSelectedShifts={this.saveSelectedShifts}
                                                        timeZone={this.state.timeZone}
                                                        handleCheck={this.handledCheck}
                                                        activeShifts={this.state.activeShifts}
                                                        usesShifts={this.state.usesShifts}
                                                        untagged_products={this.state.untagged_products}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {!this.state.inDetails && (
                                        <>
                                            {!this.state.uses_latest_report ? (
                                                <div className={class_name} style={{ background: "transparent" }}>
                                                    {this.showReport()}
                                                </div>
                                            ) : (
                                                <div className={class_name} style={{ margin: 0,  width: "100%", boxShadow: "none", paddingTop: "10px", background: "transparent" }}>
                                                    {this.showReport()}
                                                </div>   
                                            )}
                                        </>
                                    )}
                                    {this.showDailyRunTabs()}
                                    {this.renderModal()}
                                    {!this.state.uses_latest_report ? this.showLostTimeDrillDownModal() : null}
                                </div>
                                <Footer />
                            </div>
                        </div>
                    )}
                </Animate>
            </div>
        );
    }
}

export default Reports;
