import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core';

import {Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'

import { AddOutlined } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { minimumRole } from '../../PermissionsHelper';
import OutlinedChevronIcon from '../../icons/OutlinedChevronIcon';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment-timezone';

Date.prototype.addDays = function (days) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
};

function getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(currentDate);
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

export default function OldDailyReportRunTable(props) {
    const classes = useStyles();
    const [user_role, setUser_role] = useState("")

    useEffect(() => {
            setUser_role(JSON.parse(window.localStorage.getItem('factory')).role)
    }, [])

    const getTotalSensorCount = runs => {
        let sensorCount = 0;

        runs.forEach(run => {
            sensorCount += parseInt(run.actual_output_pieces);
        });

        return sensorCount;
    };

    if (props.run != null) {
        return <div />;
    }

    const showReportTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Start Date
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Line
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                SKU
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Product
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Start Time
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                End Time
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                {localStorage.getItem("efficiency_string_short") !== 'undefined' ? localStorage.getItem("efficiency_string_short") : "OEE"}
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Average Speed
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Target Speed
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Sensor Count
                            </TableCell>
                            <TableCell align={'left'} className={classes.table_head_cell}>
                                Finished Goods
                            </TableCell>
                            {props.usesHeadcount && (
                                <TableCell align={'left'} className={classes.table_head_cell}>
                                    Labour
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>{report()}</TableBody>
                </Table>
            </TableContainer>
        );
    };

    function renderSummary(report, table_info_list) {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                </TableRow>
                <TableRow style={Styles.tableRowStyle(1)}>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date}>&nbsp;</TableCell>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_normal}>&nbsp;</TableCell>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_normal}>&nbsp;</TableCell>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_normal}>&nbsp;</TableCell>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_normal}>&nbsp;</TableCell>
                    <TableCell className={classes.table_cell_normal} style={{ ...Styles.tableRowColumnStyleRunLeftAlign(), textAlign: "left" }}>
                        Average
                    </TableCell>
                    <TableCell
                        className={classes.table_cell_normal}
                        style={{ ...Styles.tableRowColumnStyleRunCenterBorder(), textAlign: "left" }}
                    >
                        {parseFloat(report.efficiency_percent).toFixed(1) + '%'}
                    </TableCell>
                    <TableCell className={classes.table_cell_normal} style={{ ...Styles.tableRowColumnStyleRunRightBorder(), textAlign: "left" }}>
                        {Util.calculateAverageSpeed(table_info_list) + ' units/min'}
                    </TableCell>
                    <TableCell className={classes.table_cell_normal} style={{ ...Styles.tableRowColumnStyleRunLeftAlign(), textAlign: "left" }}>
                        Total
                    </TableCell>
                    <TableCell className={classes.table_cell_normal} style={{ ...Styles.tableRowColumnStyleRunRightBorder(), textAlign: "left" }}>
                        {getTotalSensorCount(table_info_list) + ' units'}
                    </TableCell>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_normal}>&nbsp;</TableCell>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_icon}>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                    <TableCell sx={{border: "none"}} className={classes.table_cell_date} />
                </TableRow>
            </React.Fragment>
        );
    }

    const report = () => {
        const { report } = props;
        if (!report) {
            return;
        }

        if (!report.table_info_list) {
            return;
        }

        const table_info_list = report.table_info_list;

        const useStylesBootstrap = makeStyles(theme => ({
            arrow: {
                color: theme.palette.common.black
            },
            tooltip: {
                backgroundColor: theme.palette.common.black
            }
        }));

        function BootstrapTooltip(props) {
            const classes = useStylesBootstrap();

            return <Tooltip arrow classes={classes} {...props} />;
        }

        return (
            <React.Fragment>
                {table_info_list.map((item, index) => {
                    let date_from = new Date(item.run_start_time);
                    let date_to = item.run_end_time ? new Date(item.run_end_time) : new Date();
                    const dateMomentStart = moment(item.run_start_time).tz(props.timeZone).format('DDD');
                    const dateMomentEnd = moment(date_to).tz(props.timeZone).format('DDD');
                    const difference_in_day = +dateMomentEnd - dateMomentStart;
                    const dates = getDates(date_from, date_from.addDays(difference_in_day));
                    return (
                        <TableRow
                            key={'tablerow-' + index}
                            onClick={props.handleTableClick.bind(this, item)}
                            className="report__row"
                        >
                            <TableCell className={classes.table_cell_date}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'no-wrap',
                                        position: 'relative',
                                        justifyContent: 'left',
                                        alignItems: 'baseline'
                                    }}
                                >
                                    {dates.length > 1 ? (
                                        <BootstrapTooltip
                                            title={
                                                <div
                                                    style={{
                                                        padding: '10px',
                                                        paddingTop: '7px',
                                                        width: '200px',
                                                        height: ' 99px',
                                                        boxSizing: 'border-box',
                                                        color: '#DDD'
                                                    }}
                                                >
                                                    <div style={{ marginBottom: '10px' }}>
                                                        <CalendarTodayIcon
                                                            style={{
                                                                marginTop: '2px',
                                                                widht: '14px',
                                                                color: 'white',
                                                                height: '14px'
                                                            }}
                                                        />
                                                        <Typography
                                                            style={{
                                                                color: 'white',
                                                                fontWeight: '700',
                                                                fontSize: '15px'
                                                            }}
                                                            component="span"
                                                            variant="caption"
                                                        >
                                                            {dates.length}-day run
                                                        </Typography>
                                                    </div>
                                                    <div style={{ marginBottom: '5px' }}>
                                                        <Typography style={{ marginRight: '7px' }} variant="caption">
                                                            Start
                                                        </Typography>{' '}
                                                        <Typography variant="caption" component="span">
                                                            {moment(item.run_start_time)
                                                                .tz(props.timeZone)
                                                                .format('LLL')}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ padingBottom: '10px' }}>
                                                        <Typography style={{ marginRight: '14px' }} variant="caption">
                                                            End
                                                        </Typography>{' '}
                                                        <Typography component="span" variant="caption">
                                                            {item.run_end_time
                                                                ? moment(item.run_end_time)
                                                                      .tz(props.timeZone)
                                                                      .format('LLL')
                                                                : 'in Progress'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'no-wrap',
                                                    marginRight: '5px',
                                                    paddingRight: '5px',
                                                    marginTop: '9px'
                                                }}
                                            >
                                                <CalendarTodayIcon
                                                    style={{
                                                        color: '#004485',
                                                        widht: '17px',
                                                        paddingTop: '1px',
                                                        height: '17px'
                                                    }}
                                                />
                                                <Typography variant="caption" style={{ color: '#004485' }}>
                                                    {dates.length}
                                                </Typography>
                                            </div>
                                        </BootstrapTooltip>
                                    ) : null}
                                    <div>
                                        {moment(item.run_start_time)
                                            .tz(props.timeZone)
                                            .format('LLLL')
                                            .split(', ')
                                            .slice(0, 2)
                                            .join(' ')}
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>{item.line_name}</TableCell>
                            <TableCell className={classes.table_cell_normal}>{item.product_name}</TableCell>
                            <TableCell className={classes.table_cell_normal}>{item.product_description}</TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {moment(item.run_start_time).tz(props.timeZone).format('LT')}
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {item.run_end_time ? (
                                    moment(item.run_end_time).tz(props.timeZone).format('LT')
                                ) : (
                                    <div
                                        style={{
                                            backgroundColor: '#EFF8ED',
                                            width: '85px',
                                            padding: '10px',
                                            textAlign: 'left',
                                            color: '#197A01',
                                            borderRadius: '3px',
                                        }}
                                    >
                                        In Progress
                                    </div>
                                )}
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {parseFloat(item.run_efficiency_percent).toFixed(1) + '%'}
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {`${item && item.average_speed_pieces_per_min} ${
                                    item &&
                                    item.unit_info &&
                                    item.unit_info.speed &&
                                    item.unit_info.speed.display_unit_name
                                        ? item.unit_info.speed.display_unit_name
                                        : ''
                                }`}
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {`${item && item.target_speed_pieces_per_min} ${
                                    item &&
                                    item.unit_info &&
                                    item.unit_info.speed &&
                                    item.unit_info.speed.display_unit_name
                                        ? item.unit_info.speed.display_unit_name
                                        : ''
                                }`}
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {`${item && item.actual_output_pieces} ${
                                    item &&
                                    item.unit_info &&
                                    item.unit_info.output &&
                                    item.unit_info.output.display_unit_name
                                        ? item.unit_info.output.display_unit_name
                                        : ''
                                }`}
                            </TableCell>
                            <TableCell className={classes.table_cell_normal}>
                                {`${item && item.run_finished_goods} ${
                                    item &&
                                    item.unit_info &&
                                    item.unit_info.finished_goods &&
                                    item.unit_info.finished_goods.display_unit_name
                                        ? item.unit_info.finished_goods.display_unit_name
                                        : ''
                                }`}
                            </TableCell>
                            {props.usesHeadcount && (
                                <TableCell className={classes.table_cell_normal}>
                                    {item && Util.roundTo(item.headcount, 2)}
                                </TableCell>
                            )}
                        </TableRow>
                    );
                })}
                {renderSummary(report, table_info_list)}
            </React.Fragment>
        );
    };

    return (
        <div className={classes.card}>
            {
                /*
                minimumRole('factory_admin') && (
                    // <Tooltip title={'Add Run'}>

                    <Fab
                        color="primary"
                        //className={classes.fab}
                        onClick={props.showAddRunModal}
                        disabled={
                            Util.isEmpty(props.lines) ||
                            Util.isEmpty(props.products) ||
                            Util.isEmpty(ProductStore.getFull())
                        }
                    >
                        <AddOutlined />
                    </Fab>

                )
                  */
                // </Tooltip>
            }
            <Box display="flex">
                <div>
                    <Typography variant={'subtitle2'} align={'left'} className={classes.title}>
                        Run Log
                    </Typography>
                </div>
                <Box flexGrow={1} />
                <div style={{ display: 'flex' }}>
                    
                    {minimumRole('factory_admin') && user_role !== "scoreboard" && user_role !== "factory_read_only" && user_role !== "floor_team" && (
                        <Button
                            onClick={props.showAddRunModal}
                            disabled={
                                Util.isEmpty(props.lines) ||
                                Util.isEmpty(props.products) ||
                                Util.isEmpty(ProductStore.getFull())
                            }
                            style={{ height: '36px', marginTop: '16px', marginRight: '40px', marginLeft: '15px' }}
                            color="primary"
                            variant="contained"
                            startIcon={<AddOutlined />}
                        >
                            Add run
                        </Button>
                    )}
                </div>
            </Box>
            <Divider />
            {showReportTable()}
        </div>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            margin: '0 20px 40px 20px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.12)',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        fab: {
            position: 'absolute',
            top: 0,
            right: 40,
            marginTop: '-28px'
        },
        title: {
            margin: '20px 30px'
        },
        table_head_cell: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 1) !important'
        },
        table_head_cell_icon: {
            fontSize: '14px',
            color: '#666',
            maxWidth: '4%'
        },
        table_cell_date: {
            fontSize: '0.875rem',
            padding: '16px 12px 16px 24px',
            color: '#000000',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'left',
            verticalAlign: 'baseline'
        },
        table_cell_normal: {
            fontSize: '0.875rem',
            padding: '16px 12px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'left',
            verticalAlign: 'baseline'
        },
        table_cell_icon: {
            paddingTop: '16px',
            padding: '16px 24px 0 12px',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'left'
        },
        icon: {
            marginTop: '-12px'
        }
    })
);