// import { Button } from "@material-ui/core"
import { Menu, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import ScoreboardBasicMockup from '../styleLayoutImg1.svg'
import ScoreboardBasicMockup2 from '../styleLayoutImg2.svg'
import ScoreboardBasicMockup3 from '../styleLayoutImg3.svg'
import ScoreboardBasicMockup4 from '../styleLayoutImg4.svg'
import ScoreboardBasicMockup8 from '../styleLayoutImg8.svg'

import ScoreboardBasicGraphMockup from '../hourlyLayoutImg1.svg'
import ScoreboardBasicGraphMockup2 from '../hourlyLayoutImg2.svg'
import ScoreboardBasicGraphMockup3 from '../hourlyLayoutImg3.svg'
import ScoreboardBasicGraphMockup4 from '../hourlyLayoutImg4.svg'

import ScoreboardBasicTextMockup from '../textLayoutImg1.svg'

import ScoreboardTextParetoMockup from '../paretoLayoutImg1.svg'

import displayBasicOneLine from './basicViews/displayBasicOneLine';
import displayBasicTwoLines from './basicViews/displayBasicTwoLines';
import displayBasicThreeLines from './basicViews/displayBasicThreeLines';
import displayBasicFourLines from './basicViews/displayBasicFourLines';
import displayBasicEightLines from './basicViews/displayBasicEightLines';

import displayBasicGraphOneLine from './basicGraphViews/displayBasicGraphOneLine';
import displayBasicGraphTwoLines from './basicGraphViews/displayBasicGraphTwoLines';
import displayBasicGraphThreeLines from './basicGraphViews/displayBasicGraphThreeLines';
import displayBasicGraphFourLines from './basicGraphViews/displayBasicGraphFourLines';
import displayBasicText from './basicTextViews/displayBasicText';
import displayBasicTextPareto from './basicTextParetoViews/displayBasicTextPareto';
import DeleteConfirmationModal from '../../../modals/DeleteConfirmationModal';

const AddScoreboard = (props) => {

    const [numberOfLines, setNumberOfLines] = useState(1);
    const [layout, setLayout] = useState(props.selectedLayout);
    const [lines, setLines] = useState([])
    const [scoreboardName, setScoreboardName] = useState("")
    const [scoreboardNameError, setScoreboardNameError] = useState(false)
    const [metrics, setMetrics] = useState({})
    const [templates, setTemplates] = useState([])
    const [templateInfo, setTemplateInfo] = useState({})
    const [templateError, setTemplateError] = useState(false)

    const [basicTextData, setBasicTextData] = useState([{}])
    const [basicTextParetoData, setBasicTextParetoData] = useState([{position1: null, position2: null, position3: null, position3_number_of_items: 24}])
    const [basicGraphData, setBasicGraphData] = useState([{position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}])
    const [basicData, setBasicData] = useState([{position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}])

    const handleChangeLayout = (event: SelectChangeEvent) => {
        setNumberOfLines(1)
        getTemplateInfo(event.target.value, templates)
        setTemplateError(false)
        setScoreboardNameError(false)
        setBasicTextData([{}])
        setBasicTextParetoData([{position1: null, position2: null, position3: null, position3_number_of_items: 24}])
        setBasicGraphData([{position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}])
        setBasicData([{position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}])
        setLayout(event.target.value);
    };

    const handleChangeNumberOfLines = (event: SelectChangeEvent) => {
        setTemplateError(false)
        setScoreboardNameError(false)
        setBasicTextData([{}])
        setBasicTextParetoData([{position1: null, position2: null, position3: null, position3_number_of_items: 24}])
        setBasicGraphData([{position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}, {position1: null, position2: null, position3: null, position3_number_of_items: 24}])
        setBasicData([{position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}, {position1: null, position2: null}])
        setNumberOfLines(event.target.value);
    };

    // const onChangeBasicTextData = (data, identificationCode) => {
    //     const newBasicTextData = [...basicTextData]
    //     const arrayId = identificationCode.split("-")[0]
    //     const entryName = identificationCode.split("-")[1]
    //     newBasicTextData[arrayId][entryName] = data
    //     console.log(newBasicTextData);
    //     setBasicTextData(newBasicTextData)
    // }

    const onChangeData = (type, data, identificationCode) => {
        let newData
        let setData
        if (type === "basicText") {
            newData = [...basicTextData]
            setData = setBasicTextData
        }
        else if (type === "basicTextPareto") {
            newData = [...basicTextParetoData]
            setData = setBasicTextParetoData
        }
        else if (type === "basicGraph") {
            newData = [...basicGraphData]
            setData = setBasicGraphData
        }
        else if (type === "basicData") {
            newData = [...basicData]
            setData = setBasicData
        }
        const arrayId = identificationCode.split("-")[0]
        const entryName = identificationCode.split("-")[1]
        if (newData.length < 7) {
            newData.push(...[{}, {}, {}, {}, {}, {}, {}])
        }
        setTemplateError(false)
        newData[arrayId][entryName] = data
        setData(newData)
    }

    const getLines = () => {
        let params = {
            path: 'get_lines',
            success: fetchLinesSuccess,
            error: () => {}
        };

        API.c(params);
    };

    const fetchLinesSuccess = data => {
        setLines(data)
    };

    const getTemplateOptions = () => {
        let params = {
            path: 'get_scoreboard_template_names',
            success: fetchTemplateOptionsSuccess,
            error: () => {}
        };

        API.c(params);
    };

    const fetchTemplateOptionsSuccess = data => {
        setTemplates(data);
        console.log(layout);
        getTemplateInfo(layout)
    };

    const getTemplateInfo = (id) => {
        let params = {
            path: 'get_scoreboard_template_kpis',
            path_variables: { ID: id },
            success: fetchTemplateInfoSuccess,
            error: () => {}
        };

        API.c(params);
    };

    const fetchTemplateInfoSuccess = data => {
        setTemplateInfo(data)
    };

    const getMetrics = () => {
        let params = {
            path: 'get_scoreboard_kpi_names',
            success: fetchMetricsSuccess,
            error: () => {}
        };

        API.c(params);
    };

    const fetchMetricsSuccess = data => {
        setMetrics(data)
    };

    const fetchCreateTemplateSuccess = data => {
        props.onClose()
    };

    const onSaveClick = () => {

        if (isTemplateError() || !scoreboardName) {
            console.log(123);
            if (isTemplateError()) {
                console.log(345);
                setTemplateError(true)
            }
            if (!scoreboardName) {
                setScoreboardNameError(true)
            }
        }
        else {
            let template_id = 0
            templates.forEach((item) => {
                if (item.front_end_identifier === layout) {
                    template_id = item.id
                }
            })
            const payload = {
                scoreboard_name: scoreboardName,
                template: template_id,
                number_up: numberOfLines,
                line_scoreboard_mapping: layout === 1 ? basicTextData : layout === "template_text_scoreboard_style" ? basicData : layout === "template_text_and_hourly" ? basicGraphData : layout === "template_text_and_pareto" ? basicTextParetoData : []
            }
            payload.line_scoreboard_mapping.splice(numberOfLines, 10)
            payload.line_scoreboard_mapping.forEach((item, i) => {
                item.line_orientation = i + 1
                item.position1_number_of_items = null
            })
    
            if (props.edit) {
                let params = {
                    path: 'edit_scoreboard_template',
                    path_variables: {
                        ID: props.scoreboard.id
                    },
                    data: payload,
                    success: fetchCreateTemplateSuccess,
                    error: () => {}
                };
        
                API.c(params, 2);
            }
            else {
                let params = {
                    path: 'create_scoreboard_template',
                    data: payload,
                    success: fetchCreateTemplateSuccess,
                    error: () => {}
                };
        
                API.c(params, 2);
            }
        }


        
    }

    const isTemplateError = () => {
        let isError = false
        let data
        switch (layout) {
            case 1: 
                data = basicTextData
                break ;
            case "template_text_scoreboard_style":
                data = basicData
                break ;
            case "template_text_and_hourly":
                data = basicGraphData
                break ;
            case "template_text_and_pareto":
                data = basicTextParetoData
                break ;
            default:
                data = []
                break ;
        }

        if (data.length > 0) {
            data.forEach((entry, i) => {
                if (!entry.line && i + 1 <= numberOfLines) {
                    console.log("0");
                    isError = true
                }
                if (layout === 1 && !entry.position1 && i + 1 <= numberOfLines) {
                    console.log("1");
                    isError = true
                }
                if (layout === "template_text_scoreboard_style" && i + 1 <= numberOfLines) {
                    if (entry.position1 === null || entry.position2 === null) {
                        console.log("2");
                        isError = true
                    }
                }
                if (layout === "template_text_and_hourly" && i + 1 <= numberOfLines) {
                    if (entry.position1 === null || entry.position2 === null || entry.position3 === null) {
                        console.log("3");
                        isError = true
                    }
                }
                if (layout === "template_text_and_pareto" && i + 1 <= numberOfLines) {
                    if (entry.position1 === null || entry.position2 === null || entry.position3 === null) {
                        console.log("4");
                        isError = true
                    }
                }

                var keys = Object.keys(entry);
                var dupe = false;
        
                for(var i=0;i<keys.length;i++){
                    for(var j=i+1;j<keys.length;j++){
                        if(entry[keys[i]] && entry[keys[j]] && keys[i] !== "line" && keys[j] !== "line" && entry[keys[i]] === entry[keys[j]]){
                            dupe = true;
                            isError = true;
                            break;
                        }
                    }
                    if(dupe) {
                        break;
                    }
                }
            })
        }

        return isError
    }

    useEffect(() => {

        if (props.edit) {
            setLayout(props.selectedLayout)
            setNumberOfLines(props.scoreboard.number_up)
            setScoreboardName(props.scoreboard.scoreboard_name)
            let setData
            switch (props.selectedLayout) {
                case "template_basic_text": 
                    setData = setBasicTextData
                    break ;
                case "template_text_scoreboard_style":
                    setData = setBasicData
                    break ;
                case "template_text_and_hourly":
                    setData = setBasicGraphData
                    break ;
                case "template_text_and_pareto":
                    setData = setBasicTextParetoData
                    break ;
                default:
                    setData = () => {}
                    break ;
            }
            setData(props.scoreboard.line_scoreboard_mapping)
        }

        getLines()
        getTemplateOptions()
        // getTemplateInfo(layout)
        getMetrics()
    }, [])

    return (
        <div style={{ padding: "0px 36px" }}>
            <Button variant="text"
                color="primary"
                onClick={() => props.onClose()}
                style={{ marginTop: "8px" }}
                fullWidth={false}>
                Back to all scoreboards
            </Button>
            <Typography variant="body1" style={{ marginLeft: '8px', marginTop: "8px" }}>
                SCOREBOARD DETAILS
            </Typography>
            <div style={{ marginTop: "32px", display: "flex", alignItems: "center" }}>
                <FormControl variant="standard" sx={{ m: 1, width: "50vw" }}>
                    {/* <InputLabel>Scoreboard name</InputLabel> */}
                    <TextField error={scoreboardNameError} value={scoreboardName} onChange={(e) => { setScoreboardName(e.target.value); setScoreboardNameError(false) }} label="Scoreboard Name" id="scoreboard-name" variant="standard" />
                </FormControl>
            </div>
            <div style={{ marginTop: "0px", display: "flex", alignItems: "start" }}>
                <FormControl variant="standard" style={{}} sx={{ m: 1, minWidth: 220 }}>
                    <InputLabel id="scoreboardlayout">Scoreboard layout</InputLabel>
                    <Select
                        labelid="scoreboardlayout"
                        id="scoreboard-layout"
                        value={layout}
                        onChange={handleChangeLayout}
                        label="Layout"
                    >
                        {templates.map((item, i) => {
                            return (
                                <MenuItem value={item.front_end_identifier}>{item.human_readable_name}</MenuItem>
                            )
                        })}
                        {/* <MenuItem value={1}>Text</MenuItem>
                        <MenuItem value={2}>Style</MenuItem>
                        <MenuItem value={3}>Text+Hourly</MenuItem>
                        <MenuItem value={4}>Text+Pareto</MenuItem> */}
                    </Select>
                </FormControl>
                <FormControl variant="standard" style={{ marginLeft: "48px" }} sx={{ m: 1, minWidth: 220 }}>
                    <InputLabel id="numberoflines">Number of lines</InputLabel>
                    <Select
                        labelid="numberoflines"
                        id="number-of-lines"
                        value={numberOfLines}
                        onChange={handleChangeNumberOfLines}
                        label="Number of lines"
                    >
                        <MenuItem value={1}>1</MenuItem>
                        {(layout === "template_text_scoreboard_style" || layout === "template_text_and_hourly") && (
                            <MenuItem value={2}>2</MenuItem>
                        )}
                        {(layout === "template_text_scoreboard_style" || layout === "template_text_and_hourly") && (
                            <MenuItem value={3}>3</MenuItem>
                        )}
                        {(layout === "template_text_scoreboard_style" || layout === "template_text_and_hourly") && (
                            <MenuItem value={4}>4</MenuItem>
                        )}
                        {layout === "template_text_scoreboard_style" && (
                            <MenuItem value={8}>8</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            {numberOfLines > 0 && layout ? (
                <div style={{ display: "flex", justifyContent: "left", alignItems: "start", marginTop: "32px" }}>
                    <div>
                        <Typography variant="body1" style={{ marginLeft: '8px', marginTop: "8px" }}>
                            CONFIGURE KPIs
                        </Typography>
                        {layout === "template_text_scoreboard_style" ? (
                            <>
                                {numberOfLines === 1 ? (
                                    displayBasicOneLine(lines, onChangeData, templateInfo, metrics, basicData, templateError)
                                ) : numberOfLines === 2 ? (
                                    displayBasicTwoLines(lines, onChangeData, templateInfo, metrics, basicData, templateError)
                                ) : numberOfLines === 3 ? (
                                    displayBasicThreeLines(lines, onChangeData, templateInfo, metrics, basicData, templateError)
                                ) : numberOfLines === 4 ? (
                                    displayBasicFourLines(lines, onChangeData, templateInfo, metrics, basicData, templateError)
                                ) : numberOfLines === 8 ? (
                                    displayBasicEightLines(lines, onChangeData, templateInfo, metrics, basicData, templateError)
                                ) : null}
                            </> 
                        ) : layout === "template_text_and_hourly" ? (
                            <>
                                {numberOfLines === 1 ? (
                                    displayBasicGraphOneLine(lines, onChangeData, templateInfo, metrics, basicGraphData, templateError)
                                ) : numberOfLines === 2 ? (
                                    displayBasicGraphTwoLines(lines, onChangeData, templateInfo, metrics, basicGraphData, templateError)
                                ) : numberOfLines === 3 ? (
                                    displayBasicGraphThreeLines(lines, onChangeData, templateInfo, metrics, basicGraphData, templateError)
                                ) : numberOfLines === 4 ? (
                                    displayBasicGraphFourLines(lines, onChangeData, templateInfo, metrics, basicGraphData, templateError)
                                ) : null}
                            </>
                        ) : layout === 1 ? (
                            displayBasicText(lines, onChangeData, templateInfo, metrics, basicTextData, templateError)
                        ) : (
                            displayBasicTextPareto(lines, onChangeData, templateInfo, metrics, basicTextParetoData, templateError) 
                        )}
                    </div>
                    <img style={{ maxWidth: "50%", width: "auto", marginTop: "48px", marginLeft: `${layout === 3 && numberOfLines === 3 ? "20px" : "120px"}` }} src={
                        layout === "template_text_scoreboard_style" ? 
                            numberOfLines === 1 ? ScoreboardBasicMockup : 
                            numberOfLines === 2 ? ScoreboardBasicMockup2 : 
                            numberOfLines === 3 ? ScoreboardBasicMockup3 : 
                            numberOfLines === 4 ? ScoreboardBasicMockup4 : 
                            numberOfLines === 8 ? ScoreboardBasicMockup8 : 
                            null : 
                        layout === "template_text_and_hourly" ?
                            numberOfLines === 1 ? ScoreboardBasicGraphMockup : 
                            numberOfLines === 2 ? ScoreboardBasicGraphMockup2 : 
                            numberOfLines === 3 ? ScoreboardBasicGraphMockup3 : 
                            numberOfLines === 4 ? ScoreboardBasicGraphMockup4 : 
                            null :
                        layout === 1 ? ScoreboardBasicTextMockup : 
                        layout === "template_text_and_pareto" ? ScoreboardTextParetoMockup : 
                        null} />
                </div>
            ) : null}
            {/* <Button disabled={isTemplateError() || !scoreboardName} style={{ marginLeft: "0px", marginTop: "43px", marginRight: "12px", width: "91px", height: "42px" }} variant="text">Preview</Button> */}
            <Button onClick={() => onSaveClick()} style={{ marginLeft: "0px", marginTop: "43px", width: "91px", height: "42px" }} variant="contained">Save</Button>
        </div>
    )
}

export default AddScoreboard