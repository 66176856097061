const colorPalette = {
    base: {
        white: "#fff",
        main: "#F2F2F1",
    },
    neutral: {
        inverse: "#EDF0F3",
        20: "#F2F2F2",
        30: "#DDDDDD",
        40: "#BBBBBB",
        60: "#999999",
        80: "#666666",
        100: "#111827",
    },
    primary: {
        10: "#E7F1FA",
        20: "#CEE3F5",
        40: "#9DC6EB",
        60: "#6CAAE2",
        80: "#3B8DD8",
        100: "#0A71CE",
        200: "#004485",
    },
    accent: {
        10: "#FCE8EE",
        20: "#F9D2DD",
        30: "#F7BBCB",
        40: "#F4A4BA",
        60: "#EE7798",
        80: "#E94975",
        100: "#E31C53",
        200: "#AF0534",
    },
    error: {
        10: "#FCE5E5",
        20: "#F9CCCC",
        30: "#F6B3B3",
        40: "#F39999",
        60: "#EC6666",
        80: "#E63333",
        100: "#E00000",
    },
    warning: {
        10: "#FFF9E5",
        20: "#FFF4CC",
        30: "#FFEEB3",
        40: "#FFE899",
        60: "#FEDD66",
        80: "#FED133",
        100: "#FEC600",
    },
    success: {
        10: "#E9F5E5",
        20: "#D2EBCC",
        30: "#BCE2B3",
        40: "#A6D899",
        60: "#79C466",
        80: "#4CB133",
        100: "#1F9D00",
    }
  };

  export default colorPalette;