import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import SideNav from "../components/SideNav";
import Lines from "../components/Lines";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Snackbar, TextField,
    Typography
} from "@material-ui/core";
import LineDetailsMode from "../components/LineDetailsMode";
import PresentationMode from "./PresentationMode";
import Modal from "../components/Modal";
import ChangeScoreboardViewModal from "../modals/ChangeScoreboardViewModal";
import Footer from "../components/Footer";
import HeaderTopNav from "../components/HeaderTopNav";
import AddNewRun from "../modals/AddNewRun";

class LiveView extends Component {
    constructor(props) {
        super(props);
        this.gridViews = [1, 2, 3, 4, 8];
        this.state = {
            lines: null,
            view_type: this.isPresentationMode() ? 5 : 3,
            user: null,
            presentation_mode: this.isPresentationMode(),
            line_detail_mode: false,
            position: 0,
            grid_view: 1,
            showAllGreen: false,
            show_modal: false,
            collapse: true,
            menu_open: !this.isPresentationMode(),
            showAddRun: false,
            line: null,
            products: null,
            selectedProduct: '',
            selectProductError: null,
            snackbar: false
        };
    }

    componentDidMount() {
        this.setHotKey();
        User.subscribe(this.handleUserChange);
        LineStore.subscribe(this.handleLineChange);
        ProductStore.subscribeFull(this.handleProductChange);
        this.getFactorySettings()
        document.title = "LiveTracking | LiveView";
        if (window.ga) {
            window.ga("set", "page", "/liveview");
            window.ga("send", "pageview");
        }
    }

    componentWillUnmount() {
        this.cancelHotKey();
        User.unsubscribe(this.handleUserChange);
        LineStore.unsubscribe(this.handleLineChange);
        ProductStore.unsubscribe(this.handleProductChange);
    }

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.onGetFactorySettingsSuccess(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = (data) => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
    }

    setHotKey = () => {
        window.onkeydown = (event) => {
            if (event.shiftKey && event.keyCode === 71) {
                this.changeViewType(4);
            }
        };
    };

    cancelHotKey = () => {
        window.onkeydown = null;
    };

    isPresentationMode = () => {
        let q = window.location.search;

        return q === "?scoreboard=true";
    };

    handleUserChange = () => {
        this.setState({
            user: User.get(),
        });
    };

    handleLineChange = () => {
        this.setState({
            lines: LineStore.get(),
        });
    };

    handleProductChange = () => {
        this.setState({
            products: ProductStore.getFull(),
        })
    }

    changeViewType = (type, position) => {
        this.setState({
            view_type: type,
        });

        if (type == 4) {
            this.setState({ show_modal: true });
        }

        if (type == 5) {
            this.setState({
                line_detail_mode: true,
                position: position,
            });
        }
    };

    hidePresentationMode = () => {
        this.setHotKey();
        this.setState({
            presentation_mode: false,
            line_detail_mode: false,
            view_type: 3,
            menu_open: true,
        });
        window.history.replaceState(null, null, window.location.pathname);
    };

    showModal = () => {
        if (this.state.show_modal === true) {
            return (
                <Modal
                    title="Select Scoreboard Grid"
                    hideModal={this.hideModal}
                    class_name={"__add_event"}
                >
                    <ChangeScoreboardViewModal
                        hideModal={this.hideModal}
                        onGridSelected={this.onGridSelected}
                        grid_view={this.state.grid_view}
                        showAllGreen={this.state.showAllGreen}
                    />
                </Modal>
            );
        }
    };

    showStartRunDialog = () => {
        if(this.state.showAddRun) {
            return <AddNewRun hideStartNewRun={this.hideStartNewRun}
                              onStartNewRunSuccess={this.onStartNewRunSuccess}
                              onRunCurrentlyRunning={this.onRunCurrentlyRunning}
                              products={this.state.products}
                              line={this.state.line}/>
        }
    }

    hideModal = () => {
        this.setState({
            show_modal: false,
        });
    };

    onGridSelected = (v, showAllGreen) => {
        this.setState({
            grid_view: v,
            showAllGreen: showAllGreen,
            presentation_mode: true,
            menu_open: false,
        });
        window.history.replaceState(
            null,
            null,
            window.location.pathname + "?scoreboard=true"
        );
        this.hideModal();
        // this.requestFullScreen(document.getElementById("scoreboard"))
    };

    showLineDetailsModal = () => {
        if (this.state.line_detail_mode) {
            return (
                <LineDetailsMode
                    hidePresentationMode={this.hidePresentationMode}
                    linePosition={this.state.position}
                />
            );
        }
    };

    showLines() {
        // issue
        // let class_name = this.state.collapse ? "liveview--collapse" : "reports";
        let class_name = "liveview--collapse";
        if (this.state.lines == null || this.state.lines.constructor != Array) {
            return (
                <div className={class_name}>
                    <div>
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        if (this.state.presentation_mode === true) {
            return (
                <PresentationMode
                    lines={this.state.lines}
                    grid_view={this.gridViews[this.state.grid_view]}
                    showAllGreen={this.state.showAllGreen}
                    hidePresentationMode={this.hidePresentationMode}
                    changeViewType={this.changeViewType}
                />
            );
        }

        return (
            <div className={class_name}>
                <Lines
                    changeViewType={this.changeViewType}
                    view_type={this.state.view_type}
                    lines={this.state.lines}
                    onStartRunClick={this.onStartRunClick}
                    preventClick={false}
                />
                {this.showLineDetailsModal()}
            </div>
        );
    }

    onStartRunClick = (line) => {
        this.setState({
            showAddRun: true,
            line
        })
    };

    hideStartNewRun = () => {
        this.setState({
            showAddRun: false,
            line: null,
            selectedProduct: ''
        })
    }

    onStartNewRunSuccess = (data) => {
        this.setState({
            showAddRun: false,
            line: null,
            selectedProduct: ''
        })

        let href = "/reports/?run=" + data.id + "&return=liveview"
        this.props.history.push(href)
    }

    onRunCurrentlyRunning = () => {
        LineStore.fetch()

        this.setState({
            showAddRun: false,
            line: null,
            selectedProduct: '',
            snackbar: true
        })
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbar: false
        })
    }

    onCollapseClickChange = (collapse) => {
        this.setState({ collapse });
    };

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.onGetFactorySettingsSuccess(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = (data) => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
    }

    onFactoryChange = () => {
        this.setState({ lines: null, products: null });
        ProductStore.clearFull();
        ProductStore.fetch();
        LineStore.clear();
        LineStore.fetch();
        this.getFactorySettings()
    };

    render() {
        if (!this.state.user) {
            return <div />;
        }

        let header_class_name = this.state.collapse ? "header header--collapse" : "header"

        return (
            <div className="container">
                <HeaderTopNav
                    className={header_class_name}
                    onFactoryChange={this.onFactoryChange}
                    onShowScoreboardClicked={() => {
                        this.changeViewType(4);
                    }}
                />
                <SideNav
                    onCollapseClick={this.onCollapseClickChange}
                    onShowScoreboardClicked={() => {
                        this.changeViewType(4);
                    }}
                    menu_open={this.state.menu_open}
                    title="Live View"
                />
                <div className="main_content_wrapper">
                    <div id="scoreboard">
                        {this.showModal()}
                        {this.showStartRunDialog()}
                        {this.showLines()}
                    </div>
                    {!this.state.presentation_mode && <Footer />}
                </div>
                <Snackbar
                    open={this.state.snackbar}
                    onClose={this.handleSnackbarClose}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    message="There is a run currently running on line"
                />
            </div>
        );
    }
}

export default withRouter(LiveView);
