import { Typography } from "@mui/material";
import colorPalette from "../Palette";
import BackIcon from './icons/BackIcon.svg';

const PageHeader = (props) => {

    const sx = {
        card: {
            width: 'calc(100%)',
            padding: `30px 12px 30px 30px`,
            boxSizing: 'border-box',
            background: 'transparent',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: "1px solid #E3E7ED"
        },
        title_div: {
            background: 'transparent',
        },
        title: {
            fontWeight: '500',
            fontSize: `32px`,
            color: colorPalette.neutral[100]
        },
        backLabel: {
            color: colorPalette.neutral[60],
            fontSize: `16px`,
            fontWeight: "400"
        }
    }

    return (
        <div style={{...sx.card, ...props.cardStyles}}>
            <div style={sx.title_div}>
                {props.backCallback ? (
                    <div onClick={props.backCallback} style={{ display: "flex", gap: "8px", alignItems: "center", cursor: "pointer" }}>
                        <img src={BackIcon} />
                        <Typography sx={sx.backLabel}>
                            {props.backLabel}
                        </Typography>
                    </div>
                ) : null}
                <Typography sx={sx.title} >
                    {props.children}
                </Typography>
            </div>
            {props.customActions ? props.customActions : null}
        </div>
    )
}

export default PageHeader;