import moment from "moment";

const formatToHHhMMmSSs = (time) => {
    return moment().startOf('day').seconds(time).format('H[h] mm[m] ss[s]')
}

const formatToHhMmSs = (time, type) => {
    let duration = time;
    let hours = duration/3600;
    duration = duration % (3600);

    let min = parseInt(duration/60);
    duration = duration % (60);

    let sec = parseInt(duration);

    sec = `${sec}`;
    min = `${min}`;

    if (type) {
        if (type === "hh") {
            return (`${parseInt(hours, 10)}h`)
        }
        else if (type === "hhmm") {
            return (`${parseInt(hours, 10)}h ${min}m`)
        }
    }

    if (parseInt(hours, 10) > 0) {
    return (`${parseInt(hours, 10)}h ${min}m ${sec}s`)
    }
    return (`${min}m ${sec}s`)
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export { formatToHHhMMmSSs, formatToHhMmSs, numberWithCommas }