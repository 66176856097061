import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import colorPalette from "../../Palette";
import { Pagination } from "@mui/material";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from "moment";
import { formatToHHhMMmSSs, formatToHhMmSs } from "../../newUtils/timeFormatter";




const NewReportParetoChart = (props) => {

    let { report, getShortReason } = props;

    const [data, setData] = useState([])
    const [xAxisLabels, setXAxisLabels] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [bgColors, setBgColors] = useState([]);

    const [perPages, setPerPages] = useState(props.perPage);

    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPerPages(props.perPage)
        setPage(1)
    }, [props.perPage])

    useEffect(() => {
        let reportData = [];
        let unreportedBar = null;

        // setPerPages(props.perPage)
        // setPage(1)
        
        if (props.type === "Reason") {
            if (report.lost_time_list[0]) {
                let unreportedBarInfo = report.lost_time_list.find(lost_time => {
                    return lost_time.reason_name === 'Unrecorded';
                });
                if (unreportedBarInfo) {
                    unreportedBar = {
                        name: report.lost_time_list.length,
                        reason: 'Unreported',
                        reason_id: unreportedBarInfo.reason_id,
                        category: null,
                        short: getShortReason('Unreported'),
                        time_lost: parseFloat(unreportedBarInfo.lost_time_percent).toFixed(1),
                        time_lost_seconds: unreportedBarInfo.lost_time_seconds,
                        amt: Math.floor(unreportedBarInfo.lost_time_percent * 1000) / 1000,
                        non_oee: unreportedBarInfo.not_factored_in_oee
                    };
                }
    
                report.lost_time_list.forEach((item, i) => {
                    if (item !== undefined && item.reason_name !== 'Unrecorded') {
                        reportData.push({
                            name: i + 1,
                            reason: item.reason_name,
                            reason_id: item.reason_id,
                            category: item.reason_category,
                            short: props.getShortReason(item.reason_name),
                            time_lost: parseFloat(item.lost_time_percent).toFixed(1),
                            time_lost_seconds: item.lost_time_seconds,
                            amt: Math.floor(item.lost_time_percent * 1000) / 1000,
                            non_oee: item.not_factored_in_oee
                        });
                    }
                })
    
                let index_of_null_item = 1;
                while (index_of_null_item !== -1) {
                    index_of_null_item = reportData.findIndex(el => el.amt === 0);
                    if (index_of_null_item > -1) {
                        reportData.splice(index_of_null_item, 1);
                    }
                }
    
                let index_of_not_report = 1;
                while (index_of_not_report !== -1) {
                    index_of_not_report = reportData.findIndex(el => el.amt < 0.05 && el.amt > -0.05);
                    if (index_of_not_report > -1) {
                        reportData.splice(index_of_not_report, 1);
                    }
                }
                reportData.sort((a, b) => {
                    return b.amt - a.amt;
                });
    
                if (unreportedBar) {
                    reportData.push(unreportedBar);
                }
            }
        }
        else if (props.type === "Category") {
            if (report.lost_time_list_by_category) {
                let unreportedBarInfo = report.lost_time_list_by_category["Unrecorded"] ? report.lost_time_list_by_category["Unrecorded"] : null
                if (unreportedBarInfo) {
                    unreportedBar = {
                        name: -1,
                        reason: 'Unreported',
                        reason_id: unreportedBarInfo.id,
                        category: null,
                        short: getShortReason('Unreported'),
                        time_lost: parseFloat(unreportedBarInfo.lost_time_percent).toFixed(1),
                        time_lost_seconds: unreportedBarInfo.lost_time_seconds,
                        amt: Math.floor(unreportedBarInfo.lost_time_percent * 1000) / 1000,
                        // non_oee: unreportedBarInfo.not_factored_in_oee
                    };
                }

                let i = 0
                for (const [key, value] of Object.entries(report.lost_time_list_by_category)) {
                    if (key !== 'Unrecorded') {
                        reportData.push({
                            name: i + 1,
                            reason: key,
                            reason_id: value.id,
                            // category: item.reason_category,
                            short: props.getShortReason(key),
                            time_lost: parseFloat(value.lost_time_percent).toFixed(1),
                            time_lost_seconds: value.lost_time_seconds,
                            amt: Math.floor(value.lost_time_percent * 1000) / 1000,
                            // non_oee: item.not_factored_in_oee
                        });
                        i++;
                    }
                }
    
                let index_of_null_item = 1;
                while (index_of_null_item !== -1) {
                    index_of_null_item = reportData.findIndex(el => el.amt === 0);
                    if (index_of_null_item > -1) {
                        reportData.splice(index_of_null_item, 1);
                    }
                }
    
                let index_of_not_report = 1;
                while (index_of_not_report !== -1) {
                    index_of_not_report = reportData.findIndex(el => el.amt < 0.05 && el.amt > -0.05);
                    if (index_of_not_report > -1) {
                        reportData.splice(index_of_not_report, 1);
                    }
                }
                reportData.sort((a, b) => {
                    return b.amt - a.amt;
                });
    
                if (unreportedBar) {
                    reportData.push(unreportedBar);
                }
            }
            else {
                reportData = []
            }
        }

        
        setPages(reportData.length > props.perPage ? Math.ceil((reportData.length)/ props.perPage) : 1)
        setData(reportData);

        const newXAxisLabels = [];
        const newXAxisData = [];
        const newBgColors = [];
        reportData.forEach((item, i) => {
            newXAxisData.push(parseFloat(item.amt).toFixed(1).toString());
            newXAxisLabels.push(item.reason);
            if (!props.lostTimeOpen) {
                if (props.active_lost_time_reason) {
                    if (props.active_lost_time_reason === item.reason) {
                        newBgColors.push(colorPalette.error[60]);
                    }
                    else {
                        newBgColors.push(colorPalette.error[10]);
                    }
                }
                else {
                    newBgColors.push(colorPalette.error[60]);
                }  
            }
            else {
                // console.log(props.active_lost_time_reason);
                // console.log(item.reason);
                
                
                if (props.type === "Reason") {
                    
                    if (props.lostTimeReasonName === item.reason && props.lostTimeReasonId === item.reason_id) {
                        newBgColors.push(colorPalette.error[60]);
                    }
                    else {
                        newBgColors.push(colorPalette.error[10]);
                    }
                }
                else {
                    if (props.lostTimeCategory === item.reason && props.lostTimeCategoryId === item.reason_id) {
                        newBgColors.push(colorPalette.error[60]);
                    }
                    else {
                        newBgColors.push(colorPalette.error[10]);
                    }
                }
            }
        });
        setBgColors(newBgColors);
        setXAxisData(newXAxisData);
        // setXAxisLabels(newXAxisLabels.map((e) => splitLabelForChart(e)));
        setXAxisLabels(newXAxisLabels);

    }, [report?.lost_time_list, report?.lost_time_list_by_category, props]);

    useEffect(() => {
        setPage(1)
    }, [props.type])

    const getMaxValue = (data) => {
        let max = 0;
        data.forEach((d) => {
            if (parseInt(d) > max) {
                max = parseInt(d);
            }
        });
        if (max === 0) {
            return 5;
        }
        if (max < 5) {
            return max * 2.5;
        }
        return max * 1.5;
    }

    const splitLabelForChart = (label) => {
        const words = label.split(' ');
        const lineLimit = 14;
        const lines = [];
      
        let line = '';
        let currentWordIdx = 0;
      
        while (currentWordIdx < words.length) {
          if (line.length + words[currentWordIdx].length < lineLimit) {
            line += `${words[currentWordIdx]} `;
            currentWordIdx++;
      
            if (currentWordIdx === words.length) {
              lines.push(line);
            }
          } else {
            if (line.length) {
              lines.push(line);
              line = '';
            }
      
            if (words[currentWordIdx].length >= lineLimit) {
              lines.push([words.currentWord]);
              currentWordIdx++;
            }
          }
        }
        if (lines.length === 1) {
            return label
        }
      
        return lines;
      }

    const handleChangePage = (event, value) => {
        if (props.active_lost_time_reason) {
            props.reset_active_lost_time_reason();
        }
        setPage(value);
    };

    if (data.length === 0) {
        return <div />;
    }

    return (
        <div style={{ width: "97%", paddingLeft: "22px", height: props.lostTimeOpen ? "35%" : "80%", paddingTop: props.lostTimeOpen ? "34px" : "0px", display: "flex", justifyContent: "center", position: "relative" }}>
            <Bar
                plugins={[ChartDataLabels]}
                data={{
                    labels: xAxisLabels.slice((page - 1) * perPages, page * perPages),
                    datasets: [{
                        label: 'Reasons',
                        data: xAxisData.slice((page - 1) * perPages, page * perPages),
                        backgroundColor: bgColors.slice((page - 1) * perPages, page * perPages),
                        hoverBackgroundColor: bgColors.slice((page - 1) * perPages, page * perPages),
                        borderRadius: {
                            topLeft: 4,
                            topRight: 4
                        }
                    }]
                }}
                options={{
                    resizeDelay: 200,
                    onClick: (evt, element) => props.onLostTimeBarClick(data.slice((page - 1) * perPages, page * perPages)[element[0].index]),
                    onHover: (event, chartElement) => {
                        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                    },
                    interaction: {
                        intersect: false,
                        mode: "x"
                    },
                    maintainAspectRatio: false,
                    scales : {
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 90,
                                // minRotation: 90
                            }
                        },
                        y: {
                            suggestedMax: getMaxValue(xAxisData.slice((page - 1) * perPages, page * perPages)),
                            title: {
                                display: true,
                                text: "% of Total",
                                padding: {
                                    bottom: 24        
                                }
                            },
                            grid: {
                                offset: true,
                                color: "#E2E4E9"
                            },
                            border: {
                                dash: [4, 5]
                            },
                            ticks: {
                                callback: function(value, index, ticks) {
                                    return value + "%";
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            clamp: true,
                            formatter: (value) => `${value}%`,
                            font: {
                              weight: 'bold',
                              size: `${20 / 19.2 * window.innerWidth / 100}px`
                            },
                            color: "#000"
                        },
                        tooltip: {
                            yAlign: "top",
                            xAlign: "center",
                            position: "nearest",
                            backgroundColor: "#FFF",
                            multiKeyBackground: "#fff",
                            titleColor: "#111827",
                            titleFont: {
                                weight: 'bold',
                                size: "14px",
                            },
                            bodyColor: "#666666",
                            bodyFont: {
                                weight: 'normal',
                                // size: "14px",
                            },
                            boxWidth: 4,
                            boxPadding: 4,
                            borderColor: "rgba(122, 122, 122, 0.2)",
                            borderWidth: 1,
                            callbacks: {
                                title: function(context) {
                                    return `${props.type}: ${context[0].label.replaceAll(",", "")}`;
                                },
                                beforeTitle: function(context) {
                                    if (props.type === "Reason") {
                                        return data.slice((page - 1) * perPages, page * perPages)[context[0].dataIndex].category ? `Category: ${data.slice((page - 1) * perPages, page * perPages)[context[0].dataIndex].category}` : "Category: No category";
                                    }
                                    else {
                                        return null
                                    }
                                    
                                },
                                label: function(context) {
                                    return `Downtime: ${formatToHhMmSs(data.slice((page - 1) * perPages, page * perPages)[context.dataIndex].time_lost_seconds)}`;
                                }
                            }
                        }
                    }
                }}
            />
            {/* {pages > 0 ? ( */}
            {props.lostTimeOpen ? (
                null
            ) : (
                <Pagination sx={{ position: "absolute", bottom: "-48px", right: "0px", visibility: pages > 1 ? "visible" : "hidden" }}
                            count={pages} 
                            shape="rounded"
                            page={page}
                            onChange={(event, page) => handleChangePage(event, page)} />
            )}
            {/* ) : null} */}
        </div>
    )
}

export default NewReportParetoChart;