import React, { PureComponent } from 'react';
import { Switch, FormControlLabel, CircularProgress, Typography, Divider } from '@material-ui/core';
import { Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTimeSpeedTooltip from './CustomTimeSpeedTooltip';
import LegendItem from './LegendItem';
import moment from 'moment-timezone';

class OldSpeedChangeChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            data: null,
            areDowntimesHidden: true
        };
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount() {
        this.getSpeedChanges();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shiftIds.join('') !== this.props.shiftIds.join('')) {
            this.getSpeedChanges();
        }
    }

    /* ######## FUNCTIONS ######## */

    renderXAxis = () => {
        let startTime;
        let endTime;

        if (this.props.shift_start_time && this.props.run.run_start_time_tz) {
            const shiftStartTime = this.props.shift_start_time
                ? new Date(this.props.shift_start_time.replace(/ /g, 'T')).getTime() / 1000
                : null;
            const shiftEndTime = this.props.shift_end_time
                ? new Date(this.props.shift_end_time.replace(/ /g, 'T')).getTime() / 1000
                : null;
            const runStartTime = this.props.run.run_start_time_tz
                ? new Date(this.props.run.run_start_time_tz.replace(/ /g, 'T')).getTime() / 1000
                : null;
            const runEndTime =
                (this.props.run.run_end_time_tz
                    ? new Date(this.props.run.run_end_time_tz.replace(/ /g, 'T'))
                    : new Date()
                ).getTime() / 1000;
            startTime = shiftStartTime > runStartTime ? shiftStartTime : runStartTime;
            endTime = shiftEndTime < runEndTime ? shiftEndTime : runEndTime;
        } else {
            startTime = this.props.run.run_start_time
                ? new Date(this.props.run.run_start_time.replace(/ /g, 'T')).getTime() / 1000
                : null;
            endTime =
                (this.props.run.run_end_time
                    ? new Date(this.props.run.run_end_time.replace(/ /g, 'T'))
                    : new Date()
                ).getTime() / 1000;
        }
        let duration = endTime - startTime;
        let interval = 60;
        if (duration <= 300) {
            interval = 60;
        } else if (duration > 300 && duration <= 900) {
            interval = 180;
        } else if (duration > 900 && duration <= 1800) {
            interval = 300;
        } else if (duration > 1800 && duration <= 3600) {
            interval = 600;
        } else if (duration > 3600 && duration <= 10800) {
            interval = 1800;
        } else if (duration > 10800) {
            interval = 3600;
        }

        let xAxisLabels = [];
        for (let i = startTime; i < endTime; i += interval) {
            xAxisLabels.push(i * 1000);
        }
        xAxisLabels.push(endTime * 1000);
        return xAxisLabels;
    };

    findTargetPosition = () => {
        let target = this.state.data.target_speed;
        let average = this.state.data.average_speed;
        if (target - average < 10 && target - average >= 0) {
            return 'insideTopRight';
        }

        return 'insideBottomRight';
    };

    /* ######## API CALLS ######## */

    getSpeedChanges = () => {
        let namesShift = [];
        let params = {
            path: 'get_speed_changes',
            path_variables: {
                RUN_ID: this.props.run_id
            },
            success: this.onGetSuccess,
            error: this.onGetError
        };

        if (this.props.shiftIds) {
            this.props.shiftIds.map(shiftId => {
                namesShift.push(`shift_id=${shiftId}`);
            });
            params = {
                path: 'get_speed_changes',
                path_variables: {
                    RUN_ID: this.props.run_id,
                    SHIFT_ID: `?${namesShift.join('&')}`
                },
                success: this.onGetSuccess,
                error: this.onGetError
            };
        }

        API.c(params, 2);
    };

    onGetSuccess = data => {
        this.setState({
            data
        });
    };

    onGetError = error => {
        this.setState({
            error
        });
    };

    /* ######## RENDERS ######## */
    renderChart = () => {
        if (!this.state.data) {
            return (
                <div
                    style={{
                        height: '400px',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-around'
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }

        let startTime;
        let endTime;
        if (this.props.shift_start_time && this.props.run.run_start_time_tz) {
            const shiftStartTime = this.props.shift_start_time
                ? new Date(this.props.shift_start_time.replace(/ /g, 'T')).getTime()
                : null;
            const shiftEndTime = this.props.shift_end_time
                ? new Date(this.props.shift_end_time.replace(/ /g, 'T')).getTime()
                : null;
            const runStartTime = this.props.run.run_start_time_tz
                ? new Date(this.props.run.run_start_time_tz.replace(/ /g, 'T')).getTime()
                : null;
            const runEndTime = (this.props.run.run_end_time_tz
                ? new Date(this.props.run.run_end_time_tz.replace(/ /g, 'T'))
                : new Date()
            ).getTime();
            startTime = shiftStartTime > runStartTime ? shiftStartTime : runStartTime;
            endTime = shiftEndTime < runEndTime ? shiftEndTime : runEndTime;
        } else {
            startTime = this.props.run.run_start_time
                ? new Date(this.props.run.run_start_time.replace(/ /g, 'T')).getTime()
                : null;
            endTime = (this.props.run.run_end_time
                ? new Date(this.props.run.run_end_time.replace(/ /g, 'T'))
                : new Date()
            ).getTime();
        }
        let data = [];
        let allSpeedData =
            this.state.data.speed_changes_list.length !== 0
                ? this.state.data.speed_changes_list
                : [{ speed: 0, time_utc: endTime }];
        let list = [];
        //constrain the data points to the run bounds
        allSpeedData.forEach(e => {
            let eTime;
            if (e.time_tz) {
                eTime = new Date(e.time_tz).getTime();
            } else {
                eTime = new Date(e.time_utc).getTime();
            }
            if (e.time_tz) {
                if (eTime >= startTime && eTime <= endTime) {
                    list.push({ speed: e.speed, time_tz: e.time_tz });
                }
            } else {
                if (eTime >= startTime && eTime <= endTime) {
                    list.push(e);
                }
            }
        });
        if (list.length > 0) {
            //Pushing first positive speed to the start of the array in case the run start time changed and there's no speed event at start
            let firstPositiveSpeed = list.findIndex(event => event.speed > 0);
            if (firstPositiveSpeed !== -1) {
                data.push({ amt: list[firstPositiveSpeed].speed, time: startTime });
            }
            if (this.props.run.run_start_time_tz) {
                list.sort((a, b) => {
                    let aTime = a.time_tz ? new Date(a.time_tz) : new Date();
                    let bTime = b.time_tz ? new Date(b.time_tz) : new Date();
                    return new Date(aTime).getTime() - new Date(bTime).getTime();
                });
            } else {
                list.sort((a, b) => {
                    let aTime = a.time_utc ? new Date(a.time_utc) : new Date();
                    let bTime = b.time_utc ? new Date(b.time_utc) : new Date();
                    return new Date(aTime).getTime() - new Date(bTime).getTime();
                });
            }

            if (!this.state.areDowntimesHidden) {
                if (this.props.run.run_start_time_tz) {
                    list.map(event => {
                        data.push({
                            amt: event.speed,
                            time: (event.time_tz ? new Date(event.time_tz) : new Date()).getTime()
                        });
                    });
                } else {
                    list.map(event => {
                        data.push({
                            amt: event.speed,
                            time: (event.time_utc ? new Date(event.time_utc) : new Date()).getTime()
                        });
                    });
                }
            } else {
                if (this.props.run.run_start_time_tz) {
                    list.map(event => {
                        if (event.speed === 0) {
                            data.push({
                                amt: null,
                                time: (event.time_tz ? new Date(event.time_tz) : new Date()).getTime()
                            });
                        } else {
                            data.push({
                                amt: event.speed,
                                time: (event.time_tz ? new Date(event.time_tz) : new Date()).getTime()
                            });
                        }
                    });
                } else {
                    list.map(event => {
                        if (event.speed === 0) {
                            data.push({
                                amt: null,
                                time: (event.time_utc ? new Date(event.time_utc) : new Date()).getTime()
                            });
                        } else {
                            data.push({
                                amt: event.speed,
                                time: (event.time_utc ? new Date(event.time_utc) : new Date()).getTime()
                            });
                        }
                    });
                }
            }

            //Pushing last speed event to the end of the array to show continuity on the last speed until the run has finished

            // this.findTargetPosition()label={{value: "Average: " + this.state.data.average_speed.toFixed(2) + " Strokes/min", position: 'insideBottomRight'}}label={{value: "Target: " + this.state.data.target_speed + " Strokes/min", position: "insideBottomLeft"}}

            return (
                <div style={{ height: '100%' }}>
                    <div
                        className="reports__data-lost-time"
                        style={{ width: 'calc(100% - 28px)', marginLeft: '-16px', marginTop: '60px' }}
                    >
                        <ResponsiveContainer
                            width="100%"
                            height={400}
                            className="reports_speed-chart--responsive-container"
                        >
                            <LineChart data={data}>
                                <XAxis
                                    dataKey="time"
                                    interval={'preserveStartEnd'}
                                    allowDuplicatedCategory
                                    scale={'time'}
                                    type={'number'}
                                    domain={['dataMin', 'dataMax']}
                                    ticks={this.renderXAxis()}
                                    tickFormatter={v => {
                                        return moment(v).tz(this.props.timeZone).format('LT');
                                    }}
                                />
                                <YAxis tick={{ fontSize: 28 }} />
                                <Tooltip
                                    active={true}
                                    content={<CustomTimeSpeedTooltip timeZone={this.props.timeZone} />}
                                    cursor={data.length !== 0}
                                />
                                <ReferenceLine
                                    y={this.state.data.average_speed}
                                    stroke="#2967c1"
                                    strokeDasharray="3 3"
                                    strokeWidth={2}
                                />
                                <ReferenceLine
                                    y={this.state.data.target_speed}
                                    stroke="#2967c1"
                                    strokeDasharray="10 5"
                                    strokeWidth={4}
                                />
                                <Line
                                    dataKey="amt"
                                    stroke="#6ebd3a"
                                    dot={false}
                                    type={'stepAfter'}
                                    isAnimationActive={false}
                                    connectNulls
                                />
                            </LineChart>
                        </ResponsiveContainer>
                        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                }}
                            >
                                {this.props.run.unit_info.speed && (
                                    <LegendItem
                                        style={{ stroke: '#2967c1', strokeDasharray: '3 3', strokeWidth: 2 }}
                                        content={
                                            'Average: ' +
                                            this.state.data.average_speed.toFixed(2) +
                                            ' ' +
                                            this.props.run.unit_info.speed.display_unit_name
                                        }
                                    />
                                )}
                                {this.props.run.unit_info.speed && (
                                    <LegendItem
                                        style={{ stroke: '#2967c1', strokeDasharray: '10 5', strokeWidth: 4 }}
                                        content={
                                            'Target: ' +
                                            this.state.data.target_speed +
                                            ' ' +
                                            this.props.run.unit_info.speed.display_unit_name
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    //+ " (" + this.props.run.product_multiplier + " sets/stroke)"

    renderToggle = () => {
        return (
            <div style={{ display: 'grid', paddingLeft: '5px', clear: 'both' }}>
                <div className="reports__toggle">
                    <div className="reports__toggle--inner">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={event => this.setState({ areDowntimesHidden: event.target.checked })}
                                    color="primary"
                                    checked={this.state.areDowntimesHidden}
                                />
                            }
                            label={'Hide Downtimes'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        if (this.state.error) {
            return <div />;
        }

        return (
            <div className="reports_card">
                <Typography variant={'subtitle2'} align={'left'} className="reports_card--title">
                    Run Timeline: Speed
                </Typography>
                <Divider />
                {this.renderChart()}
                {this.renderToggle()}
            </div>
        );
    }
}

export default OldSpeedChangeChart;