import React, { useEffect, useState } from 'react';
import {
    Box,
    Divider,
    Paper,
    Tooltip,
    Typography
} from '@material-ui/core';

import {Button, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'

import { AddOutlined } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { minimumRole } from '../../PermissionsHelper';
import OutlinedChevronIcon from '../../icons/OutlinedChevronIcon';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import TooltipIcon from '../../icons/TooltipIcon.svg';
import RunOverviewIcon from '../../icons/RunOverviewIcon.svg';
import RunOverviewCalendarIcon from '../../icons/RunOverviewCalendarIcon.svg';
import moment from 'moment-timezone';
import colorPalette from '../../../Palette';
import ReportsCard from '../../ReportsCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatToHhMmSs, numberWithCommas } from '../../../newUtils/timeFormatter';

Date.prototype.addDays = function (days) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
};

function getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(currentDate);
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

export default function DailyReportRunTable(props) {
    const classes = useStyles();
    const width = window.innerWidth;
    const [user_role, setUser_role] = useState("")
    const [tableLines, setTableLines] = useState([]);
    const [hiddenLines, setHiddenLines] = useState([])

    useEffect(() => {
            setUser_role(JSON.parse(window.localStorage.getItem('factory')).role)

            let newTableLines = []

            props.report.table_info_list.forEach(run => {
                if (!newTableLines.includes(run.line_name)) {
                    newTableLines.push(run.line_name)
                }
            })

            setTableLines(newTableLines)
    }, [])

    const showHideLine = (line) => {
        if (hiddenLines.includes(line)) {
            setHiddenLines(hiddenLines.filter(hiddenLine => hiddenLine !== line))
        } else {
            setHiddenLines([...hiddenLines, line])
        }
    }

    if (props.run != null) {
        return <div />;
    }

    const isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0;
    }

    const showReportTableNew = () => {
        if (!props.report.run_overview_by_line || props.report.run_overview_by_line.length === 0) {
            return null
        }
        
        return (
            <>
                {props.report.run_overview_by_line.map((line, index) => {
                    return ( 
                        <>
                            <div style={{ padding: `24px`, width: "96%"  }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {hiddenLines.includes(line.line_name) ? (
                                        <KeyboardArrowDownIcon onClick={() => showHideLine(line.line_name)} sx={{ border: "1px solid #E2E4E9", borderRadius: "4px", padding: `4px`, cursor: "pointer" }} />
                                    ) : (
                                        <KeyboardArrowUpIcon onClick={() => showHideLine(line.line_name)} sx={{ border: "1px solid #E2E4E9", borderRadius: "4px", padding: `4px`, cursor: "pointer" }} />
                                    )}
                                    <span style={{ fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[100], marginLeft: `16px` }}>{line.line_name}</span>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: `16px`, marginLeft: `16px`, background: "#F6F8FA" }}>
                                        <div style={{ width: `10px`, height: `10px`, borderRadius: "100%", background: colorPalette.success[100] }} />
                                        <span style={{ fontSize: `12px`, color: colorPalette.neutral[100], marginLeft: `10px` }}>
                                            OEE: <span style={{ fontWeight: "600" }}>
                                                    {line.oee_percent.toFixed(1)}%
                                                </span> 
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: `16px`, marginLeft: `16px`, background: "#F6F8FA" }}>
                                        <div style={{ width: `10px`, height: `10px`, borderRadius: "100%", background: colorPalette.success[100] }} />
                                        <span style={{ fontSize: `12px`, color: colorPalette.neutral[100], marginLeft: `10px` }}>
                                            Average Speed: <span style={{ fontWeight: "600" }}>
                                                    {numberWithCommas(line.average_speed_units_per_min)} units/min
                                                </span> 
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: `16px`, marginLeft: `16px`, background: "#F6F8FA" }}>
                                        <div style={{ width: `10px`, height: `10px`, borderRadius: "100%", background: colorPalette.success[100] }} />
                                        <span style={{ fontSize: `12px`, color: colorPalette.neutral[100], marginLeft: `10px` }}>
                                            Sensor Count: <span style={{ fontWeight: "600" }}>
                                                    {numberWithCommas(line.sensor_count_units.toFixed(0))} units
                                                </span> 
                                        </span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "4px", padding: `16px`, marginLeft: `16px`, background: "#F6F8FA" }}>
                                        <div style={{ width: `10px`, height: `10px`, borderRadius: "100%", background: colorPalette.success[100] }} />
                                        <span style={{ fontSize: `12px`, color: colorPalette.neutral[100], marginLeft: `10px` }}>
                                            Total Running Time: <span style={{ fontWeight: "600" }}>
                                                    {line.total_actual_time_secs ? formatToHhMmSs(line.total_actual_time_secs) : "0s"}
                                                </span> 
                                        </span>
                                    </div>
                                </div>
                                {hiddenLines.includes(line.line_name) ? null : (
                                    <TableContainer sx={{ boxShadow: "none", marginTop: `32px`, border: "1px solid #E2E4E9", borderRadius: "4px", width: "100%" }} component={Paper}>
                                        <Table sx={{ boxShadow: "none", width: "100%" }}>
                                        {renderTableHeader()}
                                        <TableBody sx={{ boxShadow: "none", width: "100%" }}>
                                            {props.report.table_info_list.map((item) => {
                                                const useStylesBootstrap = makeStyles(theme => ({
                                                    arrow: {
                                                        color: theme.palette.common.black
                                                    },
                                                    tooltip: {
                                                        backgroundColor: theme.palette.common.black
                                                    }
                                                }));

                                                function BootstrapTooltip(props) {
                                                    const classes = useStylesBootstrap();

                                                    return <Tooltip arrow classes={classes} {...props} />;
                                                }

                                                let date_from = new Date(item.run_start_time);
                                                let date_to = item.run_end_time ? new Date(item.run_end_time) : new Date();
                                                const dateMomentStart = moment(item.run_start_time).tz(props.timeZone).format('DDD');
                                                const dateMomentEnd = moment(date_to).tz(props.timeZone).format('DDD');
                                                const difference_in_day = +dateMomentEnd - dateMomentStart;
                                                const dates = getDates(date_from, date_from.addDays(difference_in_day));
                                                return (
                                                    <>
                                                        {item.line_name !== line.line_name ? null : (
                                                            <TableRow
                                                                key={item.run_id}
                                                                onClick={props.handleTableClick.bind(this, item)}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { background: "#E4E4E4" }, boxShadow: "none", cursor: "pointer" }}
                                                            >
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        {dates.length > 1 ? (
                                                                            <BootstrapTooltip
                                                                                title={
                                                                                    <div
                                                                                        style={{
                                                                                            padding: `10px`,
                                                                                            paddingTop: `7px`,
                                                                                            width: `200px`,
                                                                                            height: `90px`,
                                                                                            boxSizing: 'border-box',
                                                                                            color: '#DDD'
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ marginBottom: `10px` }}>
                                                                                            <CalendarTodayIcon
                                                                                                style={{
                                                                                                    marginTop: `2px`,
                                                                                                    widht: `14px`,
                                                                                                    color: 'white',
                                                                                                    height: `14px`
                                                                                                }}
                                                                                            />
                                                                                            <Typography
                                                                                                style={{
                                                                                                    color: 'white',
                                                                                                    fontWeight: '700',
                                                                                                    fontSize: `15px`
                                                                                                }}
                                                                                                component="span"
                                                                                                variant="caption"
                                                                                            >
                                                                                                {dates.length}-day run
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <div style={{ marginBottom: `5px` }}>
                                                                                            <Typography style={{ marginRight: `7px` }} variant="caption">
                                                                                                Start
                                                                                            </Typography>{' '}
                                                                                            <Typography variant="caption" component="span">
                                                                                                {moment(item.run_start_time)
                                                                                                    .tz(props.timeZone)
                                                                                                    .format('LLL')}

                                                                                            </Typography>
                                                                                        </div>
                                                                                        <div style={{ padingBottom: `10px` }}>
                                                                                            <Typography style={{ marginRight: `14px` }} variant="caption">
                                                                                                End
                                                                                            </Typography>{' '}
                                                                                            <Typography component="span" variant="caption">
                                                                                                {item.run_end_time
                                                                                                    ? moment(item.run_end_time)
                                                                                                        .tz(props.timeZone)
                                                                                                        .format('LLL')
                                                                                                    : 'in Progress'}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexWrap: 'no-wrap',
                                                                                        marginRight: `5px`,
                                                                                        paddingRight: `5px`,
                                                                                        marginTop: `14px`
                                                                                    }}
                                                                                >
                                                                                    {/* <CalendarTodayIcon
                                                                                        style={{
                                                                                            color: '#004485',
                                                                                            widht: '17px',
                                                                                            paddingTop: '1px',
                                                                                            height: '17px'
                                                                                        }}
                                                                                    /> */}
                                                                                    <img width={`24px`} src={RunOverviewCalendarIcon}
                                                                                                style={{
                                                                                                    color: '#004485',
                                                                                                    width: `20px`,
                                                                                                    paddingTop: `1px`,
                                                                                                    height: `20px`
                                                                                                }}/>
                                                                                    <Typography variant="caption" style={{ color: colorPalette.neutral[100], marginTop: `14px` }}>
                                                                                        {dates.length}
                                                                                    </Typography>
                                                                                </div>
                                                                            </BootstrapTooltip>
                                                                            // null
                                                                        ) : null}
                                                                        <div style={{ display: "flex", alignItems: "center", display: 'flex',
                                                                                        flexWrap: 'no-wrap',
                                                                                        marginRight: `5px`,
                                                                                        paddingRight: `5px`,
                                                                                        // marginTop: '8px'
                                                                                    }}>
                                                                            {moment(item.run_start_time)
                                                                                .tz(props.timeZone)
                                                                                .format('llll')
                                                                                .split(', ')
                                                                                .slice(0, 2)
                                                                                .join(', ')}
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, width: "20%", fontWeight: "500", color: colorPalette.neutral[100] }}>{item.product_name} ({item.product_description})</TableCell>
                                                                {/* <TableCell sx={{ fontSize: "16px", fontWeight: "500", color: colorPalette.neutral[100] }}>{item.line_name}</TableCell> */}
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {moment(item.run_start_time).tz(props.timeZone).format('LT')}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {item.run_end_time ? (
                                                                        moment(item.run_end_time).tz(props.timeZone).format('LT')
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: '#EFF8ED',
                                                                                width: `85px`,
                                                                                padding: `10px`,
                                                                                textAlign: 'left',
                                                                                color: '#197A01',
                                                                                borderRadius: '3px',
                                                                            }}
                                                                        >
                                                                            In Progress
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {parseFloat(item.run_efficiency_percent).toFixed(1) + '%'}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {`${item && item.average_speed_pieces_per_min} ${
                                                                        item &&
                                                                        item.unit_info &&
                                                                        item.unit_info.speed &&
                                                                        item.unit_info.speed.display_unit_name
                                                                            ? item.unit_info.speed.display_unit_name
                                                                            : ''
                                                                    }`}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {`${item && item.target_speed_pieces_per_min} ${
                                                                        item &&
                                                                        item.unit_info &&
                                                                        item.unit_info.speed &&
                                                                        item.unit_info.speed.display_unit_name
                                                                            ? item.unit_info.speed.display_unit_name
                                                                            : ''
                                                                    }`}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {`${item && numberWithCommas(Number(item.actual_output_pieces).toFixed(0))} ${
                                                                        item &&
                                                                        item.unit_info &&
                                                                        item.unit_info.output &&
                                                                        item.unit_info.output.display_unit_name
                                                                            ? item.unit_info.output.display_unit_name
                                                                            : ''
                                                                    }`}
                                                                </TableCell>
                                                                <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                    {`${item && numberWithCommas(Number(item.run_finished_goods).toFixed(0))} ${
                                                                        item &&
                                                                        item.unit_info &&
                                                                        item.unit_info.finished_goods &&
                                                                        item.unit_info.finished_goods.display_unit_name
                                                                            ? item.unit_info.finished_goods.display_unit_name
                                                                            : ''
                                                                    }`}
                                                                </TableCell>
                                                                {props.usesHeadcount && (
                                                                    <TableCell sx={{ fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[100] }}>
                                                                        {item && Util.roundTo(item.headcount, 2)}
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </div>
                            {props.report.run_overview_by_line[index + 1] ? (
                                <hr style={{ border: "none", height: "1px", width: "100%", background: "#E2E4E9" }} />
                            ) : null}
                        </>
                    )
                })}
            </>
        )
    }

    const renderTableHeader = () => {
        return (
            <TableHead sx={{ background: "#F2F2F2", border: "none", width: "100%" }}>
                <TableRow sx={{ border: "none" }}>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Date</TableCell>
                    <TableCell sx={{ border: "none", width: "20%", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>SKU</TableCell>
                    {/* <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Line</TableCell> */}
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Start Time</TableCell>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>End Time</TableCell>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>OEE</TableCell>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Average Speed</TableCell>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Target Speed</TableCell>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Sensor Count</TableCell>
                    <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "500", color: colorPalette.neutral[60] }}>Finished Goods</TableCell>
                    {props.usesHeadcount ? (
                        <TableCell sx={{ border: "none", fontSize: `14px`, fontWeight: "600", color: colorPalette.neutral[60] }}>Labour</TableCell>
                    ) : null}
                </TableRow>
            </TableHead>
        )
    }

    return (
        <div style={{ padding: `24px 30px 0px` }}>
            <ReportsCard size="large" 
                         height={"100%"} 
                         mainBoxStyles={{ alignItems: "baseline", flexDirection: "column" }} 
                         icon={RunOverviewIcon} 
                         customSubtitle={
                            <div style={{ display: "flex", alignItems: "center", gap: `16px`, marginLeft: `16px` }}>
                                <span style={{ fontSize: `14px`, color: colorPalette.neutral[100], fontWeight: "400" }}>OEE: <span style={{ fontWeight: "600" }}>{props.report.efficiency_percent}%</span></span>
                                <span style={{ fontSize: `14px`, color: colorPalette.neutral[100], fontWeight: "400" }}>Average Speed: <span style={{ fontWeight: "600" }}>{props.report.run_overview_average_speed_units_per_minute ? numberWithCommas(props.report.run_overview_average_speed_units_per_minute) : "0"} units/min</span></span>
                                <span style={{ fontSize: `14px`, color: colorPalette.neutral[100], fontWeight: "400" }}>Sensor Count: <span style={{ fontWeight: "600" }}>{props.report.run_overview_sensor_count_units ? numberWithCommas(props.report.run_overview_sensor_count_units.toFixed(0)) : "0"} units</span></span>
                                <span style={{ fontSize: `14px`, color: colorPalette.neutral[100], fontWeight: "400" }}>Total Running Time: <span style={{ fontWeight: "600" }}>{props.report.total_actual_time_secs ? formatToHhMmSs(props.report.total_actual_time_secs) : "0s"}</span></span>
                            </div>
                         }
                         customButton={<Button onClick={props.showAddRunModal}
                                               disabled={ 
                                                Util.isEmpty(props.lines) ||
                                                Util.isEmpty(props.products) ||
                                                Util.isEmpty(ProductStore.getFull())
                                               }
                                               color="primary" 
                                               sx={{ height: `34px`, fontSize: `14px`, textTransform: "none", boxShadow: "none" }} 
                                               variant='contained' 
                                        >
                                             <span style={{ fontSize: `14px`, fontWeight: "400", marginRight: `8px` }}>+</span> Add New Run
                                        </Button>}
                         tooltip={"The run details page provides a comprehensive overview of key performance indicators (KPIs) for each production run. It highlights essential metrics such as efficiency (OEE), downtime, utilization, planned vs. unplanned downtime, waste, and rework, giving you real-time insights into your production performance for this run."} 
                         title="Run Overview">

                    {showReportTableNew()}
                
            </ReportsCard>
            {/* <Divider /> */}
        </div>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            margin: '0 20px 40px 20px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.12)',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        fab: {
            position: 'absolute',
            top: 0,
            right: 40,
            marginTop: '-28px'
        },
        title: {
            margin: '20px 30px'
        },
        table_head_cell: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 1) !important'
        },
        table_head_cell_icon: {
            fontSize: '14px',
            color: '#666',
            maxWidth: '4%'
        },
        table_cell_date: {
            fontSize: '0.875rem',
            padding: '16px 12px 16px 24px',
            color: '#000000',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'left',
            verticalAlign: 'baseline'
        },
        table_cell_normal: {
            fontSize: '0.875rem',
            padding: '16px 12px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'left',
            verticalAlign: 'baseline'
        },
        table_cell_icon: {
            paddingTop: '16px',
            padding: '16px 24px 0 12px',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'left'
        },
        icon: {
            marginTop: '-12px'
        }
    })
);
