import { DialogContent, DialogTitle, Typography, Dialog, Paper, Button, DialogActions } from '@mui/material';
import FontIcon from 'material-ui/FontIcon';
import React, { useEffect, useState } from 'react';

import template_basic_text from './textLayoutScheme.svg'
import template_text_scoreboard_style from './styledLayoutScheme.svg'
import template_text_and_hourly from './texthourlyLayoutScheme.svg'
import template_text_and_pareto from './textparetoLayoutScheme.svg'

const ScoreboardLayoutModal = (props) => {

    const [selectedLayout, setSelectedLayout] = useState(null)
    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0)
    const [templates, setTemplates] = useState([
        // {
        //     id: 1,
        //     human_readable_name: "Template Basic Text",
        //     front_end_identifier: "template_basic_text"
        // },
        // {
        //     id: 2,
        //     human_readable_name: "Template Text Scoreboard Style",
        //     front_end_identifier: "template_text_scoreboard_style"
        // },
        // {
        //     id: 3,
        //     human_readable_name: "Template Text + Hourly",
        //     front_end_identifier: "template_text_and_hourly"
        // },
        // {
        //     id: 4,
        //     human_readable_name: "Template Text + Pareto",
        //     front_end_identifier: "template_text_and_pareto"
        // }
     
    ])
    const layoutHoverStyle = {
        border: "0px",
        boxShadow: "none",
        display: "flex",
        justifyContent: "center",
        border: "2px solid rgba(10, 113, 206, 0)",
        alignItems: "start",
        width: "33%",
        height: "216px",
        margin: "0px 8px",
        "&:hover": {
          borderRadius: "8px",
          backgroundColor: 'rgba(10, 113, 206, 0.07)',
          boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
        },
      };

    const layoutSelectedStyle = {
        border: "2px solid rgba(10, 113, 206, 0.4)",
        boxShadow: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        width: "33%",
        height: "216px",
        margin: "0px 8px",
      };

    // let templates = [
    //     {
    //         name: "Layout 1",
    //         description: "Description of the scoreboard here",
    //         imgsrc: textLayoutScheme
    //     },
    //     {
    //         name: "Layout 2",
    //         description: "Description of the scoreboard here",
    //         imgsrc: styledLayoutScheme
    //     },
    //     {
    //         name: "Layout 3",
    //         description: "Description of the scoreboard here",
    //         imgsrc: texthourlyLayoutScheme
    //     },
    //     {
    //         name: "Layout 4",
    //         description: "Description of the scoreboard here",
    //         imgsrc: textparetoLayoutScheme
    //     },
    // ]

    const onClickNext = () => {
        props.onNext()
    }

    const showNextCarousel = (next, active) => {
        if (currentCarouselIndex < 3) {
            setCurrentCarouselIndex(currentCarouselIndex + 1)
        }
    }

    const showPrevCarousel = (prev, active) => {
        if (currentCarouselIndex > 0) {
            setCurrentCarouselIndex(currentCarouselIndex - 1)
        }
    }

    const getTemplateOptions = () => {
        let params = {
            path: 'get_scoreboard_template_names',
            success: fetchTemplateOptionsSuccess,
            error: () => {}
        };

        API.c(params);
    };

    const fetchTemplateOptionsSuccess = data => {
        setTemplates(data)
    };

    const getLayoutImage = (identifier) => {
        switch (identifier) {
            case "template_basic_text":
                return template_basic_text
            case "template_text_scoreboard_style":
                return template_text_scoreboard_style
            case "template_text_and_hourly":
                return template_text_and_hourly
            case "template_text_and_pareto":
                return template_text_and_pareto
            default:
                return null  
        }
    }

    useEffect(() => {
        getTemplateOptions()
    }, [])


    return (
        <Dialog
            open={true}
            onClose={props.onHide}
            PaperProps={{ sx: {maxWidth: "90vw !important"} }}
            sx={{ width: "90vw !important", maxWidth: "90vw !important", height: "470px !important", left: "5vw", top: "15%" }}
        >
            <DialogTitle style={{ display: "flex", justifyContent: "space-between", padding: "32px 32px 12px 32px" }} disableTypography>
                <Typography variant="h5">{`SELECT SCOREBOARD TYPE`}</Typography>
                <div
                    className="modal__content-title__close"
                    onClick={props.onHide}>
                    <FontIcon className="zmdi zmdi-close" style={{color: "rgba(0, 0, 0, 0.54)"}} />
                </div>
            </DialogTitle>
            <DialogContent sx={{ width: "79vw !important", maxWidth: "1140px !important", height: "470px !important" }}>
                <div style={{ display: "flex", justifyContent: "space-evenly", marginLeft: "0px", marginBottom: "24px" }}>
                    {templates && templates.length > 0 && templates.map((layout, i) => {
                        return (
                            <Paper onClick={() => {setSelectedLayout(layout.front_end_identifier)}} sx={selectedLayout === layout.front_end_identifier ? layoutSelectedStyle : layoutHoverStyle}>
                                <div style={{ width: "176px", marginTop: "16px" }}>
                                    <img src={getLayoutImage(layout.front_end_identifier)} />
                                    <Typography variant="h6" sx={{ margin: "32px 0px", textAlign: "center" }}> {layout.human_readable_name} </Typography>
                                    <Typography variant="h6" sx={{ fontSize: "20px", textAlign: "center" }}> {layout.description} </Typography>
                                </div>
                            </Paper>
                        )
                    })}
                </div>
                <DialogActions>
                    <Button variant="text"
                        color="primary"
                        onClick={props.onHide}
                    >
                            Cancel
                    </Button>
                    <Button variant="contained"
                        color="primary"
                        disabled={selectedLayout === null}
                        onClick={() => props.onNext(selectedLayout)}
                    >
                            Next
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ScoreboardLayoutModal