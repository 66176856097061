import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import colorPalette from '../../../Palette';


const UtilizationDayComponent = (props) => {

    const { time_from, time_to, factoryStartTime } = props;

    const convertTime = (time) => {
        
        let d = new Date();
        if (time) {
            let [hours, minutes, seconds] = time.split(':');
            d.setHours(+hours);
            d.setMinutes(minutes);
            d.setSeconds(seconds);
        }
        return d
    }

    // When Utilisation start time is before Factory start time then Utilisation end time cannot be after Factory start time of the next day.

    // When Utilisation times are overnight eg. The start time is 9:00pm and end time is 4:00am.
        //  Need to ensure that utilisation end time cannot be greater than factory start time which is 5:00am.

    const shouldDisableTime = (time, type, disabled) => {

        // console.log(time, type);

        if (disabled) {
            return false
        }

        const start = time_from
        const end = time_to
        const factory = factoryStartTime
        const factoryEnd = moment(convertTime(factoryStartTime)).add(23, 'hours').add(55, 'minutes').format('HH:mm:ss');
        time = moment(time).format('HH:mm:ss');

        // console.log(factory);
        // console.log(factoryEnd);
        // console.log("=====================================");

        if (type === "from") {
            if (time > end && time < factory) {
                return true
            }
            if (time > factory && factory > end) {
                return false;
            }
            if (time > end && time > factory) {
                return true;
            }
            if (time < factory && end > factory) {
                return true;
            }
        }
        else {
            // if (time < start && time < factory && start < factory) {
            //     return true;
            // }
            // if (time > factoryEnd && time < factory) {
            //     return true;
            // }
            // if (time.format("HH:mm:ss") === "06:00:00") {
            //     console.log(time, factoryEnd, time.isAfter(factoryEnd));
            // }
            if (time > factory && time < start) {
                return true;
            }
            if (start < factory && time > factoryEnd) {
                return true;
            }
            if (start < factory && time < start) {
                return true
            }
            if (start > factory && time > factoryEnd && time < factory) {
                return true;
            }
        }
    }

    return (
        <div style={{ marginBottom: "24px" }}>
            <FormGroup>
                <FormControlLabel control={<Switch disabled={!props.override} checked={props.checked} onChange={props.onChange} />} sx={{ color: !props.checked || !props.override ? colorPalette.neutral[60] : colorPalette.neutral[100] }} label={props.day} />
            </FormGroup>
            <div style={{ display: "flex", alignItems: "center", marginTop: "16px", gap: "28px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        slotProps={{ field: { shouldRespectLeadingZeros: true } }}
                        shouldRespectLeadingZeros={true}
                        format="LT"
                        id={`${props.type}-${props.day}-from`}
                        onAccept={props.handleTimeFromChange}
                        disabled={!props.checked || !props.override}
                        shouldDisableTime={(value) => shouldDisableTime(value, "from", !props.checked || !props.override)}
                        // ampm={false}
                        value={moment(convertTime(props.time_from))}
                        label="Start Time"
                    />
                </LocalizationProvider>
                <p>to</p>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                        slotProps={{ field: { shouldRespectLeadingZeros: true } }}
                        shouldRespectLeadingZeros={true}
                        format="LT"
                        id={`${props.type}-${props.day}-to`}
                        onAccept={props.handleTimeToChange}
                        disabled={!props.checked || !props.override}
                        shouldDisableTime={(value) => shouldDisableTime(value, "to", !props.checked || !props.override)}
                        // ampm={false}
                        value={moment(convertTime(props.time_to))}
                        label="End Time"
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}

export default UtilizationDayComponent;