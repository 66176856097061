
// size = 'small' | 'medium' | 'large' (default small)
// title = 'string' | null
// icon = 'component' | null
// tooltip = 'string' | null
// pareto = true | false
// paretoValue = 'string' | null
// buttonText = 'string' | null
// buttonCallback = function | null
// child component inside the card

import { Button, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import colorPalette from "../Palette.js";
import TooltipIcon from './icons/TooltipIcon.svg';
import PerPage from "./reports/per-page/per-page.js";

const ReportsCard = (props) => {

    const width = window.innerWidth;

    const { size, title, icon, tooltip, radio, radioValue, radioOne, radioTwo, buttonText, buttonCallback, perPageEnabled, perPageCallback } = props;
    const { cardStyles, topBoxStyles, topBoxLeftStyles, titleStyles, buttonStyles, mainBoxStyles, height } = props;
    const sx = {
        card: {
            width: `${size === 'large' ? '100%' : size === 'medium' ? '50%' : '33%'}`,
            height: `${ height ? height :  "525px"}`,
            borderRadius: '18px',
            border: '1px solid #E2E5EB',
            backgroundColor: 'transparent',
            boxShadow: "none",
            transition: 'all 0.6s ease',
        },
        topBox: {
            height: '48px',
            width: 'auto',
            backgroundColor: '#F6F8FA',
            padding: '6px 24px 6px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: "none"
        },
        topBoxLeft: {
            display: 'flex',
            backgroundColor: '#F6F8FA',
            alignItems: 'center',
            justifyContent: 'flex-start',
            boxShadow: "none"
        },
        title: {
            fontSize: `16px`,
            fontWeight: '600',
            color: colorPalette.neutral[100],
            alignSelf: 'center',
            marginLeft: "8px",
            marginRight: "6px",
        },
        button: {
            fontSize: '14px !important',
            fontWeight: '500 !important',
            color: 'rgba(17, 24, 39, 0.5) !important',
            display: 'flex !important',
            textTransform: "none !important",
            textDecoration: "underline !important",
            ":hover": {
                background: "none !important",
            }
        },
        mainBox: {
            height: '85%',
            width: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: "none"
        }
    }

    return (
        <Card sx={{...sx.card, ...cardStyles}}>
            <Card sx={{...sx.topBox, ...topBoxStyles}}>
                <Card sx={{...sx.topBoxLeft, ...topBoxLeftStyles}}>
                    {icon && <img style={{ width: `22px` }} src={icon} />}
                    <p style={{ ...sx.title, ...titleStyles }}>{title}</p>
                    {tooltip && (
                        <Tooltip arrow title={<p style={{fontSize: "12px", lineHeight: "16px", padding: "4px", whiteSpace: "pre-line"}}>{tooltip}</p>} placement="top">
                            <img style={{ width: `16px` }} src={TooltipIcon} />
                        </Tooltip>
                    )}
                    {props.customSubtitle ? (
                        <>
                            {props.customSubtitle}
                        </>
                    ) : null}
                </Card>
                {radio ? (
                    <div style={{ display: "flex", fontSize: `16px`, }}>
                        <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "24px", fontSize: `16px`, }}>
                            <FormLabel sx={{ fontSize: `16px`, color: `${colorPalette.neutral[60]} !important` }}>View by:</FormLabel>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                defaultValue={radioOne}
                                name="radio-buttons-group"
                                value={radioValue}
                                onChange={buttonCallback}
                                sx={{ display: "flex", fontSize: `16px`, flexDirection: "row", gap: "6px" }}
                            >
                                <FormControlLabel value={radioOne} sx={{ fontSize: `16px`, color: radioValue === radioOne ? colorPalette.primary[100] : colorPalette.neutral[60] }} control={<Radio size="small" />} label={<p style={{ fontSize: `16px` }}>{radioOne}</p>} />
                                <FormControlLabel value={radioTwo} sx={{ fontSize: `16px`, color: radioValue === radioTwo ? colorPalette.primary[100] : colorPalette.neutral[60] }} control={<Radio size="small" />} label={<p style={{ fontSize: `16px` }}>{radioTwo}</p>} />
                            </RadioGroup>
                        </FormControl>
                        {perPageEnabled ? (
                            <PerPage sendResponseToUpPerPage={perPageCallback} />
                        ) : null}
                    </div>
                ) : (
                    <>
                        {props.customButton ? (
                            <div style={{ display: 'flex', alignItems: "center" }}>
                                {props.customButton}
                            </div>
                        ) : (
                            <>
                                {buttonText && (
                                    <Button sx={{ ...sx.button, ...buttonStyles }} disableRipple onClick={buttonCallback} variant="text">
                                        {buttonText}
                                        {buttonCallback && (
                                            <ArrowOutwardIcon sx={{ marginLeft: '4px', fontSize: "18px", }} />
                                        )}
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                )}
            </Card>
            <Card sx={{ ...sx.mainBox, ...mainBoxStyles }}>
                {props.children}
            </Card>
        </Card>
    );
}

export default ReportsCard;