class API {
    /*
    Usage to call is like this:
    path is required.
    path_variables is optional, and used for replacement in paths, like 'claims/%claim_id%/add_commment'
    data is optional.

    params = {
      path: "claims"
      path_variables:
        claim_id: 1
      data:
        id: "xxx"
      success: @function
      error: @function
    }
    API.c(params)
  */

    constructor() {
        this.paths = {
            login: {
                path: 'accounts/login/',
                method: 'POST'
            },
            register_admin: {
                path: 'accounts/register/',
                method: 'POST'
            },
            register_operator: {
                path: 'operator',
                method: 'POST'
            },
            password_reset: {
                path: 'accounts/password/reset/',
                method: 'POST'
            },
            password_change: {
                path: 'accounts/password/',
                method: 'POST'
            },
            reset_password_confirm: {
                path: 'accounts/password/reset/confirm/',
                method: 'POST'
            },
            get_all_admins: {
                path: 'accounts/allusers/',
                method: 'GET'
            },
            get_all_operators: {
                path: 'operator',
                method: 'GET'
            },
            get_departments: {
                path: 'department',
                method: 'GET'
            },
            add_department: {
                path: 'department',
                method: 'POST'
            },
            edit_department: {
                path: 'department/%ID%',
                method: 'PUT'
            },
            delete_department: {
                path: 'department/%ID%',
                method: 'DELETE'
            },
            me: {
                path: 'accounts/me/',
                method: 'GET'
            },
            update_user: {
                path: 'accounts/me/',
                method: 'PUT'
            },
            update_operator: {
                path: 'operator/%operator_id%',
                method: 'PUT'
            },
            delete_admin: {
                path: 'accounts/user/%admin_id%',
                method: 'DELETE'
            },
            delete_operator: {
                path: 'operator/%operator_id%',
                method: 'DELETE'
            },
            toggle_user: {
                path: 'accounts/user/%user_id%',
                method: 'PUT'
            },
            liveview_all: {
                // path: "web/liveview/all",
                path: 'basic/liveview/all',
                method: 'GET'
            },
            liveview_info: {
                path: 'web/liveview/line/%line_id%/detail',
                method: 'GET'
            },
            report: {
                // path: "reporting/line/range",
                path: 'basic/reporting/line/range',
                method: 'GET'
            },
            report_weekly: {
                path: 'basic/reporting/weekly',
                method: 'GET'
            },
            report_weekly_cache: {
                path: 'basic/reporting/weekly/cache',
                method: 'GET'
            },
            run_data: {
                path: 'reporting/daily/run/%run_id%',
                method: 'GET'
            },
            get_batch_details: {
                path: 'batch/%batch_id%/detail',
                method: 'GET'
            },
            add_batch_item: {
                path: 'batchingredient',
                method: 'POST'
            },
            delete_batch_item: {
                path: 'batchingredient/%batch_ingredient_id%',
                method: 'DELETE'
            },
            edit_batch_item: {
                path: 'batchingredient/%batch_ingredient_id%',
                method: 'PUT'
            },
            add_weight: {
                path: 'run/%RUN_ID%/addweighttolastbatch',
                method: 'PUT'
            },
            remove_weight: {
                path: 'run/%RUN_ID%/batchnumber/%batch_number%/removeweight',
                method: 'PUT'
            },
            get_pallet_details: {
                path: 'run/%RUN_ID%/palletnumber/%PALLET_NUMBER%/details',
                method: 'GET'
            },
            edit_output: {
                path: 'output/%OUTPUT_ID%',
                method: 'PUT'
            },
            delete_output: {
                path: 'output/%OUTPUT_ID%',
                method: 'DELETE'
            },
            add_output: {
                path: 'output',
                method: 'POST'
            },
            add_downtime: {
                path: 'downtime',
                method: 'POST'
            },
            add_waste: {
                path: 'waste',
                method: 'POST'
            },
            add_rework: {
                path: 'rework',
                method: 'POST'
            },
            delete_output_event: {
                path: 'output/%ID%',
                method: 'DELETE'
            },
            delete_downtime_event: {
                path: 'downtime/%ID%',
                method: 'DELETE'
            },
            delete_waste: {
                path: 'waste/%ID%',
                method: 'DELETE'
            },
            add_finished_goods: {
                path: 'finishedgoods',
                method: 'POST'
            },
            delete_rework: {
                path: 'rework/%ID%',
                method: 'DELETE'
            },
            delete_finished_goods: {
                path: 'finishedgoods/%ID%',
                method: 'DELETE'
            },
            edit_finished_goods: {
                path: 'finishedgoods/%ID%',
                method: 'PUT'
            },
            edit_downtime_event: {
                path: 'downtime/%ID%',
                method: 'PUT'
            },
            edit_waste: {
                path: 'waste/%ID%',
                method: 'PUT'
            },
            edit_rework: {
                path: 'rework/%ID%',
                method: 'PUT'
            },
            add_pallet: {
                path: 'pallet',
                method: 'POST'
            },
            add_batch: {
                path: 'batch',
                method: 'POST'
            },
            get_raw_material: {
                path: 'rawmaterial',
                method: 'GET'
            },
            edit_transfer: {
                path: 'run/%RUN_ID%/edittransfer',
                method: 'PUT'
            },
            delete_transfer: {
                path: 'run/%RUN_ID%/removetransfer',
                method: 'DELETE'
            },
            batch_lookup: {
                path: 'batchrun/filter',
                method: 'GET'
            },
            update_run: {
                path: 'run/%RUN_ID%',
                method: 'PUT'
            },
            all_products: {
                path: 'product/names',
                method: 'GET'
            },
            all_tags: {
                path: 'producttag',
                method: 'GET'
            },
            add_tag: {
                path: 'producttag',
                method: 'POST'
            },
            delete_tag: {
                path: 'producttag/%ID%',
                method: 'DELETE'
            },
            edit_tag: {
                path: 'producttag/%ID%',
                method: 'PUT'
            },
            all_categories: {
                path: 'productcategory',
                method: 'GET'
            },
            add_category: {
                path: 'productcategory',
                method: 'POST'
            },
            delete_category: {
                path: 'productcategory/%ID%',
                method: 'DELETE'
            },
            edit_category: {
                path: 'productcategory/%ID%',
                method: 'PUT'
            },
            line_products: {
                path: 'line/%ID%/products',
                method: 'GET'
            },
            delete_auto_start: {
                path: 'run/autostart/%ID%',
                method: 'DELETE'
            },
            delete_auto_end: {
                path: 'run/autoend/%ID%',
                method: 'DELETE'
            },
            products: {
                path: 'product',
                method: 'GET'
            },
            // Новые
            shift: {
                path: 'shift/?%LINE_IDS%',
                method: 'GET'
            },
            shift_for_run: {
                path: 'run_shift/', //run_shift/?run_id=&date=
                method: 'GET'
            },
            get_shift: {
                path: 'shift/',
                method: 'GET'
            },
            add_shift: {
                path: 'shift/',
                method: 'POST'
            },
            update_shift: {
                path: 'shift/%ID%/',
                method: 'PUT'
            },
            delete_shift: {
                path: 'shift/%ID%',
                method: 'DELETE'
            },
            update_shift_cache: {
                path: 'shiftcache/update/',
                method: 'GET'
            },
            all_reason_categories: {
                path: "reasoncategory",
                method: 'GET'
            },
            add_reason_category: {
                path: 'reasoncategory',
                method: 'POST'
            },
            edit_reason_category: {
                path: 'reasoncategory/%ID%',
                method: 'PUT'
            },
            delete_reason_category: {
                path: 'reasoncategory/%ID%',
                method: 'DELETE'
            },
            get_features_check: {
                path: 'features/check',
                method: 'GET'
            },
            //
            add_product: {
                path: 'product',
                method: 'POST'
            },
            update_product: {
                path: 'product/%PRODUCT_ID%',
                method: 'PUT'
            },
            product_details: {
                path: 'web/settings/product/%PRODUCT_ID%',
                method: 'GET'
            },
            updatebatchoutput: {
                path: 'updatebatchoutput/%OUTPUT_ID%',
                method: 'PUT'
            },
            createbatchoutput: {
                path: 'createbatchoutput',
                method: 'POST'
            },
            giveaway_details: {
                path: 'run/%RUN_ID%/location/%LOCATION_ID%/giveaway/detail',
                method: 'GET'
            },
            update_giveaway: {
                path: 'giveaway',
                method: 'PUT'
            },
            delete_giveaway: {
                path: 'giveaway/%GIVEAWAY_ID%',
                method: 'DELETE'
            },
            move_weight: {
                path:
                    'batchrunsource/%RUN_ID_SOURCE%/batchnumbersource/%BATCH_NUMBER_SOURCE%/moveto/batchrundestination/%RUN_ID_DESTINATION%/batchnumberdestination/%BATCH_NUMBER_DESTINATION%',
                method: 'PUT'
            },
            last_production_day: {
                path: 'basic/reporting/daily/lastproductionday',
                method: 'GET'
            },
            add_recipe_ingredient: {
                path: 'recipeingredient',
                method: 'POST'
            },
            update_recipe_ingredient: {
                path: 'recipeingredient/%RECIPE_INGREDIENT_ID%',
                method: 'PUT'
            },
            delete_recipe_ingredient: {
                path: 'recipeingredient/%ID%',
                method: 'Delete'
            },
            get_product_speed: {
                path: 'productspeed',
                method: 'GET'
            },
            add_product_speed: {
                path: 'productspeed',
                method: 'POST'
            },
            update_product_speed: {
                path: 'productspeed/%PRODUCT_SPEED_ID%',
                method: 'PUT'
            },
            update_product_line_info: {
                path: 'web/settings/line/%LINE_ID%/product/%PRODUCT_ID%',
                method: 'PUT'
            },
            add_giveaway_target: {
                path: 'giveawaytarget',
                method: 'POST'
            },
            update_giveaway_target: {
                path: 'giveawaytarget/%GIVEAWAY_TARGET_ID%',
                method: 'PUT'
            },
            get_lines: {
                path: 'line',
                method: 'GET'
            },
            add_line: {
                path: 'line',
                method: 'POST'
            },
            get_line_details: {
                path: 'web/settings/line/%LINE_ID%',
                method: 'GET'
            },
            delete_line: {
                path: 'line/%ID%',
                method: 'DELETE'
            },
            edit_line: {
                path: 'line/%ID%',
                method: 'PUT'
            },
            get_emailed_reports_daily: {
                path: 'emailedreport/daily',
                method: 'GET'
            },
            get_emailed_reports_weekly: {
                path: 'emailedreport/weekly',
                method: 'GET'
            },
            get_emailed_reports_monthly: {
                path: 'emailedreport/monthly',
                method: 'GET'
            },
            get_emailed_reports_custom: {
                path: 'emailedreport/custom',
                method: 'GET'
            },
            add_emailed_report_daily: {
                path: 'emailedreport/daily',
                method: 'POST'
            },
            add_emailed_report_weekly: {
                path: 'emailedreport/weekly',
                method: 'POST'
            },
            add_emailed_report_monthly: {
                path: 'emailedreport/monthly',
                method: 'POST'
            },
            add_emailed_report_custom: {
                path: 'emailedreport/custom',
                method: 'POST'
            },
            get_emailed_report_daily: {
                path: 'emailedreport/daily/%REPORT_ID%',
                method: 'GET'
            },
            get_emailed_report_weekly: {
                path: 'emailedreport/weekly/%REPORT_ID%',
                method: 'GET'
            },
            get_emailed_report_monthly: {
                path: 'emailedreport/monthly/%REPORT_ID%',
                method: 'GET'
            },
            get_emailed_report_custom: {
                path: 'emailedreport/custom/%REPORT_ID%',
                method: 'GET'
            },
            delete_emailed_report_daily: {
                path: 'emailedreport/daily/%REPORT_ID%',
                method: 'DELETE'
            },
            delete_emailed_report_weekly: {
                path: 'emailedreport/weekly/%REPORT_ID%',
                method: 'DELETE'
            },
            delete_emailed_report_monthly: {
                path: 'emailedreport/monthly/%REPORT_ID%',
                method: 'DELETE'
            },
            delete_emailed_report_custom: {
                path: 'emailedreport/custom/%REPORT_ID%',
                method: 'DELETE'
            },
            edit_emailed_report_daily: {
                path: 'emailedreport/daily/%REPORT_ID%',
                method: 'PUT'
            },
            edit_emailed_report_weekly: {
                path: 'emailedreport/weekly/%REPORT_ID%',
                method: 'PUT'
            },
            edit_emailed_report_monthly: {
                path: 'emailedreport/monthly/%REPORT_ID%',
                method: 'PUT'
            },
            edit_emailed_report_custom: {
                path: 'emailedreport/custom/%REPORT_ID%',
                method: 'PUT'
            },
            get_emailed_report_history_daily: {
                path: 'reporthistory/daily/%REPORT_ID%',
                method: 'GET'
            },
            get_emailed_report_history_weekly: {
                path: 'reporthistory/weekly/%REPORT_ID%',
                method: 'GET'
            },
            get_emailed_report_history_monthly: {
                path: 'reporthistory/monthly/%REPORT_ID%',
                method: 'GET'
            },
            get_emailed_report_history_custom: {
                path: 'reporthistory/custom/%REPORT_ID%',
                method: 'GET'
            },
            change_emailed_report_type: {
                path: 'emailedreports/deletereportandchangereporttype',
                method: 'PUT'
            },
            delete_product: {
                path: 'product/%ID%',
                method: 'DELETE'
            },
            get_downtime_reasons: {
                path: 'web/settings/reason/downtime',
                method: 'GET'
            },
            get_reasons: {
                path: 'web/settings/reason',
                method: 'GET'
            },
            get_downtime_reason_details: {
                path: 'web/settings/reason/%ID%',
                method: 'GET'
            },
            delete_reason: {
                path: 'reason/%ID%',
                method: 'DELETE'
            },
            update_downtime_reason: {
                path: 'web/settings/reason/%ID%',
                method: 'PUT'
            },
            add_downtime_reason: {
                path: 'reason',
                method: 'POST'
            },
            rank_lines: {
                path: 'web/settings/line/rank',
                method: 'PUT'
            },
            delete_run: {
                path: 'run/%ID%',
                method: 'DELETE'
            },
            add_closed_run: {
                path: 'run',
                method: 'POST'
            },
            add_batch_run_expected: {
                path: '/batchrun/expectednumberbatches/%NUMBER_OF_BATCHES%',
                method: 'POST'
            },
            add_batch_run: {
                path: '/batchrun',
                method: 'POST'
            },
            edit_downtime: {
                path: '/downtime/%ID%',
                method: 'PUT'
            },
            delete_downtime: {
                path: '/downtime/%ID%',
                method: 'DELETE'
            },
            export_downtime: {
                path: 'export/downtime/daterange/excel',
                method: 'GET'
            },
            export_run_details: {
                path: 'export/daterange/excel',
                method: 'GET'
            },
            get_productsettings_log: {
                path: 'productsettings/log',
                method: 'POST'
            },
            get_rundetails_log: {
                path: 'rundetails/log',
                method: 'POST'
            },
            get_outputdetails_log: {
                path: 'outputdetails/log',
                method: 'POST'
            },
            get_reworkdetails_log: {
                path: 'reworkdetails/log',
                method: 'POST'
            },
            get_wastedetails_log: {
                path: 'wastedetails/log',
                method: 'POST'
            },
            get_downtimedetails_log: {
                path: 'downtimedetails/log',
                method: 'POST'
            },
            get_autostart_log: {
                path: 'autostart/log',
                method: 'POST'
            },
            get_autoend_log: {
                path: 'autoend/log',
                method: 'POST'
            },
            get_lineattributes_log: {
                path: 'lineattributes/log',
                method: 'POST'
            },
            get_productattributes_log: {
                path: 'productattributes/log',
                method: 'POST'
            },
            get_log: {
                path: 'run/%RUN_ID%/log',
                method: 'GET'
            },
            get_log_shift: {
                path: 'run/%RUN_ID%/log?%EVENT%=%TOGGLE%&%SHIFT_ID%',
                method: 'GET'
            },
            get_last_edit: {
                path: 'log/%PAGE_NAME%/lastedit',
                method: 'GET'
            },
            get_last_edit_product: {
                path: 'lastedit/product/%PRODUCT_ID%',
                method: 'GET'
            },
            get_last_edit_run: {
                path: 'lastedit/run/%RUN_ID%',
                method: 'GET'
            },
            get_last_edit_line: {
                path: 'lastedit/line/%LINE_ID%',
                method: 'GET'
            },
            get_line_reasons: {
                path: 'line/%LINE_ID%/reason',
                method: 'GET'
            },
            get_speed_changes: {
                path: 'run/%RUN_ID%/speedchanges%SHIFT_ID%', //%SHIFT_ID%
                method: 'GET'
            },
            check_run_update: {
                path: 'run/%RUN_ID%/changes',
                method: 'GET'
            },
            confirm_move_run_data: {
                path: 'run/%RUN_ID%/move/changes',
                method: 'GET'
            },
            move_run_data: {
                path: 'run/%RUN_ID%/move',
                method: 'PUT'
            },
            get_previous_and_next_on_line: {
                path: 'run/%RUN_ID%/previousandnextonline',
                method: 'GET'
            },
            confirm_split_run_data: {
                path: 'run/%RUN_ID%/split/changes',
                method: 'GET'
            },
            split_run: {
                path: 'run/%RUN_ID%/split',
                method: 'PUT'
            },
            get_factory_settings: {
                path: 'factory',
                method: 'GET'
            },
            update_factory_settings: {
                path: 'factory',
                method: 'PUT'
            },
            get_individual_issue: {
                path: 'basic/reporting/weekly/losttime',
                method: 'GET'
            },
            get_organizations: {
                path: 'auth0/me/search/organization',
                method: 'GET'
            },
            get_users_by_org_or_factory: {
                path: 'auth0/user/search',
                method: 'GET'
            },
            add_user_to_org: {
                path: 'auth0/user/create',
                method: 'POST'
            },
            edit_user_in_org: {
                path: 'auth0/user/%ID%',
                method: 'PUT'
            },
            get_editable_roles: {
                path: 'auth0/user/roles',
                method: 'GET'
            },
            force_auth0_sso_logout: {
                path: 'https://livetracking.auth0.com/v2/logout',
                method: 'GET'
            },
            get_units: {
                path: 'unit',
                method: 'GET'
            },
            add_unit: {
                path: 'unit',
                method: 'POST'
            },
            edit_unit: {
                path: 'unit/%ID%',
                method: 'PUT'
            },
            archive_unit: {
                path: 'unit/%ID%',
                method: 'DELETE'
            },
            edit_line_units_defaults: {
                path: 'web/settings/unit/line/%LINE_ID%/default',
                method: 'POST'
            },
            get_product_on_line_units: {
                path: 'settings/unit/line/%LINE_ID%/product/%PRODUCT_ID%',
                method: 'GET'
            },
            get_multi_day_report: {
                path: 'basic/reporting/line/multidayselection',
                method: 'POST'
            },
            get_reason_runs: {
                path: 'basic/reporting/daily/drilldown/downtime',
                method: 'GET'
            },
            get_trend_reason_runs: {
                path: 'basic/reporting/trend/downtime',
                method: 'GET'
            },
            get_reason_category_runs: {
                path: 'basic/reporting/daily/drilldown/downtime/reasoncategory',
                method: 'GET'
            },
            create_run: {
                path: 'line/%LINE_ID%/createrun',
                method: 'POST'
            },
            end_run: {
                path: 'endrun/%RUN_ID%',
                method: 'PUT'
            },
            get_downtime_notifications: {
                path: 'notifications/downtime',
                method: 'GET'
            },
            get_start_run_notifications: {
                path: 'notifications/startrun',
                method: 'GET'
            },
            get_end_run_notifications: {
                path: 'notifications/endrun',
                method: 'GET'
            },
            create_downtime_notification: {
                path: 'notifications/downtime',
                method: 'POST'
            },
            create_start_run_notification: {
                path: 'notifications/startrun',
                method: 'POST'
            },
            create_end_run_notification: {
                path: 'notifications/endrun',
                method: 'POST'
            },
            edit_downtime_notification: {
                path: 'notifications/downtime/%ID%',
                method: 'PUT'
            },
            edit_start_run_notification: {
                path: 'notifications/startrun/%ID%',
                method: 'PUT'
            },
            edit_end_run_notification: {
                path: 'notifications/endrun/%ID%',
                method: 'PUT'
            },
            subscribe_downtime_notification: {
                path: 'notifications/downtime/%ID%/subscribe',
                method: 'PUT'
            },
            unsubscribe_downtime_notification: {
                path: 'notifications/downtime/%ID%/unsubscribe',
                method: 'PUT'
            },
            subscribe_start_run_notification: {
                path: 'notifications/startrun/%ID%/subscribe',
                method: 'PUT'
            },
            unsubscribe_start_run_notification: {
                path: 'notifications/startrun/%ID%/unsubscribe',
                method: 'PUT'
            },
            subscribe_end_run_notification: {
                path: 'notifications/endrun/%ID%/subscribe',
                method: 'PUT'
            },
            unsubscribe_end_run_notification: {
                path: 'notifications/endrun/%ID%/unsubscribe',
                method: 'PUT'
            },
            get_all_scoreboard_templates: {
                path: 'scoreboard',
                method: 'GET'
            },
            get_scoreboard_template_names: {
                path: 'scoreboardtemplatename',
                method: 'GET'
            },
            get_scoreboard_template_kpis: {
                path: 'scoreboardtemplate/%ID%',
                method: 'GET'                
            },
            get_scoreboard_kpi_names: {
                path: 'metric',
                method: 'GET'
            },
            create_scoreboard_template: {
                path: 'scoreboard',
                method: 'POST'
            },
            edit_scoreboard_template: {
                path: 'scoreboard/%ID%',
                method: 'PUT'
            },
            delete_scoreboard_template: {
                path: 'scoreboard/%ID%',
                method: 'DELETE'
            },
            get_product_import_history: {
                path: 'productimporthistory',
                method: 'GET'
            },
            get_product_export_scv: {
                path: 'export/products',
                method: 'GET'
            },
            validate_product_import: {
                path: 'import/validate',
                method: 'POST'
            },
            product_import: {
                path: 'import/products',
                method: 'POST'
            },
            rebase_line_run_data: {
                path: 'rebaselinerundata/line/%LINE_ID%/product/%PRODUCT_ID%',
                method: 'PUT'
            },
            get_utilization_settings: {
                path: 'lineutilizationsetting',
                method: 'GET'
            },
            update_utilization_settings: {
                path: 'lineutilizationsetting',
                method: 'POST'
            },
            get_trend_report: {
                path: 'basic/reporting/trend',
                method: 'POST'
            }
        };
    }

    /*    server(params) {
        let factory = window.localStorage.getItem('factory');
        console.log(factory);
        //let url = 'https://livetrackingtesting.livetrackingsystems.com';

        let url = 'http://livetrackingtest-dteam.ca-central-1.elasticbeanstalk.com/';
        // let url = 'http://livetrackingsandbox-env.eba-7gz2ij39.us-east-1.elasticbeanstalk.com/';
        // url = url.replace(/^https:\/\//i, 'http://');
        return url.endsWith('/') ? url : url + '/';
    }*/
    server(params) {
        let factory = window.localStorage.getItem('factory');
        if (!factory) {
            return ' ';
        } else {
            let url = JSON.parse(factory).url;
            return url.endsWith('/') ? url : url + '/';
        }
        // url = url.replace(/^http:\/\//i, 'https://');
    }

    method(params) {
        return this.paths[params.path].method;
    }

    path(params) {
        this.p = '';
        if (params.path === 'force_auth0_sso_logout') {
            this.p = this.paths[params.path].path;
        } else {
            this.p = this.server(params) + this.paths[params.path].path;
        }

        for (let key in params.path_variables) {
            let value = params.path_variables[key];
            this.p = this.p.replace('%' + key + '%', value);
        }
        if (
            this.paths[params.path].method === 'GET' &&
            this.paths[params.path].path !== 'shift/' &&
            this.paths[params.path].path !== 'shift/?%LINE_IDS%' &&
            this.paths[params.path].path !== 'reporting/daily/run/%run_id%' &&
            this.paths[params.path].path !== 'run/%RUN_ID%/speedchanges%SHIFT_ID%' &&
            this.paths[params.path].path !== 'run/%RUN_ID%/log?%EVENT%=%TOGGLE%&%SHIFT_ID%'
        ) {
            this.p = this.p + this.dataAsGet(params.data);
        }
        return this.p;
    }

    dataAsGet(data) {
        this.d = '?';
        let c = 0;

        if (data === undefined) {
            return this.d;
        }

        for (let key in data) {
            if (data[key] != null) {
                if (data[key] instanceof Array) {
                    for (let k = 0; k < data[key].length; k++) {
                        if (c > 0) {
                            this.d += '&';
                        }
                        let value = data[key][k];
                        this.d += `${key}=${encodeURIComponent(value)}`;
                        c++;
                    }
                } else {
                    if (c > 0) {
                        this.d += '&';
                    }

                    let value = data[key];
                    this.d += `${key}=${encodeURIComponent(value)}`;
                    c++;
                }
            }
        }

        return this.d;
    }

    data(params) {
        let data;

        if (params.data != null) {
            data = params.data;
        } else {
            data = {};
        }

        return data;
    }

    processData(params) {
        if (params.path == 'update_user') {
            return false;
        } else {
            return '';
        }
    }

    contentType(params) {
        if (params.path == 'update_user') {
            return false;
        } else {
            return '';
        }
    }

    c(params, json = false, csv = false, fd = false) {
        if (!this.paths[params.path]) {
            console.error("PATH WITH KEY '" + params.path + "' NOT FOUND");
            return;
        }

        let formData = new FormData();
        let data = {};
        for (let prop in params.data) {
            formData.append(prop, params.data[prop]);
            data[prop] = params.data[prop];
        }

        let data2 = params.data;
        let config = {
            method: this.method(params),
            mode: 'no-cors',
            cache: 'default',
            body: formData
        };

        let xhr = new XMLHttpRequest();

        xhr.open(this.method(params), this.path(params), true);

        xhr.responseType = 'json';

        if (fd) {
            // xhr.setRequestHeader('Content-Type', 'multipart/form-data')
            // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            // xhr.setRequestHeader("Content-Type", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        }
        else if (json) {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
        else if (csv) {
            xhr.setRequestHeader('Content-Type', 'text/csv');
            xhr.responseType = 'blob';
        }

        // if (Global.getCookie("auth_token")) {
        //     xhr.setRequestHeader("Authorization", "Token " + Global.getCookie("auth_token"));
        // }
        xhr.onload = () => {
            let response = xhr.response;
            let header = null;
            if (xhr.status.toString().indexOf('2', 0) == 0) {
                if (typeof response == 'object') {
                    params.success(response, xhr.getAllResponseHeaders());
                } else {
                    if (response.length > 0) {
                        if (csv) {
                            params.success(response, xhr.getAllResponseHeaders());
                        }
                        else {
                            params.success(JSON.parse(response), xhr.getAllResponseHeaders());
                        }
                    } else {
                        params.success(response, xhr.getAllResponseHeaders());
                    }
                }
            } else {
                params.error(response);
            }
        };

        xhr.onerror = () => {
            params.error(xhr.statusText);
        };

        let idToken = window.localStorage.getItem('idToken');
        if (idToken) {
            let factory = window.localStorage.getItem('factory');
            if (factory != null) {
                factory = JSON.parse(factory);
                xhr.setRequestHeader('FACTORY-ID', factory.id);
            }

            if (Auth0.isAuthenticated()) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + idToken);
                if (fd == true) {
                    xhr.send(data2)
                }
                else if (json == false) {
                    xhr.send(formData);
                } else if (json == 2) {
                    xhr.send(JSON.stringify(data2));
                } else {
                    xhr.send(JSON.stringify(data));
                }
            } else {
                Auth0.refreshToken((err, authResult) => {
                    if (authResult) {
                        Auth0.setSession(authResult);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + authResult.idToken);
                        if (fd == true) {
                            xhr.send(data2)
                        }
                        else if (json == false) {
                            xhr.send(formData);
                        } else if (json == 2) {
                            xhr.send(JSON.stringify(data2));
                        } else {
                            xhr.send(JSON.stringify(data));
                        }
                    } else {
                        window.location.pathname = '/login';
                    }
                    // Renewed tokens or error
                });
            }
        }
    }
}

export default new API();
