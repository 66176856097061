import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatToHHhMMmSSs, formatToHhMmSs } from "../../newUtils/timeFormatter";
import colorPalette from "../../Palette";

const WasteParetoChart = (props) => {

    const [xAxisLabels, setXAxisLabels] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);

    useEffect(() => {

        let labels = [];
        let data = [];
    
        if (props.report && props.report.lost_time_waste_list) {
            props.report.lost_time_waste_list.forEach((d, i) => {
                if (i <= 4) {
                    labels.push(d.reason_name);
                    data.push(d.lost_time_percent);
                }
            });
        }

        setXAxisLabels(labels);
        setXAxisData(data);
    }, [props])

    const getMaxValue = (data) => {
        let max = 0;
        data.forEach((d) => {
            if (parseInt(d) > max) {
                max = parseInt(d);
            }
        });
        if (max === 0) {
            return 5;
        }
        if (max < 5) {
            return max * 2.5;
        }
        return max * 1.5;
    }

    return (
        <div style={{ width: "96%", paddingLeft: "0px", height: "80%", paddingTop: "0px", display: "flex", justifyContent: "center", position: "relative" }}>
            <Bar
                plugins={[ChartDataLabels]}
                data={{
                    labels: xAxisLabels,
                    datasets: [{
                        label: 'Reasons',
                        data: xAxisData,
                        backgroundColor: colorPalette.error[60],
                        hoverBackgroundColor: colorPalette.error[60],
                        borderRadius: {
                            topLeft: 4,
                            topRight: 4
                        }
                    }]
                }}
                options={{
                    resizeDelay: 200,
                    onHover: (event, chartElement) => {
                        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                    },
                    interaction: {
                        intersect: false,
                        mode: "x"
                    },
                    maintainAspectRatio: false,
                    scales : {
                        x: {
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            suggestedMax: getMaxValue(xAxisData),
                            title: {
                                display: true,
                                text: "% of Total",
                                padding: {
                                    bottom: 24        
                                }
                            },
                            grid: {
                                color: "#E2E4E9"
                            },
                            border: {
                                dash: [4, 5]
                            },
                            ticks: {
                                callback: function(value, index, ticks) {
                                    return value + "%";
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            clamp: true,
                            formatter: (value) => `${value.toFixed(1)}%`,
                            font: {
                              weight: 'bold',
                              size: `${20 / 19.2 * window.innerWidth / 100}px`
                            },
                            color: "#000"
                        },
                        tooltip: {
                            backgroundColor: "#FFF",
                            multiKeyBackground: "#fff",
                            titleColor: "#111827",
                            titleFont: {
                                weight: 'bold',
                                size: "14px",
                            },
                            bodyColor: "#666666",
                            bodyFont: {
                                weight: 'normal',
                                // size: "14px",
                            },
                            boxWidth: 4,
                            boxPadding: 4,
                            borderColor: "rgba(122, 122, 122, 0.2)",
                            borderWidth: 1,
                            callbacks: {
                                title: function(context) {
                                    return `Reason: ${context[0].label.replaceAll(",", "")}`;
                                },
                                beforeTitle: function(context) {
                                    return props.report.lost_time_waste_list[context[0].dataIndex].reason_category ? `Category: ${props.report.lost_time_waste_list[context[0].dataIndex].reason_category}` : "Category: No category";
                                },
                                label: function(context) {
                                    return `Downtime: ${formatToHhMmSs(props.report.lost_time_waste_list[context.dataIndex].lost_time_seconds)}`;
                                }
                            }
                        }
                    }
                }}
            />
        </div>
    )
}

export default WasteParetoChart;