import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';

const displayBasicFourLines = (lines, onChangeData, templateInfo, metrics, inputData, templateError) => {
    const [data, setData] = useState(inputData)

    useEffect(() => {
        setData(inputData)
    }, [inputData])

    const getError = (cell, position) => {
        let error = false;
        let selectedKpi = data && data[cell] && data[cell][`position${position}`]
        if (data && data.length > 0 && data[cell]) {
            for (const [key, value] of Object.entries(data[cell])) {
                if (typeof key === "string" && key !== `position${position}`) {
                    if (value && selectedKpi && value === selectedKpi) {
                        error = true
                    }
                }
            }
        }
        return error
    }

    const getKpiError = (cell, position) => {
        if (data && data.length > 0 && data[cell] && !data[cell][`position${position}`]) {
            return true
        }
        return false
    }

    const getLineError = (cell) => {
        if (data && data.length > 0 && data[cell] && !data[cell].line) {
            return true
        }
        return false
    }



    return (
        <div>
            <div style={{ display: "flex", borderBottom: "1px solid #d3d3d3" }}>
                <div style={{ borderRight: "1px solid #d3d3d3" }}>
                    <div>
                        <FormControl error={templateError && getLineError(0)} variant="standard" sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[0] && data[0].line ? true : false} sx={{ fontWeight: "600" }}>Select Line</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"0-line"}
                                value={data[0] && data[0].line ? data[0].line : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                                sx={{ fontWeight: "600" }}
                            >
                                {lines.map((line, i) => {
                                    return (
                                        <MenuItem value={line.id}>{line.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(0, 1) || (templateError && getKpiError(0, 1))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[0] && data[0].position1 ? true : false}>Select KPI #1</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"0-position1"}
                                value={data[0] && data[0].position1 ? data[0].position1 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position1 ? templateInfo.position1.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(0, 2) || (templateError && getKpiError(0, 2))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[0] && data[0].position2 ? true : false}>Select KPI #2</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"0-position2"}
                                value={data[0] && data[0].position2 ? data[0].position2 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position2 ? templateInfo.position2.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div>
                    <div>
                        <FormControl error={templateError && getLineError(1)} variant="standard" sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[1] && data[1].line ? true : false} sx={{ fontWeight: "600" }}>Select Line</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"1-line"}
                                value={data[1] && data[1].line ? data[1].line : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                                sx={{ fontWeight: "600" }}
                            >
                                {lines.map((line, i) => {
                                    return (
                                        <MenuItem value={line.id}>{line.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(1, 1) || (templateError && getKpiError(1, 1))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[1] && data[1].position1 ? true : false}>Select KPI #1</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"1-position1"}
                                value={data[1] && data[1].position1 ? data[1].position1 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position1 ? templateInfo.position1.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(1, 2) || (templateError && getKpiError(1, 2))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[1] && data[1].position2 ? true : false}>Select KPI #2</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"1-position2"}
                                value={data[1] && data[1].position2 ? data[1].position2 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position2 ? templateInfo.position2.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{borderRight: "1px solid #d3d3d3" }}>
                    <div>
                        <FormControl error={templateError && getLineError(2)} variant="standard" sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[2] && data[2].line ? true : false} sx={{ fontWeight: "600" }}>Select Line</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"2-line"}
                                value={data[2] && data[2].line ? data[2].line : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                                sx={{ fontWeight: "600" }}
                            >
                                {lines.map((line, i) => {
                                    return (
                                        <MenuItem value={line.id}>{line.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(2, 1) || (templateError && getKpiError(2, 1))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[2] && data[2].position1 ? true : false}>Select KPI #1</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"2-position1"}
                                value={data[2] && data[2].position1 ? data[2].position1 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position1 ? templateInfo.position1.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(2, 2) || (templateError && getKpiError(2, 2))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[2] && data[2].position2 ? true : false}>Select KPI #2</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"2-position2"}
                                value={data[2] && data[2].position2 ? data[2].position2 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position2 ? templateInfo.position2.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div>
                    <div>
                        <FormControl error={templateError && getLineError(3)} variant="standard" sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[3] && data[3].line ? true : false} sx={{ fontWeight: "600" }}>Select Line</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"3-line"}
                                value={data[3] && data[3].line ? data[3].line : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                                sx={{ fontWeight: "600" }}
                            >
                                {lines.map((line, i) => {
                                    return (
                                        <MenuItem value={line.id}>{line.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(3, 1) || (templateError && getKpiError(3, 1))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[3] && data[3].position1 ? true : false}>Select KPI #1</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"3-position1"}
                                value={data[3] && data[3].position1 ? data[3].position1 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position1 ? templateInfo.position1.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl variant="standard" error={getError(3, 2) || (templateError && getKpiError(3, 2))} sx={{ m: 1, minWidth: 200, maxWidth: 200 }}>
                            <InputLabel shrink={data[3] && data[3].position2 ? true : false}>Select KPI #2</InputLabel>
                            <Select
                                id="number-of-lines"
                                name={"3-position2"}
                                value={data[3] && data[3].position2 ? data[3].position2 : null}
                                onChange={(e) => onChangeData("basicData", e.target.value, e.target.name)}
                                label="Select Line"
                            >
                                {templateInfo && templateInfo.position2 ? templateInfo.position2.map((kpi, i) => {
                                    return (
                                        <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                    )
                                }) : null}
                                {/* <MenuItem value={1}>One</MenuItem> */}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default displayBasicFourLines