// title = string
// type = "text" | "rangeDate"
// selectedArray = [name: string]
// isOpen = boolean
// callback = function

import { Card } from "@mui/material";
import colorPalette from "../Palette";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Filter = (props) => {

    const { title, type, selectedArray, isOpen, displayAll, onClick } = props;
    let charCount = 0;
    let left = selectedArray.length;
    

    const sx = {
        mainCard: {
            width: `${type === "rangeDate" ? `325px` : selectedArray.length <= 0 ? `180px` : "106%"}`,
            minWidth: `${displayAll ? `168px` : selectedArray.length <= 0 ? `190px` : `240px`}`,
            maxWidth: `400px`,
            // minWidth: "fit-content",
            height: `42px`,
            borderRadius: '4px',
            border: '1px solid #E9EBEE',
            backgroundColor: 'transparent',
            display: "flex",
            alignItems: "center",
            boxShadow: "none"
        },
        leftCard: {
            // minWidth: "80px",
            // maxWidth: "131px",
            // maxWidth: "131px",
            flex: `1 0 100px`,
            height: "100%",
            padding: `4px 12px`,
            backgroundColor: colorPalette.primary[10],
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "none",
            cursor: "pointer"
        },
        titleStyle: {
            fontSize: `15px`,
            fontWeight: "500",
            color: colorPalette.primary[100],
        },
        rightCard: {
            // maxWidth: "309px",
            // maxWidth: "340px",
            flex: `${type === "rangeDate" ? "1 0 309px" : "1 1 309px"}`,
            height: "100%",
            padding: `4px 12px`,
            backgroundColor: 'transparent',
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            boxShadow: "none"
        },
        arrayText: {
            fontSize: `15px`,
            fontWeight: "500",
            color: colorPalette.neutral[100],
            maxWidth: "calc(90%)",
            marginRight: "6px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        moreText: {
            fontSize: `15px`,
            fontWeight: "600",
            color: colorPalette.neutral[100],
            textDecoration: "underline",
            maxWidth: "calc(10%)"
        }
    }

    return (
        <Card sx={{...sx.mainCard}}>
            <Card onClick={() => onClick()} sx={{...sx.leftCard}}>
                <p style={{...sx.titleStyle}}>
                    {`${title}`}
                </p>
                {isOpen ? <ExpandLessIcon style={{ color: colorPalette.primary[100] }} /> : <ExpandMoreIcon style={{ color: colorPalette.primary[100] }} />}
            </Card>
            <Card sx={{...sx.rightCard}}>
                <p style={{...sx.arrayText}}>
                    {type != "rangeDate" ? (
                        <> 
                            {displayAll ? "All" : selectedArray.length > 0 ? (
                                <>
                                    {selectedArray.map((item, index) => {
                                        if (item.name.startsWith("Inval")) {
                                            return null;
                                        }
                                        if (index > 0) {
                                            charCount += item.name.length + 3;
                                            if (charCount > 38) {
                                                if (left === selectedArray.length) {
                                                    left = selectedArray.length - index;
                                                }
                                                return;
                                            }
                                            return (
                                                `${item.name}${selectedArray[index + 1] && charCount + selectedArray[index + 1].name.length + 3 < 38 ? ", " : ", "}`
                                            )
                                        }
                                        charCount += item.name.length;
                                        return (
                                            `${item.name}${selectedArray[index + 1] && charCount + selectedArray[index + 1].name.length + 3 < 38 ? ", " : ""}`
                                        )
                                    })}
                                </>
                            ) : "None"}
                        </>
                    ) : `${selectedArray[0]} - ${selectedArray[1]}`}
                </p>
                {type != "rangeDate" ? (
                    <p style={{...sx.moreText}}>
                        {left > 0 && left < selectedArray.length && `+${left}`}
                    </p>
                ) : null}
            </Card>
        </Card>
    )
}

export default Filter;