// title = "title"
// items = [{name: 'name', id: 'id'}]
// selectedItems = [{name: 'name', id: 'id'}]
// onSave = (newArray) => {}
// onClose = () => {}

import { useState } from "react";
import colorPalette from "../Palette";
import { Button, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { textTransform } from "@mui/system";


const FilterDropdown = (props) => {

    const { title, items, selectedItems, onSave, onClose, allowNoItems, radio } = props;

    // let newArray = [...selectedItems];
    const [newArray, setNewArray] = useState([...selectedItems]);

    const sx = {
        containerStyle: {
            width: items.length <= 8 ? `354px` : `763px`,
            height: "auto",
            maxHeight: `533px`,
            padding: `24px 24px 16px 24px`,
            background: colorPalette.base.white,
            borderRadius: "4px",
            boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
            boxShadow: "0px 8px 10px 1px rgba(0, 0, 0, 0.14)",
            boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)"

        },
        topStyle: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: `16px`
        },
        titleStyle: {
            fontSize: `18px`,
            fontWeight: "500",
            color: colorPalette.neutral[100],
            // marginBottom: "24px"
        },
        itemsContainerStyle: {
            display: "grid",
            maxHeight: `378px`,
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: `6px 24px`,
            overflowY: "auto",
        },
        itemsContainerSmallStyle: { 
            display: "grid",
            maxHeight: `378px`,
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: `6px 24px`,
            overflowY: "hidden",
        },
        itemStyle: {
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            height: `42px`,
            cursor: "pointer",
        },
        checkboxStyle: {
            width: `20px`,
            height: `20px`,
            marginRight: `8px`,
            border: `1px solid ${colorPalette.primary[100]}`,
        },
        itemTextStyle: {
            fontSize: `16px`,
            fontWeight: "400",
            marginLeft: `8px`,
            color: colorPalette.neutral[100]
        },
        buttonsContainer: {
            display: "flex",
            justifyContent: "end",
            marginTop: `24px`,
            paddingTop: `24px`,
            borderTop: "1px solid #E9EBEE",
        },
        closeButtonStyle: {
            marginRight: `16px`,
            width: `112px`,
            height: `36px`,
            fontSize: `13px`,
            textTransform: "none"
        },
        saveButtonStyle: {
            width: `112px`,
            height: `36px`,
            fontSize: `13px`,
            textTransform: "none"
        }
    }

    const selectAllNone = () => {
        if (newArray.length === items.length) {
            setNewArray([])
        } else {
            setNewArray(items)
        }
    }


    return (
        <div style={{ ...sx.containerStyle }}>
            <div style={{ ...sx.topStyle }}>
                <p style={{ ...sx.titleStyle }}>
                    {title}
                </p>
                <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
            </div>
            {items.length <= 8 ? (
                <div style={{ ...sx.itemsContainerSmallStyle }}>
                    {items.map((item, index) => {
                        return (
                            <div key={index} onClick={() => {
                                if (newArray.some((line) => line.id === item.id)) {
                                    if (radio) {
                                        setNewArray([item])
                                        return
                                    }
                                    let tempArray = [...newArray]
                                    tempArray = tempArray.filter((i) => i.id !== item.id)
                                    setNewArray(tempArray)
                                } else {
                                    if (radio) {
                                        setNewArray([item])
                                        return
                                    }
                                    const tempArray = [...newArray]
                                    tempArray.push(item)
                                    setNewArray(tempArray)
                                }
                            }} style={{ ...sx.itemStyle }}>
                                <Checkbox
                                    checked={newArray.some((line) => line.id === item.id)}
                                />
                                <p style={{ ...sx.itemTextStyle }}>
                                    {item.name}
                                </p>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div style={{ ...sx.itemsContainerStyle }}>
                    {items.map((item, index) => {
                        return (
                            <div key={index} onClick={() => {
                                if (newArray.some((line) => line.id === item.id)) {
                                    if (radio) {
                                        setNewArray([item])
                                        return
                                    }
                                    let tempArray = [...newArray]
                                    tempArray = tempArray.filter((i) => i.id !== item.id)
                                    setNewArray(tempArray)
                                } else {
                                    if (radio) {
                                        setNewArray([item])
                                        return
                                    }
                                    const tempArray = [...newArray]
                                    tempArray.push(item)
                                    setNewArray(tempArray)
                                }
                            }} style={{ ...sx.itemStyle }}>
                                <Checkbox
                                    checked={newArray.some((line) => line.id === item.id)}
                                />
                                <p style={{ ...sx.itemTextStyle }}>
                                    {item.name}
                                </p>
                            </div>
                        )
                    })}
                </div>
            )}
            
            <div style={{ ...sx.buttonsContainer }}>
                {!radio && (
                    <Button variant="text" style={{ ...sx.closeButtonStyle }} onClick={selectAllNone}>
                        {`Select ${newArray.length === items.length ? "None" : "All"} `}
                    </Button>
                )}
                <Button disabled={allowNoItems ? false :newArray.length <= 0} variant="contained" style={{ ...sx.saveButtonStyle }} onClick={() => onSave(newArray)}>
                    Save
                </Button>
            </div>
        </div>
    )

}

export default FilterDropdown;