import { Button, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from "lodash"
import { useState } from 'react';
import { useEffect } from 'react';

const AutoStartEnd = (props) => {

    const {
        data,
        label,
        lineId,
        auto_start_data,
        auto_end_data
    } = props


    const styles = {
        mainContainer: {
            minHeight: "148px"
        },
        topPartContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#F9FBFC",
            border: "1px solid #D4D4D4",
            borderWidth: "1px 1px 0px 1px",
            borderRadius: "8px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            height: "67px"
        },
        switchContainer: {
            marginLeft: "20px",
            display: "flex",
            alignItems: "center"
        },
        label: {
            fontSize: "16px",
            fontWeight: "500",
            color: "#5A5D66"
        },
        addEditLabel: {
            display: "flex",
            color: "#FFFFFF",
            fontSize: "14px",
            fontWeight: "700",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
            cursor: "pointer"

        },
        bottomPartContainer: {
            border: "1px solid #D4D4D4",
            borderRadius: "8px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            height: "86px"
        },
        noAutoStartLabel: {
            fontWeight: "400",
            fontSize: "14px",
            color: "rgba(90, 93, 102, 0.5)",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        tableHeadersContainer: {
            fontSize: "13px",
            display: "flex",
            // justifyContent: "space-between",
            padding: "15px 20px 5px 20px"
        },
        tableHeadersOEE: {
            fontWeight: 600,
            color: "#5A5D66"
        },
        tableHeadersDays: {
            width: "140px",
            marginRight: "20px",
            fontWeight: 600,
            color: "#5A5D66"
        },
        tableHeadersTime: {
            width: "135px",
            fontWeight: 600,
            color: "#5A5D66"
        },
        tableHeadersProduct: {
            fontWeight: 600,
            color: "#5A5D66"
        },
        tableFieldDays: {
            lineHeight: "16px",
            width: "140px",
            marginRight: "20px"
        },
        tableFieldTime: {
            width: "135px"
        },
        tableFieldProduct: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "185px"
        }
    };

    const [autoStartEndData, setAutoStartEndData] = useState([])

    const onClick = () => {
        props.openModal()
    }

    useEffect(() => {
        setAutoStartEndData(data.filter((item) => item.line === lineId))
    }, [data])

    return (
        <div style={styles.mainContainer}>
            <div style={styles.topPartContainer}>
                <div style={styles.switchContainer}>
                    {/* <Switch /> */}
                    <span style={styles.label}>{label}</span>
                </div>
                <div style={styles.addEditLabel}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onClick}
                        style={Styles.buttonStyle()}
                    >
                        {autoStartEndData.length > 0 ? "Edit" : `+ Add ${label}`}
                    </Button>
                </div>
            </div>
            <div style={styles.bottomPartContainer}>
                {autoStartEndData.length > 0 ? (
                    <div>
                        {label === "Auto-Start" ? (
                            <>
                                <div style={styles.tableHeadersContainer}>
                                    <div style={styles.tableHeadersDays}>
                                        Days
                                    </div>
                                    <div style={styles.tableHeadersTime}>
                                        Time
                                    </div>
                                    <div style={styles.tableHeadersProduct}>
                                        Product
                                    </div>
                                </div>
                                {autoStartEndData.map((item, i) => {
                                    const days = item.days_of_the_week.split(",").map((item) => item.substring(0, 3)).join(", ")
                                    let timeString = item.start_time;
                                    const H = +timeString.substr(0, 2);
                                    const h = H % 12 || 12;
                                    const ampm = (H < 12 || H === 24) ? " am" : " pm";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    let product_name = item.product ? item.product : "N/A"
                                    if (Number.isInteger(product_name)) {
                                        props.line_products.forEach((item) => {
                                            if (item.product_id === product_name) {
                                                product_name = `${item.product_name} - ${item.product_desc}`
                                            }
                                        })
                                    }
                                    if (i === 0) {
                                        return (
                                            <div style={styles.tableHeadersContainer}>
                                                <div style={styles.tableFieldDays}>
                                                    {days}
                                                </div>
                                                <div style={styles.tableFieldTime}>
                                                    {timeString}
                                                </div>
                                                <div style={styles.tableFieldProduct}>
                                                    {item.is_use_previous_run_product ? "Use product from previous run" : product_name}
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            </>
                        ) : (
                            <>
                                <div style={styles.tableHeadersContainer}>
                                    <div style={styles.tableHeadersDays}>
                                        Days
                                    </div>
                                    <div style={styles.tableHeadersTime}>
                                        Time
                                    </div>
                                    <div style={styles.tableHeadersOEE}>
                                        min. OEE %
                                    </div>
                                </div>
                                {autoStartEndData.map((item, i) => {
                                    const days = item.days_of_the_week.split(",").map((item) => item.substring(0, 3)).join(", ")
                                    let timeString = item.start_time ? item.start_time : item.end_time;
                                    const H = +timeString.substr(0, 2);
                                    const h = H % 12 || 12;
                                    const ampm = (H < 12 || H === 24) ? " am" : " pm";
                                    timeString = h + timeString.substr(2, 3) + ampm;
                                    if (i === 0) {
                                        return (
                                            <div style={styles.tableHeadersContainer}>
                                                <div style={styles.tableFieldDays}>
                                                    {days}
                                                </div>
                                                <div style={styles.tableFieldTime}>
                                                    {timeString}
                                                </div>
                                                <div style={styles.tableFieldOEE}>
                                                    {item.minimum_oee_to_delete_run ? item.minimum_oee_to_delete_run : "0"}
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            </>
                        )}
                    </div>
                ) : (
                    <div style={styles.noAutoStartLabel}>
                        <span>No {label} added yet...</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AutoStartEnd;