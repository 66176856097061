import React, {Component} from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return <div>
            <div className="footer__help" style={{ padding: "24px 0px" }}>
                &copy; 2025 LiveTracking |
                <a className="footer__help--anchor" href="/">Home</a> |
                <a className="footer__help--anchor" href="https://www.livetracking.io/privacy-policy">Privacy Policy</a> |
                <a  className="footer__help--anchor" href="mailto:support@livetracking.io">Send Feedback</a>
            </div>
        </div>
    }
}

export default Footer