import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Divider, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import SelectLineModal from '../../../modals/SelectLineModal';
import SelectProductModal from '../../../modals/SelectProductModal';
import MultiDatesPicker from '../../../modals/MultiDatesPicker';

import ByShiftTop from './by-shift-top';
import FiltersParse from './filters-parse';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SelectCategoryModal from '../../../modals/SelectCategoryModal';
import SelectTagModal from '../../../modals/SelectTagModal';
import colorPalette from '../../../Palette';
import Filter from '../../Filter';
import FilterDropdown from '../../FilterDropdown';
import moment from 'moment';
import _ from 'lodash';

export default function DailyReportTitleAndOptions(props) {
    const classes = useStyles();

    const [wasChange, setWasChange] = useState(false);
    const [shifts, setShifts] = useState([]);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [moreFilters, setMoreFilters] = useState(false);
    const currentDate = moment.utc().format('YYYY-MM-DD');
    const wasChanged = () => {
        setWasChange(true);
    };

    const {
        selected_lines,
        lines,
    } = props;

    useEffect(() => {
        if (props.selected_lines.length === 0) {
            setShifts([]);
        }
        else {
            getShifts()
        }
    }, [props.selected_lines]);

    useEffect(() => {
        getShifts();
    }, []);

    const getShifts = () => {
        let names = [];
        if (!_.isEmpty(selected_lines) && lines && lines.length > 0) {
            lines.map(lineId => {
                names.push(`line_id=${lineId.id}`);
            });
        }

        let params = {};

        params = {
            path: 'shift',
            path_variables: {
                LINE_IDS: names.join('&')
            },
            success: function (resp) {
                const newArr = resp.sort(function compare(a, b) {
                    const datea = moment(`${currentDate} ${a.start_time}`).format('HH:mm:ss');
                    const dateb = moment(`${currentDate} ${b.start_time}`).format('HH:mm:ss');
                    return datea.localeCompare(dateb);
                });
                setShifts(newArr);
            },
            error: function (err) {
                setShifts([]);
            }
        };
        API.c(params);
    }

    const showLines = () => {
        if (props.lines == null || props.lines.length === 0) {
            return <div>
                {/* Loading... */}
            </div>;
        }

        let count = '0';

        if (props.selected_lines && props.lines[0])
            if (props.selected_lines.length === props.lines.length) {
                count = 'All';
            } else {
                count = props.selected_lines.length.toString();
            }

        let selectedArray = []
        
        if (_.isEmpty(props.lines)) {
            selectedArray = [{name: " "}]
        }
        else {
            props.selected_lines.forEach((item) => {
                const line = props.lines.find((line) => line.id === item)
                if (line) {
                    selectedArray.push({ name: line.name, id: line.id })
                }
            })
        }

        let totalArray = []

        if (props.lines && props.lines[0]) {
            props.lines.forEach((item) => {
                totalArray.push({ name: item.name, id: item.id })
            })
        }


        return (
            <div style={{ marginRight: '40px', padding: '0px 0 0px 0px', float: 'left', position: "relative" }}>
                <Filter onClick={props.show_select_lines ? props.cancelSelectLines : props.openSelectLines} title={`Lines`} type={"text"} displayAll={count === "All"} selectedArray={selectedArray} isOpen={props.show_select_lines}  />
                {props.show_select_lines && (
                    <div style={{ position: "absolute", left: "0px", marginTop: "10px", zIndex: "100" }}>
                        <FilterDropdown title={"Line Filter"} items={totalArray} selectedItems={selectedArray} onSave={props.hideSelectLines} onClose={props.cancelSelectLines} />   
                    </div>
                )}
            </div>
        );
    };

    const showProducts = () => {
        if (props.products == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (props.selected_products && props.products[0])
            if (props.selected_products.length === props.products.length) {
                count = 'All';
            } else {
                count = props.selected_products.length.toString();
            }

        let selectedArray = []
        if (_.isEmpty(props.products)) {
            selectedArray = [{name: " "}]
        }
        else {
            props.selected_products.forEach((item) => {
                const product = props.products.find((product) => product.product_id === item)
                if (product) {
                    selectedArray.push({ name: product.display_name, id: product.product_id })
                }
            })
        }

        let totalArray = []

        if (props.products && props.products[0]) {
            props.products.forEach((product) => {
                totalArray.push({ name: product.display_name, id: product.product_id })
            })
        }

        return (
            <div style={{ marginRight: '40px', padding: '0px 0 0px 0px', float: 'left', position: "relative" }}>
                <Filter onClick={props.show_select_products ? props.cancelSelectProducts : props.openSelectProducts} title={`Products`} type={"text"} displayAll={count === "All"} selectedArray={selectedArray} isOpen={props.show_select_products}  />
                    {props.show_select_products && (
                        <div style={{ position: "absolute", left: "0px", marginTop: "10px", zIndex: "100" }}>
                            <FilterDropdown title={"Product Filter"} items={totalArray} selectedItems={selectedArray} onSave={props.hideSelectProducts} onClose={props.cancelSelectProducts} />   
                        </div>
                )}
            </div>
        );
    };
    const showCategories = () => {

        let count = '0';

        if (props.selected_categories && props.categories && props.categories[0]) {
            if (props.selected_categories.length === props.categories.length && props.uncategorized_products) {
                count = 'All';
            } else {
                let uncategorized_count = props.uncategorized_products ? 1 : 0;
                count = (props.selected_categories.length + uncategorized_count).toString();
            }
        }
        else if (props.uncategorized_products) {
            count = 'All';
        }
        else {
            count = '0'
        }

        let selectedArray = []

        props.selected_categories.forEach((item) => {
            const category = props.categories.find((category) => category.id === item)
            if (category) {
                selectedArray.push({ name: category.name, id: category.id })
            }
        })

        if (props.uncategorized_products) {
            selectedArray.push({ name: "No category", id: -1 })
        }

        let totalArray = []

        if (props.categories && props.categories[0]) {
            props.categories.forEach((category) => {
                totalArray.push({ name: category.name, id: category.id })
            })
            totalArray.push({ name: "No category", id: -1 })
        }

        return (
            <div style={{ marginRight: '40px', padding: '0px 0 0px 0px', float: 'left', position: "relative" }}>
                <Filter onClick={props.show_select_categories ? props.cancelSelectCategories : props.openSelectCategories} title={`Categories`} type={"text"} displayAll={count === "All"} selectedArray={selectedArray} isOpen={props.show_select_categories}  />
                    {props.show_select_categories && (
                        <div style={{ position: "absolute", left: "0px", marginTop: "10px", zIndex: "100" }}>
                            <FilterDropdown title={"Category Filter"} items={totalArray} selectedItems={selectedArray} onSave={props.hideSelectCategories} onClose={props.cancelSelectCategories} />   
                        </div>
                )}
            </div>
        );
    };
    const showTags = () => {
        if (props.tags == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (props.selected_tags && props.tags && props.tags[0]) {
            if (props.selected_tags.length === props.tags.length && props.untagged_products) {
                count = 'All';
            } else {
                let untagged_count = props.untagged_products ? 1 : 0;
                count = (props.selected_tags.length + untagged_count).toString();
            }
        }
        else if (props.untagged_products) {
            count = 'All';
        }
        else {
            count = '0'
        }

        let selectedArray = []

        props.selected_tags.forEach((item) => {
            const tag = props.tags.find((tag) => tag.id === item)
            if (tag) {
                selectedArray.push({ name: tag.name, id: tag.id })
            }
        })

        if (props.untagged_products) {
            selectedArray.push({ name: "No tag", id: -1 })
        }

        let totalArray = []

        if (props.tags && props.tags[0]) {
            props.tags.forEach((tag) => {
                totalArray.push({ name: tag.name, id: tag.id })
            })
            totalArray.push({ name: "No tag", id: -1 })
        }

        return (
            <div style={{ marginRight: '40px', padding: '0px 0 0px 0px', float: 'left', position: "relative" }}>
                <Filter onClick={props.show_select_tags ? props.cancelSelectTags : props.openSelectTags} title={`Tags`} type={"text"} displayAll={count === "All"} selectedArray={selectedArray} isOpen={props.show_select_tags}  />
                    {props.show_select_tags && (
                        <div style={{ position: "absolute", left: "0px", marginTop: "10px", zIndex: "100" }}>
                            <FilterDropdown title={"Tags Filter"} items={totalArray} selectedItems={selectedArray} onSave={props.hideSelectTags} onClose={props.cancelSelectTags} />   
                        </div>
                )}
            </div>
        );
    };

    const showShifts = () => {

        if (props.tags == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (selectedShifts && shifts && shifts[0]) {
            if (selectedShifts.length === shifts.length) {
                count = 'All';
            } else {
                count = selectedShifts.length.toString();
            }
        }
        else {
            count = '0'
        }

        let selectedArray = []

        selectedShifts.forEach((shift) => {
            if (shift.start_time && shift.end_time) {
                selectedArray.push({ name: `${shift.name} ${moment(`${currentDate} ${shift.start_time}`).format('LT')} - ${moment(`${currentDate} ${shift.end_time}`).format('LT')}`, id: shift.id })
            }
            else {
                selectedArray.push({ name: `${shift.name}`, id: shift.id })
            }
        })

        let totalArray = []

        if (shifts && shifts[0]) {
            shifts.forEach((shift) => {
                totalArray.push({ name: `${shift.name} ${moment(`${currentDate} ${shift.start_time}`).format('LT')} - ${moment(`${currentDate} ${shift.end_time}`).format('LT')}`, id: shift.id })
            })
        }

        //shifts
        //selectedShits
        //usesShifts

        return (
            <>
                {props.usesShifts ? (
                    <div style={{ marginRight: '30px', padding: '0px 0 0px 0px', float: 'left', position: "relative" }}>
                        <Filter onClick={() => props.show_select_shifts ? props.cancelSelectCategories() : props.open_select_shifts() } title={`Shifts`} type={"text"} displayAll={count === "All"} selectedArray={selectedArray} isOpen={props.show_select_shifts}  />
                            {props.show_select_shifts && (
                                <div style={{ position: "absolute", left: "0px", marginTop: "10px", zIndex: "100" }}>
                                    <FilterDropdown allowNoItems title={"Shifts Filter"} items={totalArray} selectedItems={selectedArray} onSave={(array) => { props.saveSelectedShifts(array); setSelectedShifts(array); props.cancelSelectCategories() }} onClose={() => props.cancelSelectCategories()} />   
                                </div>
                        )}
                    </div>
                ) : null}
            </>
        );
    }

    const showSelectDates = () => {
        const selectedArray = []
        const monthNames = ["Jan", "Feb", "Mar", "Apr",
            "May", "Jun", "Jul", "Aug",
            "Sep", "Oct", "Nov", "Dec"]; 
        if (props.multi_date) {
            props.selected_dates.forEach((item) => {
                const day = item.getDate();
                const monthIndex = item.getMonth();
                const monthName = monthNames[monthIndex];
                const year = item.getFullYear();
    
                selectedArray.push({ name: `${day} ${monthName} ${year}` })
            })
        }
        else {
            if (props.date_from && props.date_to) {
                const dayFrom = props.date_from.getDate();
                const monthFromIndex = props.date_from.getMonth();
                const monthFromName = monthNames[monthFromIndex];
                const yearFrom = props.date_from.getFullYear();

                selectedArray.push(`${dayFrom} ${monthFromName} ${yearFrom}`)

                const dayTo = props.date_to.getDate();
                const monthToIndex = props.date_to.getMonth();
                const monthToName = monthNames[monthToIndex];
                const yearTo = props.date_to.getFullYear();

                selectedArray.push(`${dayTo} ${monthToName} ${yearTo}`)
            }
        }

        return (
            <div style={{ marginRight: '40px', padding: '0px 0 0px 0px', float: 'left' }}>
                <Filter onClick={props.openSelectDates} title={`Dates`} type={`${props.multi_date ? "text" : "rangeDate"}`} selectedArray={selectedArray.length > 0 ? selectedArray : ["", ""]} isOpen={props.show_select_dates}  />
            </div>


        );
    };

    const showDatesModal = () => {
        return (
            props.show_select_dates && (

                <MultiDatesPicker
                    setReportMultipleDates={props.setReportMultipleDates}
                    setReportRangeDates={props.setReportRangeDates}
                    type={props.multi_date ? 'days' : 'range'}
                    selectedDays={props.selected_dates}
                    from={props.date_from}
                    to={props.date_to}
                    handleClose={props.handleClose}
                />
            )
        );
    };

    return (
        <div style={{ marginTop: "6px" }}>
            <div className={props.collapse ? classes.card_collapse : classes.card} style={{ background: "transparent" }}>
                <div className={classes.title_div} style={{ background: "transparent" }}>
                    <Typography style={{ fontWeight: "500", fontSize: `32px` }} variant="h5" className={classes.title}>
                        Daily Report
                    </Typography>
                </div>
                <hr style={{ margin: "24px -30px 0px -30px", borderTop: "1px solid #E3E7ED" }} />
                <Box display="flex">
                    <Box style={{ width: "100%" }}>
                        <div className={classes.options_div} style={{ width: "100%" }}>
                            <div className={classes.options_selectors} style={{ width: "100%" }}>
                                <div className={classes.lines_products_div} style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "32px" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {showSelectDates()}
                                        {showLines()}
                                        {showShifts()}
                                    </div>
                                    <div>
                                        <Button
                                            style={{
                                                marginTop: '0px',
                                                marginRight: `24px`,
                                                height: `42px`,
                                                width: `120px`,
                                                fontSize: `14px`,
                                                textTransform: 'none'
                                            }}
                                            variant={'outlined'}
                                            color={'primary'}
                                            onClick={() => setMoreFilters(!moreFilters)}
                                        >
                                            {moreFilters ? 'Less Filters' : 'More Filters'}
                                        </Button>
                                        <Button
                                            style={{
                                                marginTop: '0px',
                                                height: `42px`,
                                                width: `120px`,
                                                fontSize: `14px`,
                                                textTransform: 'none'
                                            }}
                                            variant={'contained'}
                                            color={'primary'}
                                            disabled={!wasChange && props.canUpdateOptions()}
                                            onClick={props.getReport.bind(this, 1, true)}
                                        >
                                            Apply Filters
                                        </Button>
                                    </div>
                                </div >
                            </div >
                            {moreFilters ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "24px" }}>
                                    {showProducts()}
                                    {showCategories()}
                                    {showTags()}
                                </div>
                            ) : null}
                        </div >
                    </Box >
                </Box >
                {showDatesModal()}
            </div >
        </div >
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            width: 'calc(100%)',
            // margin: '20px 20px 0px 176px',
            padding: "30px",
            boxSizing: 'border-box',
            background: '#fff',
            // boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'inline-block'
        },
        card_collapse: {
            width: 'calc(100%)',
            // margin: '20px 0px 0px 56px',
            padding: "30px",
            boxSizing: 'border-box',
            background: '#fff',
            // boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'inline-block'
        },
        title_div: {
            background: '#fff',
            // padding: '30px'
        },
        title: {
            fontWeight: '600',
            fontSize: "32px",
            color: colorPalette.neutral[100]
        },
        options_div: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        options_selectors: {
            display: 'flex',
            flexDirection: 'row'
        },
        options_buttons: {
            margin: '30px 0',
            display: 'flex',
            alignItems: 'center'
        },
        lines_products_div: {
            // margin: '15px 30px 15px 0',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        date_pickers_div: {
            // margin: '15px 30px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        button: {
            marginBottom: '4px'
        },
        option_item: {
            fontSize: '16px !important',
            marginRight: '10px !important',
            marginTop: '12px !important',
            textAlign: 'start !important',
            textTransform: 'capitalize !important'
        },
        option_item_dropdown: {
            justifyContent: 'start',
            fontSize: '16px !important',
            marginRight: '10px !important',
            marginTop: '12px !important',
            textAlign: 'start !important',
            textTransform: 'capitalize !important'
        },
        option_item_date: {
            display: 'flex',
            flexDirection: 'column',
            fontSize: '16px !important',
            marginRight: '10px !important',
            marginTop: '12px !important',
            textAlign: 'start !important',
            textTransform: 'capitalize !important'
        },
        shiftsSelect: {
            marginTop: '-4px',
            justifyContent: 'center',
            width: '204px'
        }
    })
);