import React, {Component} from 'react';
import {
    Button,
    CircularProgress,
    MenuItem,
    TextField
} from "@material-ui/core";
import {DatePicker, TimePicker} from "@material-ui/pickers"
import moment from 'moment';
import {getDate, disableFromFutureDates, disableToFutureDates, handleDateFromChange, handleDateToChange, handleTimeFromChange, handleTimeToChange} from '../components/DateTimeHelperUtils'

class AddClosedRun extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date_from: getDate().minutes(getDate().minutes() - 1),
            time_from: getDate().minutes(getDate().minutes() - 1),
            date_to: getDate(),
            time_to: getDate(),
            finished_goods: 0,
            finished_goods_error: null,
            time_from_error: null,
            time_to_error: null,
            loading: false,
            selected_product: null,
            selected_line: props.lines[0].id,
            products: null,
            lines: props.lines,
            submitting_edit: false,
            error: null,
            delete: false,
            delete_confirmed: false,
            delete_error: null,
            item_deleted_text: '',
            case_count: 0
        }
    }


    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount = () => {
        this.setKeyListener();
        this.getLineProducts();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                if(!this.state.delete) {
                    this.addClosedRun()
                }
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    /* ######## FUNCTIONS ######## */

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleSelectLineChange = (event) => {
        this.setState({
            selected_line: event.target.value,
            products: null,
            selected_product: null,
            case_count: 0
        });
        this.getLineProducts(event.target.value);

    }


    handleSelectProductChange = (event) => {
        this.setState({
            selected_product: event.target.value,
            case_count: this.getCaseCount(event.target.value)
        })

    }

    getCaseCount = (id) => {
        let products = ProductStore.getFull();
        let caseCount = 1;
        products.forEach(product => {
            if(product.id === id) {
                caseCount = product.product_case_count;
            }
        })

        return caseCount;
    }

    handleDateFromChange = (date) => {
        let state = handleDateFromChange(date, this.state.time_from, this.state.time_to)
        this.setState(state, () => console.log(this.state))
    }


    handleDateToChange = (date) => {
        let state = handleDateToChange(date, this.state.time_to, this.state.date_from)
        this.setState(state)
    }

    handleTimeFromChange = (time) => {
        let state = handleTimeFromChange(time, this.state.time_to, this.state.date_from)
        this.setState(state)
    }

    handleTimeToChange = (time) => {
        let state = handleTimeToChange(time, this.state.time_from, this.state.date_to)
        this.setState(state)
    }

    validateInput = () => {
        let errorFree = true;
        this.setState({
            finished_goods_error: null
        })

        if(this.state.finished_goods < 0) {
            errorFree =false;
            this.setState({
                finished_goods_error: "Should be positive number"
            })
        }

        if(this.state.time_to_error) {
            errorFree = false;
        }

        return errorFree;
    }

    fetchProductsSuccess = (data) => {
        if (data.length === 0) {
            this.setState({
                products: [],
                selected_product: null,
                case_count: 0
            })
            return;
        }
        this.setState({
            products: data,
            selected_product: data[0].product_id,
            case_count: data[0].product_case_count
        })
        // this.getCaseCount(data[0].product_id)
    }

    /* ######## API CALLS ######## */

    getLineProducts = (line_id) =>{
        const id = line_id ? line_id : this.state.selected_line;
        let params = {
            path: "line_products",
            path_variables: {ID: id},
            success: this.fetchProductsSuccess,
            error: (e) => {console.log(e);}
        }

        API.c(params, 2)
    }

    addClosedRun = () => {
        if(this.validateInput()) {
            let end_time;
            if (this.state.date_to != null) {
                end_time = this.state.date_to.toISOString();
            } else {
                end_time = null
            }
            this.setState({
                submitting: true
            });

            const calculatedFinishedGoods = this.state.finished_goods;
            let data = {
                line: this.state.selected_line,
                start_time: this.state.date_from.toISOString(),
                end_time: end_time,
                product: this.state.selected_product,
                finished_goods: calculatedFinishedGoods ? calculatedFinishedGoods : 0
            }

            let params = {
                path: "add_closed_run",
                data: data,
                success: this.updateRunSuccess,
                error: this.updateRunError
            };

            API.c(params, 2);
        }
    }

    updateRunSuccess = () => {
        this.props.modified();
        this.props.hideModal();
    }

    updateRunError = (error) => {
        this.setState({
            submitting: false,
            received_data: false,
            error: error
        })
    }

    /* ######## RENDERS ######## */

    showLines = () => {
        if (this.state.lines == null) {
            return <div>Loading...</div>;
        }

        return <div className="reports__options-item">
            <TextField
                select
                fullWidth={true}
                label="Line"
                value={this.state.selected_line}
                onChange={this.handleSelectLineChange}>
                {this.iterateLines()}
            </TextField>
        </div>;
    }

    iterateLines = () => {
        if (this.state.lines == null || this.state.lines.constructor !== Array) {
            return;
        }

        return this.state.lines.map((item, index) =>
            <MenuItem value={item.id}
                      key={"reports-line-"+index}>
                {item.name}
            </MenuItem>
        )
    }

    fromDateTimePickers = () => {
        return <div>
            <DatePicker
                label="Start Date"
                shouldDisableDate={disableFromFutureDates.bind(this, this.state.date_to, this.state.max_date_time)}
                minDate={this.state.min_date_time}
                value={this.state.date_from}
                onChange={this.handleDateFromChange}
                className="reports__options-item_picker"
                format="yyyy-MMMM-DD"
            />
            <TimePicker
                id={"start-time-time-picker"}
                onChange={this.handleTimeFromChange}
                value={this.state.time_from}
                label="Start Time"
                error={this.state.time_from_error !== null}
                helperText={this.state.time_from_error}
                className="reports__options-item_picker"
            />
        </div>
    }

    toDateTimePickers = () => {
        return <div>
            <DatePicker
                label="End Date"
                minDate={this.state.date_from}
                shouldDisableDate={disableToFutureDates.bind(this, this.state.max_date_time)}
                value={this.state.date_to}
                onChange={this.handleDateToChange}
                className="reports__options-item_picker"
                format="yyyy-MMMM-DD"
            />
            <TimePicker
                id={"end-time-time-picker"}
                onChange={this.handleTimeToChange}
                value={this.state.time_to}
                label="End Time"
                error={this.state.time_to_error !== null}
                helperText={this.state.time_to_error}
                className="reports__options-item_picker"
            />
        </div>
    }

    showProducts = () => {
        if(this.state.products == null) {
            return <div>Loading...</div>
        }

        return <div className="reports__options-item--products">
            <TextField
                select
                fullWidth={true}
                label="Product"
                value={this.state.selected_product}
                onChange={this.handleSelectProductChange}
                style={{width: '450px'}}>
                {this.iterateProducts()}
            </TextField>
        </div>;
    }

    iterateProducts = () => {
        if(this.state.products == null || this.state.products.constructor != Array) {
            return;
        }

        const sortedProducts = this.state.products.sort((a, b) => {
            if (a.product_name < b.product_name) {
                return -1;
            }
            if (a.product_name > b.product_name) {
                return 1;
            }
            return 0;
        })

        return sortedProducts.map((item, index) =>
            <MenuItem value={item.product_id}
                      key={"product-"+index}>
                {`${item.product_name} - ${item.product_desc}`}
            </MenuItem>
        )
    }

    showFinishedGoods = () => {
        return <div className="reports__options-item">
            <TextField
                id="finished_goods"
                label="Finished Goods (Case)"
                value={this.state.finished_goods}
                onChange={this.handleChange.bind(this)}
                error={this.state.finished_goods_error !== null}
                helperText={this.state.finished_goods_error !== null ? this.state.finished_goods_error : ''}
                style={{width: '200px'}}
                type="number"/>
        </div>
    }

    showButtons = () => {
        if(this.state.submitting) {
            return <div className="edit-run-buttons__wrapper"><CircularProgress size={20} thickness={2}/></div>
        } else {
            return <div className="edit-run-buttons__wrapper">
                <Button variant="contained"
                        color="primary"
                        disabled={this.state.products == null}
                        onClick={this.addClosedRun}>Add Closed Run</Button>
            </div>
        }
    }

    editStatus () {
        let {error} = this.state
        if (error) {
            let e = "Server Error: Please retry";

            if (error) {
                if (error.error) {
                    e = error.error;
                }

                if (error.detail) {
                    e = error.detail;
                }

                if (error.message) {
                    e = error.message;
                }
            }

            return <div className="update-status__error">{e}</div>
        } else {
            return <div/>
        }
    }

    buttonStyle () {
        return {
            marginRight: "15px"
        }
    }

    render(){
        return <div>
            <div style={{display: 'grid'}}>
                <div>
                    {this.showLines()}
                    {this.showProducts()}
                </div>
                <div>
                    {this.showFinishedGoods()}
                    {this.fromDateTimePickers()}
                    {this.toDateTimePickers()}
                </div>
            </div>
            {this.showButtons()}
            {this.editStatus()}
        </div>
    }
}

export default AddClosedRun