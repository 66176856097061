import React, { Fragment, useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Box,
    Table,
    Divider,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { minimumRole } from '../../PermissionsHelper';
import AddNewRun from '../../../modals/AddNewRun';
import EndRun from '../../../modals/EndRun';
import Util from '../../Util';

import Filters from './filters';
import { utcToLocal } from '../../../utils/utcToLocal';
import PageHeader from '../../PageHeader';
import colorPalette from '../../../Palette';

import RecentEditsIcon from '../../icons/RecentEditsIcon.svg';

/**
 * @return {null}
 */

export default function RunReportTitleCard(props) {
    //console.log(window.API, 'WINDOW API ____________________________');
    //console.log(props, 'props of RUN REPORT TITLE');
    let params = {
        path: 'shift',
        success: data => {
            console.log('success', data);
        },
        error: error => {
            console.log(error);
        }
    };

    //const checkAPIlog = API.c(params);
    /*const checkAPIlog2 = API.c({
        path: 'products',
        success: data => {
            console.log('success', data);
        },
        error: error => {
            console.log(error);
        }
    });
    */

    /*const requestTest = async () => {
        const response = await fetch(
            'http://livetrackingsandbox-env.eba-7gz2ij39.us-east-1.elasticbeanstalk.com/shift/'
        );

        const json = await response.json();
        console.log(json, 'RESPONSE ---------------');
    };
    */

    //requestTest();

    // console.log(checkAPIlog, 'CHECKAPILOG ---------------------------');
    const classes = useStyles();
    const [showEndRunDialog, setShowEndRunDialog] = useState(false);
    const [showAddNewRunDialog, setShowAddNewRunDialog] = useState(false);
    const [user_role, setUser_role] = useState("")
    const [lastEditData, setLastEditData] = useState({})

    const getLastEdit = () => {
        let lastEditParams = {
            path: 'get_last_edit_run',
            path_variables: { RUN_ID: props.runId },
            success: (data) => setLastEditData(data),
            error: () => {}
        };

        API.c(lastEditParams, 2);
    };

    useEffect(() => {
        getLastEdit()
        setUser_role(JSON.parse(window.localStorage.getItem('factory')).role)
    }, [])

    useEffect(() => {
        setLastEditData({})
        getLastEdit()
    }, [props.runId, props.run])

    function promptEndRunDialog() {
        setShowEndRunDialog(true);
    }

    function hideEndRunDialog() {
        setShowEndRunDialog(false);
    }

    function onEndRunSuccess() {
        props.refreshReport();
        hideEndRunDialog();
        promptAddNewRunDialog();
    }

    function promptAddNewRunDialog() {
        setShowAddNewRunDialog(true);
    }

    function hideAddNewRunDialog() {
        setShowAddNewRunDialog(false);
    }

    function onStartNewRunSuccess(data) {
        hideAddNewRunDialog();

        props.goToNewRun(data);
    }

    function onGetRunSuccess(data) {
        props.goToDateRun(data);
    }

    function onGetRunSuccessApply(data) {
        props.goToDateRunApply(data);
    }

    function onRunCurrentlyRunning() {
        hideAddNewRunDialog();

        props.onRunCurrentlyRunning();
    }

    const getLastEditUser = () => {
        if (this.state.factory_users && this.state.factory_users.length > 0) {
            const userData =  this.state.factory_users.filter((user) => {
                return user.user_id === this.state.lastEditData["Updated by"]
            })
            if (userData[0] && userData[0].user_metadata && (userData[0].user_metadata.first_name || userData[0].user_metadata.last_name)) {
                return `${userData[0].user_metadata.first_name} ${userData[0].user_metadata.last_name}`
            }
            else if (userData[0] && userData[0].name) {
                return userData[0].name
            }
            else if (userData[0] && userData[0].email) {
                return userData[0].email
            }
        }

        return "System User"
    }

    const renderLastEditString = () => {
        if (lastEditData["Action"] === "Deleted") {
            return `Last Edit: ${Object.keys(lastEditData["Previous values"])[0]} deleted by ${props.getLastEditUser(lastEditData["Updated by"])} on ${lastEditData["Date"]}`
        }
        else {
            return `Last Edit: ${Object.keys(lastEditData["New values"])[0]} changed to ${Object.values(lastEditData["New values"])[0]} by ${props.getLastEditUser(lastEditData["Updated by"])} on ${lastEditData["Date"]}`
        }
    }

    const backButton = () => {
        if (props.backTo === 'liveview') {
            return (
                <Button variant="text" color="primary" href={'/liveview'}>
                    Back to Liveview
                </Button>
            );
        }

        return (
            <Button
                disabled={props.line_products === null}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                variant="text"
                color="primary"
                onClick={props.onBackToLineClick.bind(this)}
            >
                Back to daily report
            </Button>
        );
    };

    const renderRunInfo = () => {
        if (props.run === null) {
            return (
                <div className={classes.loading_card}>
                    <CircularProgress />
                </div>
            );
        } else {
            const start = moment(props.run.run_start_time).tz(props.timeZone).format('LLL');
            const end = moment(props.run.run_end_time).tz(props.timeZone).format('LLL');

            let product_description = props.run.product_desc;
            let product_name = props.run.product_name;
            let line_name = props.run.line_name;
            let last_updated = '';
            if (Object.keys(lastEditData).length === 0) {
                last_updated = "No recent edits"
            }
            else {
                let lastEditKey = Object.keys(lastEditData["New values"])[0]
                let lastEditValue = Object.values(lastEditData["New values"])[0]
                if (lastEditKey === "Status") {
                    lastEditValue = lastEditValue === true ? "Active" : "Inactive"
                }
                if (lastEditData.Action) {
                    last_updated = renderLastEditString()
            }
            }
            let multiplier = props.run.product_multiplier ? parseInt(props.run.product_multiplier) : 1;

            return (
                <div style={{ width: '96.5%', background: "#fff", padding: `32px` }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                        <Typography style={{ fontSize: `28px`, fontWeight: "600", color: colorPalette.neutral[100] }}>{line_name}</Typography>
                        {props.usesShifts && (
                            <Filters
                                setCurrentPage={props.setCurrentPage}
                                handlePageClick={props.handlePageClick}
                                start_time={props.run.run_start_time}
                                end_time={props.run.run_end_time}
                                lines={props.selected_lines}
                                timezone={props.timeZone}
                                onGetRunSuccessApply={onGetRunSuccessApply}
                                onGetRunSuccess={onGetRunSuccess}
                                runLineId={props.runLineId}
                                run={props.run}
                                run_id={props.runId}
                                runReportPage={true}
                                lineProducts={props.line_products}
                            />
                            // <p>Shifts Filter</p>
                        )}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }} className={classes.info_details_column}>
                            <p style={{ fontSize: `20px`, marginBottom: `16px`, fontWeight: "600", color: colorPalette.neutral[100] }}>
                                Product: <span style={{ fontWeight: "400", marginRight: `16px` }}>{product_description}</span> SKU: <span style={{ fontWeight: "400" }}>{product_name}</span>
                            </p>
                            <p style={{ fontSize: `20px`, marginBottom: `16px`, fontWeight: "600", color: colorPalette.neutral[100] }}>
                                <span style={{ fontSize: `20px`, fontWeight: "600", color: colorPalette.neutral[100] }}>
                                    Started: <span style={{ fontWeight: "400", marginRight: `16px` }}>{start}</span>
                                </span>
                            </p>
                            <p style={{ fontSize: `20px`, marginBottom: `34px`, fontWeight: "600", color: colorPalette.neutral[100] }}>
                                <span style={{ fontSize: `20px`, fontWeight: "600", color: colorPalette.neutral[100] }}>
                                    {props.run.run_end_time ? 'Ended' : 'Run Status'}: <span style={{ fontWeight: "400", marginRight: `16px` }}>{props.run.run_end_time ? end : 'In Progress'}</span>
                                </span>
                            </p>
                            <p style={{ fontSize: `12px`, display: "flex", alignItems: "center", fontWeight: "400", color: colorPalette.neutral[60] }}><img style={{ marginRight: `8px` }} src={RecentEditsIcon}/>{last_updated}</p>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderDialog = () => {
        if (showEndRunDialog) {
            return (
                <EndRun
                    line={{ line_id: props.run.line_id, line_name: props.run.line_name }}
                    finishedGoods={props.run.run_finished_goods_cases}
                    runId={props.runId}
                    hideEndRun={hideEndRunDialog}
                    onEndRunSuccess={onEndRunSuccess}
                />
            );
        } else if (showAddNewRunDialog) {
            return (
                <AddNewRun
                    line={{ line_id: props.run.line_id, line_name: props.run.line_name }}
                    hideStartNewRun={hideAddNewRunDialog}
                    onStartNewRunSuccess={onStartNewRunSuccess}
                    onRunCurrentlyRunning={onRunCurrentlyRunning}
                />
            );
        }
    };

    return (
        <div style={{ paddingTop: "0px", background: "transparent" }}>
            <PageHeader backLabel={"Back to Daily Report"} backCallback={ props.backTo === "liveview" ? () => window.location.pathname = '/liveview' : props.onBackToLineClick}
                        customActions={minimumRole('factory_admin') && user_role !== "scoreboard" && user_role !== "factory_read_only" && user_role !== "floor_team" ? (
                            <div style={{ display: "flex", alignItems: "center", gap: `24px` }}>
                                <Button onClick={props.showMoveRunDataModal} 
                                        disabled={!props.products[0] || !props.line_products}
                                        style={{ textTransform: "unset", width: `152px`, height: `36px`, fontSize: `14px` }} 
                                        variant='outlined' 
                                        color="primary">
                                    Move Run Data
                                </Button>
                                <Button onClick={props.showEditRunModal}
                                        disabled={!props.products[0] || !props.line_products} 
                                        style={{ textTransform: "unset", boxShadow: "none", width: `152px`, height: `36px`, fontSize: `14px` }} 
                                        variant='contained' 
                                        color="primary">
                                    Edit Run Details
                                </Button>
                            </div>
                        ) : null}>
                Run Report
            </PageHeader>
            {renderRunInfo()}
            {renderDialog()}
        </div>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        report_info_div: {
            background: 'transparent',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '60px 60px 0 60px'
        },
        info_details_column: {
            marginTop: '30px'
        },
        last_updated: {
            marginBottom: '30px',
            marginTop: '20px'
        },
        buttons_column: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        button: {
            marginBottom: '8px'
        },
        table_cell_title: {
            color: '#999',
            padding: '0 10px 0 0',
            borderBottom: 'none'
        },
        table_cell_value: {
            padding: '0 10px 0 0',
            borderBottom: 'none'
        },
        loading_card: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
            width: '100%'
        },
        end_run_button: {
            marginBottom: '30px',
            width: '160px'
        }
    })
);
