import colorPalette from "../../Palette";

const ReportPlannedUnplannedChart = (props) => {
    
    const { report } = props;

    let planned;
    let unplanned;

    if (report.planned_downtime_percent === null || report.unplanned_downtime_percent === null) {
        // console.log(123);
        // console.log(report);
        
        
        planned = 0
        unplanned = 0
    }
    else {
        planned = report.planned_downtime_percent
        unplanned = report.unplanned_downtime_percent
    }
    // const unplanned = 100 - planned
    const totalSize = 300
    const maxSize = 160
    const minSize = 80
    let plannedSize = totalSize * planned / 100
    let unplannedSize = totalSize * unplanned / 100
    if (plannedSize < minSize) {
        plannedSize = minSize
    }
    if (plannedSize > maxSize) {
        plannedSize = maxSize
    }
    if (unplannedSize < minSize) {
        unplannedSize = minSize
    }
    if (unplannedSize > maxSize) {
        unplannedSize = maxSize
    }
    
    return (
        <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <div style={{ width: `${plannedSize}px`, height: `${plannedSize}px`, borderRadius: "100%", border: `${planned > unplanned ? "4px solid white" : ""}`, background: colorPalette.primary[100], display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontSize: `${plannedSize / 4}px`, opacity: `${planned > unplanned ? "90%" : "100%"}`, zIndex: `${planned > unplanned ? "20" : "10"}`, position: "absolute", marginTop: `10px`, marginRight: `100px`  }}>
                {planned.toFixed(0)}%
            </div>
            <div style={{ width: `${unplannedSize}px`, height: `${unplannedSize}px`, borderRadius: "100%", border: `${planned > unplanned ? "" : "4px solid white"}`, background: colorPalette.error[60], display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontSize: `${unplannedSize / 4}px`, opacity: `${planned > unplanned ? "100%" : "90%"}`, zIndex: `${planned > unplanned ? "10" : "20"}`, position: "absolute", marginBottom: `120px`, marginLeft: `80px` }}>
                {unplanned.toFixed(0)}%
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", bottom: "28px" }}>
                <div style={{ width: "10px", height: "10px", background: colorPalette.primary[100], borderRadius: "100%", marginRight: "6px" }} />
                <p style={{ fontSize: `16px`, color: colorPalette.neutral[60], marginRight: "24px" }}>Planned</p>
                <div style={{ width: "10px", height: "10px", background: colorPalette.error[60], borderRadius: "100%", marginRight: "6px" }} />
                <p style={{ fontSize: `16px`, color: colorPalette.neutral[60] }}>Unplanned</p>
            </div>
        </div>
    )
}

export default ReportPlannedUnplannedChart;