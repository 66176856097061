import React from 'react';
import Button from '@material-ui/core/Button';
import colorPalette from '../../../../Palette';
const FilterButton = ({ value, handleSelectButton, id, selected, buttonHeight, lineProducts }) => {
    return (
        <Button
            onClick={() => handleSelectButton(id, value)}
            // disabled={!lineProducts}
            variant={selected === id ? 'contained' : 'outlined'}
            style={{
                // width: 136,
                // height: buttonHeight,
                textTransform: 'none',
                boxShadow: 'none',
                color: selected === id ? '#fff' : '#666',
                zIndex: 3,
                width: `128px`,
                height: `36px`,
                fontSize: `14px`,
                // marginBottom: '10px',
                // marginRight: '20px',
                backgroundColor: selected === id ? colorPalette.neutral[80] : 'transparent'
            }}
        >
            {value}
        </Button>
    );
};
export default FilterButton;
