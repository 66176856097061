import { Chart, Doughnut, Pie } from "react-chartjs-2";
import colorPalette from "../../Palette";
import { useState } from "react";
import { formatToHHhMMmSSs, formatToHhMmSs } from "../../newUtils/timeFormatter";

const ReportUtilizationChart = (props) => {
      const { report } = props;

      
        
      const [chartData, setChartData] = useState({ 
          labels: [formatToHhMmSs(report.utilization_active_seconds !== null ? report.utilization_active_seconds : 0), 
                    formatToHhMmSs(report.utilization_inactive_seconds !== null ? report.utilization_inactive_seconds : 0)],
          datasets: [{
            data: [report.utilization_percent !== null ? report.utilization_percent : 50, 
                    report.utilization_percent !== null ? 100 - report.utilization_percent : 50],
            backgroundColor: [
              colorPalette.primary[100],
              colorPalette.neutral[20],
            ],
            borderColor: [
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
            ],
            borderWidth: [0, 0]
      }] })


        
      const pieLabelsLine = {
          id: "pieLabelsLine",
          afterDraw(chart) {
            const {
              ctx,
              chartArea: { width, height },
            } = chart;
  
            const cx = chart._metasets[0].data[0].x;
            const cy = chart._metasets[0].data[0].y;
  
            const sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
  
            chart.data.datasets.forEach((dataset, i) => {
              chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                const { x: a, y: b } = datapoint.tooltipPosition();
  
                const x = 2 * a - cx;
                const y = 2 * b - cy;
  
                // draw line
                const halfwidth = width / 2;
                const halfheight = height / 2;
                const xLine = x >= halfwidth ? x + 20 : x - 20;
                const yLine = y >= halfheight ? y + 20 : y - 20;
  
                const extraLine = x >= halfwidth ? 10 : -10;
  
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
                ctx.fill();
                ctx.moveTo(x, y);
                ctx.lineTo(xLine, yLine);
                ctx.lineTo(xLine + extraLine, yLine);
                // ctx.strokeStyle = dataset.backgroundColor[index];
                ctx.strokeStyle = "black";
                ctx.stroke();
  
                // text
                const textWidth = ctx.measureText(chart.data.labels[index]).width;
                ctx.font = `12px Roboto`;
                // control the position
                const textXPosition = x >= halfwidth ? "left" : "right";
                const plusFivePx = x >= halfwidth ? 5 : -5;
                ctx.textAlign = textXPosition;
                ctx.textBaseline = "middle";
                // ctx.fillStyle = dataset.backgroundColor[index];
                ctx.fillStyle = "black";
  
              //   ctx.fillText(
              //     ((chart.data.datasets[0].data[index] * 100) / sum).toFixed(1) + "%",
              //     xLine + extraLine + plusFivePx,
              //     yLine
              //   );

                ctx.fillText(
                  chart.data.labels[index],
                  xLine + extraLine + plusFivePx,
                  yLine
                );
              });
            });
          },
        };

        return (
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                <div style={{ width: "100%", height: "220px", marginBottom: "38px", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                    <Pie options={{
                            // width: "50%",
                            events: [],
                            maintainAspectRatio: false,
                            layout: {
                                padding: 30,
                            },
                            plugins: {
                                legend: {
                                  display: false,
                                },
                            },
                    }} plugins={[pieLabelsLine]} data={chartData} />
                    <div style={{ position: "absolute", left: "50%", top: "50%", display: "flex", justifyContent: "center", alignItems: "center", transform: "translate(-50%, -50%)", borderRadius: "100%", width: "120px", height: "120px", background: "white"}}>
                      <p style={{ fontSize: `18px`, color: "#878787", textAlign: "center" }}>
                        Total: <p style={{ fontWeight: "600", fontSize: `18px`, color: "#111827", textAlign: "center", marginTop: "6px" }}>{report.utilization_percent ? report.utilization_percent : "0"}%</p>
                      </p>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", bottom: "30px" }}>
                  <div style={{ width: "10px", height: "10px", background: colorPalette.neutral[30], borderRadius: "100%", marginRight: "10px" }} />
                  <p style={{ fontSize: `16px`, color: "#878787", marginRight: "24px" }}>Line Not Operating</p>
                  <div style={{ width: "10px", height: "10px", background: colorPalette.primary[100], borderRadius: "100%", marginRight: "10px" }} />
                  <p style={{ fontSize: `16px`, color: "#878787" }}>Line Operating</p>
                </div>
            </div>
        )
}

export default ReportUtilizationChart;