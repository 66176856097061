import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import colorPalette from "../../../Palette";
import ReportsCard from "../../../components/ReportsCard";
import UtilizationDayComponent from "./UtilizationDayComponent";
import { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";

const UtilizationSettings = (props) => {

    const [lines, setLines] = useState([]);
    const [factoryStartTime, setFactoryStartTime] = useState();
    const [factoryStartTimeDate, setFactoryStartTimeDate] = useState();
    const [utilizationSettings, setUtilizationSettings] = useState({});
    const [saveDisabled, setSaveDisabled] = useState(true);

    const getLines = () => {
        let params = {
            path: 'get_lines',
            success: fetchLinesSuccess,
            error: fetchLinesError
        };

        API.c(params);
    };

    const fetchLinesSuccess = data => {
        getFactory(data);
        setLines(data);
    };

    const fetchLinesError = error => {
        console.log(error);
    };

    const getFactory = (lines) => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => fetchFactorySuccess(data, lines),
            error: fetchFactoryError
        };

        API.c(params);
    };

    const fetchFactorySuccess = (data, lines) => {
        // console.log("factory: ", data);
        let newDate = new Date()
        newDate.setHours(data.start_hour_of_day_local_tz);
        newDate.setMinutes(data.start_minute_of_day_local_tz);
        newDate.setSeconds('00');
        getUtilizationSettings(lines, moment(newDate).format('HH[:]mm[:]ss'));
        setFactoryStartTimeDate(newDate);
        setFactoryStartTime(moment(newDate).format('HH[:]mm[:]ss'));
    };

    const fetchFactoryError = error => {
        console.log(error);
    };

    const getUtilizationSettings = (lines, factoryStartTime) => {
        let params = {
            path: 'get_utilization_settings',
            success: (data) => fetchUtilizationSettingsSuccess(data, lines, factoryStartTime),
            error: fetchUtilizationSettingsError
        };

        API.c(params);
    }

    const fetchUtilizationSettingsSuccess = (data, lines, factoryStartTime) => {
        
        if (_.isEmpty(data.factory_default_line_utilization_settings)) {
            data.factory_default_line_utilization_settings = {
                "is_monday": false,
                "start_time_monday": factoryStartTime,
                "end_time_monday": factoryStartTime,
                "is_tuesday": false,
                "start_time_tuesday": factoryStartTime,
                "end_time_tuesday": factoryStartTime,
                "is_wednesday": false,
                "start_time_wednesday": factoryStartTime,
                "end_time_wednesday": factoryStartTime,
                "is_thursday": false,
                "start_time_thursday": factoryStartTime,
                "end_time_thursday": factoryStartTime,
                "is_friday": false,
                "start_time_friday": factoryStartTime,
                "end_time_friday": factoryStartTime,
                "is_saturday": false,
                "start_time_saturday": factoryStartTime,
                "end_time_saturday": factoryStartTime,
                "is_sunday": false,
                "start_time_sunday": factoryStartTime,
                "end_time_sunday": factoryStartTime,
                "is_factory_default": true
            }
        }
        if (data.line_utilization_settings.length === 0) {
            lines.forEach(line => {
                data.line_utilization_settings.push({
                    "line": line.id,
                    "is_active": false,
                    "is_factory_default": false,
                    "is_monday": false,
                    "start_time_monday": factoryStartTime,
                    "end_time_monday": factoryStartTime,
                    "is_tuesday": false,
                    "start_time_tuesday": factoryStartTime,
                    "end_time_tuesday": factoryStartTime,
                    "is_wednesday": false,
                    "start_time_wednesday": factoryStartTime,
                    "end_time_wednesday": factoryStartTime,
                    "is_thursday": false,
                    "start_time_thursday": factoryStartTime,
                    "end_time_thursday": factoryStartTime,
                    "is_friday": false,
                    "start_time_friday": factoryStartTime,
                    "end_time_friday": factoryStartTime,
                    "is_saturday": false,
                    "start_time_saturday": factoryStartTime,
                    "end_time_saturday": factoryStartTime,
                    "is_sunday": false,
                    "start_time_sunday": factoryStartTime,
                    "end_time_sunday": factoryStartTime,
                })
            })
        }
        setUtilizationSettings(data);
    }

    const fetchUtilizationSettingsError = error => {
        console.log(error);
    }

    const onCheckDefault = (day) => {
        let newUtilizationSettings = { ...utilizationSettings };
        newUtilizationSettings.factory_default_line_utilization_settings[day] = !newUtilizationSettings.factory_default_line_utilization_settings[day];
        setUtilizationSettings(newUtilizationSettings);
        setSaveDisabled(false);
    }

    const onChangeDefaultTime = (field, value) => {
        let newUtilizationSettings = { ...utilizationSettings };
        newUtilizationSettings.factory_default_line_utilization_settings[field] = moment(value).format('HH[:]mm[:]ss');
        setUtilizationSettings(newUtilizationSettings);
        setSaveDisabled(false);
    }

    const onCheckLine = (id, day) => {
        let newUtilizationSettings = { ...utilizationSettings };
        let line = newUtilizationSettings.line_utilization_settings[id][day] = !newUtilizationSettings.line_utilization_settings[id][day];
        setUtilizationSettings(newUtilizationSettings);
        setSaveDisabled(false);
    }

    const onChangeLineTime = (id, field, value) => {
        let newUtilizationSettings = { ...utilizationSettings };
        newUtilizationSettings.line_utilization_settings[id][field] = moment(value).format('HH[:]mm[:]ss');
        setSaveDisabled(false);
    }

    const onSaveChanges = () => {
        let params = {
            
            path: 'update_utilization_settings',
            data: utilizationSettings,
            success: (data) => setSaveDisabled(true),
            error: (error) => console.log(error)
        }

        API.c(params, 2)
    }

    useEffect(() => {
        getLines();
        // getUtilizationSettings();
    }, [])


    return (
        <div>
            <div style={{ padding: "32px", width: "96.5%", borderBottom: "1px solid #E3E7ED" }}>
                <p style={{ fontSize: "32px", fontWeight: "500", color: colorPalette.neutral[100] }}>
                    Utilization Settings
                </p>
                <div style={{ display: "flex", marginTop: "12px", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <p style={{ marginTop: "8px", fontSize: "14px", fontWeight: "400", color: colorPalette.neutral[80], width: "38vw", }}>
                        <p style={{ lineHeight: "16px" }}>
                            Use this page to manage timeframes for each production line to ensure accurate utilization metrics. You can set uniform timeframes for the entire factory or customize them for individual lines.
                        </p>
                        <p style={{ marginTop: "12px" }}>
                            Note that utilization periods must exist within the factory day, which for your facility starts at: <span style={{ fontWeight: "600" }}>{moment(factoryStartTimeDate).format("H:mm A")}</span>.
                        </p>
                    </p>
                    <Button disabled={saveDisabled} onClick={onSaveChanges} color="primary" variant="contained" sx={{ textTransform: "unset" }}>
                        Save Changes
                    </Button>
                </div>
            </div>
            {utilizationSettings.factory_default_line_utilization_settings && factoryStartTime ? (
                <>
                    <div style={{ padding: "32px 32px 24px 32px", width: "96.5%" }}>
                        <ReportsCard mainBoxStyles={{ display: "grid", alignItems: "baseline", gridTemplateAreas: "'a a a a'", columnGap: "43px", width: "97%", padding: "24px" }} height={"400px"} title={"Factory Settings"} size={"large"}>
                            <UtilizationDayComponent day={"Monday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_monday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_monday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_monday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_monday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_monday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_monday} />
                            <UtilizationDayComponent day={"Tuesday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_tuesday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_tuesday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_tuesday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_tuesday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_tuesday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_tuesday} />
                            <UtilizationDayComponent day={"Wednesday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_wednesday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_wednesday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_wednesday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_wednesday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_wednesday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_wednesday} />
                            <UtilizationDayComponent day={"Thursday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_thursday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_thursday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_thursday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_thursday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_thursday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_thursday} />
                            <UtilizationDayComponent day={"Friday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_friday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_friday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_friday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_friday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_friday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_friday} />
                            <UtilizationDayComponent day={"Saturday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_saturday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_saturday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_saturday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_saturday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_saturday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_saturday} />
                            <UtilizationDayComponent day={"Sunday"} factoryStartTime={factoryStartTime} override={true} onChange={() => onCheckDefault("is_sunday")} checked={utilizationSettings.factory_default_line_utilization_settings.is_sunday} handleTimeFromChange={(e) => onChangeDefaultTime("start_time_sunday", e)} time_from={utilizationSettings.factory_default_line_utilization_settings.start_time_sunday} handleTimeToChange={(e) => onChangeDefaultTime("end_time_sunday", e)} time_to={utilizationSettings.factory_default_line_utilization_settings.end_time_sunday} />
                        </ReportsCard>
                    </div>
                    <div style={{ padding: "0px 32px 32px 32px", width: "96.5%", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "24px" }}>
                        {utilizationSettings.line_utilization_settings.map((line, index) => {
                            return (
                                <ReportsCard mainBoxStyles={{ display: "block", padding: "24px 24px 0px 24px", height: "90%" }} height="100%" tooltip="In this module, adjust the timeframes in this specific line to ensure accurate utilization metrics. Setting the timeframe here will overwrite the factory timeframe settings." customButton={
                                    <FormGroup>
                                        <FormControlLabel sx={{ color: line.is_active ? colorPalette.neutral[100] : colorPalette.neutral[60], fontSize: "16" }} control={<Switch checked={line.is_active} onChange={() => { onCheckLine(index, 'is_active'); setSaveDisabled(false)} } />} label={"Override factory settings"} />
                                    </FormGroup>
                                } title={lines.find((item) => line.line === item.id) ? lines.find((item) => line.line === item.id).name : "Line"} size={"large"}>
                                    <UtilizationDayComponent day={"Monday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_monday")} checked={line.is_monday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_monday", e)} time_from={line.start_time_monday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_monday", e)} time_to={line.end_time_monday} />
                                    <UtilizationDayComponent day={"Tuesday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_tuesday")} checked={line.is_tuesday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_tuesday", e)} time_from={line.start_time_tuesday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_tuesday", e)} time_to={line.end_time_tuesday} />
                                    <UtilizationDayComponent day={"Wednesday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_wednesday")} checked={line.is_wednesday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_wednesday", e)} time_from={line.start_time_wednesday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_wednesday", e)} time_to={line.end_time_wednesday} />
                                    <UtilizationDayComponent day={"Thursday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_thursday")} checked={line.is_thursday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_thursday", e)} time_from={line.start_time_thursday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_thursday", e)} time_to={line.end_time_thursday} />
                                    <UtilizationDayComponent day={"Friday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_friday")} checked={line.is_friday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_friday", e)} time_from={line.start_time_friday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_friday", e)} time_to={line.end_time_friday} />
                                    <UtilizationDayComponent day={"Saturday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_saturday")} checked={line.is_saturday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_saturday", e)} time_from={line.start_time_saturday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_saturday", e)} time_to={line.end_time_saturday} />
                                    <UtilizationDayComponent day={"Sunday"} factoryStartTime={factoryStartTime} override={line.is_active} onChange={() => onCheckLine(index, "is_sunday")} checked={line.is_sunday} handleTimeFromChange={(e) => onChangeLineTime(index, "start_time_sunday", e)} time_from={line.start_time_sunday} handleTimeToChange={(e) => onChangeLineTime(index, "end_time_sunday", e)} time_to={line.end_time_sunday} />
                                </ReportsCard>
                            )
                        })}
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default UtilizationSettings;