import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import LiveViewIcon from './icons/LiveViewIcon';
import LogoLiveTracking from './icons/Logolivetracking.svg'
import LogoLiveTrackingText from './icons/Logolivetrackingtext.svg'

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LTIcon from './icons/LTIcon';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import TimelineIcon from '@mui/icons-material/Timeline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

//######## STYLE ########

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 249,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        height: `calc(100%)`
    },
    drawerOpen: {
        width: 249,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        '@global': {
            'div > div > ul > li > a > div': {
                opacity: 1
            },
            'div > div > ul > li > div > div': {
                opacity: 1
            },
            'div > div > ul > li > div > img': {
                opacity: '1 !important'
            }
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: 96,
    },
    paper: {
        top: 0,
        minWidth: 96,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: `calc(100%)`,
        background: '#F5F5F5 !important',
        backgroundColor: '#F5F5F5 !important',
        backgroundPosition: 'center',
        overflowX: 'hidden'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    nested: {
        paddingLeft: theme.spacing(1)
    },
    noHoverListItemRoot: {
        color: '#fff',
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        marginTop: theme.spacing(1)
    },
    listItemRoot: {
        color: '#fff',
        fontWeight: '100',
        border: 'solid 2px transparent',
        borderLeft: 'none',
        paddingLeft: '34px !important',
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        margin: theme.spacing(1, 0),
        height: "56px",
        '&:hover': {
            // border: 'solid 2px #fff',
            // borderLeft: 'none',
            // fontWeight: 'bold',
            // borderTopRightRadius: 12,
            // borderBottomRightRadius: 12,
            // margin: theme.spacing(1, 0)
            backgroundColor: 'rgba(33, 150, 243, 0.04) !important',
        }
    },
    listItemRootLogo: {
        color: '#fff',
        fontWeight: '100',
        border: 'solid 2px transparent',
        borderLeft: 'none',
        paddingLeft: '34px !important',
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        margin: theme.spacing(1, 0),
        height: "56px",
    },
    selectedListItem: {
        backgroundColor: 'rgba(33, 150, 243, 0.08) !important',
        // border: 'solid 2px #fff',
        // borderLeft: 'none',
        fontWeight: 'bold',
        // color: theme.palette.primary.main,
        // borderTopRightRadius: 12,
        // borderBottomRightRadius: 12,
        // margin: theme.spacing(1, 0)
    },
    subListItemRoot: {
        color: '#fff',
        fontWeight: '100',
        border: 'solid 2px transparent',
        borderRight: 'none',
        fontSize: '14px !important',
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        margin: theme.spacing(1, 0),
        '&:hover': {
            border: 'solid 2px #004485',
            borderRight: 'none',
            fontWeight: 'bold',
            fontSize: '14px !important',
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            margin: theme.spacing(1, 0)
        }
    },
    selectedSubListItem: {
        backgroundColor: '#004485 !important',
        border: 'solid 2px #004485',
        borderRight: 'none',
        fontWeight: 'bold',
        fontSize: '14px !important',
        color: '#fff',
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        margin: theme.spacing(1, 0)
    },
    collapse: {
        marginRight: theme.spacing(-1),
        marginLeft: theme.spacing(1),
        padding: 0
    },
    listRoot: {
        marginRight: 0,
        padding: 0,
        height: "calc(100vh - 50px)"
    },
    listText: {
        paddingLeft: '8px',
        opacity: 0,
        transition: "opacity 0.3s ease-in",
        '@global': {
            'span': {
                fontSize: "20px !important",
                fontWeight: "500 !important",
            }
        }
    },
    icon: {
        color: '#fff',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    divider: {
        backgroundColor: '#e0e0e0'
    }
}));

function SideNav(props) {
    const classes = useStyles();
    //######## STATE ########
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [settingsClicked, setSettingsClicked] = useState(false);
    const [usesShifts, setUsesShifts] = useState(false);
    const [userRole, setUserRole] = useState("")

    const factory_id = JSON.parse(window.localStorage.getItem('factory'));

    useEffect(() => {
        setSelected(getSelectedListItem());
        const userRole = JSON.parse(window.localStorage.getItem('factory')).role
        setUserRole(userRole)
        window.addEventListener('click', function(e){   
            if (document.getElementById('drawer_menu').contains(e.target)){
              // Clicked in drawer menu
            } else if (document.getElementById('drawer_sub_menu').contains(e.target)) {
              // Clicked in drawer sub menu
            }
            else {
                setSettingsClicked(false)
                // handleDrawerClose()
            }
          });
    }, []);

    useEffect(() => {
        let params = {
            path: 'get_features_check',
            success: resp => resp ? setUsesShifts(resp.uses_shifts) : 0
        };

        API.c(params, 2);
    }, [factory_id.id]);
    //######## FUNCTIONS ########
    const getSelectedListItem = () => {
        let p = window.location.pathname.split('/');
        switch (p[2]) {
            case 'daily':
                return 1;
            case 'trend':
                return 2;
            case 'products':
                return 4;
            case 'factory':
                return 5;
            case 'lines':
                return 6;
            case 'reasons':
                return 7;
            case 'scoreboard':
                return 8;
            case 'automated-reports':
                return 9;
            case 'notifications':
                return 10;
            case 'shifts':
                return 11;
            case 'reports':
                return 12;
            case 'changelog':
                setSettingsClicked(true);
                return 13;
            case 'utilization':
                return 14;
            // case 'import-export':
            //     setSettingsClicked(true);
            //     return 14;
            case 'scoreboard-settings':
                return 15;
            default:
                switch (p[1]) {
                    case 'liveview':
                        return 0;
                    case 'reports':
                        return 1;
                    case 'user-management':
                        return 3;
                    default:
                        return -1;
                }
        }
    };

    const handleDrawerOpen = () => {
        props.onCollapseClick(false);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        let selected = getSelectedListItem();
        // if (
        //     !(
        //         selected === 3 ||
        //         selected === 4 ||
        //         selected === 5 ||
        //         selected === 6 ||
        //         selected === 7 ||
        //         selected === 9 ||
        //         selected === 10 ||
        //         selected === 11 ||
        //         selected === 12
        //     )
        // ) {
        //     setSettingsClicked(false);
        // }
        if (settingsClicked === false) {
            props.onCollapseClick(true);
            setOpen(false);
        }
    };

    const handleSettingsClick = () => {
        if (settingsClicked) {
            setSettingsClicked(false)
        }
        else {
            setSettingsClicked(true);
        }
    };

    //######## RENDER ########
    return (
        <>
            <Drawer
                id="drawer_menu"
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(classes.paper, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
                PaperProps={{ sx: { transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;", zIndex: "5" } }}
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
            >
                <Box sx={{ width: '100%', height: "100%", maxWidth: 249, bgcolor: '#f5f5f5' }}>
                    <List classes={{ root: classes.listRoot }} sx={{ marginRight: "0px !important" }}>
                        <ListItem classes={{root: classes.listItemRootLogo}} sx={{ marginBottom: "20px", padding: "0px" }} disablePadding disableGutters>
                            {/* <ListItemButton
                                    classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                > */}
                                <ListItemIcon>
                                    <img style={{ margin: "0px 8px 3.5px -4px"}} id="logo" width="30px" src={LogoLiveTracking} />
                                    <img id="logo_livetracking_text" style={{ opacity: "0", transition: "opacity 0.3s ease-in" }} width="100px" src={LogoLiveTrackingText} />
                                </ListItemIcon>
                            {/* </ListItemButton> */}
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 0}
                                to={'/liveview'}
                            >
                                <ListItemIcon>
                                    <LiveViewIcon color="inherit" />
                                </ListItemIcon>
                                <ListItemText className={classes.listText} primary={'Liveview'} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 1}
                                to={'/reports/daily'}
                            >
                                <ListItemIcon>
                                    <EqualizerIcon color={'inherit'} />
                                </ListItemIcon>
                                <ListItemText className={classes.listText} primary={'Daily'} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 2}
                                to={'/reports/trend'}
                            >
                                <ListItemIcon>
                                    <TimelineIcon color={'inherit'} />
                                </ListItemIcon>
                                <ListItemText className={classes.listText} primary={'Trend'} />
                            </ListItemButton>
                        </ListItem>
                            {/*<Fragment>
                                <Collapse
                                    in={open && settingsClicked}
                                    timeout="auto"
                                    className={classes.collapse}
                                    unmountOnExit
                                >
                                    
                                </Collapse>
                            </Fragment> */}
                        {userRole !== "scoreboard" && userRole !== "factory_read_only" && (
                        <ListItem sx={{ position: "absolute", bottom: "0", padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                onClick={handleSettingsClick}
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={
                                    settingsClicked ||
                                    selected === 3 ||
                                    selected === 4 ||
                                    selected === 5 ||
                                    selected === 6 ||
                                    selected === 7 ||
                                    selected === 9 ||
                                    selected === 10 ||
                                    selected === 11 ||
                                    selected === 12 ||
                                    selected === 13 ||
                                    selected === 14 || 
                                    selected === 15
                                }
                            >
                                <ListItemIcon>
                                    <SettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText className={classes.listText} primary={'Settings'} />
                            </ListItemButton>
                        </ListItem>
                        )}
                    </List>
                </Box>
            </Drawer>
            <Drawer
                id="drawer_sub_menu"
                // onMouseEnter={handleDrawerOpen}
                PaperProps={{
                    sx: { marginLeft: "250px", width: "249px", zIndex: "4" }
                }}
                variant="persistent"
                open={open && settingsClicked}>
                <ListItem sx={{ margin: "22px 0px 32px 0px", padding: "0px" }} disablePadding disableGutters>
                    <ListItemIcon sx={{ marginLeft: "40px" }}>
                        <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ padding: "0px", marginLeft: "-20px", opacity: '1 !important' }} className={classes.listText} primary={'Settings'} />
                </ListItem>
                {userRole !== "factory_supervisor" && userRole !== "floor_team" && (
                    <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                        <ListItemButton
                            classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                            selected={selected === 3}
                            to={'/user-management'}
                        >
                            <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Users'} inset />
                        </ListItemButton>
                    </ListItem>
                )}
                <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                    <ListItemButton
                        button
                        classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                        selected={selected === 4}
                        to={'/settings/products'}
                    >
                        <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Products'} inset />
                    </ListItemButton>
                </ListItem>
                {userRole !== "floor_team" && (
                    <Fragment>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 5}
                                to={'/settings/factory'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Factory'} inset />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 6}
                                to={'/settings/lines'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Lines'} inset />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 7}
                                to={'/settings/reasons/downtime'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Reasons'} inset />
                            </ListItemButton>
                        </ListItem>
                        {usesShifts && (
                            <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                                <ListItemButton
                                    classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                    selected={selected === 11}
                                    to={'/settings/shifts'}
                                >
                                    <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Shifts'} inset />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 12}
                                to={'/settings/reports'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Emailed Reports'} inset />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 13}
                                to={'/settings/changelog'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Changelog'} inset />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                selected={selected === 14}
                                to={'/settings/utilization'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Utilization'} inset />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton
                                classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                to={'/settings/scoreboard-settings'}
                            >
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Scoreboard'} inset />
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton button
                                    classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                    selected={selected === 10}

                                    to={"/settings/notifications"}>
                                <ListItemText sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Notifications'} inset/>
                            </ListItemButton>
                        </ListItem>
                        {/* <ListItem sx={{ padding: "0px" }} disablePadding disableGutters>
                            <ListItemButton button
                                    classes={{ selected: classes.selectedListItem, root: classes.listItemRoot }}
                                    selected={selected === 14}

                                    to={"/settings/import-export"}>
                                <ListItemText  sx={{ paddingLeft: "10px", opacity: "1 !important" }} className={classes.listText} primary={'Import / Export'} inset />
                            </ListItemButton>
                        </ListItem> */}
                    </Fragment>
                )}
            </Drawer>
        </>
    );
}

export default withRouter(SideNav);
