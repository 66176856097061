import { useEffect, useState } from "react";
import API from "../API";
import { Chart, Doughnut } from "react-chartjs-2";
import { Chart as Chartjs, ArcElement } from "chart.js";

const ReportEfficiencyChartNew = (props) => {
    const { report } = props;

    Chartjs.register(ArcElement);

    const [chartData, setChartData] = useState()

        let efficiency_percent = report.efficiency_percent ? report.efficiency_percent : 0;
        if (efficiency_percent > 100) {
            efficiency_percent = 100;
        }
        if (efficiency_percent < 0) {
            efficiency_percent = 0;
        }

        efficiency_percent = parseFloat(parseFloat(efficiency_percent).toFixed(1));
        let amt = parseFloat(efficiency_percent).toFixed(1);
        let total;

        if (efficiency_percent >= 100) {
            total = 0;
        } else if (efficiency_percent >= 0) {
            total = 100 - amt;
        } else if (efficiency_percent > -100) {
            total = -100 - amt;
        } else {
            total = 0;
        }

        if (report.efficiency_percent == null) {
            amt = 'N/A';
        }

        let target = report.efficiency_target ? report.efficiency_target : 0;
        // let target = 20;
        let targetLabel = target + '%';

        let targetMet = efficiency_percent > target ? true : false;
        
        let firstSegment, secondSegment, thirdSegment, fourthSegment;

        if (targetMet) {
            firstSegment = target - 0.7;
            secondSegment = 0.7;
            thirdSegment = efficiency_percent - firstSegment - 0.7;
            fourthSegment = 100 - firstSegment - secondSegment - thirdSegment;
        }
        else {
            firstSegment = efficiency_percent;
            secondSegment = target - efficiency_percent;
            thirdSegment = 0.7;
            fourthSegment = 100 - firstSegment - secondSegment - thirdSegment;
        }

    useEffect(() => {
        let targetColor = target ? "#000000" : targetMet ? "#1F9D00" : "#F2F2F2";
        setChartData({ 
            labels: ['Efficiency', 'Left'],
            datasets: [{
              label: 'Efficiency',
              data: [firstSegment, secondSegment, thirdSegment, fourthSegment],
              backgroundColor: [
                '#1F9D00',
                `${targetMet ? targetColor : "#F2F2F2"}`,
                `${targetMet ? "#1F9D00" : targetColor}`,
                '#F2F2F2',
              ],
              borderColor: [
                'rgba(0, 0, 0, 0)',
                'rgba(0, 0, 0, 0)',
                'rgba(0, 0, 0, 0)',
                'rgba(0, 0, 0, 0)',
              ],
              borderWidth: [0, 0, 0, 0]
            }] })
    }, [props])

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <div style={{ position: "relative", width: "300px", paddingBottom: "10%" }}>
                {chartData && <Doughnut 
                                    options={{ 
                                        events: [],
                                        rotation: -90, 
                                        circumference: 180, 
                                        cutout: "85%",
                                        plugins: {
                                            legend: {
                                                display: false,
                                            }
                                        }
                                    }} data={chartData} />}
                <div style={{ position: "absolute", marginInline: "auto", left: 0, right: 0, bottom: "32%" }}>
                    <p style={{ fontSize: `16px`, marginBottom: `4px`, color: "#878787", textAlign: "center" }}> Actual OEE: </p>
                    <p style={{ fontSize: `45px`, color: "#1F9D00", textAlign: "center" }}> {report.efficiency_percent ? parseFloat(parseFloat(report.efficiency_percent).toFixed(1)) : 0}% </p>
                </div>
            </div>
            {target ? (
                <div style={{ position: "absolute", marginInline: "auto", justifyContent: "center", display: "flex", alignItems: "center", marginRight: "10px", left: 0, right: 0, bottom: "36px" }}>
                    <div style={{ width: "10px", height: "10px", background: "#000", borderRadius: "100%", marginRight: "10px" }} />
                    <p style={{ fontSize: `16px`, color: "#878787" }}>Target: <span style={{ fontWeight: "600", color: "#111827" }}>{targetLabel}</span></p>
                </div>
            ) : null}
        </div>
    );
};

export default ReportEfficiencyChartNew;