import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';

const displayBasicGraphTwoLines = (lines, onChangeData, templateInfo, metrics, inputData, templateError) => {
    const [data, setData] = useState(inputData)

    useEffect(() => {
        setData(inputData)
    }, [inputData])

    const getError = (cell, position) => {
        let error = false;
        let selectedKpi = data && data[cell] && data[cell][`position${position}`]
        if (data && data.length > 0 && data[cell]) {
            for (const [key, value] of Object.entries(data[cell])) {
                if (typeof key === "string" && key !== `position${position}`) {
                    if (value && selectedKpi && value === selectedKpi) {
                        error = true
                    }
                }
            }
        }
        return error
    }

    const getKpiError = (cell, position) => {
        if (data && data.length > 0 && data[cell] && !data[cell][`position${position}`]) {
            return true
        }
        return false
    }

    const getLineError = (cell) => {
        if (data && data.length > 0 && data[cell] && !data[cell].line) {
            return true
        }
        return false
    }


    return (
        <div style={{ display: "flex" }}>
            <div style={{ borderRight: "1px solid #d3d3d3" }}>
                <div>
                    <FormControl error={templateError && getLineError(0)} variant="standard" sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                        <InputLabel shrink={data[0] && data[0].line ? true : false} sx={{ fontWeight: "600" }}>Select Line</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"0-line"}
                            value={data[0] && data[0].line ? data[0].line : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                            sx={{ fontWeight: "600" }}
                        >
                            {lines.map((line, i) => {
                                return (
                                    <MenuItem value={line.id}>{line.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="standard" error={getError(0, 1) || (templateError && getKpiError(0, 1))} sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                        <InputLabel shrink={data[0] && data[0].position1 ? true : false}>Select KPI #1</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"0-position1"}
                            value={data[0] && data[0].position1 ? data[0].position1 : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            {templateInfo && templateInfo.position1 ? templateInfo.position1.map((kpi, i) => {
                                return (
                                    <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                )
                            }) : null}
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="standard" error={getError(0, 2) || (templateError && getKpiError(0, 2))} sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                        <InputLabel shrink={data[0] && data[0].position2 ? true : false}>Select KPI #2</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"0-position2"}
                            value={data[0] && data[0].position2 ? data[0].position2 : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            {templateInfo && templateInfo.position2 ? templateInfo.position2.map((kpi, i) => {
                                return (
                                    <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                )
                            }) : null}
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: "flex" }}>
                    <FormControl variant="standard" error={getError(0, 3) || (templateError && getKpiError(0, 3))} sx={{ m: 1, minWidth: 143, maxWidth: 143 }}>
                        <InputLabel shrink={data[0] && data[0].position3 ? true : false}>Hourly Graph KPI</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"0-position3"}
                            value={data[0] && data[0].position3 ? data[0].position3 : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            {templateInfo && templateInfo.position3 ? templateInfo.position3.map((kpi, i) => {
                                return (
                                    <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                )
                            }) : null}
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 90, maxWidth: 90 }}>
                        <InputLabel shrink={data[0] && data[0].position3_number_of_items ? true : false}># Of Bars</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"0-position3_number_of_items"}
                            value={data[0] && data[0].position3_number_of_items ? data[0].position3_number_of_items : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={21}>21</MenuItem>
                            <MenuItem value={22}>22</MenuItem>
                            <MenuItem value={23}>23</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div>
                <div>
                    <FormControl error={templateError && getLineError(1)} variant="standard" sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                        <InputLabel shrink={data[1] && data[1].line ? true : false} sx={{ fontWeight: "600" }}>Select Line</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"1-line"}
                            value={data[1] && data[1].line ? data[1].line : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                            sx={{ fontWeight: "600" }}
                        >
                            {lines.map((line, i) => {
                                return (
                                    <MenuItem value={line.id}>{line.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="standard" error={getError(1, 1) || (templateError && getKpiError(1, 1))} sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                        <InputLabel shrink={data[1] && data[1].position1 ? true : false}>Select KPI #1</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"1-position1"}
                            value={data[1] && data[1].position1 ? data[1].position1 : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            {templateInfo && templateInfo.position1 ? templateInfo.position1.map((kpi, i) => {
                                return (
                                    <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                )
                            }) : null}
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="standard" error={getError(1, 2) || (templateError && getKpiError(1, 2))} sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                        <InputLabel shrink={data[1] && data[1].position2 ? true : false}>Select KPI #2</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"1-position2"}
                            value={data[1] && data[1].position2 ? data[1].position2 : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            {templateInfo && templateInfo.position2 ? templateInfo.position2.map((kpi, i) => {
                                return (
                                    <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                )
                            }) : null}
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: "flex" }}>
                    <FormControl variant="standard" error={getError(1, 3) || (templateError && getKpiError(1, 3))} sx={{ m: 1, minWidth: 143, maxWidth: 143 }}>
                        <InputLabel shrink={data[1] && data[1].position3 ? true : false}>Hourly Graph KPI</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"1-position3"}
                            value={data[1] && data[1].position3 ? data[1].position3 : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            {templateInfo && templateInfo.position3 ? templateInfo.position3.map((kpi, i) => {
                                return (
                                    <MenuItem value={kpi}>{metrics[kpi]}</MenuItem>
                                )
                            }) : null}
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 90, maxWidth: 90 }}>
                        <InputLabel shrink={data[1] && data[1].position3_number_of_items ? true : false}># Of Bars</InputLabel>
                        <Select
                            id="number-of-lines"
                            name={"1-position3_number_of_items"}
                            value={data[1] && data[1].position3_number_of_items ? data[1].position3_number_of_items : null}
                            onChange={(e) => onChangeData("basicGraph", e.target.value, e.target.name)}
                            label="Select Line"
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={21}>21</MenuItem>
                            <MenuItem value={22}>22</MenuItem>
                            <MenuItem value={23}>23</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            {/* <MenuItem value={1}>One</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default displayBasicGraphTwoLines