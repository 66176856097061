import { useEffect, useState } from "react";
import API from "../API";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import colorPalette from "../../Palette";
import { formatToHhMmSs } from "../../newUtils/timeFormatter";

const NewLostTimeDrillDown = (props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [max_duration, setMaxDuration] = useState(1);
    const [parsedData, setParsedData] = useState([]);
    
    
    


    useEffect(() => {
        setLoading(true);
        setError(null);
        setData(null);
        setMaxDuration(1);
        setParsedData([]);
        getReasonRuns();
    }, [props.reason_id, props.reason, props.runs]);

    useEffect(() => {
        // getReasonRuns();
    }, [])

    const getLostTime = r => {
        let lost_time = 0;

        switch (r.downtime_reason_name) {
            case 'Slow Running':
                lost_time = r.total_slow_running_seconds;
                break;
            case 'Waste':
                lost_time = r.total_waste_seconds;
                break;
            case 'Rework':
                lost_time = r.total_rework_seconds;
                break;
            default:
                lost_time = r.total_reason_downtime_seconds;
                break;
        }

        return lost_time;
    };

    const getReasonRuns = () => {
        let params = {
            path: 'get_reason_runs',
            data: {
                reason_id: props.reason_id,
                run_id: props.runs
            },
            success: onGetReasonRunsSuccess,
            error: onGetReasonRunsError
        };
        if (props.reason_id === -1 || (props.reason_id === null && props.reason !== 'Slow Running' && props.reason !== 'Unreported')) {
            onGetReasonRunsError()
        }
        API.c(params, 2);
    };

    const onGetReasonRunsSuccess = data => {
        setError(null);
        
        let info = [...data];
        info.sort((a, b) => {
            return getLostTime(b) - getLostTime(a);
        });

        let arr = [];
        info.forEach(e => {
            arr.push(getLostTime(e));
        });

        let max
        if (arr && arr.length > 0) {
            max = arr.reduce(function (a, b) {
                return Math.max(a, b);
            });
        }
        else {
            setLoading(false);
            setData(null)
            setMaxDuration(1);
            setParsedData([]);
        }
        setLoading(false);
        setData(info);
        setMaxDuration(max);

        let newParsedData = [];

        if (info && info.length > 0) {
            info.forEach(r => {
                if (r.downtime_reason_name === props.reason) {
                    newParsedData.push(r);
                }
            });
        }
        setParsedData(newParsedData);

    };

    const onGetReasonRunsError = err => {
        setLoading(false);
        setError('Error loading runs with selected lost time reason');
    };

    const renderLostTimeBar = r => {
        let duration = 0;

        switch (r.downtime_reason_name) {
            case 'Slow Running':
                duration = r.total_slow_running_seconds;
                break;
            case 'Waste':
                duration = r.total_waste_seconds;
                break;
            case 'Rework':
                duration = r.total_rework_seconds;
                break;
            default:
                duration = r.total_reason_downtime_seconds;
                break;
        }

        let width = (Math.abs(duration) / max_duration) * 100;

        return (
            <div style={{ width: width + '%', height: '24px', borderRadius: "2px", background: duration >= 0 ? colorPalette.error[60] : '#A5CF9B' }}>
                &nbsp;
            </div>
        );
    };

    return (
        <div style={{ padding: "0px 24px 24px 24px", width: "98%", transition: "all 2s ease-out", minHeight: props.open ? "278px" : "0px !important", maxHeight: "278px", display: props.open ? "block" : "none" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "24px", padding: "20px 0px" }}>
                <p style={{ fontWeight: "400" }}>Downtime Details: <span style={{ fontWeight: "500" }}>{props.downtime_category ? props.downtime_category + ' - ' : ''} {props.reason}</span></p>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={props.hideModal} />
            </div>
            <hr style={{ margin: "0px -24px", border: "none", height: "1px", background: "#D9D9D9" }} />
            {loading ? (
                <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
            ) : error ? (
                <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    {/* <p>{error}</p> */}
                    No runs available
                </div>
            ) : !parsedData || parsedData.length === 0 ? (
                <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    No runs available
                </div>
            ) : (
                    <div>
                        <TableContainer sx={{ boxShadow: "none", height: "254px" }} component={Paper}>
                            <Table>
                                <TableHead sx={{ height: "56px" }}>
                                    <TableRow>
                                        <TableCell sx={{ paddingLeft: "8px", color: colorPalette.neutral[100] }}>Date & Time</TableCell>
                                        <TableCell sx={{ paddingLeft: "0px", color: colorPalette.neutral[100] }}>Line</TableCell>
                                        <TableCell sx={{ paddingLeft: "0px", color: colorPalette.neutral[100] }}>Product</TableCell>
                                        <TableCell sx={{ paddingLeft: "0px", color: colorPalette.neutral[100] }}>Lost Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflowY: "auto" }}>
                                    {parsedData.map((r) => {
                                        return (
                                            <TableRow
                                                // key={r.}
                                                onClick={(event) => props.onRowClick(r, event)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { background: "#eee" }, borderRadius: "4px", cursor: "pointer", borderRight: "0px", borderLeft: "0px", boxShadow: "none" }}
                                            >
                                                <TableCell sx={{ paddingLeft: "8px", color: colorPalette.neutral[60] }} component="th" scope="row">
                                                    {Util.formatDateFull(r.run_start_time)} {Util.formatHourMinuteAmPm(r.run_start_time)}
                                                </TableCell>
                                                <TableCell sx={{ paddingLeft: "0px", color: colorPalette.neutral[60] }}>
                                                    {r.line_name}
                                                </TableCell>
                                                <TableCell sx={{ paddingLeft: "0px", color: colorPalette.neutral[60] }}>
                                                    {r.product_category} {r.product_desc}
                                                </TableCell>
                                                <TableCell  sx={{ display: "flex", alignItems: "center", gap: "8px", paddingLeft: "0px" }}>
                                                    <>
                                                        <p style={{ minWidth: "88px", color: colorPalette.neutral[60] }}>
                                                            {formatToHhMmSs(getLostTime(r))}
                                                        </p>
                                                    </>
                                                    <>
                                                        {renderLostTimeBar(r)}
                                                    </>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )
            }
        </div>
    )
}

export default NewLostTimeDrillDown;