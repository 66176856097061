import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import colorPalette from '../../../../Palette';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const CheckboxItem = ({
    index,
    id,
    textValue,
    wasChanged,
    data,
    runReportPage,
    handle,
    tempCheckboxes,
    activeShifts,
    filterBy,
    lines,
    updateHistory,
    handleSubmitApply
}) => {
    const [checked, setChecked] = useState(_.includes(activeShifts, String(id)));
    const checks = JSON.parse(localStorage.getItem(`checkedItems${filterBy}`));
    const selectedLines = lines.sort((a, b) => b - a).join(',');
    const firstUpdate = useRef(true);

    const handleChange = () => {
        // записать в localstorage AOIndexes новое значение  и обновить компонент
        if (window.location.href.includes('?run')) {
            _.forEach(checks, (check, i) => {
                checks[i] = false;
            });
        }
        checks[index] = !checked;
        localStorage.setItem(`checkedItems${filterBy}`, JSON.stringify(checks));
        handle(checks, id);
        setChecked(checks[index]);
        wasChanged();
        if (runReportPage && !firstUpdate.current) {
            handleSubmitApply();
        }
    };

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (!window.location.href.includes('?run')) {
            localStorage.removeItem('checkedItemsrunList');
            const newCheckboxes = data.map(() => false);
            localStorage.setItem(`checkedItemsrunList`, JSON.stringify(newCheckboxes));
            updateHistory([]);
            handle(newCheckboxes, null);
        }
    }, [selectedLines]);

    const handleClickBlock = e => {
        e.preventDefault();
        handleChange(e, id);
    };

    useEffect(() => {
        if (!window.location.href.includes('run')) {
            setChecked(_.includes(activeShifts, String(id)));
        }
    }, [activeShifts]);

    useEffect(() => {
        if (window.location.href.includes('run')) {
            let localChecks = JSON.parse(localStorage.getItem(`checkedItems${filterBy}`));
            if (localChecks) {
                setChecked(!!localChecks[index]);
            }
        }
    }, [tempCheckboxes]);

    return (
        <div
            aria-disabled={tempCheckboxes}
            onClick={e => {
                handleClickBlock(e);
            }}
            style={{
                pointerEvents: checked && runReportPage ? 'none' : 'auto',
                border: "none",
                padding: '8px',
                marginRight: '0px',
                marginBottom: "12px",
                borderRadius: '4px',
                
                // marginTop: '10px',
                backgroundColor: colorPalette.neutral[30],
                opacity: _.some(tempCheckboxes) ? (tempCheckboxes[index] ? 1 : 0.7) : 0.7
            }}
        >
            <Box style={{ cursor: 'pointer' }} display="flex">
                <div>
                    <React.Fragment>
                        <Checkbox
                            style={{
                                padding: 0,
                                paddingTop: `4px`,
                                margin: 0,
                                // width: `24px`,
                                // height: `24px`,
                                marginRight: 7,
                                
                                color: colorPalette.neutral[100],
                                "icon": {
                                    width: `24px`,
                                    height: `24px`,
                                }
                            }}
                            name={!runReportPage ? `checkbox-buttons${index}` : null}
                            checked={checked}
                            onChange={handleChange}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </React.Fragment>
                </div>
                <Typography
                    variant="body2"
                    style={{
                        paddingTop: '4px',
                        paddingRight: '3px',
                        fontSize: `16px`,
                        fontWeight: 400,
                        color: colorPalette.neutral[100]
                    }}
                    component="div"
                >
                    {textValue}
                </Typography>
            </Box>
        </div>
    );
};

export default CheckboxItem;
