import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import './checkbox-list.css';
import CheckboxItem from './checkbox-item';
import _ from 'lodash';
import moment from 'moment-timezone';

const updateHistory = ids => {
    let href = window.location.href;
    let replaceHref = href.split('&shift_id')[0];
    _.forEach(ids, id => {
        replaceHref += `&shift_id=${id}`;
    });
    window.history.replaceState(null, 'LiveTracking', replaceHref);
};

const CheckboxList = ({
    wasChanged,
    lines,
    timeZone,
    onGetRunSuccess,
    runLineId,
    runReportPage,
    run_id,
    dates,
    selectItem,
    activeShifts,
    switched,
    handledCheck,
    date_to,
    filterBy,
    handleSubmitApply,
    selectedButton
}) => {
    const [loading, setLoading] = useState(true);
    const [checkboxes, setCheckboxes] = useState(null);
    const [tempCheckboxes, setTempCheckboxes] = useState([]);
    const [error, setError] = useState(null);
    const currentDate = moment.utc().format('YYYY-MM-DD');
    const [wasMounted, setWasMounted] = useState(true);

    useEffect(() => {
        if (runReportPage) {
            if (selectItem > 0) {
                let params = {
                    path: 'shift_for_run',
                    data: {
                        run: run_id,
                        date: moment(dates[selectItem - 1])
                            .tz(timeZone)
                            .format('YYYY-MM-DD')
                    },
                    success: resp => {
                        setCheckboxes(resp);
                        setLoading(false);
                    },
                    error: function (err) {
                        setCheckboxes(null);
                        setError(err.error);
                        setLoading(false);
                    }
                };
                API.c(params);
            }
        }
    }, [selectItem]);

    useEffect(() => {
        const request = async () => {
            let names = [];
            if (!_.isEmpty(lines)) {
                lines.map(lineId => {
                    names.push(`line_id=${lineId}`);
                });
            }

            let params = {};

            if (!runReportPage) {
                params = {
                    path: 'shift',
                    path_variables: {
                        LINE_IDS: names.join('&')
                    },
                    success: function (resp) {
                        const newArr = resp.sort(function compare(a, b) {
                            const datea = moment(`${currentDate} ${a.start_time}`).format('HH:mm:ss');
                            const dateb = moment(`${currentDate} ${b.start_time}`).format('HH:mm:ss');
                            return datea.localeCompare(dateb);
                        });
                        setCheckboxes(newArr);
                        setLoading(false);
                    },
                    error: function (err) {
                        setCheckboxes(null);
                        setError(err.error);
                        setLoading(false);
                    }
                };
                API.c(params);
            }
        };

        request().then(() => {
            wasChanged();
        });
    }, [lines]);

    useEffect(() => {
        if (!window.location.href.includes('run')) {
            updateHistory(activeShifts);
        }
        setWasMounted(false);
    }, [activeShifts]);
    useEffect(() => {
        if (checkboxes) {
            const checkedItemsIndexes = window.location.href.includes('run')
                ? []
                : !!JSON.parse(localStorage.getItem(`checkedItems${filterBy}`))
                ? JSON.parse(localStorage.getItem(`checkedItems${filterBy}`))
                : [];
            const shiftIndexes = [];
            checkboxes.map((i, index) => {
                shiftIndexes.push(i.id);
                if (index === 0 && filterBy === 'runReport') {
                    checkedItemsIndexes.push(true);
                } else {
                    checkedItemsIndexes.push(false);
                }
            });
            localStorage.setItem(`shiftIds${filterBy}`, JSON.stringify(shiftIndexes));
            if (filterBy === 'runList' && !!JSON.parse(localStorage.getItem(`checkedItemsrunList`))) {
                localStorage.setItem(
                    `checkedItems${filterBy}`,
                    JSON.stringify(JSON.parse(localStorage.getItem(`checkedItemsrunList`)))
                );
            } else {
                localStorage.setItem(`checkedItems${filterBy}`, JSON.stringify(checkedItemsIndexes));
            }
            if (filterBy === 'runReport') {
                localStorage.setItem(`checkedItems${filterBy}`, JSON.stringify(checkedItemsIndexes));
            }
            setTempCheckboxes(checkedItemsIndexes);
            if (runReportPage) {
                handleSubmitApply();
            }
        }
    }, [checkboxes]);

    useEffect(() => {
        if (filterBy === 'runReport') {
            localStorage.removeItem(`shiftIds${filterBy}`);
        }
    }, [filterBy]);

    const handleChecked = (temp, id) => {
        setTempCheckboxes(temp);
        if (!window.location.href.includes('run')) {
            handledCheck({ id });
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            <div className="checkbox-list-container">
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '10px'
                    }}
                >
                    {!_.isEmpty(checkboxes) ? (
                        checkboxes.map((item, index) => {
                            return (
                                <CheckboxItem
                                    filterBy={filterBy}
                                    wasChanged={wasChanged}
                                    key={index}
                                    id={item.id}
                                    index={index}
                                    textValue={`${item.name} ${moment(`${currentDate} ${item.start_time}`).format(
                                        'LT'
                                    )} - ${moment(`${currentDate} ${item.end_time}`).format('LT')}`}
                                    data={checkboxes}
                                    runReportPage={runReportPage}
                                    handle={handleChecked}
                                    tempCheckboxes={tempCheckboxes[index]}
                                    activeShifts={activeShifts}
                                    lines={lines}
                                    updateHistory={updateHistory}
                                    handleSubmitApply={handleSubmitApply}
                                />
                            );
                        })
                    ) : (
                        <Typography style={{ color: '#666' }} component={'div'} variant="body2">
                            {error ? error : 'No shifts have been set up for your factory'}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CheckboxList;