import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, MenuItem, TimePicker } from 'material-ui';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { InfoOutlined, AccessTime } from '@material-ui/icons';
import { useEffect } from 'react';
import { FormLabel, Radio, RadioGroup } from '@mui/material';

export default function AddAutoStartEnd(props) {
    const classes = useStyles();

    const {
        label,
        line,
        line_products,
        lineId,
        data
    } = props

    const [selectedDays, setSelectedDays] = useState([])
    const [daysError, setDaysError] = useState(false)
    const [productError, setProductError] = useState(false)
    const [time, setTime] = useState(new Date());
    const [OEE, setOEE] = useState(0)
    const [productType, setProductType] = useState(data && data.length > 0 ? data[0].is_use_previous_run_product ? "previous" : "specific" : "previous")
    const [selectedProduct, setSelectedProduct] = useState((props.line_products && props.line_products.length > 0) ? props.line_products[0].product_id : null)
    const [isDeleteBeingConfirmed, setIsDeleteBeingConfirmed] = useState(false)
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false)
    const [timePickerOpen, seTimePickerOpen] = useState(false)

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const daysOfWeek = [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun',
    ]

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 340,
            height: `${isDeleteBeingConfirmed ? "170px" : label === "Start" ? "435px" : "350px"}`,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
        },
        headerContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "20px",
            marginBottom: "40px"
        },
        headerText: {
            fontSize: "18px",
            fontWeight: "700",
            color: "#5A5D66"
        },
        headerClose: {
            color: "#5A5D66"
        },
        daysSelector: {
            width: "100%",
        },
        selectDaysLabel: {
            fontSize: "14px",
            fontWeight: "500",
            color: "#5A5D66"
        },
        selectTimeLabel: {
            fontSize: "14px",
            fontWeight: "500",
            color: "#5A5D66"
        },
        '& .MuiInputBase-input': {
            width: "400px"
        }
    };

    useEffect(() => {
        if (data && data.length > 0) {
            let newDays = data[0].days_of_the_week.split(",").map((item) => item.substring(0, 3))
            if (data[0].start_time) {
                let newTime = new Date()
                const timeParsed = data[0].start_time.split(":")
                newTime.setHours(timeParsed[0], timeParsed[1], timeParsed[2])
                setTime(newTime)
            }
            else {
                let newTime = new Date()
                const timeParsed = data[0].end_time.split(":")
                newTime.setHours(timeParsed[0], timeParsed[1], timeParsed[2])
                setTime(newTime)
            }
            setSelectedDays(newDays)
            setSelectedProduct(data[0].product)
            setOEE(data[0].minimum_oee_to_delete_run)
        }
    }, [])

    useEffect(() => {
        const input = document.querySelector("#start-picker");
        if (input) {
            input.setAttribute("disabled", "true");
        }
    }, [time])

    const handleClose = () => {
        props.onClose()
    }

    const handleChangeDays = (event) => {
        const {
            target: { value },
        } = event;
        const newDays = typeof value === 'string' ? value.split(',') : value
        if (newDays.length > 0) {
            setDaysError(false)
            setSelectedDays(newDays);
        }
    };

    const handleChangeTime = (newValue) => {
        setTime(newValue.toDate());
    };

    const onMinimumOEEChange = (event) => {
        setOEE(event.target.value)
    }

    const handleChangeProduct = (event) => {
        setSelectedProduct(event.target.value)
        setProductError(false)
    }

    const showProducts = () => {
        if (props.line_products == null) {
            return <div>Loading...</div>;
        }

        return (
            <div style={{ marginTop: "6px" }}>
                <Typography style={styles.selectTimeLabel} variant="caption" component="h6">
                    Product
                </Typography>
                <Box sx={{ marginTop: "2px" }} />
                <TextField
                    select
                    error={productError}
                    helperText={productError ? "Please select a product" : ""}
                    fullWidth={true}
                    value={selectedProduct}
                    onChange={(event) => handleChangeProduct(event)}
                    style={{ width: '340px', marginBottom: "40px" }}
                    disabled={productType === "previous"}
                >
                    {iterateProducts()}
                </TextField>
            </div>
        );
    };

    const iterateProducts = () => {
        if (props.line_products == null || !Array.isArray(props.line_products)) {
            return;
        }
        props.line_products.sort((a, b) => {
            return parseInt(a.product_name) - parseInt(b.product_name)
        })
        // if (props.line_products[0] && props.line_products[0].product_id !== null) {
        //     props.line_products.unshift({ product_desc: "", product_id: null, product_name: "" })
        // }
        return props.line_products.map((item, index) => (
            <MenuItem value={item.product_id} key={'product-' + item.product_id}>
                {item.product_id ? item.product_name + ' - ' + item.product_desc : ''}
            </MenuItem>
        ));
    };

    const onDelete = () => {
        let params = {
            path: label === "Start" ? "delete_auto_start" : "delete_auto_end",
            path_variables: { ID: data[0].id },
            success: () => handleClose()
        };

        API.c(params, 2);
    }

    const onSave = () => {
        let selectedDaysString = ""
        selectedDays.forEach((item) => {
            switch (item) {
                case "Mon":
                    selectedDaysString += "monday,"
                    break
                case "Tue":
                    selectedDaysString += "tuesday,"
                    break
                case "Wed":
                    selectedDaysString += "wednesday,"
                    break
                case "Thu":
                    selectedDaysString += "thursday,"
                    break
                case "Fri":
                    selectedDaysString += "friday,"
                    break
                case "Sat":
                    selectedDaysString += "saturday,"
                    break
                case "Sun":
                    selectedDaysString += "sunday,"
                    break
                default:
                    break
            }
        })
        selectedDaysString = selectedDaysString.slice(0, -1)

        if (selectedDaysString === "") {
            setDaysError(true)
            if (!selectedProduct && label === "Start") {
                setProductError(true)
            }
            return
        }
        if (!selectedProduct && label === "Start") {
            setProductError(true)
            return
        }

        const checkTime = (i) => {
            return (i < 10) ? "0" + i : i;
        }
        const h = checkTime(time.getHours())
        const m = checkTime(time.getMinutes())

        const bodyData = {
            end_time: `${h}:${m}:00`,
            start_time: `${h}:${m}:00`,
            days_of_the_week: selectedDaysString,
            line: lineId,
            minimum_oee_to_delete_run: parseFloat(OEE),
            product: selectedProduct
        }
        if (label === "Start") {
            bodyData.is_use_previous_run_product = productType === "previous"
        }

        if (data && data.length > 0) {
            const factoryId = JSON.parse(window.localStorage.getItem("factory")).id
            const url = `${JSON.parse(window.localStorage.getItem("factory")).url}${label === "Start" ? "/run/autostart/" : "/run/autoend/"}${data[0].id}`
            // const url = label === "Start" ? `http://livetracking-core-staging2.ca-central-1.elasticbeanstalk.com/run/autostart/${data[0].id}` : `http://livetracking-core-staging2.ca-central-1.elasticbeanstalk.com/run/autoend/${data[0].id}`
            const idToken = window.localStorage.getItem('idToken');
            const editAutoStartEnd = async (url, data) => {
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'factory-id': factoryId,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                return await response.json();
            }

            editAutoStartEnd(url, bodyData)
                .then((data) => {
                    handleClose()
                });
        }

        else {

            const factoryId = JSON.parse(window.localStorage.getItem("factory")).id
            const url = `${JSON.parse(window.localStorage.getItem("factory")).url}${label === "Start" ? "/run/autostart" : "/run/autoend"}`
            // const url = label === "Start" ? `http://livetracking-core-staging2.ca-central-1.elasticbeanstalk.com/run/autostart` : `http://livetracking-core-staging2.ca-central-1.elasticbeanstalk.com/run/autoend`
            const idToken = window.localStorage.getItem('idToken');
            const addAutoStartEnd = async (url, data) => {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'factory-id': factoryId,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                return await response.json();
            }

            addAutoStartEnd(url, bodyData)
                .then((data) => {
                    handleClose()
                });
        }
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            // style={{ height: "400px" }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={styles.modalBox}>
                <div style={styles.headerContainer}>
                    <Typography style={styles.headerText} id="modal-modal-title" variant="h6" component="h2">
                        Auto-{label}
                    </Typography>
                    <Box sx={{ cursor: "pointer" }}>
                        <Typography style={styles.headerClose} onClick={handleClose} id="modal-modal-title" variant="h6" component="h2">
                            ✖
                        </Typography>
                    </Box>
                </div>
                {isDeleteBeingConfirmed ? (
                    <div>
                        <Box style={{ marginTop: "55px" }} />
                        <FormControlLabel
                            style={{ margin: "0px 0px" }}
                            control={
                                <Checkbox
                                    checked={isDeleteConfirmed}
                                    onClick={(event) => setIsDeleteConfirmed(event.target.checked)}
                                    style={{ width: "20px" }} />
                            }
                            label={`Do you want to delete this Auto-${label}?`}
                        />
                        <Box style={{ marginTop: "35px" }} />
                        <DialogActions>
                            <Button color="primary"
                                variant="contained" style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={() => setIsDeleteBeingConfirmed(false)} autoFocus>
                                Cancel
                            </Button>
                            <Button color="secondary"
                                variant="contained" disabled={!isDeleteConfirmed} style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={onDelete}>
                                Delete
                            </Button>
                        </DialogActions>
                    </div>
                ) : (
                    <>
                        <Typography style={styles.selectDaysLabel} variant="caption" component="h6">
                            Select {label} Day(s)
                        </Typography>
                        <Box sx={{ marginTop: "2px" }} />
                        <TextField
                            select
                            error={daysError}
                            helperText={daysError ? "Please select at least one day" : ""}
                            fullWidth={true}
                            SelectProps={{
                                multiple: true,
                                value: selectedDays,
                                onChange: handleChangeDays
                            }}
                            style={{ width: '340px', marginBottom: "0px" }}
                        >
                            {daysOfWeek.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={selectedDays.includes(name) ? { backgroundColor: "rgba(0, 0, 0, 0.066)", fontWeight: "600" } : {}}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Box sx={{ marginTop: "20px" }} />
                        <Typography style={styles.selectTimeLabel} variant="caption" component="h6">
                            Select {label} time
                        </Typography>
                        <Box sx={{ marginTop: "0px" }} />
                        <KeyboardTimePicker
                            margin="normal"
                            id="start-picker"
                            ampm={true}
                            value={time}
                            onChange={handleChangeTime}
                            keyboardIcon={<AccessTime />}
                            format="h:mm A"
                            style={{ width: "100%", marginTop: "4px" }}
                            variant="outlined"
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                                'style': { padding: 0 },
                            }}
                        />
                        <Box sx={{ marginTop: "0px" }} />
                        {label === "End" ? (
                            <>
                                <Box sx={{ marginTop: "12px" }} />

                                <div style={{ display: "flex", marginBottom: "50px" }}>
                                    <div style={{ width: "100%" }}>
                                        <Typography style={styles.selectTimeLabel} variant="caption" component="h6">
                                            Minimum OEE, %
                                        </Typography>
                                        <Box sx={{ marginTop: "2px" }} />
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            style={{ width: "100%" }}
                                            value={OEE}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: { min: 0, max: 100 }
                                            }}
                                            onChange={onMinimumOEEChange}
                                        />
                                    </div>
                                    <div style={{ position: "absolute", left: 150 }}>
                                        <Tooltip
                                            disableFocusListener
                                            disableTouchListener
                                            interactive
                                            placement={'left'}
                                            arrow
                                            title={<div>Please enter 0 for the auto-delete feature to be turned off</div>}
                                        >
                                            <InfoOutlined style={{ fontSize: 14 }} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <FormControl>
                                    <FormLabel sx={{ fontSize: "14px", marginTop: "12px" }} id="product-type-radio">Select Product</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="product-type-radio"
                                        defaultValue="previous"
                                        name="product-type-radio-group"
                                        value={productType}
                                        onChange={() => { setProductType(productType === "previous" ? "specific" : "previous") }}
                                    >
                                        <FormControlLabel value="previous" control={<Radio />} label="Use the product from the previous run" />
                                        <FormControlLabel value="specific" control={<Radio />} label="Select from product list" />
                                    </RadioGroup>
                                </FormControl>
                                {showProducts()}
                            </>
                        )}
                        {data && data.length > 0 ? (
                            <Button style={{ position: "absolute", right: "100px", bottom: "20px" }} onClick={() => setIsDeleteBeingConfirmed(true)} color="secondary" variant="contained">Delete</Button>
                        ) : null}
                        <Button style={{ position: "absolute", right: "20px", bottom: "20px" }} onClick={onSave} color="primary" variant="contained">Save</Button>
                    </>
                )}

            </Box>
        </Modal >
    )
}

const useStyles = makeStyles((theme) => ({
    textField: {
        minWidth: '100px'
    }
}))