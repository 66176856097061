import React, { Component } from 'react';
import {
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
    Fab,
    Chip,
} from '@material-ui/core';
import { AddOutlined, ArrowDropDown } from '@material-ui/icons';
import {auth0UserManagementAPIInstance} from "../../components/Auth0API"
import ReactPaginate from 'react-paginate';
import AddScoreboard from './AddScoreboard/AddScoreboard';
import ScoreboardLayoutModal from './ScoreboardLayoutModal';
import Modal from '../../components/Modal';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import FireTvLogo from '../misc/fire-tv-logo.svg';


class ScoreboardSettings extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            scoreboards: [],
            deleteModalOpen: false,
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount() {
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root bold MuiTypography-h5">Scoreboard Settings</h5>`
        document.title = "LiveTracking | Scoreboard settings";
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
        })
        this.getOrgUsers(org);
        this.getLines();
        this.getAllScoreboards();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handlePageClick = data => {
        let selected = data ? data.selected : 0;
        let offset = Math.ceil(selected * 20);
        this.setState({ offset: offset, currentPage: selected });
    };

    getLines = () => {
        let params = {
            path: 'get_lines',
            success: this.fetchLinesSuccess,
            error: () => {}
        };

        API.c(params);
    };

    fetchLinesSuccess = data => {
        this.setState({
            lines: data,
        });
    };

    getAllScoreboards = () => {
        let params = {
            path: 'get_all_scoreboard_templates',
            success: this.onGetAllScoreboardSuccess,
            error: () => {}
        };

        API.c(params);
    }

    onGetAllScoreboardSuccess = (data) => {
        this.setState({
            scoreboards: data
        })
    }

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get("auth0/user/search?organization_id=" + org)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                const copy_factory_users = [...factory_users]
                let selected = copy_factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1]))
                selected.forEach((item, i) => {
                    selected[i] = item.user_id
                })
                this.setState({
                    all_users: res.data,
                    org_users,
                    factory_users: factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1])),
                    selected_users: selected
                })
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    getItemLines = (item) => {
        let itemLines = []
        if (this.state.lines && this.state.lines.length >= 0) {
            item.line_scoreboard_mapping.forEach((lineObject) => {
                const correspondingLineObject = this.state.lines.find((item) => {
                    return item.id === lineObject.line 
                })
                if (correspondingLineObject && correspondingLineObject.name) {
                    itemLines.push(correspondingLineObject.name)
                }
            })
        }
        return itemLines
    }

    actionButton = () => {
        this.setState({
            selectLayoutModalOpen: true
        });
    };

    showActionButton() {
        if (this.state.productDetails) {
            return;
        }
        return (
            <Fab color="secondary" className="settings_fab" onClick={this.actionButton}>
                <AddOutlined />
            </Fab>
        );
    }

    onCloseAddScoreboard = () => {
        this.setState({
            add_scoreboard: false,
            edit_scoreboard: false
        })
        this.getAllScoreboards();
    }

    onClickDeleteScoreboard = (item) => {
        this.setState({
            deletedScoreboard: { id: item.id, name: item.scoreboard_name },
            deleteModalOpen: true
        })
    }

    onDeleteScoreboardSuccess = () => {
        this.setState({
            deleteModalOpen: false,
        })
        this.getAllScoreboards()
    }

    renderChips = chips => {
        // let chips = [];
        // if (
        //     this.state.products_speed &&
        //     this.state.products_speed[0] !== null &&
        //     this.state.lines != null &&
        //     !Util.isEmpty(this.state.lines)
        // ) {
        //     this.state.products_speed.forEach(ps => {
        //         if (ps.product === product_id && ps.is_active) {
        //             chips.push(ps);
        //         }
        //     });
        // }

        // const arrayOfChips = []
        // chips.forEach((item) => {
        //     arrayOfChips.push(item.line)
        // })
        // const isFiltered = this.state.selected_lines.some(item => arrayOfChips.includes(item))
        // if (chips.length > 0 && isFiltered) {
        if (chips.length > 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap' }}>
                    {chips.map(c => {
                        // Conditional to prevent line being undefined when factories are switched
                        // quickly causing product speed call delays
                        // let line = null;
                        // if (!Util.isEmpty(this.state.lines)) {
                        //     line = this.state.lines.find(l => l.id === c.line);
                        // }
                        // let line_name = line ? line.name : '';
                        return (
                            <Chip
                                key={'chip-' + c}
                                style={{ marginRight: '4px', marginBottom: '4px' }}
                                label={c}
                            />
                        );
                    })}
                </div>
            );
        }
    };

    renderChildren = () => {
        let pageCount = Math.ceil(this.state.scoreboards.length / 20);
        return (
        
            <div>
                {this.state.deleteModalOpen ? (
                    <Modal title={"Delete Scoreboard"} hideModal={() => this.setState({deleteModalOpen: false})}>
                        <DeleteConfirmationModal
                            hideModal={() => this.setState({deleteModalOpen: false})}
                            modified={() => this.onDeleteScoreboardSuccess()}
                            item_deleted_text={this.state.deletedScoreboard.name}
                            delete_path="delete_scoreboard_template"
                            id={this.state.deletedScoreboard.id}
                        />
                    </Modal>
                ) : (
                    null
                )}
                {this.showActionButton()}
                <div className="settings__table">
                    <Table>
                        <TableHead>
                            <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                >
                                    <div>Scoreboard name</div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                    style={{ width: "10%" }}
                                >
                                    <div>Scoreboard Layout</div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                >
                                    <div>Selected Line</div>
                                </TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.showScoreboards()}</TableBody>
                    </Table>
                </div>
                { this.state.selectLayoutModalOpen ? <ScoreboardLayoutModal onNext={(selectedLayoutIndex) => { this.setState({ selectLayoutModalOpen: false, selectedLayout: selectedLayoutIndex, add_scoreboard: true }) }} onHide={() => this.setState({ selectLayoutModalOpen: false })} /> : null }
            </div>

        )
    }

    showScoreboards = () => {
        return this.state.scoreboards.map((item, index) => {
            return (
                <TableRow key={'log-' + index}>
                    <TableCell style={{ width: "25%" }} align={'left'} className={'logs_table_body_cell'}>
                        {item.scoreboard_name}
                    </TableCell>
                    <TableCell style={{ width: "25%" }} align={'left'} className={'logs_table_body_cell'}>
                        {item.template_name}
                    </TableCell>
                    <TableCell style={{ width: "25%" }} align={'left'} className={'logs_table_body_cell'}>
                        {this.renderChips(this.getItemLines(item))}
                    </TableCell>
                    <TableCell align="right">
                        <div>
                            <Button
                                variant="text"
                                className={'settings-line-table-button'}
                                color="primary"
                                onClick={() => { this.setState({ edit_scoreboard: true, editedScoreboard: item }) }}
                            >
                                EDIT
                            </Button>
                            <Button
                                variant="text"
                                className={'settings-line-table-button'}
                                color="secondary"
                                disabled={false}
                                onClick={() => this.onClickDeleteScoreboard(item)}
                            >
                                DELETE
                            </Button>
                        </div>
                    </TableCell>
                </TableRow>
            )
        })
    };

    render() {
        if (this.state.add_scoreboard) {
            return (
                <div style={{ marginTop: '20px' }}>
                    <div>
                        <AddScoreboard
                        edit={false}
                        selectedLayout={this.state.selectedLayout}
                        onClose={this.onCloseAddScoreboard} />
                    </div>
                </div>
            )
        }
        if (this.state.edit_scoreboard) {
            console.log(this.state.editedScoreboard);
            return ( 
                <div style={{ marginTop: '20px' }}>
                    <div>
                        <AddScoreboard
                        edit={true}
                        selectedLayout={this.state.editedScoreboard.template_front_end_identifier}
                        scoreboard={this.state.editedScoreboard}
                        onClose={this.onCloseAddScoreboard} />
                    </div>
                </div>
            )
        }
        return <div style={{ marginTop: '20px' }}>
            <div>
                <div style={{ position: "relative" }}>
                    <div style={{ display: "flex", alignItems: "center", padding: "32px 32px 0px 32px" }}>
                        <img src={FireTvLogo} />
                        <div style={{ marginLeft: "24px" }}>
                            <p style={{ fontSize: "16px", fontWeight: "400", marginBottom: "10px", color: "#111827" }}>
                                We’re excited to announce the release of our new Smart TV app for Amazon Firestick!
                            </p>
                            <p style={{ fontSize: "14px", fontWeight: "400", marginTop: "10px", color: "#999999" }}>
                                Follow these quick steps to set it up in your factory.
                            </p>
                        </div>
                    </div>
                    <p style={{ position: "absolute", right: "114px", top: "72px", fontSize: "12px"  }}> For detailed steps, please consult the <a target='_blank' href="https://livetracking.io">FAQ page</a>. </p>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "32px 114px" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "14vw", maxWidth: "194px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: "12px", marginBottom: "16px", background: "#0A71CE", borderRadius: "100%", color: "white", width: "24px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                1
                            </p>
                            <p style={{ marginBottom: "8px", fontSize: "14px", fontWeight: "500", color: "rgba(0, 0, 0, 0.87)" }}>
                                Set Up the Scoreboards via Settings
                            </p>
                            <p style={{ fontSize: "10px", fontWeight: "400", color: "rgba(0, 0, 0, 0.5)" }}>
                                Configure your scoreboards using the tool below.
                            </p>
                        </div>
                        <hr style={{ width: "10vw" }} />
                        <div style={{ display: "flex", flexDirection: "column", width: "14vw", maxWidth: "194px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: "12px", marginBottom: "16px", background: "#0A71CE", borderRadius: "100%", color: "white", width: "24px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                2
                            </p>
                            <p style={{ marginBottom: "8px", fontSize: "14px", fontWeight: "500", color: "rgba(0, 0, 0, 0.87)" }}>
                                Purchase and Set Up the Firestick 4K
                            </p>
                            <p style={{ fontSize: "10px", fontWeight: "400", color: "rgba(0, 0, 0, 0.5)" }}>
                                Order a Firestick 4K from Amazon. Plug it into your display and follow the instructions to connect to your factory's Wi-Fi.
                            </p>
                        </div>
                        <hr style={{ width: "10vw" }} />
                        <div style={{ display: "flex", flexDirection: "column", width: "14vw", maxWidth: "194px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: "12px", marginBottom: "16px", background: "#0A71CE", borderRadius: "100%", color: "white", width: "24px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                3
                            </p>
                            <p style={{ marginBottom: "8px", fontSize: "14px", fontWeight: "500", color: "rgba(0, 0, 0, 0.87)" }}>
                                Download the LiveTracking App
                            </p>
                            <p style={{ fontSize: "10px", fontWeight: "400", color: "rgba(0, 0, 0, 0.5)" }}>
                                Search for "LiveTracking" in the Amazon App Store on the Firestick and download it.
                            </p>
                        </div>
                        <hr style={{ width: "10vw" }} />
                        <div style={{ display: "flex", flexDirection: "column", width: "14vw", maxWidth: "194px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                            <p style={{ fontSize: "12px", marginBottom: "16px", background: "#0A71CE", borderRadius: "100%", color: "white", width: "24px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                4
                            </p>
                            <p style={{ marginBottom: "8px", whiteSpace: "pre-wrap", fontSize: "14px", fontWeight: "500", color: "rgba(0, 0, 0, 0.87)" }}>
                                {`Log in and \n Configure`}
                            </p>
                            <p style={{ fontSize: "10px", fontWeight: "400", color: "rgba(0, 0, 0, 0.5)" }}>
                                Open the app, log in with your LiveTracking credentials, and select the appropriate scoreboard for that display.
                            </p>
                        </div>
                    </div>
                </div>
                {this.renderChildren()}
            </div>
        </div>
    }
}

export default ScoreboardSettings